// @flow

import React from 'react';
import type { Node } from 'react';

import './guide-item.scss';

type Props = {
  title: Node,
  children: Node,
}

const GuideItem = ({ title, children }: Props) => (
  <div className="guide-item">
    <strong className="text_lg">
      {title}
    </strong>
    <div className="guide-item__content">
      {children}
    </div>
  </div>
);

export default GuideItem;
