// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';

import {
  Button,
  ButtonLoading,
} from '@setapp/ui-kit';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroupButton from 'react-bootstrap/lib/InputGroupButton';
import InfoButtonWithTooltip from 'components/shared/info-button/info-button-with-tooltip';

import { addSuccessNotification, addDangerNotification } from 'state/notifier/notifier-actions';
import { fetchPersonalKey } from 'state/jamf/jamf-actions';
import { getJamf } from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';

import type { ModalType } from 'components/modals';

import './personal-key.scss';

type Props = {
  fetchPersonalKey: () => Promise<any>,
  jamfKey: string,
  isJamfProcessing: boolean,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  showModal: (ModalType) => any,
}

class PersonalKey extends PureComponent<Props> {
  componentDidMount() {
    const { fetchPersonalKey } = this.props;

    return fetchPersonalKey();
  }

  render() {
    const { jamfKey, isJamfProcessing } = this.props;

    return (
      <>
        <p>
          <FormattedMessage
            id="jamfGuide.personalKey.description"
            defaultMessage="The key is used to authenticate new members in a Setapp team. Add the key to the config file when building a distribution package."
          />
        </p>
        <p className="personal-key__label">
          <FormattedMessage
            id="jamfGuide.personalKey.label"
            defaultMessage="Your team key:"
          />
        </p>
        <InputGroup>
          <FormControl
            readOnly
            id="jamf-key"
            value={jamfKey}
          />
          <InputGroupButton>
            <ButtonLoading
              isLoading={isJamfProcessing}
              variant="primary-outline"
              onClick={this.onCopyKeyClick}
            >
              <FormattedMessage
                id="jamfGuide.personalKey.copyButton"
                defaultMessage="Copy"
              />
            </ButtonLoading>
          </InputGroupButton>
        </InputGroup>
        <Button
          className="jamf-guide__button personal-key__reissue-button"
          size="sm"
          variant="link"
          onClick={this.onReissueClick}
        >
          <FormattedMessage
            id="jamfGuide.personalKey.reissueButton"
            defaultMessage="Reissue key"
          />
        </Button>
        <InfoButtonWithTooltip>
          <span className="personal-key__tooltip-content text_sm">
            <FormattedMessage
              id="jamfGuide.personalKey.reissueHint"
              defaultMessage="Issuing a new key will make it impossible to sign up for those members who already received Setapp with your previous key."
            />
          </span>
        </InfoButtonWithTooltip>
      </>
    );
  }

  onReissueClick = () => {
    const { showModal } = this.props;

    showModal('REVOKE_JAMF_KEY');
  };

  onCopyKeyClick = () => {
    const {
      jamfKey,
      addSuccessNotification,
      addDangerNotification,
    } = this.props;
    const isLinkCopied = copy(jamfKey);

    if (isLinkCopied) {
      addSuccessNotification({
        message: (
          <FormattedMessage
            id="jamfGuide.personalKey.copyNotifications.success"
            defaultMessage="Key was added to the clipboard"
          />
        ),
      });
    } else {
      addDangerNotification({
        message: (
          <FormattedMessage
            id="jamfGuide.personalKey.copyNotifications.error"
            defaultMessage="Oh, something went wrong. Please copy the key manually"
          />
        ),
      });
    }
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  jamfKey: getJamf(state).key,
  isJamfProcessing: getJamf(state).isLoading,
});

const mapActionsToProps = {
  fetchPersonalKey,
  addSuccessNotification,
  addDangerNotification,
  showModal,
};

export { PersonalKey as PurePersonalKey };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PersonalKey);
