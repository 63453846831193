import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { getSupportUrl } from 'utils/support';

import supportLogo from './support.svg';


const SupportIconLink = () => (
  <a href={getSupportUrl()} target="_blank" className="icon-link" rel="noopener noreferrer">
    <span className="icon-link__svg">
      <InlineSvg src={supportLogo} />
    </span>
    <p className="icon-link__text">
      <FormattedMessage id="linkIcons.contactSupport" defaultMessage="Contact support" />
    </p>
  </a>
);

export default SupportIconLink;
