// @flow

import analytics, { events } from 'utils/analytics';
import type { PaddleCheckoutEvent } from './paddle-checkout-events';


export default function trackEvent(paddleEvent: PaddleCheckoutEvent): boolean {
  switch (paddleEvent.event) {
    case 'Checkout.Location.Submit': {
      analytics.trackEvent(events.PAYMENT_DETAILS_COUNTRY_SELECT, {
        eventLabel: paddleEvent.eventData.country,
      });

      return true;
    }

    case 'Checkout.PaymentMethodSelected': {
      analytics.trackEvent(events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT, {
        eventLabel: paddleEvent.eventData.payment_method,
      });

      return true;
    }

    case 'Checkout.Error': {
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR, {
        eventLabel: paddleEvent.eventData.error,
      });

      return true;
    }

    default: {
      return false;
    }
  }
}
