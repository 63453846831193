import { defineMessages } from 'react-intl';

export default defineMessages({
  vatNumber: {
    id: 'vat.number',
    defaultMessage: 'VAT number',
  },
  company: {
    id: 'vat.company',
    defaultMessage: 'Company name',
  },
  street: {
    id: 'vat.street',
    defaultMessage: 'Street name',
  },
  address: {
    id: 'vat.address',
    defaultMessage: 'Address details',
  },
  building: {
    id: 'vat.building',
    defaultMessage: 'Building number',
  },
  city: {
    id: 'vat.city',
    defaultMessage: 'City',
  },
  postalCode: {
    id: 'vat.postal',
    defaultMessage: 'Postal code',
  },
});
