// @flow

import * as actionTypes from '../team-plan-action-types';

export default function teamMembersLoading(
  state: Array<number> = [],
  action: actionTypes.TeamPlanAction
): Array<number> {
  switch (action.type) {
    case actionTypes.MODIFY_MEMBER_REQUEST: {
      return [...state, action.payload];
    }
    case actionTypes.MODIFY_MEMBER_SUCCESS: {
      return state.filter((memberId) => memberId !== action.payload);
    }
    case actionTypes.MODIFY_MEMBER_ERROR: {
      return state.filter((memberId) => memberId !== action.payload.memberId);
    }
    default:
      return state;
  }
}
