// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import type { Node } from 'react';
import type { IntlShape } from 'react-intl';

import { AnimatedLogo, Button, Modal as BaseModal } from '@setapp/ui-kit';

import globalMessages from '../../../global-messages';

import BackButton from './back-button/back-button';

import './modal.scss';

type Props = {|
  fullScreen: boolean,
  title: Node,
  show: boolean,
  onHide: ?() => void,
  onExited: ?() => void,
  onGoBack: ?() => void,
  children: ?Node,
  intl: IntlShape,
  qaLabel?: string,
  isLoading: boolean,
  withCancelButton: boolean,
  withBackButton: boolean,
|};

const Modal = (props: Props) => {
  const {
    fullScreen,
    title,
    show,
    onHide,
    onExited,
    onGoBack,
    children,
    intl,
    qaLabel,
    isLoading,
    withCancelButton,
    withBackButton,
  } = props;

  return (
    <BaseModal
      fullScreen={fullScreen}
      isOpen={show}
      onRequestClose={onHide}
      onAfterClose={onExited}
      ariaContentLabelledBy="modal-title"
      data-qa={qaLabel}
    >
      <BaseModal.Header
        onCloseClick={onHide}
        ariaCloseButtonLabel={intl.formatMessage(globalMessages.btnCloseAccessibilityLabel)}
      />
      <BaseModal.Body>
        {withBackButton && onGoBack && <BackButton onClick={onGoBack} />}
        <BaseModal.Title>
          {title}
        </BaseModal.Title>
        {isLoading && (
          <div className="teams-modal__loader">
            <AnimatedLogo animate />
          </div>
        )}
        {!isLoading && (
          <>
            {children}
            {withCancelButton && (
              <Button
                variant="secondary-outline"
                className="teams-modal__cancel-button"
                block
                onClick={onHide}
              >
                <FormattedMessage id="modal.cancelButtonTitle" defaultMessage="Cancel" />
              </Button>
            )}
          </>
        )}
      </BaseModal.Body>
    </BaseModal>
  );
};

Modal.defaultProps = {
  fullScreen: false,
  show: false,
  qaLabel: undefined,
  isLoading: false,
  withCancelButton: false,
  withBackButton: false,
  onHide: undefined,
  onExited: undefined,
  onGoBack: undefined,
};

export { Modal as PureModal };

export default injectIntl(Modal);
