// @flow

export type UserPermission =
  | 'team.management.view'

  | 'team.role.share.team_manager'

  | 'team.member.invite-with-purchase'
  | 'team.member.remove'

  | 'team.member.additional-seat.add'
  | 'team.member.additional-seat.remove'

  | 'team.additional-seat.purchase';

// View team management tab in team cabinet
export const TEAM_MANAGEMENT_VIEW = 'team.management.view';

// Management of roles for team members
export const TEAM_ROLE_SHARE_TEAM_MANAGEMENT = 'team.role.share.team_manager';

// Management of invites for team members
export const TEAM_MEMBER_INVITE_WITH_PURCHASE = 'team.member.invite-with-purchase';
export const TEAM_MEMBER_REMOVE = 'team.member.remove';

// Management of additional seats for team members
export const TEAM_MEMBER_ADDITIONAL_SEAT_ADD = 'team.member.additional-seat.add';
export const TEAM_MEMBER_ADDITIONAL_SEAT_REMOVE = 'team.member.additional-seat.remove';

// Management of additional seats
export const TEAM_ADDITIONAL_SEAT_PURCHASE = 'team.additional-seat.purchase';
