// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import InfoField from 'components/shared/info-field/info-field';
import ActiveMembersCount from 'components/shared/active-members-count/active-members-count';

type Props = {|
  activeMembers: Array<TeamMember>,
|};

const ActiveMembersRow = ({ activeMembers }: Props) => (
  <InfoField
    lightValue
    qaLabel="activeMembersCount"
    title={<FormattedMessage id="subscriptionInfo.membersInTeam" defaultMessage="Members in team" />}
    value={(
      <ActiveMembersCount activeMembers={activeMembers} />
    )}
  />
);

export default ActiveMembersRow;
