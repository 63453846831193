// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import InContentNotification from 'components/shared/in-content-notification/in-content-notification';

const ActiveTillNotification = () => (
  <InContentNotification type="danger">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.activeTill"
      defaultMessage="You have cancelled your subscription. You can use all the apps until the end of your billing period. After that they’ll become unavailable."
    />
  </InContentNotification>
);

export default ActiveTillNotification;
