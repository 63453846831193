// @flow

import React, { Component, createRef } from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import Observer from '@researchgate/react-intersection-observer';
import type { RouterHistory } from 'react-router-dom';

import NotificationsContainer from 'components/shared/notification/notifications-container';
import Footer from 'components/layout/app-layout/footer/footer';
import CookieBanner from 'components/shared/banners/cookie-banner/cookie-banner';

import ModalRoot from './modal-root/modal-root';
import Navigation from './sidebar-navigation/sidebar-navigation';
import PermanentNotificationsContainer from './permanent-notifications-container/permanent-notifications-container';
import AppHeader from './header/app-header';

import './app-layout.scss';

type Props = {
  children: Node,
  contentClasses?: string,
  history: RouterHistory
};

type State = {
  isScrolled: boolean,
  isSidebarOpened: boolean,
  isSidebarClosed: boolean,
};

class AppLayout extends Component<Props, State> {
  scroller = createRef<HTMLDivElement>();

  stopHistoryListening: () => void;

  static defaultProps = {
    contentClasses: '',
  };

  state = {
    isScrolled: false,
    isSidebarOpened: false,
    isSidebarClosed: false,
  };

  componentDidMount() {
    this.listenToHistoryChange();
  }

  componentWillUnmount() {
    this.stopHistoryListening();
  }

  render() {
    const {
      contentClasses,
      children,
    } = this.props;
    const {
      isScrolled,
      isSidebarOpened,
      isSidebarClosed,
    } = this.state;
    const appContentClasses = classnames('app-layout__content', contentClasses);
    const headerClasses = classnames('app-layout__header', {
      'app-layout__header_with-shadow': isScrolled,
    });
    const sidebarClasses = classnames('app-layout__sidebar', {
      'app-layout__sidebar_open': isSidebarOpened,
      'app-layout__sidebar_closed': isSidebarClosed,
    });
    const sidebarOverlayClasses = classnames('app-layout__sidebar-overlay', {
      'app-layout__sidebar-overlay_shown': isSidebarOpened,
      'app-layout__sidebar-overlay_hidden': isSidebarClosed,
    });

    return (
      <div className="app-layout">
        <header className={headerClasses}>
          <AppHeader onOpenSidebarClick={this.openSidebar} />
        </header>
        <div className="app-layout__scrollable" ref={this.scroller}>
          <Observer
            onChange={this.handleScrollableContentIntersection}
            root={this.scroller.current}
            threshold={0}
          >
            <div className="app-layout__scroll-observable" />
          </Observer>
          <main className="app-layout__main">
            <div className={sidebarOverlayClasses} role="presentation" onClick={this.closeSidebar} />
            <aside className={sidebarClasses}>
              <div className="app-layout__sidebar-container">
                <Navigation onCloseNavigation={this.closeSidebar} />
              </div>
            </aside>
            <div className="app-layout__right">
              <div className="app-layout__permanent-notifications">
                <PermanentNotificationsContainer />
              </div>
              <div className="app-layout__temporary-notifications-container">
                <div className="app-layout__temporary-notifications">
                  <NotificationsContainer />
                </div>
              </div>
              <div className={appContentClasses}>
                {children}
              </div>
              <footer className="app-layout__footer">
                <Footer />
              </footer>
            </div>
          </main>
        </div>
        <CookieBanner />
        <ModalRoot />
      </div>
    );
  }

  handleScrollableContentIntersection = (intersection: IntersectionObserverEntry) => {
    this.setState({ isScrolled: !intersection.isIntersecting });
  };

  openSidebar = () => {
    this.setState({
      isSidebarOpened: true,
      isSidebarClosed: false,
    });
  };

  closeSidebar = () => {
    this.setState({
      isSidebarOpened: false,
      isSidebarClosed: true,
    });
  };

  handleHistoryChange = () => {
    if (!this.scroller.current) {
      return;
    }

    this.scroller.current.scrollTop = 0;
  };

  listenToHistoryChange() {
    const { history } = this.props;
    this.stopHistoryListening = history.listen(this.handleHistoryChange);
  }
}

export default AppLayout;
