// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, type RouterHistory } from 'react-router-dom';
import { ButtonClose, Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import analytics, { events } from 'utils/analytics';

import YoungFriendlyLady from '../young-friendly-lady/young-friendly-lady';

import './subscription-already-active-step.scss';

type Props = {
  handleCloseClick: () => void,
  history: RouterHistory,
};

class SubscriptionAlreadyActiveStep extends PureComponent<Props> {
  render() {
    const {
      handleCloseClick,
    } = this.props;

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="subscription-already-active-step__primary-content-wrapper" data-qa="subscriptionAlreadyActiveStep">
            <div className="subscription-already-active-step__close-button subscription-already-active-step__close-button_primary">
              <ButtonClose onClick={handleCloseClick} />
            </div>
            <h3>
              <FormattedMessage
                id="activatePage.subscriptionAlreadyActiveStep.title"
                defaultMessage="Your membership is active"
              />
            </h3>
            <span className="text_lg mt-2">
              <FormattedMessage
                id="activatePage.subscriptionAlreadyActiveStep.subtitle"
                defaultMessage="Thanks for using Setapp for Teams!"
              />
            </span>
            <div className="mt-5">
              <Button
                onClick={this.handleGoToAccountClick}
                block
                data-qa="goToAccountButton"
              >
                <FormattedMessage
                  id="activatePage.subscriptionAlreadyActiveStep.goToAccountButton"
                  defaultMessage="Go to Account"
                />
              </Button>
            </div>
          </div>
        </FullscreenLayout.PrimaryContent>
        <FullscreenLayout.SecondaryContent>
          <div className="subscription-already-active-step__secondary-content-wrapper">
            <div className="subscription-already-active-step__close-button">
              <ButtonClose onClick={handleCloseClick} />
            </div>
            <YoungFriendlyLady />
          </div>
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  handleGoToAccountClick = () => {
    const { history } = this.props;

    analytics.trackEvent(events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_GO_TO_ACCOUNT);

    history.push(urls.subscription);
  }
}

export { SubscriptionAlreadyActiveStep as PureSubscriptionAlreadyActiveStep };

export default withRouter(SubscriptionAlreadyActiveStep);
