// @flow

import React, { Component } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import RequestError from '@setapp/request-error';

import type { Node, Element, ElementRef } from 'react';

import CaptchaField from '../captcha-field/captcha-field';
import CaptchaError from '../captcha-error/captcha-error';

type FormFields = {
  [string]: any,
};

type FieldsErrors = {
  [string]: Node,
}

type State = {
  fields: FormFields,
  fieldsErrors: FieldsErrors,
  formError: Node,
  isProcessing: boolean,
  requireCaptcha: boolean,
};

type ChildrenProps = $Diff<State, {requireCaptcha: boolean}> & {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => Promise<void>,
  onFieldChange: (SyntheticEvent<HTMLInputElement>) => void,
  captcha: ?Element<typeof CaptchaField>,
  formContainer: {
    submitForm: () => Promise<any>,
    setField: (string, string) => void,
    setFieldsErrors: (FieldsErrors) => void,
    setFormError: Node => void,
    setProcessing: boolean => void,
  }
};

type Props = {
  children: ChildrenProps => Element<any>,
  initialValues: FormFields,
  onSubmit: FormFields => Promise<void>,
  validate: ?($Shape<FormFields>) => $Shape<FieldsErrors>,
  onChange: ?($Shape<FormFields>) => void,
};

const CAPTCHA_FIELD_NAME = 'captcha';

class FormContainer extends Component<Props, State> {
  captcha: ?ElementRef<typeof ReCaptcha>;

  static defaultProps = {
    validate: null,
    onChange: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: props.initialValues,
      fieldsErrors: {},
      formError: '',
      isProcessing: false,
      requireCaptcha: false,
    };
  }

  render() {
    const {
      fields,
      fieldsErrors,
      formError,
      isProcessing,
      requireCaptcha,
    } = this.state;
    const { children } = this.props;

    return children({
      fields,
      fieldsErrors,
      formError,
      isProcessing,
      captcha: requireCaptcha ? this.renderCaptchaField() : null,
      onFieldChange: this.onFieldChange,
      onSubmit: this.onSubmit,

      formContainer: {
        submitForm: this.submitForm,
        setField: this.setField,
        setFieldsErrors: this.setFieldsErrors,
        setFormError: this.setFormError,
        setProcessing: this.setProcessing,
      },
    });
  }

  renderCaptchaField() {
    const { fieldsErrors } = this.state;
    const { captcha: captchaError } = fieldsErrors;

    return (
      <CaptchaField
        errorMessage={captchaError}
        onChange={this.onCaptchaChange}
        setCaptchaRef={this.setCaptchaRef}
      />
    );
  }

  onFieldChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.currentTarget;

    if (input.type === 'checkbox') {
      this.setField(input.name, input.checked);
    } else {
      this.setField(input.name, input.value);
    }
  };

  onCaptchaChange = (value: string) => {
    this.setField(CAPTCHA_FIELD_NAME, value);
  };

  onSubmit = (event: SyntheticEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    return this.submitForm();
  };

  submitForm = (): Promise<void> => {
    const { fields, requireCaptcha } = this.state;
    const { validate, onSubmit } = this.props;

    const newState = {
      // remove form error on submitting
      formError: '',
      fieldsErrors: {},
      isProcessing: true,
    };

    if (requireCaptcha && !fields.captcha) {
      newState.fieldsErrors = {
        ...newState.fieldsErrors,
        captcha: <CaptchaError.RequiredMessage />,
      };
    }

    if (validate) {
      // set validation result as fields errors
      newState.fieldsErrors = {
        ...newState.fieldsErrors,
        ...this.filterValidationResult(validate(fields)),
      };
    }

    const isFormValid = Object.keys(newState.fieldsErrors).length === 0;
    newState.isProcessing = isFormValid;

    this.setState(newState);

    if (!isFormValid) {
      return Promise.resolve();
    }

    return onSubmit(fields)
      .then(this.onSubmitSuccess)
      .catch(this.onSubmitError);
  };

  setField = (name: string, value: string | boolean) => {
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          [name]: value,
        },
      }),
      () => {
        const { onChange } = this.props;
        const { fields } = this.state;
        if (onChange) {
          onChange(fields);
        }
      },
    );
  };

  setFieldsErrors = (fieldsErrors: FieldsErrors) => {
    this.setState({ fieldsErrors });
  };

  setFormError = (error: Node) => this.setState({ formError: error });

  setProcessing = (isProcessing: boolean) => {
    this.setState({ isProcessing });
  };

  /**
   * Temporary filtering. Validator returns empty string if there's no field error but this component
   * requires only fields with errors to present in "fieldsErrors" object.
   * Remove when all forms use this component and validator logic is updated
   */
  filterValidationResult(errors: FieldsErrors) {
    return Object.keys(errors).reduce((filteredErrors, fieldName) => {
      if (!errors[fieldName]) {
        return filteredErrors;
      }

      return {
        ...filteredErrors,
        [fieldName]: errors[fieldName],
      };
    }, {});
  }

  onSubmitSuccess = () => {
    this.setState({ isProcessing: false });
  };

  onSubmitError = (error: RequestError) => {
    if (this.captcha) {
      this.captcha.reset();
    }

    const genericError = error.getGenericError();
    const fieldsErrors = this.getRegisteredFieldsErrors(error);
    const captchaError = error.getFieldError(CAPTCHA_FIELD_NAME);

    // Rewrite error texts from the API response with the custom message
    if (captchaError) {
      fieldsErrors.captcha = <CaptchaError error={captchaError} />;
    }

    this.setState((state) => ({
      isProcessing: false,
      fieldsErrors,
      formError: (genericError && genericError.toString())
        || Object.values(this.getExtraFieldsErrors(error)).join(' ')
        || '',
      requireCaptcha: state.requireCaptcha || Boolean(captchaError),
    }));
  };

  getRegisteredFieldsErrors(requestError: RequestError): FieldsErrors {
    const { fields } = this.state;
    const responseErrors = requestError.getSimplifiedErrors();

    return Object.keys(fields).reduce((fieldsErrors, fieldName) => {
      if (!responseErrors.fieldsErrors || responseErrors.fieldsErrors[fieldName] == null) {
        return fieldsErrors;
      }

      return {
        ...fieldsErrors,
        [fieldName]: responseErrors.fieldsErrors[fieldName],
      };
    }, {});
  }

  getExtraFieldsErrors(requestError: RequestError): FieldsErrors {
    const { fields } = this.state;
    const responseErrors = requestError.getSimplifiedErrors();

    if (!responseErrors.fieldsErrors) {
      return {};
    }

    return Object.keys(responseErrors.fieldsErrors)
      // Keep only fields that don't exist in the form and ignore CAPTCHA error as it's handled separately
      .filter((fieldName) => !(fieldName in fields) && fieldName !== CAPTCHA_FIELD_NAME)
      .reduce((fieldsErrors, fieldName) => ({
        ...fieldsErrors,
        [fieldName]: responseErrors.fieldsErrors[fieldName],
      }), {});
  }

  // TODO: use ref forwarding after React is updated to v16.3 S4T-548
  setCaptchaRef = (ref: ElementRef<typeof ReCaptcha>) => {
    this.captcha = ref;
  }
}

export default FormContainer;
