// @flow

import React from 'react';

import { FormField } from '@setapp/ui-kit';

import { injectIntl, defineMessages } from 'react-intl';

import type { IntlShape } from 'react-intl';
import type { Node } from 'react';
import OuterForm from 'components/shared/outer/outer-form/outer-form';

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => any,
  onFieldChange: (SyntheticEvent<HTMLInputElement>) => any,
  isProcessing: boolean,
  fieldsErrors: {
    password: Node,
  },
  formError: Node,
  intl: IntlShape,
};

const messages = defineMessages({
  formTitle: {
    id: 'outer.newPassword.title',
    defaultMessage: 'Reset password',
  },
  formSubTitle: {
    id: 'outer.newPassword.subTitle',
    defaultMessage: 'Enter a new password to reset the old one.',
  },
  submitBtnTitle: {
    id: 'outer.newPassword.submitBtnCaption',
    defaultMessage: 'Set password and sign in',
  },
  passwordLabel: {
    id: 'outer.newPassword.passwordLabel',
    defaultMessage: 'New password',
  },
  passwordPlaceholder: {
    id: 'outer.newPassword.passwordPlaceholder',
    defaultMessage: 'Enter a new password',
  },
});

const NewPasswordForm = (props: Props) => {
  const {
    onFieldChange, onSubmit, intl, fieldsErrors, formError, isProcessing,
  } = props;

  return (
    <OuterForm
      title={intl.formatMessage(messages.formTitle)}
      subTitle={intl.formatMessage(messages.formSubTitle)}
      submitBtnCaption={intl.formatMessage(messages.submitBtnTitle)}
      onSubmit={onSubmit}
      hasLogo
      errorMessage={formError}
      isSubmitProcessing={isProcessing}
    >
      <FormField
        id="new-password"
        type="password"
        name="password"
        label={intl.formatMessage(messages.passwordLabel)}
        placeholder={intl.formatMessage(messages.passwordPlaceholder)}
        helpText={fieldsErrors.password}
        invalid={Boolean(fieldsErrors.password)}
        onChange={onFieldChange}
        autoFocus
        autoComplete="new-password"
      />
    </OuterForm>
  );
};

export { NewPasswordForm };

export default injectIntl(NewPasswordForm);
