// @type

import React from 'react';
import { FormattedMessage } from 'react-intl';

import './page-title.scss';

const SuccessfulRegistrationTitle = () => (
  <div>
    <h1 className="successful-registration__title">
      <FormattedMessage
        id="successfulRegistration.fromDesktop.title"
        defaultMessage="Your account is all set."
      />
    </h1>
    <h5 className="successful-registration__sub-title">
      <FormattedMessage
        id="successfulRegistration.fromDesktop.subTitle"
        defaultMessage="Now you can sign into Setapp on your Mac and explore your new apps!"
      />
    </h5>
  </div>
);

export default SuccessfulRegistrationTitle;
