// @flow

// theme styles should be imported first to avoid overlap with component styles
import '@setapp/ui-kit/styles/theme.scss';

import React, { Component, type Node } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { Location, RouterHistory } from 'react-router-dom';
import { IntlProvider } from 'react-intl-redux';
import queryString from 'query-string';


import { getUser } from 'state/root-reducer';
import AppInitializationError from 'components/shared/app-initialization-error/app-initialization-error';

import analytics from './utils/analytics';
import appcues from './utils/appcues';
import openDesktopAppIfRequired from './utils/desktop-app-opener';
import isMobile from './utils/is-mobile';
import healthMetrics from './utils/health-metrics';

import { setUILocale } from './state/user/user-actions';
import { detectLocale } from './utils/intl';

import './root.scss';

type Props = {
  setUILocale: string => Promise<void>,
  userId: number,
  userEmail: string,
  children: Node,
  location: Location,
  history: RouterHistory,
};

type State = {
  initializationComplete: boolean,
  initializationError: boolean,
};

class Root extends Component<Props, State> {
  state = {
    initializationComplete: false,
    initializationError: false,
  };

  getChildContext() {
    return {
      isMobile: isMobile(navigator.userAgent),
    };
  }

  async componentDidMount() {
    const { cid } = queryString.parse(window.location.search);
    const {
      setUILocale,
      location,
      history,
      userId,
    } = this.props;

    if (process.env.NODE_ENV === 'production') {
      analytics.initialize({
        cid,
        preparePayload: (payload) => {
          const {
            userEmail,
            userId,
          } = this.props;

          return {
            ...payload,
            userEmail,
            userId,
          };
        },
      });
    }

    appcues.initialize({ userId });
    openDesktopAppIfRequired(history, queryString.parse(location.search));
    healthMetrics.initialize();

    try {
      await setUILocale(detectLocale());
    } catch (error) {
      this.setState({ initializationError: true });

      return;
    }

    this.setState({ initializationComplete: true });

    analytics.trackPageView();
  }

  componentDidUpdate(prevProps: Props) {
    const { location: currentLocation } = this.props;
    const { location: previousLocation } = prevProps;
    const isLocationChanged = currentLocation.pathname !== previousLocation.pathname;

    if (isLocationChanged) {
      analytics.trackPageView();
      appcues.updatePage();
    }
  }

  render() {
    const { children } = this.props;
    const {
      initializationComplete,
      initializationError,
    } = this.state;

    if (initializationError) {
      return <AppInitializationError />;
    }

    if (initializationComplete) {
      return (
        <IntlProvider>
          {children}
        </IntlProvider>
      );
    }

    return null;
  }
}

Root.childContextTypes = {
  isMobile: PropTypes.bool,
};

export { Root as PureRoot };

const mapStateToProps = (state) => {
  const { id: userId, email: userEmail } = getUser(state);

  return { userId, userEmail };
};

const mapActionsToProps = { setUILocale };

export default connect(mapStateToProps, mapActionsToProps)(Root);
