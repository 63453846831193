// @flow

import type { TeamMember } from '../team-plan-initial-state';
import * as actionTypes from '../team-plan-action-types';
import type { Subscription } from '../../subscription/subscription-initial-state';

export default function teamMembers(
  state: Array<TeamMember> = [],
  action: actionTypes.TeamPlanAction
): Array<TeamMember> {
  switch (action.type) {
    case actionTypes.REQUEST_SUCCESS: {
      if (!action.payload) {
        return state;
      }

      return action.payload.members;
    }

    case actionTypes.ADD_MEMBER: {
      return [action.payload, ...state];
    }

    case actionTypes.REMOVE_MEMBER: {
      return state.filter((member) => member.id !== action.payload);
    }

    case actionTypes.ADD_DEVICE_TO_MEMBER: {
      return state.map((member) => {
        if (member.id !== action.payload.memberId) {
          return member;
        }

        return {
          ...member,
          additionalSeats: [...member.additionalSeats, action.payload.additionalSeat],
          additionalDevicesCount: member.additionalDevicesCount + 1,
        };
      });
    }

    case actionTypes.REMOVE_DEVICE_FROM_MEMBER: {
      return state.map((member) => {
        if (member.id !== action.payload.memberId) {
          return member;
        }

        return {
          ...member,
          additionalSeats: member.additionalSeats
            .filter((seatSubscription) => seatSubscription.id !== action.payload.subscriptionId),
          additionalDevicesCount: member.additionalDevicesCount - 1,
        };
      });
    }

    default: {
      return state;
    }
  }
}

export const isAnyAdditionalSeatPaymentPending = (state: Array<TeamMember>): boolean => state
  .some(({ additionalSeats }) => additionalSeats && additionalSeats.some(({ paymentPending }) => paymentPending));
export const getOwner = (state: Array<TeamMember>): ?TeamMember => state.find((member) => member.isOwner);
export const getOwnersAdditionalSeats = (state: Array<TeamMember>): Array<Subscription> => {
  const owner = getOwner(state);

  return owner ? owner.additionalSeats : [];
};
