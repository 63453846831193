// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
  onClick(): mixed,
}

const PaidLimit = ({ pricePerMonthPerMember, onClick }: Props) => (
  <>
    <div className="mt-8 mb-8">
      <FormattedMessage
        id="inviteMemberModal.invitationsLimit.paidLimit.description"
        defaultMessage="You’ve reached the limit of invitations. Get more invitations for {pricePerMember}/mo each to invite more members."
        values={{
          pricePerMember: (
            <FormattedPrice
              price={pricePerMonthPerMember.price}
              currency={pricePerMonthPerMember.currency}
            />
          ),
        }}
      />
    </div>
    <Button block onClick={onClick}>
      <FormattedMessage
        id="inviteMemberModal.invitationsLimit.paidLimit.button"
        defaultMessage="Purchase invitations"
      />
    </Button>
  </>
);

export default PaidLimit;
