// @flow

import React from 'react';
import type { Node } from 'react';

import './payment-method-presentation.scss';

type Props = {
  details?: Node,
  logo?: ?Node,
  expires?: ?string,
};

const PaymentMethodPresentation = ({ details, logo, expires }: Props) => (
  <div className="payment-method-presentation">
    <div className="payment-method-presentation__content">
      {logo && <div className="payment-method-presentation__logo">{logo}</div>}
      {details && <div className="payment-method-presentation__details">{details}</div>}
      {expires && <div className="payment-method-presentation__expires">{expires}</div>}
    </div>
  </div>
);

PaymentMethodPresentation.defaultProps = {
  details: null,
  expires: null,
  logo: null,
};

export default PaymentMethodPresentation;
