// eslint-disable-next-line import/no-anonymous-default-export
export default (options = {}) => (next) => (reducer, initialState, enhancer) => {
  const { resetActionType = 'RESET_STATE', preserveState = [] } = options;

  const enhanceReducer = (state, action) => {
    if (action.type !== resetActionType) {
      return reducer(state, action);
    }

    const filteredState = preserveState.reduce((filteredState, key) => {
      if (!state[key]) {
        return filteredState;
      }

      return {
        ...filteredState,
        [key]: state[key],
      };
    }, {});

    return reducer(filteredState, action);
  };

  return next(enhanceReducer, initialState, enhancer);
};
