// @flow

import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import type { Application } from 'state/apps/apps-initial-state';
import {
  fetchApps,
  setRestrictedApplications,
} from 'state/apps/apps-actions';
import {
  getAppsList,
  getApps,
} from 'state/root-reducer';
import { addDangerNotification } from 'state/notifier/notifier-actions';

import { COOKIES_ROOT_DOMAIN } from 'config/app';

import analytics from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';
import FuzzySearcher from 'utils/fuzzy-searcher';

import PageTitle from 'components/shared/page-title/page-title';
import PageDescription from 'components/shared/page-description/page-description';
import DefaultError from 'components/shared/default-error/default-error';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import SearchForm from './search-form/search-form';
import AppsList from './apps-list/apps-list';
import hintImage from './images/hintImage.svg';


import './apps-management-page.scss';


const SKIP_DESCRIPTION_COOKIE_NAME = 'skipAppsManagementDescription';

type Props = {
  apps: Array<Application>,
  fetchApps: () => Promise<void>,
  setRestrictedApplications: (Array<number>) => Promise<void>,
  addDangerNotification: (Node | {message: Node}) => void,
  isAppsProcessing: boolean,
  campaign: ?string,
};

type State = {
  searchQuery: ?string,
  searchedApps: Array<Application>,
  isDescriptionShown: boolean,
};

const AppsSearchOptions = {
  threshold: 0.3,
  keys: [
    {
      name: 'name',
      weight: 0.7,
    },
    {
      name: 'shortDescription',
      weight: 0.3,
    },
  ],
};

class AppsManagementPage extends Component <Props, State> {
  fuzzyAppsSearcher: FuzzySearcher = new FuzzySearcher([], AppsSearchOptions);

  state = {
    searchQuery: null,
    searchedApps: [],
    isDescriptionShown: !Cookies.get(SKIP_DESCRIPTION_COOKIE_NAME),
  };

  static filterAppsByStatus(apps: Array<Application>): {available: Array<Application>, restricted: Array<Application>} {
    return apps.reduce((accumulator, app) => {
      if (app.isRestricted) {
        accumulator.restricted.push(app);
      } else {
        accumulator.available.push(app);
      }

      return accumulator;
    }, { available: [], restricted: [] });
  }

  static getRestrictedAppIds(apps: Array<Application>): Array<number> {
    return apps.reduce((accumulator, app) => {
      if (app.isRestricted) {
        accumulator.push(app.id);
      }

      return accumulator;
    }, []);
  }

  static toggleItemInList(list: Array<number>, targetItem: number): Array<number> {
    if (list.includes(targetItem)) {
      return list.filter((item) => item !== targetItem);
    }

    return [...list, targetItem];
  }

  componentDidMount() {
    const {
      fetchApps,
      addDangerNotification,
    } = this.props;

    fetchApps()
      .catch(() => {
        addDangerNotification({ message: <DefaultError /> });
      });
  }

  componentDidUpdate() {
    const { apps } = this.props;

    if (apps.length) {
      this.fuzzyAppsSearcher.setCollection(apps);
    }
  }

  render() {
    const {
      searchQuery,
      searchedApps,
      isDescriptionShown,
    } = this.state;
    const { apps } = this.props;

    const isEmptySearch = searchQuery && !searchedApps.length;
    const isAppsFetched = apps.length;

    if (!isAppsFetched) {
      return (
        <div className="apps-management-page__animated-logo-container">
          <AnimatedLogo animate />
        </div>
      );
    }

    return (
      <>
        <PageTitle qaLabel="appsManagementPageTitle">
          <FormattedMessage id="appsManagementPage.title" defaultMessage="Apps management" />
        </PageTitle>
        { isDescriptionShown ? (
          <div className="apps-management-page__description">
            <div className="apps-management-page__description-container">
              <div className="apps-management-page__description-content">
                <h4 className="mb-4">
                  <FormattedMessage
                    id="appsManagementPage.description.title"
                    defaultMessage="Hide specific apps"
                  />
                </h4>
                <p className="mb-6">
                  <FormattedMessage
                    id="appsManagementPage.description.line1"
                    defaultMessage="As a team owner, you can restrict access to the apps your team members can use in Setapp. It may come in handy if you think some apps can hurt your team’s productivity, or for security reasons."
                  />
                </p>
                <p className="mb-8">
                  <FormattedMessage
                    id="appsManagementPage.description.line2"
                    defaultMessage="Once you hide an app, your team members will no longer see it in the app list."
                  />
                </p>
                <Button
                  className="apps-management-page__continue-button"
                  onClick={this.handleDescriptionContinueButtonClick}
                >
                  <FormattedMessage
                    id="appsManagementPage.description.continueButton"
                    defaultMessage="Ok, I got it"
                  />
                </Button>
              </div>
              <img
                src={hintImage}
                className="apps-management-page__description-illustration"
                alt="visual example"
              />
            </div>
          </div>
        ) : (
          <>
            <PageDescription>
              <FormattedMessage
                id="appsManagementPage.subTitle"
                defaultMessage="Hide any app from your Team's app list. You can unhide the apps anytime."
              />
            </PageDescription>
            <SearchForm onSubmit={this.searchAppsByQuery} />
            {isEmptySearch ? this.renderEmptySearchState() : this.renderAppsLists()}
          </>
        )}
      </>
    );
  }

  renderAppsLists = () => {
    const {
      available: availableAppsList,
      restricted: restrictedAppsList,
    } = this.getFilteredAppsByStatus();
    const { searchQuery } = this.state;
    const { isAppsProcessing } = this.props;

    return (
      <>
        <div className="mt-4" data-qa="hiddenAppsList">
          <AppsList
            title={(
              <FormattedMessage
                id="appsManagementPage.hiddenAppsList.title"
                defaultMessage="Hidden apps"
              />
            )}
            apps={restrictedAppsList}
            hideOnEmpty={!searchQuery}
            onAppClick={this.toggleAppRestriction}
            isAppsProcessing={isAppsProcessing}
          />
        </div>
        <div className="mt-3" data-qa="allAppsList">
          <AppsList
            title={(
              <FormattedMessage
                id="appsManagementPage.allAppsList.title"
                defaultMessage="All apps"
              />
            )}
            apps={availableAppsList}
            hideOnEmpty={!searchQuery}
            onAppClick={this.toggleAppRestriction}
            isAppsProcessing={isAppsProcessing}
          />
        </div>
      </>
    );
  };

  renderEmptySearchState = () => (
    <>
      <div className="mt-4 text_lg">
        <FormattedMessage
          id="appsManagementPage.emptySearch.title"
          defaultMessage="Search results:"
        />
      </div>
      <div className="mt-1 text_sm" data-qa="emptySearchMessage">
        <FormattedMessage
          id="appsManagementPage.emptySearch.message"
          defaultMessage="Sorry! No apps matched your search"
        />
      </div>
    </>
  );

  toggleAppRestriction = (app: Application) => {
    const { apps } = this.props;
    const restrictedAppIds = AppsManagementPage.getRestrictedAppIds(apps);
    const updatedRestrictedAppIds = AppsManagementPage.toggleItemInList(restrictedAppIds, app.id);

    this.setRestrictedApplications(updatedRestrictedAppIds);

    analytics.trackEvent(
      app.isRestricted ? gaEvents.APPS_MANAGEMENT_UNHIDE_APP : gaEvents.APPS_MANAGEMENT_HIDE_APP,
      { eventLabel: app.name },
    );
  };

  setRestrictedApplications = (ids: Array<number>) => {
    const {
      setRestrictedApplications,
      addDangerNotification,
    } = this.props;
    const { searchQuery } = this.state;

    return setRestrictedApplications(ids)
      .then(() => {
        if (searchQuery && searchQuery.length) {
          this.setSearchedApps(searchQuery);
        }
      })
      .catch(() => {
        addDangerNotification({ message: <DefaultError /> });
      });
  };

  getFilteredAppsByStatus = (): {available: Array<Application>, restricted: Array<Application>} => {
    const { apps } = this.props;
    const {
      searchQuery,
      searchedApps,
    } = this.state;

    return AppsManagementPage.filterAppsByStatus(searchQuery ? searchedApps : apps);
  };

  searchAppsByQuery = (searchQuery: string) => {
    const { searchQuery: prevSearchQuery } = this.state;

    if (searchQuery.length) {
      if (!prevSearchQuery) {
        analytics.trackEvent(gaEvents.APPS_MANAGEMENT_START_SEARCH);
      }

      this.setState({ searchQuery });
      this.setSearchedApps(searchQuery);

      return;
    }

    this.setState({
      searchQuery: null,
      searchedApps: [],
    });
  };

  setSearchedApps = (searchQuery: string) => {
    const searchedApps = this.fuzzyAppsSearcher.search(searchQuery);

    this.setState({ searchedApps });
  }

  handleDescriptionContinueButtonClick = () => {
    Cookies.set(SKIP_DESCRIPTION_COOKIE_NAME, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 });
    analytics.trackEvent(gaEvents.APPS_MANAGEMENT_CLICK_LETS_START);
    this.setState({ isDescriptionShown: false });
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  apps: getAppsList(state),
  isAppsProcessing: getApps(state).isLoading,
});

const mapActionsToProps = {
  fetchApps,
  setRestrictedApplications,
  addDangerNotification,
};

export { AppsManagementPage as PureAppsManagementPage };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AppsManagementPage);
