// @flow

import type { UserState } from './user-initial-state';
import initialState from './user-initial-state';
import * as userActionTypes from './user-actions-types';
import * as userTypes from './user-types';
import * as permissions from './user-permissions';
import type { UserPermission } from './user-permissions';

const user = (state: UserState = initialState, action: userActionTypes.UserAction): UserState => {
  switch (action.type) {
    case userActionTypes.LOGIN: {
      return { ...state, isAuthenticated: true };
    }

    case userActionTypes.SET_USER_INFO: {
      return { ...state, ...action.payload };
    }

    case userActionTypes.LOGOUT: {
      return { ...state, isAuthenticated: false };
    }

    case userActionTypes.SET_USER_PAYMENT_INFO: {
      return { ...state, paymentInfoRequired: action.payload };
    }

    case userActionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case userActionTypes.REQUEST_SUCCESS:
    case userActionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default user;

export const getEmail = (state: UserState): string => state.email;
export const getId = (state: UserState): ?number => state.id;
export const getPermissions = (state: UserState): Array<UserPermission> => state.permissions;
export const getCampaign = (state: UserState): ?string => state.campaign;
export const isAuthenticated = (state: UserState): boolean => state.isAuthenticated;
export const isFree = (state: UserState): boolean => state.type === userTypes.FREE;
export const isEmailConfirmed = (state: UserState): boolean => state.emailConfirmed;
export const isRegistrationCompleted = (state: UserState): boolean => !state.paymentInfoRequired;
export const isPlainTeamMember = (state: UserState): boolean => Boolean(state.familyMember
  && !state.familyMember.isOwner
  && !state.permissions.includes(permissions.TEAM_MANAGEMENT_VIEW));
export const isTeamOwner = (state: UserState): boolean => Boolean(state.familyMember && state.familyMember.isOwner);
export const isTeamAdmin = (state: UserState): boolean => Boolean(state.familyMember
  && !state.familyMember.isOwner
  && state.permissions.includes(permissions.TEAM_MANAGEMENT_VIEW));
