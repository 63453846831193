// @flow

import React, { Component } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';

import { CAPTCHA_SITE_KEY } from 'config/auth';

import './resend-email-captcha-modal.scss';

type Props = {
  onConfirm: (?string) => void,
  onHide: () => void,
  onExited: () => void,
  show: boolean,
};

type State = {
  captcha: ?string,
}

class ResendEmailCaptchaModal extends Component<Props, State> {
  state = {
    captcha: null,
  };

  render() {
    const { onHide, onExited, show } = this.props;
    const { captcha } = this.state;

    return (
      <Modal
        onHide={onHide}
        onExited={onExited}
        show={show}
        title={<FormattedMessage id="resendEmailModal.title" defaultMessage="Resending email" />}
      >
        <div className="text-center">
          <FormattedMessage
            id="resendEmailModal"
            defaultMessage="Let us know you’re a human and are clicking those buttons on purpose."
          />
        </div>

        <div className="resend-email-captcha-modal__captcha-container">
          <div className="resend-email-captcha-modal__captcha">
            <ReCaptcha
              size="normal"
              sitekey={CAPTCHA_SITE_KEY}
              onChange={this.onCaptchaChange}
            />
          </div>
        </div>
        <Button
          block
          disabled={!captcha}
          onClick={this.onConfirmClick}
        >
          <FormattedMessage id="resendEmailModal.submitBtnTitle" defaultMessage="Resend email" />
        </Button>
      </Modal>
    );
  }

  onCaptchaChange = (value: string) => {
    this.setState({ captcha: value });
  };

  onConfirmClick = () => {
    const { onHide, onConfirm } = this.props;
    const { captcha } = this.state;

    onHide();
    onConfirm(captcha);
  };
}

export default ResendEmailCaptchaModal;
