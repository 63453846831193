// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import './vat-note-row.scss';

const VatNoteRow = () => (
  <p className="vat-note-row">
    <FormattedMessage
      id="taxNote.text"
      defaultMessage="Sales taxes may apply, depending on your country of residence."
    />
  </p>
);

export default VatNoteRow;
