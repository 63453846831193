// @flow

import React, { Component, createRef, type Node } from 'react';
import queryString from 'query-string';

import logger from 'utils/logger';

export const embedElementTypes = {
  popup: 1,
  left: 2,
  right: 3,
};

export const surveyList = {
  subscriptionCancel: 'Fst4SO',
};

type Props = {
  surveyId: string,
  type: $Keys<typeof embedElementTypes>,
  hiddenInputs: {[string]: string | number},
  children: Node,
  onInitializeEnd: () => any,
};

class Survey extends Component<Props> {
  typeformLink = createRef<?HTMLAnchorElement>();

  typeformScript: ?HTMLScriptElement;

  typeformStyles: ?HTMLLinkElement;

  static defaultProps = {
    type: 'popup',
    onInitializeEnd: () => {},
    hiddenInputs: {},
    children: null,
  };

  componentDidMount() {
    return this.initTypeform()
      .then(() => {
        const { onInitializeEnd } = this.props;

        if (!this.typeformLink.current) {
          throw new Error('Cannot find Typeform link element');
        }

        this.typeformLink.current.click();
        onInitializeEnd();
      })
      .catch((error) => {
        const { onInitializeEnd } = this.props;

        logger.logError('Couldn\'t load Typeform', error);
        onInitializeEnd();
      });
  }

  render() {
    const {
      surveyId, type, children, hiddenInputs,
    } = this.props;
    const hiddenInputsQuery = queryString.stringify(hiddenInputs);

    return (
      <a
        className="typeform-share"
        href={`https://yonick.typeform.com/to/${surveyId}?${hiddenInputsQuery}`}
        target="_blank"
        rel="noopener noreferrer"
        data-mode={type}
        ref={this.typeformLink}
      >
        {children}
      </a>
    );
  }

  initTypeform() {
    const pathToTypeForm = 'https://s3-eu-west-1.amazonaws.com/share.typeform.com/';
    let typeFormId = 'typef_orm_share',
      loadCssPromise,
      loadJsPromise,
      firstScriptOnPage,
      head;

    this.clearTypeForm();

    if (!document.getElementById(typeFormId)) {
      loadJsPromise = new Promise((resolve, reject) => {
        const typeformScript = document.createElement('script');
        typeformScript.id = typeFormId;
        typeformScript.src = `${pathToTypeForm}share.js`;
        typeformScript.onload = resolve;
        typeformScript.onerror = reject;

        [firstScriptOnPage] = document.getElementsByTagName('script');

        if (!firstScriptOnPage.parentNode) {
          reject(new Error('Cannot find the node for adding the Typeform script'));

          return;
        }

        firstScriptOnPage.parentNode.insertBefore(typeformScript, firstScriptOnPage);
        this.typeformScript = typeformScript;
      });

      typeFormId += '_';

      loadCssPromise = new Promise((resolve, reject) => {
        const typeformStyles = document.createElement('link');
        typeformStyles.rel = 'stylesheet';
        typeformStyles.id = typeFormId;
        typeformStyles.href = `${pathToTypeForm}share-button.css`;
        typeformStyles.onload = resolve;
        typeformStyles.onerror = reject;

        [head] = document.getElementsByTagName('head');
        head.appendChild(typeformStyles);
        this.typeformStyles = typeformStyles;
      });
    }

    return Promise.all([
      loadCssPromise,
      loadJsPromise,
    ]);
  }

  clearTypeForm() {
    if (this.typeformScript && this.typeformScript.parentNode) {
      this.typeformScript.parentNode.removeChild(this.typeformScript);
      this.typeformScript = null;
    }

    if (this.typeformStyles && this.typeformStyles.parentNode) {
      this.typeformStyles.parentNode.removeChild(this.typeformStyles);
      this.typeformStyles = null;
    }
  }
}

export default Survey;
