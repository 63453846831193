// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, type Location, type RouterHistory } from 'react-router-dom';
import queryString from 'query-string';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FormContainer from 'components/shared/form/form-container/form-container';

import { signUp } from 'state/user/user-actions';

import urls from 'config/urls';

import validate from 'utils/auth-validation';
import { getSignupMetadata } from 'utils/service-locators';
import logger from 'utils/logger';
import PasswordErrorMessage from 'components/shared/password-error-message/password-error-message';

import SignupMarketingBlock from './signup-marketing-block/signup-marketing-block';
import SignUpForm from './signup-form/signup-form';


import './signup-page.scss';

const signUpMetadataManager = getSignupMetadata();

type State = {
  isPasswordInvalid: boolean,
};

type Props = {
  signUp: Function,
  history: RouterHistory,
  location: Location,
};

type FormFields = {
  [string]: any,
};

type FormFieldsErrors = {
  [string]: Node,
}

class SignUpPage extends PureComponent<Props, State> {
  state = {
    isPasswordInvalid: false,
  }

  componentDidMount() {
    const { location } = this.props;
    const query = queryString.parse(location.search);

    if (query.campaign) {
      signUpMetadataManager.save({ campaign: query.campaign });
    }
  }

  render() {
    const { location } = this.props;
    const { isPasswordInvalid } = this.state;

    return (
      <FullscreenLayout cookieBannerPosition="top">
        <FullscreenLayout.PrimaryContent withLogo>
          <div className="signup-page__primary-content-wrapper">
            <h1 className="h3">
              <FormattedMessage id="outer.signup.title" defaultMessage="Sign Up & Create a Team" />
            </h1>
            <div className="signup-page__login-block text_sm">
              <FormattedMessage
                id="outer.signup.signUpLinkText"
                defaultMessage="Already have a team account? {signUpLink}"
                values={{ signUpLink: <Link className="link-outer" to={urls.login}><FormattedMessage id="outer.signup.signInLinkLabel" defaultMessage="Sign in" /></Link> }}
              />
            </div>
            <FormContainer
              initialValues={{
                companyName: '',
                email: '',
                password: '',
                marketingSubscribed: false,
                termsAccepted: false,
              }}
              onSubmit={this.onFormSubmit}
              validate={this.validateForm}
            >
              {({
                fields,
                fieldsErrors,
                formError,
                isProcessing,
                onSubmit,
                onFieldChange,
                captcha,
              }) => (
                <SignUpForm
                  onFormSubmit={onSubmit}
                  onFieldChange={(event) => {
                    this.setState({ isPasswordInvalid: false });
                    onFieldChange(event);
                  }}
                  isRequestProcessing={isProcessing}
                  companyName={fields.companyName}
                  email={fields.email}
                  password={fields.password}
                  isPasswordInvalid={isPasswordInvalid}
                  companyNameError={fieldsErrors.companyName}
                  emailError={fieldsErrors.email}
                  passwordError={fieldsErrors.password}
                  termsAcceptedError={fieldsErrors.termsAccepted}
                  genericError={formError}
                  captcha={captcha}
                />
              )}
            </FormContainer>
            <div className="text_xs signup-page__form-note">
              <FormattedMessage id="outer.signup.form.note" defaultMessage="No credit card required - Fast & safe - Cancel anytime" />
            </div>
          </div>
        </FullscreenLayout.PrimaryContent>
        <FullscreenLayout.SecondaryContent>
          <SignupMarketingBlock location={location} />
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  validateForm = (fields: $Shape<FormFields>): FormFieldsErrors => {
    const errors = validate(fields, {
      companyName: {
        required: (
          <FormattedMessage
            id="outer.signup.validation.emptyCompanyName"
            defaultMessage="Company name is required"
          />
        ),
      },
      email: {
        required: (
          <FormattedMessage
            id="outer.signup.validation.emptyEmail"
            defaultMessage="Business email is required"
          />
        ),
        emailFormat: (
          <FormattedMessage
            id="outer.signup.validation.invalidEmail"
            defaultMessage="This doesn’t look like an email"
          />
        ),
      },
      password: {
        required: true,
        passwordFormat: true,
        passwordContainsBothLowerAndUpper: true,
      },
      termsAccepted: {
        required: (
          <FormattedMessage
            id="outer.signup.validation.emptyPolicy"
            defaultMessage="Please agree with policies to continue"
          />
        ),
      },
    });

    if (errors.password) {
      this.setState({ isPasswordInvalid: true });
    }

    return {
      ...errors,
      password: errors.password && <PasswordErrorMessage password={fields.password} />,
    };
  }

  onFormSubmit = (fields: $Shape<FormFields>) => {
    const { location } = this.props;
    const query = queryString.parse(location.search);

    let signupParams = fields;

    if (query.campaign) {
      signupParams = {
        ...signupParams,
        campaign: query.campaign,
      };
    }

    if (query.signupParams) {
      const urlSignupParams = JSON.parse(query.signupParams);

      try {
        if (typeof urlSignupParams === 'object' && !Array.isArray(urlSignupParams)) {
          signupParams = {
            ...signupParams,
            ...urlSignupParams,
          };
        }
      } catch (e) {
        logger.logError('Can not parse signupParams from url');
      }
    }

    return this.signUpUser(signupParams);
  };

  async signUpUser(data: $Shape<FormFields>): Promise<void> {
    const { signUp, location } = this.props;

    await signUp(data);

    const { history } = this.props;

    history.push(urls.dashboard, { from: location });
  }
}

export { SignUpPage as PureSignupPage };

export default connect(null, { signUp })(SignUpPage);
