// @flow

import React, { type Node, PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DeviceIcon, Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import { getDevices } from 'state/root-reducer';
import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';
import { deactivateDevice, deactiveAllIosDevices } from 'state/devices/devices-actions';

import type { Device } from 'state/devices/devices-initial-state';

import './disconnect-device-modal.scss';

type Props = {|
  show: boolean,
  onHide: () => any,
  onExited: () => any,
  deactivateDevice: (number) => Promise<any>,
  deactiveAllIosDevices: () => Promise<any>,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  isProcessing: boolean,
  device: Device | 'all-ios-devices',
|};

class DisconnectDeviceModal extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      onExited,
      isProcessing,
      device,
    } = this.props;

    const title = device === 'all-ios-devices' ? (
      <FormattedMessage
        id="deviceInfo.disconnectAllIosDevicesModal.title"
        defaultMessage="Disconnecting all iOS devices"
      />
    ) : (
      <FormattedMessage
        id="deviceInfo.disconnectDeviceModal.title"
        defaultMessage="Disconnect device"
      />
    );

    const description = device === 'all-ios-devices' ? (
      <FormattedMessage
        id="deviceInfo.disconnectAllIosDevicesModal.description"
        defaultMessage="Are you sure you want to disconnect all your iOS devices? This won’t affect your payment plan."
      />
    ) : (
      <FormattedMessage
        id="deviceInfo.disconnectDeviceModal.description"
        defaultMessage="Disconnect this device to add another one in its place. This doesn’t affect your payment plan."
      />
    );

    const cancelText = device === 'all-ios-devices' ? (
      <FormattedMessage
        id="deviceInfo.disconnectAllIosDevicesModal.btnBack"
        defaultMessage="Cancel"
      />
    ) : (
      <FormattedMessage
        id="deviceInfo.disconnectDeviceModal.btnBack"
        defaultMessage="Keep using this device"
      />
    );

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={title}
      >
        <div className="disconnect-device-modal__content">
          {device !== 'all-ios-devices' && (
            <div className="text-center">
              <DeviceIcon device={device.model} />
              <div className="disconnect-device-modal__device-title">{device.name}</div>
            </div>
          )}

          <div className="disconnect-device-modal__description">
            {description}
          </div>

          <Button
            block
            onClick={this.onDeactivateDeviceClick}
            disabled={isProcessing}
            data-qa="disconnectDeviceConfirmButton"
          >
            <FormattedMessage id="deviceInfo.disconnectDeviceModal.btnDisconnect" defaultMessage="Disconnect" />
          </Button>
          <Button
            variant="secondary-outline"
            block
            onClick={onHide}
            disabled={isProcessing}
            className="mt-3"
          >
            {cancelText}
          </Button>
        </div>
      </Modal>
    );
  }

  onDeactivateDeviceClick = () => {
    const { deactivateDevice, deactiveAllIosDevices, device, onHide } = this.props;

    if (device === 'all-ios-devices') {
      return deactiveAllIosDevices()
        .then(this.onDeactivateDeviceSuccess)
        .catch(this.onDeactivateDeviceFailed)
        .finally(onHide);
    }

    return deactivateDevice(device.id)
      .then(this.onDeactivateDeviceSuccess)
      .catch(this.onDeactivateDeviceFailed)
      .finally(onHide);
  };

  onDeactivateDeviceSuccess = () => {
    const { addSuccessNotification, device } = this.props;

    const disconnectedSuccessMessage = device === 'all-ios-devices' ? (
      <FormattedMessage
        id="deviceInfo.messages.deviceAllIosDisconnectionSuccess"
        defaultMessage="All iOS devices have been successfully disconnected"
      />
    ) : (
      <FormattedMessage
        id="deviceInfo.messages.deviceDisconnectionSuccess"
        defaultMessage="Device disconnected successfully."
      />
    );

    addSuccessNotification({
      message: disconnectedSuccessMessage,
    });
  };

  onDeactivateDeviceFailed = () => {
    const { addDangerNotification } = this.props;

    addDangerNotification({ message: <DefaultError /> });
  };
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isProcessing: getDevices(state).isLoading,
});

const mapActionsToProps = {
  deactivateDevice,
  addSuccessNotification,
  addDangerNotification,
  deactiveAllIosDevices,
};

export default connect(mapStateToProps, mapActionsToProps)(DisconnectDeviceModal);

export { DisconnectDeviceModal as PureDisconnectDeviceModal };
