// @flow

import React from 'react';

import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import './jamf-guide.scss';

import GuideItem from './guide-item/guide-item';
import GoToJamfGuide from './go-to-jamf-guide/go-to-jamf-guide';
import InstallSetapp from './install-setapp/install-setapp';
import PersonalKey from './personal-key/personal-key';

const JamfGuide = () => (
  <div className="jamf-guide">
    <Row>
      <Col md={5}>
        <GuideItem
          title={(
            <FormattedMessage
              id="jamfGuide.guide.title"
              defaultMessage="Step 1: Start with the instructions"
            />
          )}
        >
          <GoToJamfGuide />
        </GuideItem>
        <GuideItem
          title={(
            <FormattedMessage
              id="jamfGuide.downloadOrgConfig.title"
              defaultMessage="Step 2: Download Setapp"
            />
          )}
        >
          <InstallSetapp />
        </GuideItem>
      </Col>
      <Col md={5} mdOffset={1}>
        <GuideItem
          title={(
            <FormattedMessage
              id="jamfGuide.personalKey.title"
              defaultMessage="Step 3: Use your team key"
            />
          )}
        >
          <PersonalKey />
        </GuideItem>
      </Col>
    </Row>
  </div>
);

export default JamfGuide;
