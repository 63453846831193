// @flow

import React from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import { getSupportUrl } from 'utils/support';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';

import './header-navigation.scss';

const HeaderNavigation = () => {
  const onContactSupportClick = () => {
    analytics.trackEvent(events.HEADER_NAVIGATION_CONTACT_SUPPORT);
  };

  const onMyAccountClick = () => {
    analytics.trackEvent(events.HEADER_NAVIGATION_MY_ACCOUNT);
  };

  return (
    <Navbar staticTop className="navbar-transparent">
      <Navbar.Header>
        <Navbar.Brand>
          <a href={urls.account}>
            <AnimatedLogo hasCaption size="sm" />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>

      <Navbar.Collapse>
        <Nav pullRight>
          <NavItem href={getSupportUrl()} target="_blank" onClick={onContactSupportClick}>
            <FormattedMessage id="navigation.support" defaultMessage="Contact support" />
          </NavItem>
          <LinkContainer to={urls.account} onClick={onMyAccountClick}>
            <NavItem>
              <FormattedMessage id="navigation.myAccount" defaultMessage="My account" />
              <svg
                className="navbar__arrow-icon ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="12"
                viewBox="0 0 6 12"
              >
                <path
                  fill="none"
                  fillRule="evenodd"
                  stroke="#26262B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M0 0L4 4 0 8"
                  transform="translate(1 2)"
                />
              </svg>
            </NavItem>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderNavigation;
