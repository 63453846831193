// @flow

import React, { type Node } from 'react';
import PaymentForm from 'components/shared/payment-form/payment-form';
import Modal from 'components/shared/modal/modal';
import type { PaymentMethodType } from 'state/payment-method/payment-method-initial-state';

type Props = {|
  show: boolean,
  onHide: () => void,
  onExited: ?() => void,
  onPaymentDetailsSaved: ?() => mixed,
  title: Node,
  braintreeFormOptions: ?$Shape<{
    paymentMethod: ?PaymentMethodType,
    submitBtnTitle: Node,
    loadingText: Node,
  }>,
|}

const PaymentDetails = (props: Props) => {
  const {
    show,
    onHide,
    onExited,
    onPaymentDetailsSaved,
    title,
    braintreeFormOptions,
  } = props;

  return (
    <Modal
      fullScreen
      show={show}
      onHide={onHide}
      onExited={onExited}
      title={title}
    >
      <PaymentForm
        onSuccessSubmit={onPaymentDetailsSaved}
        braintreeFormOptions={braintreeFormOptions}
      />
    </Modal>
  );
};

PaymentDetails.defaultProps = {
  onPaymentDetailsSaved: null,
  braintreeFormOptions: null,
  onExited: null,
};

export default PaymentDetails;
