// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Button } from '@setapp/ui-kit';

import PageTitle from 'components/shared/page-title/page-title';
import { fetchDevices } from 'state/devices/devices-actions';
import { fetchSubscription } from 'state/subscription/subscription-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';

import {
  getPricePlansList,
  getDevicesList,
  getEmptySeatsCount,
  isSubscriptionsFetched,
} from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';
import type { Device } from 'state/devices/devices-initial-state';

import type { ModalType } from 'components/modals';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import withEmailAutoConfirmation from 'components/shared/with-email-auto-confirmation/with-email-auto-confirmation';

import analytics, { events } from 'utils/analytics';

import DevicesList from './devices-list/devices-list';


import './devices-page.scss';

type Props = {|
  fetchDevices: () => Promise<void>,
  fetchPricePlans: () => Promise<void>,
  fetchSubscription: () => Promise<void>,
  devices: Array<Device>,
  isPricePlansFetched: boolean,
  isSubscriptionsFetched: boolean,
  showModal: (ModalType, {device: Device | 'all-ios-devices'}) => void,
  emptySeatsCount: number,
|};

class DevicesPage extends PureComponent<Props> {
  componentDidMount() {
    const {
      fetchSubscription,
      fetchPricePlans,
      fetchDevices,
    } = this.props;

    fetchPricePlans();
    fetchSubscription();
    fetchDevices();
  }

  render() {
    const {
      isPricePlansFetched,
      isSubscriptionsFetched,
      devices,
      emptySeatsCount,
    } = this.props;

    if (!isPricePlansFetched || !isSubscriptionsFetched) {
      return (
        <div className="devices-page__animated-logo">
          <AnimatedLogo animate />
        </div>
      );
    }

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="deviceInfo.title" defaultMessage="My devices" />
        </PageTitle>
        <Row>
          <Col lg={6}>
            <DevicesList
              devices={devices}
              onDisconnectClick={this.showDisconnectDeviceModal}
              emptySeatsCount={emptySeatsCount}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Button
              variant="danger-outline"
              size="sm"
              onClick={this.handleDisconnectAllIosClick}
            >
              <FormattedMessage id="deviceInfo.disconnectAllIosBtn" defaultMessage="Disconnect all iOS devices" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  showDisconnectDeviceModal = (device: Device) => {
    const { showModal } = this.props;

    showModal('DISCONNECT_DEVICE', { device });
  };

  handleDisconnectAllIosClick = () => {
    const { showModal } = this.props;

    analytics.trackEvent(events.DEVICE_DEACTIVE_ALL_IOS_CLICK);

    showModal('DISCONNECT_DEVICE', { device: 'all-ios-devices' });
  };
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isPricePlansFetched: Boolean(getPricePlansList(state).length),
  isSubscriptionsFetched: isSubscriptionsFetched(state),
  devices: getDevicesList(state),
  emptySeatsCount: getEmptySeatsCount(state),
});

const mapActionsToProps = {
  fetchDevices,
  fetchPricePlans,
  fetchSubscription,
  showModal,
};

export { DevicesPage as PureDevicesPage };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withEmailAutoConfirmation(DevicesPage));
