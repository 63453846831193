// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import external from 'config/external-urls';

import './paddle-verified.scss';

type Props = {
  onClick?: () => void,
}

const PaddleVerified = ({ onClick }: Props) => (
  <div className="paddle-verified">
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16">
      <path fill="#E6A873" d="M5.5 1.667C4.171 1.667 3.094 2.71 3.094 4v2.333h4.812V4c0-1.289-1.077-2.333-2.406-2.333zm0 6.666c-.76 0-1.375.597-1.375 1.334 0 .444.224.837.568 1.08l.119.074v1.512h1.375l.001-1.512c.41-.23.687-.661.687-1.154 0-.737-.616-1.334-1.375-1.334zm4.125-2h-.403c.982 0 1.778.796 1.778 1.778v6.111C11 15.204 10.204 16 9.222 16H1.778C.796 16 0 15.204 0 14.222v-6.11c0-.983.796-1.779 1.778-1.779h-.403V4c0-2.21 1.847-4 4.125-4s4.125 1.79 4.125 4v2.333z" />
    </svg>
    <span className="ml-4 text_xs">
      <FormattedMessage
        id="paddleVerified.label"
        defaultMessage="Payment information is protected by {paddleLink}."
        values={{
          paddleLink: (
            <a
              href={external.paddle}
              onClick={onClick}
              className="paddle-verified__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Paddle
            </a>
          ),
        }}
      />
    </span>
  </div>
);

/* istanbul ignore next */
PaddleVerified.defaultProps = {
  onClick: () => {},
};

export default PaddleVerified;
