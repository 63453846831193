// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import PaddleVerified from 'components/shared/paddle-verified/paddle-verified';

import './activate-promo-block.scss';

type Props = {
  paddleVerifiedClick: () => void,
}

const ActivatePromoBlock = ({ paddleVerifiedClick }: Props) => (
  <div className="activate-promo-block">
    <div className="activate-promo-block__content">
      <div className="activate-promo-block__feature text_lg">
        <div className="mr-5 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-3 0 20 20">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0H20V20H0z" transform="translate(-3 -3)" />
              <path fill="#E6A873" fillRule="nonzero" d="M12.54 13.239c2.684 0 4.86 2.176 4.86 4.86v2.552H3v-2.552c0-2.684 2.176-4.86 4.86-4.86h4.68zM10.2 3c2.141 0 3.877 1.724 3.877 3.85 0 1.286-.46 2.673-1.38 4.16-.177.286-.49.46-.827.46H8.53c-.337 0-.65-.174-.827-.46-.92-1.487-1.38-2.874-1.38-4.16C6.323 4.724 8.06 3 10.2 3z" transform="translate(-3 -3)" />
            </g>
          </svg>
        </div>
        <FormattedMessage
          id="activatePromoBlock.feature.addMembers"
          defaultMessage="Add new members to let everyone get access to dozens of apps"
        />
      </div>
      <div className="activate-promo-block__feature text_lg mt-10">
        <div className="mr-5 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill="#E6A873" fillRule="evenodd" d="M7.5 17.714v.073c0 .349.26.637.596.683l.094.006h3.62c.35 0 .638-.26.684-.596l.006-.093v-.073h6.983c.285 0 .517.232.517.518v1.25c0 .286-.232.518-.517.518H.517C.232 20 0 19.768 0 19.483v-1.251c0-.286.232-.518.517-.518H7.5zM17.471 4c.572 0 1.035.463 1.035 1.034v10.64c0 .285-.232.516-.517.516H1.88c-.285 0-.517-.231-.517-.517V5.034c0-.57.463-1.034 1.034-1.034h15.074z" />
          </svg>
        </div>
        <FormattedMessage
          id="activatePromoBlock.feature.orderDevices"
          defaultMessage="Order extra devices for members who need Setapp on more than one device"
        />
      </div>
      <div className="activate-promo-block__feature text_lg mt-10">
        <div className="mr-5 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0H20V20H0z" transform="translate(-1 -1)" />
              <path fill="#E6A873" fillRule="nonzero" d="M17.06 4.167c.86.965 1.528 2.107 1.94 3.365-.867.696-1.423 1.767-1.423 2.968s.556 2.272 1.423 2.968c-.412 1.258-1.08 2.4-1.94 3.365-1.034-.405-2.235-.351-3.271.249-1.037.6-1.684 1.618-1.852 2.72-.625.13-1.273.198-1.937.198-.664 0-1.312-.069-1.937-.199-.168-1.101-.815-2.119-1.852-2.72-1.036-.6-2.237-.653-3.271-.248-.86-.965-1.528-2.107-1.94-3.365.867-.696 1.423-1.767 1.423-2.968S1.867 8.228 1 7.532c.412-1.258 1.08-2.4 1.94-3.365 1.034.405 2.235.351 3.271-.249 1.037-.6 1.684-1.618 1.852-2.72C8.688 1.069 9.336 1 10 1c.664 0 1.312.069 1.937.199.168 1.101.815 2.119 1.852 2.72 1.036.6 2.237.653 3.271.248zM10 14.3c2.092 0 3.789-1.701 3.789-3.8 0-2.099-1.697-3.8-3.789-3.8s-3.789 1.701-3.789 3.8c0 2.099 1.697 3.8 3.789 3.8z" transform="translate(-1 -1)" />
            </g>
          </svg>
        </div>
        <FormattedMessage
          id="activatePromoBlock.feature.userAccess"
          defaultMessage="Modify user access to fit your team changes"
        />
      </div>
      <div className="activate-promo-block__footer">
        <PaddleVerified onClick={paddleVerifiedClick} />
      </div>
    </div>
  </div>
);

export default ActivatePromoBlock;
