// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import InContentNotification from 'components/shared/in-content-notification/in-content-notification';

const StatusCancelledNotification = () => (
  <InContentNotification type="danger">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.cancelledStatus"
      defaultMessage="You have cancelled your subscription. Renew your subscription to get access to all the apps."
    />
  </InContentNotification>
);

export default StatusCancelledNotification;
