import Cookies from 'js-cookie';
import { camelize } from 'humps';
import { COOKIES_ROOT_DOMAIN } from 'config/app';

export const CAMPAIGN_COOKIE_NAME = 'campaign';
export const TIER_ID_COOKIE_NAME = 'tier_id';
export const MARKETING_SUBSCRIBED_COOKIE_NAME = 'marketing_subscribed';

const cookieNames = [
  CAMPAIGN_COOKIE_NAME,
  TIER_ID_COOKIE_NAME,
  MARKETING_SUBSCRIBED_COOKIE_NAME,
];

const helpers = {
  cleanAll() {
    cookieNames.forEach((name) => Cookies.remove(name, { domain: COOKIES_ROOT_DOMAIN }));
  },
};

cookieNames.forEach((name) => {
  Object.defineProperty(helpers, camelize(name), {
    get: () => Cookies.getJSON(name),
    set: (value) => {
      if (value != null) {
        Cookies.set(name, value, { domain: COOKIES_ROOT_DOMAIN });
      }
    },
  });
});

export default helpers;
