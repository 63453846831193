import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PaymentHistoryEmptyItem from './payment-history-empty-item/payment-history-empty-item';
import PaymentHistoryItem from './payment-history-item/payment-history-item';

import './payment-history-list.scss';

const PaymentHistoryList = ({ invoices }) => {
  const isTaxColumnShown = invoices.some((invoice) => invoice.taxAmount !== 0);

  return (
    <table className="table table-hover payment-history__list">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="paymentHistory.table.columns.date" defaultMessage="Date" />
          </th>
          <th className="text-right">
            <FormattedMessage id="paymentHistory.table.columns.amount" defaultMessage="Your plan" />
          </th>
          {isTaxColumnShown && (
            <th className="text-right">
              <FormattedMessage id="paymentHistory.table.columns.taxAmount" defaultMessage="Tax charged" />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {
          !invoices.length
            ? (<PaymentHistoryEmptyItem />)
            : invoices.map((invoice) => (
              <PaymentHistoryItem
                key={invoice.id}
                isTaxShown={isTaxColumnShown}
                {...invoice}
              />
            ))
        }
      </tbody>
    </table>
  );
};

PaymentHistoryList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({
    taxAmount: PropTypes.number.isRequired,
    paymentDate: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    amountFormatted: PropTypes.string.isRequired,
    taxAmountFormatted: PropTypes.string.isRequired,
  })).isRequired,
};

export default PaymentHistoryList;
