import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ImmediatelyDownloadLink from './immediately-download-link';

const ImmediatelyDownloadLinkSection = ({ onDirectDownloadClick, customUrl }) => (
  <span>
    <FormattedMessage
      id="successfulRegistration.downloadLinkSection"
      defaultMessage="Have issues with download? Click {link} and enjoy"
      values={{
        link: (
          <ImmediatelyDownloadLink
            customUrl={customUrl}
            onDirectDownloadClick={onDirectDownloadClick}
          />
        ),
      }}
    />
  </span>
);

ImmediatelyDownloadLinkSection.propTypes = {
  customUrl: PropTypes.string,
  onDirectDownloadClick: PropTypes.func,
};

ImmediatelyDownloadLinkSection.defaultProps = {
  onDirectDownloadClick: () => {},
  customUrl: null,
};

export default ImmediatelyDownloadLinkSection;
