// @flow

import initialState, { type NotifierState } from './notifier-initial-state';
import * as actionTypes from './notifier-action-types';

const notification = (state: NotifierState = initialState, action: actionTypes.NotificationsAction): NotifierState => {
  switch (action.type) {
    case actionTypes.ADD: {
      return { ...state, list: [...state.list, { ...action.payload }] };
    }

    case actionTypes.CLEAR: {
      return { ...initialState };
    }

    case actionTypes.REMOVE: {
      return { ...state, list: state.list.filter((notification) => notification.id !== action.payload) };
    }

    default: {
      return state;
    }
  }
};

export default notification;

export const getList = (state: NotifierState) => state.list;
