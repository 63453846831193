// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux/';
import isAfter from 'date-fns/isAfter';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { SubscriptionStatus } from 'state/subscription/statuses';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { getPricePerMonthPerMember, getTotalAmountForCustomers } from 'state/root-reducer';

import StatusCancelledNotification from './status-cancelled-notification/status-cancelled-notification';
import SubscriptionBlockedNotification from './subscription-blocked-notification/subscription-blocked-notification';
import PaymentFailedNotification from './payment-failed-notification/payment-failed-notification';
import PaymentPendingNotification from './payment-pending-notification/payment-pending-notification';
import PlanChangedNotification from './plan-changed-notification/plan-changed-notification';
import ActiveTillNotification from './active-till-notification/active-till-notification';

type Props = {|
  status: SubscriptionStatus,
  lastPaymentFailed: ?boolean,
  paymentPending: boolean,
  expirationDate: number,
  nextPaymentDate: number,
  nextPricePlan: ?PricePlan,
  nextPriceSum: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

type State = {
  isReactivateBlockedFailed: boolean,
};

class SubscriptionNotification extends PureComponent<Props, State> {
  state = {
    isReactivateBlockedFailed: false,
  };

  render() {
    const {
      paymentPending,
      status,
      lastPaymentFailed,
      nextPricePlan,
      nextPaymentDate,
      nextPriceSum,
      pricePerMemberPerMonth,
      expirationDate,
    } = this.props;
    const { isReactivateBlockedFailed } = this.state;

    if (paymentPending) {
      return <PaymentPendingNotification />;
    }

    switch (status) {
      case subscriptionStatuses.CANCELLED: {
        const isSubscriptionExpired = expirationDate != null && isAfter(Date.now(), expirationDate * 1000);

        if (expirationDate == null || isSubscriptionExpired) {
          return <StatusCancelledNotification />;
        }

        return <ActiveTillNotification />;
      }

      case subscriptionStatuses.GRACE: {
        return <PaymentFailedNotification />;
      }

      case subscriptionStatuses.BLOCKED: {
        if (isReactivateBlockedFailed) {
          return <PaymentFailedNotification />;
        }

        return (
          <SubscriptionBlockedNotification />
        );
      }

      case subscriptionStatuses.ACTIVE: {
        if (nextPricePlan && nextPriceSum) {
          return (
            <PlanChangedNotification
              nextPricePlan={nextPricePlan}
              nextPaymentDate={nextPaymentDate}
              // tax is always not actual for next price plan
              tax={0}
              nextPriceSum={nextPriceSum}
              pricePerMemberPerMonth={pricePerMemberPerMonth}
            />
          );
        }

        return null;
      }

      case subscriptionStatuses.TRIAL: {
        if (lastPaymentFailed) {
          return <PaymentFailedNotification />;
        }

        return null;
      }

      default:
        return null;
    }
  }

  onReactivateBlockedFail = () => {
    this.setState({ isReactivateBlockedFailed: true });
  }
}

/* istanbul ignore next */
const mapStateToProps = (state, { nextPricePlan }) => ({
  nextPriceSum: getTotalAmountForCustomers(state, nextPricePlan),
  pricePerMemberPerMonth: getPricePerMonthPerMember(state, nextPricePlan && nextPricePlan.priceKey),
});

export { SubscriptionNotification as PureSubscriptionNotification };

export default connect(mapStateToProps)(SubscriptionNotification);
