// @flow

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';


import PageDescription from 'components/shared/page-description/page-description';
import PageTitle from 'components/shared/page-title/page-title';

import DownloadStep from './onboarding-steps/download-step';
import OpenDesktopStep from './onboarding-steps/open-desktop-step';
import InviteStep from './onboarding-steps/invite-step';
import AddPaymentStep from './onboarding-steps/add-payment-step';
import UpdatePaymentStep from './onboarding-steps/update-payment-step';

import './action-cards-view.scss';

type Props = {
  isAddPaymentDetailsStepDone: boolean,
  isDownloadDesktopAppStepDone: boolean,
  isInviteMemberStepDone: boolean,
  onDownloadClick: () => mixed,
  onUpdatePaymentDetailsClick: () => mixed,
  onAddPaymentDetailsClick: () => mixed,
  onInviteClick: () => mixed,
  onOpenDesktopClick: () => mixed,
};

class ActionCardsView extends PureComponent <Props> {
  render() {
    const {
      isAddPaymentDetailsStepDone,
      isDownloadDesktopAppStepDone,
      isInviteMemberStepDone,
      onDownloadClick,
      onUpdatePaymentDetailsClick,
      onAddPaymentDetailsClick,
      onInviteClick,
      onOpenDesktopClick,
    } = this.props;
    const { isMobile } = this.context;

    return (
      <>
        <PageTitle>
          <FormattedMessage id="dashboard.actionCards.view.title" defaultMessage="Welcome!" />
        </PageTitle>
        <PageDescription>
          <FormattedMessage
            id="dashboard.actionCards.view.subtitle"
            defaultMessage="Setapp for Teams is a desktop platform on Mac that offers a set of apps for literally any task your team has. To use Setapp at full take these three steps."
          />
        </PageDescription>

        <Row className="action-cards__container">
          <Col sm={4} data-qa="downloadStep">
            {(isDownloadDesktopAppStepDone && !isMobile) ? (
              <OpenDesktopStep
                onCtaClick={onOpenDesktopClick}
              />
            ) : (
              <DownloadStep
                onCtaClick={onDownloadClick}
              />
            )}
          </Col>
          <Col sm={4} data-qa="inviteStep">
            <InviteStep
              onCtaClick={onInviteClick}
              checked={isInviteMemberStepDone}
            />
          </Col>
          <Col sm={4} data-qa="addPaymentDetailsStep">
            {isAddPaymentDetailsStepDone ? (
              <UpdatePaymentStep
                onCtaClick={onUpdatePaymentDetailsClick}
              />
            ) : (
              <AddPaymentStep
                onCtaClick={onAddPaymentDetailsClick}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

ActionCardsView.contextTypes = {
  isMobile: PropTypes.bool,
};

export default ActionCardsView;
