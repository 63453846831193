// @flow

import React, { PureComponent } from 'react';
import type { ChildrenArray, Element } from 'react';

import SidebarNavHeader from './sidebar-nav-header/sidebar-nav-header';
import SidebarNavFooter from './sidebar-nav-footer/sidebar-nav-footer';
import SidebarNavSection from './sidebar-nav-section/sidebar-nav-section';
import SidebarNavItem from './sidebar-nav-item/sidebar-nav-item';

import './sidebar-nav.scss';

type Props = {
  children: ChildrenArray<Element<any>>,
};

class SidebarNav extends PureComponent<Props> {
  static Header = SidebarNavHeader;

  static Footer = SidebarNavFooter;

  static Section = SidebarNavSection;

  static Item = SidebarNavItem;

  render() {
    const { children } = this.props;

    return (
      <nav className="sidebar-nav">
        {children}
      </nav>
    );
  }
}

export default SidebarNav;
