// @flow

import auth from 'utils/auth';

import { DEFAULT_LOCALE } from 'config/locales';

import userInitialState from 'state/user/user-initial-state';

import type { Reducers } from './root-reducer';


type $ExtractFunctionReturn = <V>(v: (...args: any) => V) => V;
export type RootState = $ObjMap<Reducers, $ExtractFunctionReturn>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (): $Shape<RootState> => {
  const isUserAuthenticated = auth.isLoggedIn();

  return {
    intl: {
      defaultLocale: DEFAULT_LOCALE,
    },
    user: {
      ...userInitialState,
      isAuthenticated: isUserAuthenticated,
    },
  };
};
