import { Link } from 'react-router-dom';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import './not-found.scss';

import image404 from './images/404.svg';

const NotFound = () => (
  <div className="not-found text-center">
    <img className="img-responsive" src={image404} alt="" />
    <h1>
      <FormattedMessage
        id="notFound.title"
        defaultMessage="Sorry, page not found"
      />
    </h1>
    <Button
      tag={Link}
      to={urls.root}
      className="mt-9"
    >
      <FormattedMessage
        id="notFound.homeLink"
        defaultMessage="Take me to profile"
      />
    </Button>
  </div>
);

export default NotFound;
