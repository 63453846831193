// @flow

import React, { type Node } from 'react';

import NotificationContainer from 'components/shared/notification/notifications-container';
import CookieBanner from 'components/shared/banners/cookie-banner/cookie-banner';

import FullscreenLayoutPrimaryContent from './fullscreen-layout-primary-content';
import FullscreenLayoutSecondaryContent from './fullscreen-layout-secondary-content';


import './fullscreen-layout.scss';

type Props = {|
  children: Node,
  cookieBannerPosition?: 'top' | 'bottom',
|};

const FullscreenLayout = (props: Props) => {
  const { children, cookieBannerPosition } = props;

  return (
    <div className="fullscreen-layout">
      <div className="fullscreen-layout__notifications">
        <NotificationContainer />
      </div>
      <div className="fullscreen-layout__container">
        {children}
      </div>
      <CookieBanner position={cookieBannerPosition} />
    </div>
  );
};

FullscreenLayout.defaultProps = {
  cookieBannerPosition: 'bottom',
};

FullscreenLayout.PrimaryContent = FullscreenLayoutPrimaryContent;
FullscreenLayout.SecondaryContent = FullscreenLayoutSecondaryContent;

export default FullscreenLayout;
