// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

type Props = {
  pricePlan?: PricePlan,
}

const PricePlanDetails = ({ pricePlan = {} }: Props) => {
  switch (pricePlan.paidMonth) {
    case 1: {
      return (
        <div>
          <FormattedMessage
            id="pricePlans.details.monthly"
            defaultMessage="The price is per one member/month. Billed monthly."
          />
        </div>
      );
    }

    case 12: {
      return (
        <div>
          <FormattedMessage
            id="pricePlans.details.annual"
            defaultMessage="The price is per one member/month, with a 10% discount. Billed yearly."
          />
        </div>
      );
    }

    default: {
      return (
        <div>
          <FormattedMessage
            id="pricePlans.details.custom"
            defaultMessage="Pay {price} every {period, plural, one {month} other {{period} months}}."
            values={{
              price: (
                <FormattedPrice
                  currency={pricePlan.currency}
                  price={pricePlan.price}
                />
              ),
              period: pricePlan.paidMonth,
            }}
          />
        </div>
      );
    }
  }
};

export default PricePlanDetails;
