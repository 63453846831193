import { isAvailable } from '@setapp/desktop-app-helper';
import Cookies from 'js-cookie';

import { COOKIES_ROOT_DOMAIN } from '../config/app';
import auth from './auth';


const FROM_DESKTOP_COOKIE_NAME = 'from_desktop_client';

const DesktopAppHelper = {
  /**
   * Opens desktop client with cross auth token
   * @param {String} email
   * @return {Promise}
   */
  openWithAuth(email = '') { // TODO: get user's email automatically from somewhere
    return auth.requestCrossAuthToken()
      .then((resp) => {
        // Update location only after all 'then' calls completed
        setTimeout(() => {
          window.location = `setapp://system/sign_in?access_token=${resp.accessToken}&user_email=${email}`;
        }, 0);
      });
  },

  openDesktopApp({ appId }) {
    return new Promise((resolve, reject) => {
      isAvailable({
        onSuccess: () => {
          window.location = `setapp://launch/${appId}`;

          resolve();
        },
        onError: reject,
      });
    });
  },

  /**
   * Checks if current session is initiated by the desktop app
   * @return {Boolean}
   */
  hasOpenedCurrentSession() {
    return Boolean(Cookies.get(FROM_DESKTOP_COOKIE_NAME));
  },

  /**
   * Removes cookies identifying opening from desktop app
   */
  removeSession() {
    Cookies.remove(FROM_DESKTOP_COOKIE_NAME, { domain: COOKIES_ROOT_DOMAIN });
  },
};

export default DesktopAppHelper;
