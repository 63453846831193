import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { validators } from 'utils/auth-validation';

export type Props = {
  password: string;
}

const PasswordErrorMessage = ({ password }: Props) => {
  let errorMessage: ReactNode = '';

  const containsUpper = validators.passwordContainsUpper(password);
  const containsLower = validators.passwordContainsLower(password);
  const containsBothLowerAndUpper = validators.passwordContainsBothLowerAndUpper(password);

  if (!containsBothLowerAndUpper) {
    if (!containsUpper && !containsLower) {
      errorMessage = (
        <FormattedMessage
          id="outer.signup.validation.password.shouldContainsBothLowerAndUpper"
          defaultMessage="At least 1 uppercase and lowercase letter."
        />
      );
    } else if (!containsLower) {
      errorMessage = (
        <FormattedMessage
          id="outer.signup.validation.password.shouldContainsLower"
          defaultMessage="At least 1 lowercase letter."
        />
      );
    } else if (!containsUpper) {
      errorMessage = (
        <FormattedMessage
          id="outer.signup.validation.password.shouldContainsUpper"
          defaultMessage="At least 1 uppercase letter."
        />
      );
    }
  }

  if (!validators.passwordMatchMinLength(password)) {
    errorMessage = (
      <>
        {errorMessage}
        {' '}
        <FormattedMessage
          id="outer.signup.validation.password.shouldMatchMinLength"
          defaultMessage="8 characters minimum."
        />
      </>
    );
  }

  return errorMessage;
};

export default PasswordErrorMessage;
