import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import './page-description.scss';

const PageDescription = ({ children }) => (
  <Row className="page-description">
    <Col lg={7} className="text_md">
      {children}
    </Col>
  </Row>
);

PageDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageDescription;
