// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonClose } from '@setapp/ui-kit';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxNote from 'components/shared/tax-note/tax-note';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import SupportedPaymentMethods from '../supported-payment-methods/supported-payment-methods';

import PricingPlanDetails from './pricing-plan-details/pricing-plan-details';
import ActivateNote from './activate-note/activate-note';


import './add-payment-details-step.scss';

type Props = {
  withStepCounter: boolean,
  totalStepsNumber: number,
  currentStepNumber: number,
  handleCloseClick: () => void,
  activateSubscription: () => Promise<void>,
  selectedPlan: PricePlan,
  handlePaddleVerifiedClick: () => void,
};

const AddPaymentDetailsStep = (props: Props) => {
  const {
    withStepCounter,
    totalStepsNumber,
    currentStepNumber,
    handleCloseClick,
    activateSubscription,
    selectedPlan,
    handlePaddleVerifiedClick,
  } = props;

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent
        withStepCounter={withStepCounter}
        currentStepNumber={currentStepNumber}
        totalStepsNumber={totalStepsNumber}
      >
        <div className="add-payment-details-step__primary-content-wrapper">
          <div className="add-payment-details-step__close-button add-payment-details-step__close-button_primary">
            <ButtonClose onClick={handleCloseClick} />
          </div>
          <h3>
            <FormattedMessage
              id="activatePage.addPaymentDetailsTitle"
              defaultMessage="Add payment data & Buy"
            />
          </h3>
          <PaymentForm
            onSuccessSubmit={activateSubscription}
            paddleFormOptions={{
              footer: (
                <>
                  <div className="text-center">
                    <TaxNote />
                  </div>
                  <div className="add-payment-details-step__payment-cards-icons mt-2">
                    <SupportedPaymentMethods />
                  </div>
                  <div className="mt-6">
                    <ActivateNote />
                  </div>
                </>
              ),
              updateBillingNote: (
                <div className="add-payment-details-step__update-billing-note-wrapper">
                  <AnimatedLogo animate />
                  <div className="mt-5">
                    <FormattedMessage
                      id="activatePage.addPaymentDetails.paymentDetailsAdded.thankYou.label"
                      defaultMessage="Thank you!"
                    />
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </FullscreenLayout.PrimaryContent>
      <FullscreenLayout.SecondaryContent>
        <div className="add-payment-details-step__secondary-content-wrapper">
          <div className="add-payment-details-step__close-button">
            <ButtonClose onClick={handleCloseClick} />
          </div>
          <PricingPlanDetails
            paddleVerifiedClick={handlePaddleVerifiedClick}
            selectedPricePlan={selectedPlan}
          />
        </div>
      </FullscreenLayout.SecondaryContent>
    </FullscreenLayout>
  );
};

export default AddPaymentDetailsStep;
