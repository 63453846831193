import React from 'react';
import { FormattedMessage } from 'react-intl';
import InContentNotification from 'components/shared/in-content-notification/in-content-notification';

const PaymentPendingNotification = () => (
  <InContentNotification type="success">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.pending"
      defaultMessage="Your payment is being processed, we’ll send you an email once it's ready."
    />
  </InContentNotification>
);

export default PaymentPendingNotification;
