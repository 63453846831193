// @flow

import React from 'react';
import type { Node } from 'react';
import classNames from 'classnames';

import './link-button.scss';

type Props = {
  children: Node,
  className?: ?string,
  theme?: 'orange' | 'darkgray',
};

const LinkButton = (props: Props) => {
  const {
    children,
    className,
    theme,
    ...buttonProps
  } = props;
  const buttonClasses = classNames(
    'btn link-button',
    {
      'link-button_darkgray': theme === 'darkgray',
    },
    className,
  );

  return (
    <button
      {...buttonProps}
      type="button"
      className={buttonClasses}
    >
      {children}
    </button>
  );
};

LinkButton.defaultProps = {
  className: null,
  theme: 'orange',
};

export default LinkButton;
