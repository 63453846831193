// @flow
import React from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import urls from 'config/urls';
import { getCreateSupportRequestUrl } from 'utils/support';
import ErrorPage from 'components/error-page/error-page';

import './signup-member-token-error.scss';

type Props = {
  isInviteTokenExpired: boolean,
};

type Context = {
  isMobile: boolean,
}

const SignupMemberTokenError = ({ isInviteTokenExpired }: Props, context: Context) => {
  const { isMobile } = context;

  const getExpiredTokenMessage = isMobile ? (
    <FormattedMessage
      id="outer.signup.team.errorMobile"
      defaultMessage="Looks like you’ve already accepted your invitation to Setapp. Please get in touch with your team owner or log in to your account from your Mac."
    />
  ) : (
    <FormattedMessage
      id="outer.signup.team.error"
      defaultMessage="Looks like you’ve already accepted your invitation to Setapp. Please get in touch with your team owner or log in to your account."
    />
  );

  return (
    <ErrorPage
      errorTitle={
        isInviteTokenExpired
          && (
            <FormattedMessage
              id="outer.signup.team.inviteError.header"
              defaultMessage="We’re puzzled, too"
            />
          )
      }
    >
      {isInviteTokenExpired ? (
        getExpiredTokenMessage
      ) : (
        <FormattedMessage
          id="outer.signup.team.defaultError"
          defaultMessage="Please try again later or contact our {supportLink}"
          values={{
            supportLink: (
              <a href={getCreateSupportRequestUrl()}>
                <FormattedMessage
                  id="outer.signup.team.defaultErrorLink"
                  defaultMessage="support team"
                />
              </a>
            ),
          }}
        />
      )}
      {isInviteTokenExpired && (
        <div className="mt-8">
          {!isMobile && (
            <div className="signup-member-token-error__login-button mb-10">
              <Button
                block
                href={urls.login}
              >
                <FormattedMessage id="outer.signin.team.linkBtnCaption" defaultMessage="Sign in" />
              </Button>
            </div>
          )}
          <FormattedHTMLMessage
            id="outer.signup.team.invalidTokenError"
            defaultMessage="Contact <a href={supportLink}> support team</a>"
            values={{
              supportLink: getCreateSupportRequestUrl(),
            }}
          />
        </div>
      )}
    </ErrorPage>
  );
};

SignupMemberTokenError.contextTypes = {
  isMobile: PropTypes.bool,
};

export default SignupMemberTokenError;
