// @flow

import { type User } from './user-initial-state';

export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_PAYMENT_INFO = 'SET_USER_PAYMENT_INFO';
export const LOGOUT = 'LOGOUT_USER';
export const LOGIN = 'LOGIN_USER';
export const REQUEST = 'USER_REQUEST';
export const REQUEST_ERROR = 'USER_REQUEST_ERROR';
export const REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';

type SetUserInfoAction = {|
  type: 'SET_USER_INFO',
  payload: User,
|};

type SetUserPaymentInfoAction = {|
  type: 'SET_USER_PAYMENT_INFO',
  payload: boolean,
|};

type LogoutUserAction = {|
  type: 'LOGOUT_USER',
|};

type LoginUserAction = {|
  type: 'LOGIN_USER',
|};

type UserRequestAction = {|
  type: 'USER_REQUEST',
|};

type UserRequestSuccessAction = {|
  type: 'USER_REQUEST_SUCCESS',
|};

type UserRequestErrorAction = {|
  type: 'USER_REQUEST_ERROR',
  payload?: mixed,
|};

export type UserAction =
  | SetUserInfoAction
  | SetUserPaymentInfoAction
  | LogoutUserAction
  | LoginUserAction
  | UserRequestAction
  | UserRequestSuccessAction
  | UserRequestErrorAction;
