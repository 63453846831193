// @flow

import React, { useState, type Node } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import NumberField from 'components/shared/number-field/number-field';
import TaxNote from 'components/shared/tax-note/tax-note';

import MultiplyIcon from './multiply.svg';

import './purchase-invitations.scss';


const MIN_SEATS_COUNT = 1;
const MAX_SEATS_COUNT = 50;

type Props = {
  isProcessing: boolean,
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
  addMemberSeat: (number) => Promise<any>,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  onPurchaseFinish: ?() => void,
  onPurchaseSuccess: ?() => void,
  onPurchaseSubmit: ?(seatsAmount: number) => void,
}

const PurchaseInvitations = (props: Props) => {
  const {
    isProcessing,
    pricePerMonthPerMember,
    addMemberSeat,
    addSuccessNotification,
    addDangerNotification,
    onPurchaseFinish,
    onPurchaseSuccess,
    onPurchaseSubmit,
  } = props;

  const [seatsAmount, setSeatsAmount] = useState(MIN_SEATS_COUNT);
  const [hasError, setHasError] = useState(false);

  const handleSeatsAmountChange = (value: string) => {
    const seatsAmount = parseInt(value, 10);
    const isValid = seatsAmount >= MIN_SEATS_COUNT && seatsAmount <= MAX_SEATS_COUNT;

    if (isValid) {
      setSeatsAmount(seatsAmount);
    }

    setHasError(!isValid);
  };

  const handleConfirm = async () => {
    if (onPurchaseSubmit) {
      onPurchaseSubmit(seatsAmount);
    }

    try {
      await addMemberSeat(seatsAmount);
      addSuccessNotification({
        message: (
          <FormattedMessage
            id="purchaseInvitations.successfulOrder"
            defaultMessage="Your extra invitation payments are pending"
          />
        ),
      });

      if (onPurchaseSuccess) {
        onPurchaseSuccess();
      }
    } catch (error) {
      addDangerNotification({
        message: error.getSimplifiedErrors().genericError,
      });
    }

    if (onPurchaseFinish) {
      onPurchaseFinish();
    }
  };

  const errorClasses = classNames('has-error', {
    hidden: !hasError,
  });

  return (
    <div className="purchase-invitations">
      <Row>
        <Col md={6} className="text__lg">
          <FormattedMessage
            id="purchaseInvitations.price"
            defaultMessage="{price}/member"
            values={{
              price: <FormattedPrice price={pricePerMonthPerMember.price} currency={pricePerMonthPerMember.currency} />,
            }}
          />
          <img className="purchase-invitations__icon" src={MultiplyIcon} alt="" />
        </Col>
        <Col md={6} className="text-right">
          <NumberField
            defaultValue={seatsAmount}
            onValueChange={handleSeatsAmountChange}
            min={MIN_SEATS_COUNT}
            max={MAX_SEATS_COUNT}
          />
        </Col>
      </Row>
      <p className="purchase-invitations__note">
        <FormattedMessage
          id="purchaseInvitations.note"
          defaultMessage="You can purchase up to {members, plural, one {{members} invitation} other {{members} invitations}} at once"
          values={{
            members: MAX_SEATS_COUNT,
          }}
        />
      </p>
      <p className="purchase-invitations__description">
        <FormattedMessage
          id="purchaseInvitations.description"
          defaultMessage="Price is calculated according to your current billing plan till your next billing period."
        />
      </p>
      <div className="purchase-invitations__vat-container">
        <TaxNote />
      </div>
      <p className={errorClasses}>
        <span className="help-block">
          <FormattedMessage
            id="purchaseInvitations.seatsRangeError"
            defaultMessage="The seats amount may be in range from {minSeatsCount} and {maxSeatsCount}."
            values={{
              minSeatsCount: MIN_SEATS_COUNT,
              maxSeatsCount: MAX_SEATS_COUNT,
            }}
          />
        </span>
      </p>
      <Button
        block
        onClick={handleConfirm}
        disabled={isProcessing || hasError}
      >
        <FormattedMessage
          id="purchaseInvitations.confirmButtonTitle"
          defaultMessage="Confirm & purchase"
        />
      </Button>
    </div>
  );
};

PurchaseInvitations.defaultProps = {
  onPurchaseFinish: null,
  onPurchaseSuccess: null,
  onPurchaseSubmit: null,
};

export default PurchaseInvitations;
