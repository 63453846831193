// @flow

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  getActiveTeamMembers,
  getPricePerMonthPerMember,
  getTotalAmountForCustomers,
} from 'state/root-reducer';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import { FormattedPeriod, FormattedPrice } from 'components/shared/formatter/formatter';
import ActiveMembersCount from 'components/shared/active-members-count/active-members-count';
import PaddleVerified from 'components/shared/paddle-verified/paddle-verified';

import './pricing-plan-details.scss';

type Props = {
  activeMembers: Array<TeamMember>,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
  totalAmount: number,
  selectedPricePlan: PricePlan,
  paddleVerifiedClick: () => void,
}

const PricingPlanDetails = ({
  totalAmount,
  pricePerMemberPerMonth,
  activeMembers,
  selectedPricePlan,
  paddleVerifiedClick,
}: Props) => (
  <div className="pricing-plan-details">
    <span className="text_lg" data-qa="planPrice">
      <FormattedMessage
        id="activatePage.paymentDetailsScreen.pricingPlanDetails.priceRowTitle"
        defaultMessage="Plan: {planOverview}"
        values={{
          planOverview: (
            <strong>
              <FormattedMessage
                id="activatePage.paymentDetailsScreen.pricingPlanDetails.priceRowValue"
                defaultMessage="{paidMonth, plural, one {Monthly} =12 {Annual} other {Monthly}}, {price}/mo/member"
                values={{
                  price: (
                    <FormattedPrice
                      price={pricePerMemberPerMonth.price}
                      currency={pricePerMemberPerMonth.currency}
                    />
                  ),
                  paidMonth: selectedPricePlan.paidMonth,
                }}
              />
            </strong>
          ),
        }}
      />
    </span>
    <div className="text_lg mt-3" data-qa="activeMembersCount">
      <FormattedMessage
        id="activatePage.paymentDetailsScreen.pricingPlanDetails.activeMemberCount"
        defaultMessage="Members in team: {membersCount}"
        values={{
          membersCount: (
            <strong>
              <ActiveMembersCount withTooltip={false} activeMembers={activeMembers} />
            </strong>
          ),
        }}
      />
    </div>
    <div className="pricing-plan-details__delimiter mb-3 mt-4" />
    <span className="text_lg" data-qa="total">
      <FormattedMessage
        id="activatePage.paymentDetailsScreen.pricingPlanDetails.total"
        defaultMessage="Total: {total}"
        values={{
          total: (
            <strong>
              <FormattedPrice
                price={totalAmount}
                currency={selectedPricePlan.currency}
              />
              /
              <FormattedPeriod
                paidMonth={selectedPricePlan.paidMonth}
              />
            </strong>
          ),
        }}
      />
    </span>
    <span className="text_xs ml-3 pricing-plan-details__total-note">
      <FormattedMessage
        id="activatePage.paymentDetailsScreen.pricingPlanDetails.total.note"
        defaultMessage="+ sales tax"
      />
    </span>
    <div className="text_xs pricing-plan-details__note">
      <FormattedMessage
        id="activatePage.paymentDetailsScreen.pricingPlanDetails.note"
        defaultMessage="Your total is calculated according to the number of members on your Team and your Setapp plan. Add new members anytime."
      />
    </div>
    <div className="pricing-plan-details__paddle-verified-wrapper">
      <PaddleVerified onClick={paddleVerifiedClick} />
    </div>
  </div>
);

/* istanbul ignore next */
const mapStateToProps = (state, { selectedPricePlan }) => ({
  pricePerMemberPerMonth: getPricePerMonthPerMember(state, selectedPricePlan.priceKey),
  totalAmount: getTotalAmountForCustomers(state, selectedPricePlan),
  activeMembers: getActiveTeamMembers(state),
});

export { PricingPlanDetails as PurePricingPlanDetails };

export default connect(mapStateToProps)(PricingPlanDetails);
