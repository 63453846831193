// @flow

import React from 'react';
import type { Node } from 'react';

import { FormattedMessage } from 'react-intl';

import InfoField from 'components/shared/info-field/info-field';

import SubscriptionPrice from './subscription-price/subscription-price';

type Props = {|
  tax: number,
  price: number,
  currency: string,
  paidMonth: number,
  title?: Node,
|};

const PriceRow = ({
  price,
  tax,
  currency,
  paidMonth,
  title,
}: Props) => (
  <InfoField
    lightValue
    title={title || <FormattedMessage id="subscriptionInfo.priceTitle" defaultMessage="Current Price" />}
    value={(
      <SubscriptionPrice
        price={price}
        currency={currency}
        paidMonth={paidMonth}
        tax={tax}
      />
    )}
    qaLabel="planPrice"
  />
);

PriceRow.defaultProps = {
  title: null,
};

export default PriceRow;
