// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import InfoField from 'components/shared/info-field/info-field';

type Props = {|
  additionalSeatsAmount: number,
|};

const AdditionalSeatsAmountRow = ({ additionalSeatsAmount }: Props) => (
  <InfoField
    lightValue
    title={<FormattedMessage id="subscriptionInfo.additionalSeatsAmount.title" defaultMessage="Amount" />}
    value={(
      <FormattedMessage
        id="subscriptionInfo.additionalSeatsAmount.value"
        defaultMessage="{additionalSeatsAmount, plural, one {{additionalSeatsAmount} extra device} other {{additionalSeatsAmount} extra devices}}"
        values={{ additionalSeatsAmount }}
      />
    )}
    qaLabel="additionalSeatsAmount"
  />
);

export default AdditionalSeatsAmountRow;
