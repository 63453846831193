// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { Tabs } from '@setapp/ui-kit';

import { apiURL } from 'config/api';

import request from 'utils/request';
import analytics, { events } from 'utils/analytics';

import DefaultError from 'components/shared/default-error/default-error';

import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import type { PaymentMethodType } from 'state/payment-method/payment-method-initial-state';

import type { PaymentFormConfig } from './payment-form-config-type';
import PaymentTabNavContainer from './payment-form-tab-item/payment-form-tab-item';
import BrainTreePayPalForm from './paypal-form/braintree-paypal-form';
import BrainTreeCreditCardForm from './credit-card-form/braintree-credit-card-form';


type Props = {
  onSuccessSubmit?: ?() => mixed,
  paymentMethod: PaymentMethodType,
  submitBtnTitle?: ?Node,
};

type State = {|
  formConfig: PaymentFormConfig,
  initializationError: Node,
|};

class PaymentMethodForm extends Component<Props, State> {
  state = {
    formConfig: {
      token: '',
      threeDSecureRequired: false,
      additional: {
        merchantId: '',
      },
      countries: {},
    },
    initializationError: null,
  };

  static defaultProps = {
    paymentMethod: paymentMethodTypes.CREDIT_CARD,
    onSuccessSubmit: null,
    submitBtnTitle: null,
  };

  componentDidMount() {
    const { paymentMethod } = this.props;

    this.sendFormDisplayedAnalyticsEvent();
    this.sendPaymentMethodSelectedAnalyticsEvent(paymentMethod);
    this.fetchFormConfig();
  }

  componentDidUpdate(prevProps: Props) {
    const { paymentMethod } = this.props;
    const { paymentMethod: previousPaymentMethod } = prevProps;

    if (paymentMethod !== previousPaymentMethod) {
      this.sendFormDisplayedAnalyticsEvent();
    }
  }

  render() {
    const { formConfig, initializationError } = this.state;
    const { onSuccessSubmit, submitBtnTitle, paymentMethod } = this.props;

    return (
      <Tabs
        onTabSelect={this.handleTabClick}
        defaultActiveKey={paymentMethod}
        tabsId="payment-methods"
        tabs={[
          {
            key: paymentMethodTypes.CREDIT_CARD,
            title: <PaymentTabNavContainer type={paymentMethodTypes.CREDIT_CARD} />,
            content: (
              <BrainTreeCreditCardForm
                onSuccessSubmit={onSuccessSubmit}
                submitBtnTitle={submitBtnTitle}
                formConfig={formConfig}
                initializationError={initializationError}
              />
            ),
          },
          {
            key: paymentMethodTypes.PAYPAL,
            title: <PaymentTabNavContainer type={paymentMethodTypes.PAYPAL} />,
            content: (
              <BrainTreePayPalForm
                onSuccessSubmit={onSuccessSubmit}
                submitBtnTitle={submitBtnTitle}
                formConfig={formConfig}
                initializationError={initializationError}
              />
            ),
          },
        ]}
      />
    );
  }

  fetchFormConfig() {
    return request.get(apiURL.paymentConfig)
      .then((formConfig: PaymentFormConfig) => {
        this.setState({ formConfig });
      })
      .catch(() => {
        this.setState({ initializationError: <DefaultError /> });
      });
  }

  handleTabClick = (tabKey: string) => {
    this.sendPaymentMethodSelectedAnalyticsEvent(tabKey);
  };

  sendFormDisplayedAnalyticsEvent() {
    analytics.trackEvent(events.PAYMENT_DETAILS_FORM_DISPLAYED, { eventLabel: 'Braintree' });
  }

  sendPaymentMethodSelectedAnalyticsEvent(paymentMethod: string) {
    analytics.trackEvent(events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT, { eventLabel: paymentMethod });
  }
}

export default PaymentMethodForm;
