import RequestError from '@setapp/request-error';
import merge from 'deepmerge';
import HTTPStatus from 'http-status';
import { camelizeKeys, decamelizeKeys } from 'humps';

import urls from 'config/urls';
import { detectLocale, browserToServerLocale } from 'utils/intl';
import browserHistory from 'utils/custom-history';

import auth from './auth';
import { getTokenManager } from './service-locators';


const tokenManager = getTokenManager();

function buildRequest(url, meta) {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'Accept-Language': browserToServerLocale(detectLocale()),
    },
  };

  return function request() {
    if (!meta.withoutTokenAuth) {
      options.headers.Authorization = `Bearer ${tokenManager.getAccessToken()}`;
    }

    const { body, ...restMeta } = meta;

    if (body) {
      options.body = JSON.stringify(decamelizeKeys(meta.body));
    }

    return fetch(url, merge(options, restMeta));
  };
}

function handleExpiredAuthToken(request, response) {
  if (response.status !== HTTPStatus.UNAUTHORIZED) {
    return Promise.resolve(response);
  }

  return auth
    .requestNewAccessToken()
    .catch(() => {
      // behave as unauthenticated user request on all errors during requesting new access token
      browserHistory.push(urls.login);

      return Promise.reject(response);
    })
    .then(request);
}

function checkStatus(response) {
  if (!response.ok) {
    return Promise.reject(response);
  }

  return Promise.resolve(response);
}

function getJSON(response) {
  if (response.status === HTTPStatus.NO_CONTENT || response.status === HTTPStatus.CREATED) {
    return Promise.resolve({});
  }

  return response.json()
    .catch(() => Promise.reject('Invalid response format')); // TODO: logger.logError
}

function formatError(response) {
  // It's probably caused by no internet connection
  if (!(response instanceof Response)) {
    throw RequestError.create(response);
  }

  return getJSON(response)
    .catch(() => Promise.reject(RequestError.create(response)))
    .then((responseBody) => Promise.reject(RequestError.create(response, responseBody)));
}

function camelizeRules(key, convert) {
  return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
}

export function sendRequest(url, options = {}) {
  const request = buildRequest(url, options);

  return request()
    .then((response) => handleExpiredAuthToken(request, response))
    .then(checkStatus)
    .then(getJSON)
    .then((responseJSON) => camelizeKeys(responseJSON.data, camelizeRules))
    .catch(formatError);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get(url) {
    return sendRequest(url, { method: 'GET' });
  },

  post(url, meta) {
    const data = merge(meta, { method: 'POST' });

    return sendRequest(url, data);
  },

  patch(url, meta) {
    const data = merge(meta, { method: 'PATCH' });

    return sendRequest(url, data);
  },

  put(url, meta) {
    const data = merge(meta, { method: 'PUT' });

    return sendRequest(url, data);
  },

  delete(url) {
    return sendRequest(url, { method: 'DELETE' });
  },
};
