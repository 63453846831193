// @flow

import type { RequestOptions } from './requets-options-type';
import type { Subscription } from './subscription-initial-state';

export const LIST_REQUEST = 'SUBSCRIPTIONS_LIST_REQUEST';
export const LIST_REQUEST_SUCCESS = 'SUBSCRIPTIONS_LIST_REQUEST_SUCCESS';

export const ORDER_REQUEST = 'SUBSCRIPTION_ORDER_REQUEST';
export const ORDER_REQUEST_SUCCESS = 'SUBSCRIPTION_ORDER_REQUEST_SUCCESS';

export const UPDATE_REQUEST = 'SUBSCRIPTION_UPDATE_REQUEST';
export const UPDATE_REQUEST_SUCCESS = 'SUBSCRIPTION_UPDATE_REQUEST_SUCCESS';

// TODO probably split to list request error and single request error
export const REQUEST_ERROR = 'SUBSCRIPTION_REQUEST_ERROR';

export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const UPDATE_TEAM_SUBSCRIPTION = 'UPDATE_TEAM_SUBSCRIPTION';

type ListRequestAction = {|
  type: 'SUBSCRIPTIONS_LIST_REQUEST',
  meta: RequestOptions,
|};

type ListRequestSuccessAction = {|
  type: 'SUBSCRIPTIONS_LIST_REQUEST_SUCCESS',
  payload?: Array<Subscription>,
|};

type OrderRequestAction = {|
  type: 'SUBSCRIPTION_ORDER_REQUEST',
  meta: RequestOptions,
|};

type OrderRequestSuccessAction = {|
  type: 'SUBSCRIPTION_ORDER_REQUEST_SUCCESS',
  payload: Subscription,
|};

type UpdateRequestAction = {|
  type: 'SUBSCRIPTION_UPDATE_REQUEST',
  payload: number,
  meta: RequestOptions,
|};

type UpdateRequestSuccessAction = {|
  type: 'SUBSCRIPTION_UPDATE_REQUEST_SUCCESS',
  payload: Subscription,
|};

type RequestErrorAction = {|
  type: 'SUBSCRIPTION_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

type AddAction = {|
  type: 'ADD_SUBSCRIPTION',
  payload: Subscription,
|};

type UpdateTeamAction = {|
  type: 'UPDATE_TEAM_SUBSCRIPTION',
  payload: Subscription,
|};

export type SubscriptionsAction =
  | ListRequestAction
  | ListRequestSuccessAction
  | OrderRequestAction
  | OrderRequestSuccessAction
  | UpdateRequestAction
  | UpdateRequestSuccessAction
  | RequestErrorAction
  | AddAction
  | UpdateTeamAction;
