// @flow

import initialState, { type PricePlanState, type PricePlan } from './price-plans-initial-state';
import type { PricePlanKey } from './price-plans-keys';
import * as actionTypes from './price-plans-action-types';

const pricePlans = (state: PricePlanState = initialState, action: actionTypes.PricePlansAction): PricePlanState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { list: action.payload, isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default pricePlans;

export const getList = (state: PricePlanState) => state.list;
export const getAllActive = (state: PricePlanState): Array<PricePlan> => getList(state).filter((plan) => plan.active);
export const getActiveByGroup = (state: PricePlanState, group: string): Array<PricePlan> => getAllActive(state)
  .filter((plan) => plan.group === group);
export const getFirstActiveByPriceKey = (state: PricePlanState, key: PricePlanKey) => getAllActive(state)
  .filter((plan) => plan.priceKey === key)[0] || {};
