// @flow

import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Notification } from '@setapp/ui-kit';
import { connect } from 'react-redux';
import type { Notification as NotificationType } from 'state/notifier/notifier-initial-state';

import './notifications-container.scss';

import { getNotification } from 'state/root-reducer';
import { removeNotification } from 'state/notifier/notifier-actions';

type Props = {
  notifications: Array<NotificationType>,
  removeNotification: (string) => void,
};

const NotificationContainer = ({ notifications, removeNotification }: Props) => {
  const fadeDuration = 500;

  const onNotificationClose = (notification: NotificationType) => {
    if (!notification.hideCloseButton) {
      return () => removeNotification(notification.id);
    }

    return null;
  };

  return (
    <TransitionGroup className="notifications__list">
      {
        notifications.map((notification) => (
          <CSSTransition
            classNames="notifier"
            timeout={fadeDuration}
            key={notification.id}
          >
            <div className="notifications__item">
              <Notification
                type={notification.type}
                withIcon
                onClose={onNotificationClose(notification)}
              >
                {notification.message}
              </Notification>
            </div>
          </CSSTransition>
        ))
      }
    </TransitionGroup>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  notifications: getNotification(state),
});

export { NotificationContainer as PureNotificationContainer };

export default connect(mapStateToProps, { removeNotification })(NotificationContainer);
