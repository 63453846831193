// @flow

import React, { PureComponent, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';
import { cancelSubscription } from 'state/subscription/subscription-actions';
import { getPrimarySubscription, getSubscriptions } from 'state/root-reducer';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import analytics, { events } from 'utils/analytics';

import { SupportIconLink, HelpIconLink } from './icon-link/icon-link';


import './cancel-subscription-modal.scss';

type Props = {
  show: boolean,
  onHide: () => any,
  onExited: () => any,
  isProcessing: boolean,
  cancelSubscription: ({subscriptionId: number}) => Promise<void>,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  mainSubscription: ?Subscription,
  onSubscriptionCanceled: ?() => mixed,
};

class CancelSubscriptionModal extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      onExited,
      isProcessing,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={(
          <FormattedMessage
            id="subscriptionInfo.cancelModal.title"
            defaultMessage="Before you go, maybe we can help?"
          />
        )}
      >
        <div>
          <p>
            <FormattedMessage
              id="subscriptionInfo.cancelModal.content"
              defaultMessage="We just want to make sure it’s not some silly issue that makes you leave."
            />
          </p>

          <div className="subscription-cancel-modal__icons-container text-center">
            <div className="subscription-cancel-modal__icon">
              <SupportIconLink />
            </div>
            <div className="subscription-cancel-modal__icon">
              <HelpIconLink />
            </div>
          </div>

          <Button
            variant="danger-outline"
            onClick={this.cancelSubscription}
            block
            disabled={isProcessing || !show}
            data-qa="cancelMySubscriptionBtn"
          >
            <FormattedMessage id="subscriptionInfo.cancelModal.btnConfirm" defaultMessage="Cancel my subscription" />
          </Button>

          <Button
            variant="primary"
            className="mt-3"
            onClick={onHide}
            block
            disabled={isProcessing || !show}
          >
            <FormattedMessage id="subscriptionInfo.cancelModal.btnStay" defaultMessage="Stay with Setapp" />
          </Button>
        </div>
      </Modal>
    );
  }

  cancelSubscription = () => {
    const {
      mainSubscription,
      cancelSubscription,
      onHide,
      addSuccessNotification,
      addDangerNotification,
      onSubscriptionCanceled,
    } = this.props;

    if (!mainSubscription) {
      throw new Error('Main subscription is not fetched');
    }

    return cancelSubscription({ subscriptionId: mainSubscription.id })
      .then(() => {
        analytics.trackEvent(events.CANCEL_SUBSCRIPTION_CONFIRM);

        addSuccessNotification({
          message: (
            <FormattedMessage
              id="subscriptionInfo.statusNotifications.messages.cancelSuccess"
              defaultMessage="Your subscription has been cancelled."
            />
          ),
        });

        if (onSubscriptionCanceled) {
          onSubscriptionCanceled();
        }
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      })
      .finally(onHide);
  };
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  mainSubscription: getPrimarySubscription(state),
  isProcessing: getSubscriptions(state).isLoading,
});

const mapActionsToProps = {
  cancelSubscription,
  addSuccessNotification,
  addDangerNotification,
};

export { CancelSubscriptionModal as PureCancelSubscriptionModal };

export default connect(mapStateToProps, mapActionsToProps)(CancelSubscriptionModal);
