import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import { withRouter } from 'react-router-dom';
import { FormFieldWrapper } from '@setapp/ui-kit';

import Link from 'components/shared/link/link';
import urls from 'config/urls';

const CurrentPasswordField = (props) => {
  const {
    id,
    label,
    invalid,
    helpText,
    qaLabel,
    showLabel,
    email,
    history,
    staticContext, // eslint-disable-line no-unused-vars
    ...inputProps
  } = props;

  return (
    <FormFieldWrapper
      id={id}
      label={label}
      invalid={invalid}
      helpText={helpText}
      qaLabel={qaLabel}
      showLabel={showLabel}
    >
      <InputGroup>
        <FormControl
          {...inputProps}
          id={id}
          type="password"
          autoComplete="current-password"
        />
        <InputGroup.Addon>
          <Link
            data-qa="forgotPasswordLink"
            to={{
              pathname: urls.recoverPassword,
              query: email ? { email } : {},
              state: { previousPage: history.location.pathname },
            }}
          >
            <FormattedMessage id="currentPasswordInput.forgotLinkTitle" defaultMessage="Forgot?" />
          </Link>
        </InputGroup.Addon>
      </InputGroup>
    </FormFieldWrapper>
  );
};

CurrentPasswordField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  email: PropTypes.string,
  invalid: PropTypes.bool,
  helpText: PropTypes.node,
  qaLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CurrentPasswordField.defaultProps = {
  email: '',
  invalid: false,
  helpText: '',
  qaLabel: '',
  showLabel: false,
};

export { CurrentPasswordField as PureCurrentPasswordField };

export default withRouter(CurrentPasswordField);
