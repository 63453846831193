// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ReCaptcha from 'react-google-recaptcha';
import queryString from 'query-string';

import type { Node, ElementRef } from 'react';
import type { Location } from 'react-router-dom';
import CaptchaError from 'components/shared/form/captcha-error/captcha-error';

import urls from 'config/urls';

import auth from 'utils/auth';
import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

import RecoverPasswordForm from './recover-password-form';


type Props = {
  location: Location,
};

type State = {
  fields: {
    email: string,
    captcha: string,
  },
  fieldsErrors: {
    email: Node,
    captcha: Node,
  },
  genericError: Node,
  isRequestProcessing: boolean,
  isRequestConfirmed: boolean,
  requireCaptcha: boolean,
};

class RecoverPasswordPage extends PureComponent<Props, State> {
  validation = {
    email: {
      required: <FormattedMessage id="outer.recoverPassword.validation.emptyEmail" defaultMessage="Email is required" />,
      emailFormat: <FormattedMessage id="outer.recoverPassword.validation.invalidEmail" defaultMessage="Invalid email" />,
    },
  };

  captcha: ?ElementRef<typeof ReCaptcha>;

  constructor(props: Props) {
    super(props);

    const query = queryString.parse(props.location.search);
    this.state = {
      fields: {
        email: query.email || '',
        captcha: '',
      },
      fieldsErrors: {
        email: '',
        captcha: '',
      },
      genericError: '',
      isRequestProcessing: false,
      isRequestConfirmed: false,
      requireCaptcha: false,
    };
  }

  render() {
    const {
      genericError,
      isRequestProcessing,
      isRequestConfirmed,
      fields,
      fieldsErrors,
      requireCaptcha,
    } = this.state;
    const { email: emailError, captcha: captchaError } = fieldsErrors;
    const { location } = this.props;
    /**
     * $FlowFixMe 'state' key was removed from Location type for some reason
     * @see https://github.com/flow-typed/flow-typed/issues/3742
     * @see https://github.com/flow-typed/flow-typed/pull/3772
     */
    const previousPage = location?.state?.previousPage ?? urls.account;

    return (
      <RecoverPasswordForm
        onEmailChange={this.onEmailChange}
        onFormSubmit={this.onFormSubmit}
        emailError={emailError}
        genericError={genericError}
        previousPage={previousPage}
        isRequestProcessing={isRequestProcessing}
        isRequestConfirmed={isRequestConfirmed}
        email={fields.email}
        showCaptcha={requireCaptcha}
        captchaError={captchaError}
        onCaptchaChange={this.onCaptchaChange}
        setCaptchaRef={this.setCaptchaRef}
      />
    );
  }

  onEmailChange = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        email: value,
      },
    }));
  };

  onCaptchaChange = (value: string) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        captcha: value,
      },
    }));
  };

  setProcessing(isRequestProcessing: boolean) {
    this.setState({ isRequestProcessing });
  }

  displaySuccessMessage() {
    this.setState({ isRequestConfirmed: true });
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const { fields } = this.state;
    const validationResult = validate(fields, this.validation);

    const formHasError = Object.keys(validationResult).some((field) => validationResult[field]);

    this.setState((prevState) => ({
      fieldsErrors: {
        ...prevState.fieldsErrors,
        ...validationResult,
      },
    }));

    if (formHasError) {
      this.setState({ genericError: '' });

      return Promise.resolve();
    }

    this.setProcessing(true);

    return auth.requestPasswordReset(fields)
      .then(() => {
        analytics.trackEvent(events.NEW_PASSWORD_REQUEST);

        this.setProcessing(false);
        this.displaySuccessMessage();
      })
      .catch((error) => {
        if (this.captcha) {
          this.captcha.reset();
        }

        const { genericError, fieldsErrors } = error.getSimplifiedErrors();
        const captchaError = error.getFieldError('captcha');

        this.setProcessing(false);

        this.setState((prevState) => ({
          requireCaptcha: prevState.requireCaptcha || Boolean(captchaError),
          genericError,
          fieldsErrors: {
            ...prevState.fieldsErrors,
            ...fieldsErrors,
            captcha: captchaError ? <CaptchaError error={captchaError} /> : '',
          },
        }));
      });
  };

  setCaptchaRef = (ref: ElementRef<typeof ReCaptcha>) => {
    this.captcha = ref;
  }
}

export { RecoverPasswordPage as PureRecoverPasswordPage };

export default RecoverPasswordPage;
