// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownItem } from '@setapp/ui-kit';
import { Link, withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import type { Node } from 'react';

import DefaultError from 'components/shared/default-error/default-error';

import { logout as logOut } from 'state/user/user-actions';
import { addDangerNotification } from 'state/notifier/notifier-actions';

import externalUrls from 'config/external-urls';
import urls from 'config/urls';

import analytics from 'utils/analytics';
import * as events from 'utils/analytics/events';

import HeaderToggler from '../header-toggler/header-toggler';


import './profile-dropdown.scss';

type Props = {
  logOut: () => Promise<void>,
  history: RouterHistory,
  addDangerNotification: ({message: Node}) => mixed,
};

class ProfileDropdown extends PureComponent<Props> {
  render() {
    return (
      <>
        <span className="profile-dropdown__icon hidden-xs">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <path
              clipRule="evenodd"
              d="m32 16c0 5.0528-2.3421 9.5585-6 12.4907-2.7392 2.1959-6.2162 3.5093-10 3.5093s-7.2608-1.3134-10-3.5093c-3.65785-2.9322-6-7.4379-6-12.4907 0-8.83656 7.16344-16 16-16 8.8366 0 16 7.16344 16 16zm-6.5881 10.3643c.3874-.352.5881-.8595.5881-1.3829v-.4814c0-3.0564-2.1939-5.6004-5.0929-6.1431-.3015-.0564-.6031.0606-.831.2658-1.042.9382-2.406 1.6272-4.0761 1.6272s-3.0341-.689-4.0761-1.6272c-.2279-.2052-.5295-.3222-.831-.2658-2.89895.5427-5.0929 3.0867-5.0929 6.1431v.4814c0 .5234.20073 1.0309.58811 1.3829 2.48606 2.259 5.78819 3.6357 9.41189 3.6357s6.9258-1.3767 9.4119-3.6357zm-9.4119-8.231c3.125 0 4.9071-3.6333 4.9071-6.1333s-1.1571-5.06667-4.9071-5.06667-4.9071 2.56667-4.9071 5.06667 1.7821 6.1333 4.9071 6.1333z"
              fill="#e6a873"
              fillRule="evenodd"
            />
          </svg>
        </span>
        <Dropdown
          id="headerUserProfileActions"
          positionRight
          toggleElement={
            <HeaderToggler>
              <FormattedMessage id="profileDropdown.toggler" defaultMessage="Account" />
            </HeaderToggler>
          }
        >
          <DropdownItem>
            <Link to={urls.account} className="app-header__dropdown-item">
              <FormattedMessage id="profileDropdown.account" defaultMessage="Settings" />
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <a href={externalUrls.termsOfUse} className="app-header__dropdown-item" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="profileDropdown.termsLink" defaultMessage="Terms of Use" />
            </a>
          </DropdownItem>
          <DropdownItem>
            <a href={externalUrls.privacy} className="app-header__dropdown-item" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="profileDropdown.privacyLink" defaultMessage="Privacy Policy" />
            </a>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.onLogoutClick}>
            <FormattedMessage id="profileDropdown.signOut" defaultMessage="Sign out" />
          </DropdownItem>
        </Dropdown>
      </>
    );
  }

  onLogoutClick = () => {
    const { logOut, history, addDangerNotification } = this.props;

    return logOut()
      .then(() => {
        analytics.trackEvent(events.LOGOUT_SUCCESS);

        history.push(urls.login);
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }
}

const mapActionsToProps = {
  addDangerNotification,
  logOut,
};

export { ProfileDropdown as PureProfileDropdown };

export default connect(
  null,
  mapActionsToProps,
)(withRouter(ProfileDropdown));
