// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  paidMonth: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
}

const PlanRowValue = ({ paidMonth, pricePerMemberPerMonth: { price, currency } }: Props) => (
  <FormattedMessage
    id="subscriptionInfo.priceRowValue"
    defaultMessage="{price} per member/month, billed {paidMonth, plural, one {monthly} =12 {annually} other {monthly}}"
    values={{
      price: <FormattedPrice price={price} currency={currency} />,
      paidMonth,
    }}
  />
);

export default PlanRowValue;
