// @flow

import React, { PureComponent, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';
import analytics from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';

import { addDangerNotification } from 'state/notifier/notifier-actions';
import { sendDownloadLinkEmail } from 'state/user/user-actions';

import DefaultError from 'components/shared/default-error/default-error';
import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import SetappDownloaded from './setapp-downloaded/setapp-downloaded';
import LinkSent from './link-sent/link-sent';


import './successful-registration-for-mobile.scss';

type Props = {
  userEmail: string,
  sendDownloadLinkEmail: () => Promise<void>,
  addDangerNotification: ({message: Node}) => mixed,
};

type State = {
  isLinkSent: boolean,
  isSetappDownloaded: boolean,
};

class SuccessfulRegistrationForMobile extends PureComponent<Props, State> {
  state = {
    isLinkSent: false,
    isSetappDownloaded: false,
  };

  render() {
    const { userEmail } = this.props;
    const {
      isLinkSent,
      isSetappDownloaded,
    } = this.state;

    if (isLinkSent) {
      return (
        <LinkSent userEmail={userEmail} />
      );
    }

    if (isSetappDownloaded) {
      return (
        <SetappDownloaded />
      );
    }

    return (
      <div className="successful-registration-for-mobile">
        <Row>
          <Col smOffset={2} sm={8}>
            <h3 className="successful-registration-for-mobile__title">
              <FormattedMessage
                id="successfulRegistrationForMobile.title"
                defaultMessage="You’re in"
              />
            </h3>

            <div className="mb-5">
              <FormattedMessage
                id="successfulRegistrationForMobile.subTitle.line1"
                defaultMessage="Setapp is not on mobile yet. We can send a download link, and you can get Setapp when you get back on your Mac."
              />
            </div>
            <div className="mb-5">
              <FormattedMessage
                id="successfulRegistrationForMobile.subTitle.line2"
                defaultMessage="Also, you can download Setapp on your phone and install it later from the Download folder on your Mac."
              />
            </div>

            <Button
              block
              className="successful-registration-for-mobile__send-link-button"
              onClick={this.handleSendLinkButtonClick}
            >
              <FormattedMessage
                id="successfulRegistrationForMobile.cta.sendLinkCaption"
                defaultMessage="Send me a link"
              />
            </Button>

            <SetappDownloadLinkWrapper>
              <Button
                block
                href={externalUrls.downloadDesktopClient}
                onClick={this.handleDownloadButtonClick}
                variant="primary-outline"
              >
                <FormattedMessage
                  id="successfulRegistrationForMobile.cta.downloadCaption"
                  defaultMessage="Download to this device"
                />
              </Button>
            </SetappDownloadLinkWrapper>
          </Col>
        </Row>
      </div>
    );
  }

  handleSendLinkButtonClick = async () => {
    const {
      sendDownloadLinkEmail,
      addDangerNotification,
    } = this.props;

    try {
      await sendDownloadLinkEmail();
      this.setState({ isLinkSent: true });
    } catch (error) {
      addDangerNotification({
        message: <DefaultError />,
      });
    }
    analytics.trackEvent(gaEvents.MOBILE_FLOW_CLICK_SEND_ME_LINK, { eventLabel: 'Team Member' });
  }

  handleDownloadButtonClick = () => {
    this.setState({ isSetappDownloaded: true });
    analytics.trackEvent(gaEvents.MOBILE_FLOW_CLICK_DOWNLOAD_TO_THIS_DEVICE);
  }
}

const mapActionsToProps = {
  sendDownloadLinkEmail,
  addDangerNotification,
};

export { SuccessfulRegistrationForMobile as PureSuccessfulRegistrationForMobile };

export default connect(null, mapActionsToProps)(SuccessfulRegistrationForMobile);
