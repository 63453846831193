// @flow

import React from 'react';

import './footer.scss';

const Footer = () => (
  <footer className="footer text_xs">
    <span>{`© ${new Date().getFullYear()} MacPaw Way Ltd., 6 Maximou Michailidi Str, Maximos Plaza Tower 3, 4th floor, Flat/Office 401, 3106, Limassol, Cyprus`}</span>
  </footer>
);

export default Footer;
