// @flow

import queryString from 'query-string';

export function isPpc() {
  const query = queryString.parse(window.location.search);

  return 'gclid' in query || 'fbclid' in query;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isPpc,
};
