// @flow

import endOfMonth from 'date-fns/endOfMonth';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

import type { PaymentDetailsState, InvoicingInformation } from './payment-method-initial-state';

import initialPaymentMethod from './payment-method-initial-state';
import * as actionTypes from './payment-method-action-types';

const paymentMethod = (
  state: PaymentDetailsState = initialPaymentMethod,
  action: actionTypes.PaymentMethodAction,
): PaymentDetailsState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return {
        data: action.payload,
        error: null,
        isLoading: false,
        isFetched: true,
      };
    }

    case actionTypes.REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }

    case actionTypes.REMOVE_PAYMENT_METHOD: {
      return {
        ...initialPaymentMethod,
        isFetched: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default paymentMethod;

export const isCreated = (state: PaymentDetailsState) => Boolean(state.data);

export const isFetched = (state: PaymentDetailsState) => Boolean(state.isFetched);

export const creditCardExpiration = (state: PaymentDetailsState) => {
  if (!state.data || state.data.type !== 'card') {
    return {
      isExpired: false,
      isAboutToExpire: false,
    };
  }

  const { cardExpM, cardExpY } = state.data.details;
  const lastDay = endOfMonth(new Date(cardExpY, cardExpM - 1));
  const twoWeeksBeforeLastDay = startOfDay(subDays(lastDay, 14));
  const now = new Date();
  const isExpired = now > lastDay;

  return { isExpired, isAboutToExpire: !isExpired && now >= twoWeeksBeforeLastDay };
};

export const getBusinessAccountDetails = (state: PaymentDetailsState): ?InvoicingInformation => {
  if (!state.data) {
    return null;
  }

  return {
    vatId: state.data.vatId,
    company: state.data.company,
    streetName: state.data.streetName,
    addressDetail: state.data.addressDetail,
    buildingNumber: state.data.buildingNumber,
    city: state.data.city,
    postalCode: state.data.postalCode,
  };
};
