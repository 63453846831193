// @flow

import * as actionTypes from './jamf-action-types';
import initialState, { type JamfState } from './jamf-initial-state';

const jamf = (state: JamfState = initialState, action: actionTypes.JamfAction): JamfState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      return { ...action.payload, isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default jamf;
