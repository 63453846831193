import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, SelectField } from '@setapp/ui-kit';

class UserLocaleForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locale: props.currentLocale,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onLocaleChange = this.onLocaleChange.bind(this);
  }

  render() {
    const { locale } = this.state;
    const { isLoading, supportedLocales } = this.props;

    return (
      <div>
        <h5>
          <FormattedMessage id="accountSettings.userLocales.title" defaultMessage="Account language" />
        </h5>

        <form onSubmit={this.onFormSubmit} noValidate>
          <SelectField
            id="locale"
            name="locale"
            value={locale}
            options={supportedLocales}
            onChange={this.onLocaleChange}
          />

          <Button
            variant="secondary-outline"
            type="submit"
            disabled={isLoading}
            data-qa="updateLanguageBtn"
          >
            <FormattedMessage id="accountSettings.userLocales.updateButton" defaultMessage="Change language" />
          </Button>
        </form>
      </div>
    );
  }

  onLocaleChange(e) {
    this.setState({ locale: e.target.value });
  }

  onFormSubmit = (e) => {
    e.preventDefault();

    const { onSaveLocale } = this.props;
    const { locale } = this.state;

    onSaveLocale(locale);
  };
}

UserLocaleForm.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  supportedLocales: PropTypes.objectOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool,
  onSaveLocale: PropTypes.func.isRequired,
};

UserLocaleForm.defaultProps = {
  isLoading: false,
};

export default UserLocaleForm;
