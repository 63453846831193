import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import external from 'config/external-urls';

import braintreeLock from './images/braintree-lock.svg';


import './braintree-verified.scss';

const BraintreeVerified = ({ merchantId }) => (
  <a
    href={external.braintreeProfile.replace('%{merchantId}', merchantId)}
    className="braintree-verified-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={braintreeLock} alt="" />
    <span className="braintree-verified-text text_sm">
      <FormattedMessage
        id="braintreeVerified.label"
        defaultMessage="Payment information is protected."
      />
    </span>
  </a>
);

BraintreeVerified.propTypes = {
  merchantId: PropTypes.string.isRequired,
};

export default BraintreeVerified;
