// @flow

import React, { type ElementConfig, type Node } from 'react';
import { Button, Tooltip } from '@setapp/ui-kit';

import './share-role-button.scss';

type Props = {
  ...ElementConfig<typeof Button>,
  tooltipContent: Node,
};

const ShareRoleButton = ({ disabled, tooltipContent, ...elementProps }: Props) => {
  const button = <Button disabled={disabled} {...elementProps} />;

  if (disabled) {
    return (
      <div className="share-role-button__container">
        <Tooltip
          trigger="click"
          position="bottom"
          content={tooltipContent}
          distance="sm"
          useContext
        >
          {button}
        </Tooltip>
      </div>
    );
  }

  return button;
};

export default ShareRoleButton;
