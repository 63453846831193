// @flow


import logger from 'utils/logger';
import request from 'utils/request';
import { apiURL } from 'config/api';

import { type Dispatch } from '../state-types';

import type { RequestOptions } from './requets-options-type';
import * as actionTypes from './subscription-action-types';
import type { Subscription } from './subscription-initial-state';


const defaultRequestOptions: RequestOptions = {
  silent: false,
};

const listRequestStarted = (options?: $Shape<RequestOptions>) => ({
  type: actionTypes.LIST_REQUEST,
  meta: {
    ...defaultRequestOptions,
    ...options,
  },
});

const updateRequestStarted = (subscriptionId: number, options?: $Shape<RequestOptions>) => ({
  type: actionTypes.UPDATE_REQUEST,
  payload: subscriptionId,
  meta: {
    ...defaultRequestOptions,
    ...options,
  },
});

export const fetchAllSubscriptions = (options?: RequestOptions) => (dispatch: Dispatch) => {
  dispatch(listRequestStarted(options));

  return request.get(apiURL.subscriptions)
    .then((data) => {
      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

/**
 * @deprecated use fetchAllSubscriptions
 */
export const fetchSubscription = fetchAllSubscriptions;

export const reactivateSubscription = ({ subscriptionId }: {subscriptionId: number}) => (dispatch: Dispatch) => {
  dispatch(updateRequestStarted(subscriptionId));

  return request.post(apiURL.resumeSubscription(subscriptionId))
    // Must fetch all subscriptions because other subscriptions gets resumed when the primary is resumed
    .then(() => dispatch(fetchAllSubscriptions()))
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t resume subscription', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const cancelSubscription = ({ subscriptionId }: {subscriptionId: number}) => (dispatch: Dispatch) => {
  dispatch(updateRequestStarted(subscriptionId));

  return request.post(apiURL.cancelSubscription(subscriptionId))
    // Must fetch all subscriptions because other subscriptions gets cancelled when the primary is cancelled
    .then(() => dispatch(fetchAllSubscriptions()))
    .catch((error) => {
      logger.logError('Couldn\'t cancel subscription', error.getSimplifiedErrors());

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const activateSubscription = (pricePlanId: number) => (dispatch: Dispatch) => {
  dispatch(listRequestStarted());

  return request.post(apiURL.subscriptions, { body: { pricePlanId } })
    .then((data) => {
      dispatch({
        type: actionTypes.UPDATE_TEAM_SUBSCRIPTION,
        payload: data,
      });

      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t activate subscription', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const retryCharge = () => (dispatch: Dispatch) => {
  dispatch(listRequestStarted());

  return request.post(apiURL.retryPayment)
    .then((data) => {
      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t retry charge', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const changePricePlan = (
  { subscriptionId, planId }: {subscriptionId: number, planId: number}
) => (dispatch: Dispatch) => {
  dispatch(listRequestStarted());

  return request.put(apiURL.changePricePlan(subscriptionId), { body: { id: planId } })
    // TODO: update only one subscription when API starts returning Subscription instead of PricePLan
    .then(() => dispatch(fetchAllSubscriptions()))
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t change plan', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const buyAdditionalSeat = (body: {pricePlanId: number, count: number}) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.ORDER_REQUEST,
    meta: {
      silent: false,
    },
  });

  return request.post(apiURL.teamAdditionalSeat, { body })
    .then((subscription: Subscription) => {
      dispatch({
        type: actionTypes.ORDER_REQUEST_SUCCESS,
        payload: subscription,
      });

      return subscription;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t buy additional seat', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};
