// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, DeviceIcon } from '@setapp/ui-kit';

import './add-seat-from-pool.scss';

type Props = {
  memberEmail: string,
  onConfirm: () => any,
  isSubmitDisabled: boolean,
}

const AddSeatFromPool = (props: Props) => {
  const { memberEmail, onConfirm, isSubmitDisabled } = props;

  return (
    <>
      <div className="add-seat-from-pool">
        <div className="add-seat-from-pool__icon">
          <DeviceIcon device="macbook" size={80} />
        </div>
        <div className="add-seat-from-pool__content">
          <p>
            <FormattedMessage
              id="addDeviceModal.fromPool.memberInfo"
              defaultMessage="You are about to connect another device to {email}"
              values={{
                email: <strong>{memberEmail}</strong>,
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="addDeviceModal.fromPool.noPaymentNote"
              defaultMessage="Your membership does not require additional payment for this device."
            />
          </p>
        </div>
      </div>
      <Button
        block
        onClick={onConfirm}
        disabled={isSubmitDisabled}
      >
        <FormattedMessage
          id="addDeviceModal.fromPool.confirmButtonTitle"
          defaultMessage="Add device"
        />
      </Button>
    </>
  );
};

export default AddSeatFromPool;
