// @flow

import request from 'utils/request';

import { setPaymentInfo } from 'state/user/user-actions';
import { isPaymentMethodCreated } from 'state/root-reducer';
import type { Dispatch, GetState } from 'state/state-types';

import { apiURL } from 'config/api';
import analytics, { events } from 'utils/analytics';
import PaddlePaymentDetailsService, {
  type PaymentDetailsPayload as PaddlePaymentDetailsPayload,
  type UpdatePaymentDetailsPayload as UpdatePaddlePaymentDetailsPayload,
} from 'utils/api/paddle-payment-details-service';

import type { InvoicingInformation } from './payment-method-initial-state';
import * as actionTypes from './payment-method-action-types';


export type BraintreePaymentDetailsPayload = {
  ...InvoicingInformation,
  nonce: string,
  // Country is added when PayPal doesn't return user's country to provide additional evidence for Taxamo service
  country?: string,
};

const requestError = (error) => ({
  type: actionTypes.REQUEST_ERROR,
  payload: error,
  error: true,
});

export function fetchPaymentMethod() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.teamPayment)
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          /**
           * API returns an empty array if the payment details is not set
           * TODO: remove condition when API returns null for empty payment details
           */
          payload: Array.isArray(data) ? null : data,
        });
      })
      .catch((error) => {
        dispatch(requestError(error));

        return Promise.reject(error);
      });
  };
}

export const createPaddlePaymentDetails = (payload: PaddlePaymentDetailsPayload) => (dispatch: Dispatch) => {
  const paymentDetailsService = new PaddlePaymentDetailsService();

  dispatch({
    type: actionTypes.REQUEST,
  });

  return paymentDetailsService.createPaymentDetails(payload)
    .then((response) => {
      analytics.trackEvent(events.PAYMENT_DETAILS_ADD);

      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: response,
      });
      dispatch(setPaymentInfo());
    })
    .catch((error) => {
      dispatch(requestError(error));

      throw error;
    });
};

export const updatePaddlePaymentDetails = (payload: UpdatePaddlePaymentDetailsPayload) => (dispatch: Dispatch) => {
  const paymentDetailsService = new PaddlePaymentDetailsService();

  dispatch({
    type: actionTypes.REQUEST,
  });

  return paymentDetailsService.updatePaymentDetails(payload)
    .then((response) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: response,
      });
      dispatch(setPaymentInfo());
    })
    .catch((error) => {
      dispatch(requestError(error));

      throw error;
    });
};

export const saveBraintreePaymentMethod = (payload: BraintreePaymentDetailsPayload) => (
  (dispatch: Dispatch, getState: GetState) => {
    const isCreateRequest = !isPaymentMethodCreated(getState());
    const requestType = isCreateRequest ? 'post' : 'patch';

    dispatch({
      type: actionTypes.REQUEST,
    });

    return request[requestType](apiURL.payment, { body: payload })
      .then((response) => {
        analytics.trackEvent(events.PAYMENT_DETAILS_ADD);

        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: response,
        });
        dispatch(setPaymentInfo()); // TODO: investigate if still used
      })
      .catch((error) => {
        dispatch(requestError(error));

        throw error;
      });
  });
