// @flow

import { type PricePlan } from './price-plans-initial-state';

export const REQUEST = 'PRICE_PLANS_REQUEST';
export const REQUEST_SUCCESS = 'PRICE_PLANS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'PRICE_PLANS_REQUEST_ERROR';

type PricePlansRequestAction = {|
  type: 'PRICE_PLANS_REQUEST',
|};

type PricePlansRequestSuccessAction = {|
  type: 'PRICE_PLANS_REQUEST_SUCCESS',
  payload: Array<PricePlan>,
|};

type PricePlansRequestErrorAction = {|
  type: 'PRICE_PLANS_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

export type PricePlansAction = PricePlansRequestAction | PricePlansRequestSuccessAction | PricePlansRequestErrorAction;
