// @flow

import React, { type Node } from 'react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory, Location } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Navbar from 'react-bootstrap/lib/Navbar';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import OuterLayout from 'components/layout/outer-layout/outer-layout';
import DefaultError from 'components/shared/default-error/default-error';
import externalUrls from 'config/external-urls';

import setappIcon from './images/setapp-icon.svg';
import errorIllustration from './images/surprised.svg';

import './error-page.scss';

type Props = {
  history: RouterHistory,
  location: Location,
  children?: Node,
  errorTitle?: Node,
}

const ErrorPage = (props: Props) => {
  const {
    errorTitle,
    children,
  } = props;

  return (
    <OuterLayout
      {...props}
      footer={null}
      fullScreen
      header={
        <Navbar staticTop className="navbar-transparent">
          <Navbar.Header>
            <Navbar.Brand>
              <a href={ externalUrls.setappSite }>
                <AnimatedLogo hasCaption size="sm" />
              </a>
            </Navbar.Brand>
          </Navbar.Header>
        </Navbar>
      }
    >
      <div className="container mt-9">
        <div className="error-page">
          <div className="error-page__content">
            <img
              src={setappIcon}
              alt="Setapp icon"
              className="error-page__setapp-icon"
              width={160}
              height={160}
            />
            <h3 className="error-page__title">
              {errorTitle}
            </h3>
            <div className="mt-6 text_md">
              {children}
            </div>
          </div>
          <img
            className="error-page__illustration"
            src={errorIllustration}
            alt="Surprised character"
          />
        </div>
      </div>
    </OuterLayout>
  );
};

ErrorPage.defaultProps = {
  children: <DefaultError />,
  errorTitle: (
    <FormattedMessage
      id="outer.errorPage.defaultHeader"
      defaultMessage="Something went wrong, and we’re already fixing it"
    />
  ),
};

export { ErrorPage as PureErrorPage };

export default withRouter(ErrorPage);
