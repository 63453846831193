// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  isOwner: boolean,
  onClose: () => mixed,
  onAddPaymentDetails?: () => mixed,
}

const Title = (
  <FormattedMessage
    id="missedPaymentDetails.title"
    defaultMessage="Your card needs update"
  />
);

const MissedPaymentDetails = (props: Props) => {
  const { isOwner, onAddPaymentDetails, onClose } = props;

  if (isOwner) {
    return (
      <>
        <div className="mt-8 mb-8" data-qa="dialogDescription">
          <FormattedMessage
            id="missedPaymentDetails.owner.description"
            defaultMessage="Please add your payment details, looks like they’re missing."
          />
        </div>
        <Button
          block
          onClick={onAddPaymentDetails}
        >
          <FormattedMessage
            id="missedPaymentDetails.owner.ctaButtonTitle"
            defaultMessage="Add details"
          />
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="mt-8 mb-8" data-qa="dialogDescription">
        <FormattedMessage
          id="missedPaymentDetails.admin.description"
          defaultMessage="Looks like your payment data is missing. Please ask your team owner to add it."
        />
      </div>
      <Button
        block
        onClick={onClose}
      >
        <FormattedMessage
          id="missedPaymentDetails.admin.closeButtonTitle"
          defaultMessage="Ok, got it"
        />
      </Button>
    </>
  );
};

MissedPaymentDetails.Title = Title;

MissedPaymentDetails.defaultProps = {
  onAddPaymentDetails: undefined,
};

export default MissedPaymentDetails;
