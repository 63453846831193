// @flow

import React from 'react';
import classnames from 'classnames';

import type { Element } from 'react';

import './in-content-notification.scss';

type Props = {
  children: Element<any>,
  type: string,
}

const InContentNotification = ({ children, type }: Props) => {
  const notificationClasses = classnames('in-content-notification text_sm', {
    'in-content-notification_success': type === 'success',
    'in-content-notification_danger': type === 'danger',
  });

  return (
    <div
      role="alert"
      className={notificationClasses}
    >
      {children}
    </div>
  );
};

export default InContentNotification;
