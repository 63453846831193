// @flow

import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './app-visibility-status.scss';

type Props = {
  isRestricted: boolean,
}

const AppVisibilityStatus = ({ isRestricted }: Props) => {
  const titleClasses = classnames('ml-1', {
    'app-visibility-status__title_active': !isRestricted,
    'app-visibility-status__title_disabled': isRestricted,
  });

  return (
    <div className="app-visibility-status">
      {isRestricted ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g fill="none" fillRule="evenodd">
            <path d="m0 0h20v20h-20z" />
            <path
              d="m6.55502117 2.36645497.98047814 1.6978181c.78463523-.20484544 1.6110709-.31427307 2.46450069-.31427307 3.91275 0 7.257875 2.3 8.609 5.55.188.45.188.95 0 1.4-.8482707 2.0404327-2.4825552 3.7064089-4.5497805 4.6638484l.829135 1.4363633c.2301186.3985772.093556.9082359-.3050212 1.1383545s-.9082358.093556-1.1383545-.3050212l-8.3333333-14.4337567c-.23011865-.39857718-.09355602-.90823586.30502117-1.1383545.39857718-.23011865.90823585-.09355602 1.1383545.30502117zm-2.05291561 3.09778601 2.39628577 4.15166348c-.01544111.12587813-.02339133.25406346-.02339133.38409554 0 1.2927844.78583344 2.403029 1.90610436 2.8782331l1.93244664 3.3460961c-.235494.0170099-.4734542.0256708-.713551.0256708-3.91275 0-7.257875-2.3-8.609-5.55-.188-.45-.188-.95 0-1.4.64007141-1.53962963 1.72766872-2.86605818 3.11110556-3.83575902zm5.49789444 1.41075902c-.27071618 0-.53339293.03444592-.78389158.09918896l3.01181028 5.21681644c.5548016-.5641106.8970813-1.3378043.8970813-2.1910054 0-1.725-1.399125-3.125-3.125-3.125z"
              fill="#ff2c47"
            />
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g fill="none" fillRule="evenodd">
            <path d="m0 0h20v20h-20z" />
            <path
              d="m1.391 9.3c-.188.45-.188.95 0 1.4 1.351125 3.25 4.69625 5.55 8.609 5.55 3.912625 0 7.257875-2.3 8.609-5.55.188-.45.188-.95 0-1.4-1.351125-3.25-4.69625-5.55-8.609-5.55-3.912625 0-7.257875 2.3-8.609 5.55zm8.609-2.425c-1.725875 0-3.125 1.4-3.125 3.125s1.399125 3.125 3.125 3.125 3.125-1.4 3.125-3.125-1.399125-3.125-3.125-3.125z"
              fill="#479a94"
            />
          </g>
        </svg>
      )}
      <div className={titleClasses}>
        {isRestricted ? (
          <FormattedMessage
            id="appVisibilityStatus.title.unhide"
            defaultMessage="Unhide app"
          />
        ) : (
          <FormattedMessage
            id="appVisibilityStatus.title.hide"
            defaultMessage="Hide app"
          />
        )}
      </div>
    </div>
  );
};

export default AppVisibilityStatus;
