// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';

import urls from 'config/urls';
import analytics from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';

import { fetchSubscription } from 'state/subscription/subscription-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { addMemberSeat } from 'state/team-plan/team-plan-actions';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { showModal } from 'state/modal/modal-reducer';
import {
  creditCardExpiration,
  getPrimarySubscription,
  getTeamInfo,
  getPricePerMonthPerMember,
  isPaymentMethodCreated,
  isUserTeamOwner,
} from 'state/root-reducer';
import { addSuccessNotification, addDangerNotification } from 'state/notifier/notifier-actions';

import ExpiredPaymentCard from 'components/shared/expired-payment-card/expired-payment-card';
import MissedPaymentDetails from 'components/shared/missed-payment-details/missed-payment-details';
import Modal from 'components/shared/modal/modal';
import PaymentForm from 'components/shared/payment-form/payment-form';
import PurchaseInvitations from 'components/shared/purchase-invitations/purchase-invitations';

import type { SubscriptionStatus } from 'state/subscription/statuses';

import PurchaseSeatsActivateSubscription from './purchase-seats-activate-subscription/purchase-seats-activate-subscription';


type ContentVariant = 'NO_PAYMENT_DETAILS'
  | 'CARD_EXPIRED'
  | 'SUBSCRIPTION_ACTIVE'
  | 'ACTIVATE_SUBSCRIPTION'
  | 'ADD_PAYMENT_DETAILS_FOR_OWNER'
  | 'UPDATE_CARD_FOR_OWNER';

type Props = {
  show: boolean,
  onHide: () => any,
  onExited: () => any,
  fetchSubscription: () => Promise<any>,
  fetchPaymentMethod: () => Promise<any>,
  addMemberSeat: (number) => Promise<any>,
  isPaymentMethodCreated: boolean,
  isTeamProcessing: boolean,
  isUserTeamOwner: boolean,
  subscriptionStatus: SubscriptionStatus,
  history: RouterHistory,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
  creditCardExpiration: {|
    isExpired: boolean,
    isAboutToExpire: boolean,
  |},
};

type State = {
  contentVariant: ?ContentVariant,
};

class PurchaseMemberSeatsModal extends PureComponent<Props, State> {
  state = {
    contentVariant: null,
  }

  componentDidMount() {
    const {
      fetchSubscription,
      fetchPaymentMethod,
    } = this.props;

    return Promise.all([
      fetchSubscription(),
      fetchPaymentMethod(),
    ]).then(() => {
      this.selectContentVariant();
    });
  }

  render() {
    const {
      show,
      onHide,
      onExited,
    } = this.props;
    const { contentVariant } = this.state;
    const isDataLoading = contentVariant === null;

    return (
      <Modal
        fullScreen
        withCancelButton={this.isCancelButtonVisible()}
        show={show}
        onHide={onHide}
        onExited={onExited}
        isLoading={isDataLoading}
        title={this.getModalTitle()}
      >
        {!isDataLoading && this.getContent()}
      </Modal>
    );
  }

  selectContentVariant = () => {
    const {
      creditCardExpiration,
      isPaymentMethodCreated,
      subscriptionStatus,
    } = this.props;

    const isSubscriptionActive = subscriptionStatus === subscriptionStatuses.ACTIVE;

    const { isExpired } = creditCardExpiration;
    const isNoCard = !isPaymentMethodCreated;

    if (!isSubscriptionActive) {
      this.setState({ contentVariant: 'ACTIVATE_SUBSCRIPTION' });
    } else if (isNoCard) {
      this.setState({ contentVariant: 'NO_PAYMENT_DETAILS' });
    } else if (isExpired) {
      this.setState({ contentVariant: 'CARD_EXPIRED' });
    } else {
      this.setState({ contentVariant: 'SUBSCRIPTION_ACTIVE' });
    }
  }

  isCancelButtonVisible = () => {
    const { isUserTeamOwner } = this.props;
    const { contentVariant } = this.state;

    const isAdmin = !isUserTeamOwner;

    if (isAdmin) {
      return !['NO_PAYMENT_DETAILS', 'CARD_EXPIRED', 'ACTIVATE_SUBSCRIPTION'].includes(contentVariant);
    }

    return !['ADD_PAYMENT_DETAILS_FOR_OWNER', 'UPDATE_CARD_FOR_OWNER'].includes(contentVariant);
  };

  getModalTitle = () => {
    const { contentVariant } = this.state;

    switch (contentVariant) {
      case 'NO_PAYMENT_DETAILS':
        return MissedPaymentDetails.Title;
      case 'CARD_EXPIRED':
        return ExpiredPaymentCard.Title;
      case 'ADD_PAYMENT_DETAILS_FOR_OWNER':
        return (
          <FormattedMessage
            id="purchaseMemberSeatsModal.addPaymentDetails.title"
            defaultMessage="Add payment details"
          />
        );
      case 'UPDATE_CARD_FOR_OWNER':
        return (
          <FormattedMessage
            id="purchaseMemberSeatsModal.updatePaymentDetails.title"
            defaultMessage="Update payment details"
          />
        );
      case 'SUBSCRIPTION_ACTIVE':
        return (
          <FormattedMessage
            id="purchaseMemberSeatsModal.title"
            defaultMessage="Purchase invitations"
          />
        );
      case 'ACTIVATE_SUBSCRIPTION':
        return (
          <FormattedMessage
            id="purchaseMemberSeatsModal.invitationsLimit.title"
            defaultMessage="Invitations limit"
          />
        );
      default:
        return null;
    }
  }

  getContent = () => {
    const {
      isUserTeamOwner,
      isTeamProcessing,
      pricePerMonthPerMember,
      addMemberSeat,
      addSuccessNotification,
      addDangerNotification,
      onHide,
    } = this.props;
    const { contentVariant } = this.state;

    switch (contentVariant) {
      case 'NO_PAYMENT_DETAILS':
        return (
          <MissedPaymentDetails
            isOwner={isUserTeamOwner}
            onAddPaymentDetails={this.onAddPaymentDetails}
            onClose={onHide}
          />
        );
      case 'CARD_EXPIRED':
        return (
          <ExpiredPaymentCard
            isOwner={isUserTeamOwner}
            onUpdatePaymentDetails={this.onUpdatePaymentDetails}
            onClose={onHide}
          />
        );
      case 'ADD_PAYMENT_DETAILS_FOR_OWNER':
        return <PaymentForm onSuccessSubmit={this.onPaymentDetailsAdded} />;
      case 'UPDATE_CARD_FOR_OWNER':
        return <PaymentForm onSuccessSubmit={this.onPaymentDetailsUpdated} />;
      case 'SUBSCRIPTION_ACTIVE':
        return (
          <PurchaseInvitations
            isProcessing={isTeamProcessing}
            pricePerMonthPerMember={pricePerMonthPerMember}
            addMemberSeat={addMemberSeat}
            addSuccessNotification={addSuccessNotification}
            addDangerNotification={addDangerNotification}
            onPurchaseFinish={this.onPurchaseFinish}
            onPurchaseSuccess={this.onPurchaseSuccess}
            onPurchaseSubmit={this.onPurchaseInvitesSubmit}
          />
        );
      default:
        return (
          <PurchaseSeatsActivateSubscription
            isOwner={isUserTeamOwner}
            pricePerMonthPerMember={pricePerMonthPerMember}
            onConfirm={this.onActivateClick}
            onClose={onHide}
          />
        );
    }
  };

  onAddPaymentDetails = () => {
    this.setState({ contentVariant: 'ADD_PAYMENT_DETAILS_FOR_OWNER' });
  };

  onUpdatePaymentDetails = () => {
    this.setState({ contentVariant: 'UPDATE_CARD_FOR_OWNER' });
  };

  onPaymentDetailsAdded = () => {
    const { addSuccessNotification, onHide } = this.props;

    onHide();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="purchaseMemberSeatsModal.notifications.paymentDetailsAdded"
          defaultMessage="Payment details successfully added."
        />
      ),
    });
  };

  onPaymentDetailsUpdated = () => {
    const { addSuccessNotification, onHide } = this.props;

    onHide();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="purchaseMemberSeatsModal.notifications.paymentDetailsUpdated"
          defaultMessage="Payment details successfully updated."
        />
      ),
    });
  };

  onActivateClick = () => {
    const {
      subscriptionStatus,
      history,
    } = this.props;

    if (subscriptionStatus === subscriptionStatuses.TRIAL) {
      history.push(urls.activateSubscription);
    } else {
      history.push(urls.subscription);
    }

    analytics.trackEvent(gaEvents.PURCHASE_INVITES_BLOCK_CLICK_ACTIVATE_MEMBERSHIP);
  };

  onPurchaseInvitesSubmit = (seatsAmount: number) => {
    analytics.trackEvent(gaEvents.PURCHASE_INVITES_BLOCK_CLICK_PURCHASE_INVITES, {
      eventLabel: seatsAmount,
    });
  };

  onPurchaseSuccess = () => {
    analytics.trackEvent(gaEvents.PURCHASE_INVITES_BLOCK_PURCHASE_INVITES_SUCCESS);
  };

  onPurchaseFinish = () => {
    const { onHide } = this.props;
    onHide();
  };
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  creditCardExpiration: creditCardExpiration(state),
  subscriptionStatus: getPrimarySubscription(state).status,
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isTeamProcessing: getTeamInfo(state).isLoading,
  isUserTeamOwner: isUserTeamOwner(state),
  pricePerMonthPerMember: getPricePerMonthPerMember(state),
});

const mapActionsToProps = {
  fetchSubscription,
  fetchPaymentMethod,
  showModal,
  addMemberSeat,
  addSuccessNotification,
  addDangerNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(PurchaseMemberSeatsModal));

export { PurchaseMemberSeatsModal as PurePurchaseMemberSeatsModal };
