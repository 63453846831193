import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { apiURL } from 'config/api';

import './payment-history-item.scss';
import { getTokenManager } from 'utils/service-locators';

const PaymentHistoryItem = (props, context) => {
  const {
    paymentDate, id, amountFormatted, taxAmountFormatted, isTaxShown,
  } = props;
  const { isMobile } = context;
  const tokenManager = getTokenManager();

  const downloadLinkClasses = classNames([
    'payment-history__download-link',
    { 'payment-history__download-link_visible': isMobile },
  ]);

  return (
    <tr className="payment-history__item">
      <td className="payment-history__cell">
        <span>
          <FormattedDate
            value={paymentDate * 1000}
            year="numeric"
            month="short"
            day="numeric"
          />
        </span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={
            apiURL.downloadInvoice
              .replace('%{invoiceId}', id)
              .replace('%{token}', tokenManager.getAccessToken())
          }
          className={downloadLinkClasses}
        >
          <FormattedMessage id="paymentHistory.download" defaultMessage="Download" />
        </a>
      </td>
      <td className="payment-history__cell payment-history__cell_right">
        {amountFormatted}
      </td>
      {isTaxShown && (
        <td className="payment-history__cell payment-history__cell_right">
          {taxAmountFormatted}
        </td>
      )}
    </tr>
  );
};

PaymentHistoryItem.contextTypes = {
  isMobile: PropTypes.bool,
};

PaymentHistoryItem.propTypes = {
  id: PropTypes.string.isRequired,
  paymentDate: PropTypes.number.isRequired,
  amountFormatted: PropTypes.string.isRequired,
  taxAmountFormatted: PropTypes.string.isRequired,
  isTaxShown: PropTypes.bool.isRequired,
};

export default PaymentHistoryItem;
