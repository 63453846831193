// @flow

import React, { Component, type Node } from 'react';
import type { BrowserHistory, HashHistory, MemoryHistory } from 'history';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { isAvailable as isDesktopAppAvailable } from '@setapp/desktop-app-helper';

import {
  isPaymentMethodCreated,
  isUserTeamOwner,
  getTeamMembers,
  isTeamFetched,
  isPaymentMethodFetched,
  isSubscriptionsFetched,
  getUser,
} from 'state/root-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { showModal, hideModal } from 'state/modal/modal-reducer';
import {
  addSuccessNotification,
  addDangerNotification,
  addInfoNotification,
} from 'state/notifier/notifier-actions';
import { sendDownloadLinkEmail } from 'state/user/user-actions';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';

import { COOKIES_ROOT_DOMAIN } from 'config/app';
import urls from 'config/urls';

import desktopAppHelper from 'utils/desktop-app-helper';
import analytics from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import DefaultError from 'components/shared/default-error/default-error';
import withEmailAutoConfirmation from 'components/shared/with-email-auto-confirmation/with-email-auto-confirmation';

import type { ModalType } from 'components/modals';

import SurveyView from './survey-view/survey-view';
import ActionCardsView from './action-cards-view/action-cards-view';


import './dashboard-page.scss';

const DOWNLOAD_DESKTOP_APP_STEP_DONE_COOKIE_NAME = 'downloadDesktopAppStepDone';

type Props = {
  fetchPaymentMethod: () => Promise<mixed>,
  fetchAllSubscriptions: () => Promise<mixed>,
  isPaymentMethodCreated: boolean,
  isAtLeastOneMemberInvited: boolean,
  isTeamFetched: boolean,
  isUserTeamOwner: boolean,
  isPaymentMethodFetched: boolean,
  isSubscriptionsFetched: boolean,
  showModal: (ModalType, Object) => mixed,
  hideModal: () => mixed,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  addInfoNotification: ({message: Node}) => mixed,
  userEmail: string,
  sendDownloadLinkEmail: () => Promise<void>,
  getFeatureFlag: (string, mixed) => string,
  history: BrowserHistory | HashHistory | MemoryHistory,
};

type State = {
  isDownloadDesktopAppStepDone: boolean,
  isInviteSent: boolean,
};

class DashboardPage extends Component<Props, State> {
  static setCardStateToCookies(cookieName: string) {
    Cookies.set(cookieName, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 });
  }

  state = {
    isDownloadDesktopAppStepDone: Boolean(Cookies.get(DOWNLOAD_DESKTOP_APP_STEP_DONE_COOKIE_NAME)),
    isInviteSent: false,
  };

  componentDidMount() {
    const {
      isUserTeamOwner,
      isSubscriptionsFetched,
      fetchPaymentMethod,
      fetchAllSubscriptions,
      addDangerNotification,
    } = this.props;

    const dataToBeFetched = [fetchPaymentMethod()];

    if (isUserTeamOwner && !isSubscriptionsFetched) {
      dataToBeFetched.push(fetchAllSubscriptions());
    }

    return Promise.all(dataToBeFetched)
      .then(() => {
        this.handleShowInviteMembersModal();
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  render() {
    const {
      isDownloadDesktopAppStepDone,
      isInviteSent,
    } = this.state;

    const {
      isPaymentMethodCreated,
      isAtLeastOneMemberInvited,
      isTeamFetched,
      isPaymentMethodFetched,
    } = this.props;

    if (!isTeamFetched || !isPaymentMethodFetched) {
      return (
        <div className="dashboard-page__animated-logo-container">
          <AnimatedLogo animate />
        </div>
      );
    }

    const allStepsAreDone = isPaymentMethodCreated
      && isDownloadDesktopAppStepDone
      && isAtLeastOneMemberInvited;

    if (allStepsAreDone) {
      return <SurveyView />;
    }

    return (
      <ActionCardsView
        isAddPaymentDetailsStepDone={isPaymentMethodCreated}
        isDownloadDesktopAppStepDone={isDownloadDesktopAppStepDone}
        isInviteMemberStepDone={isAtLeastOneMemberInvited || isInviteSent}
        onAddPaymentDetailsClick={this.handleAddPaymentDetailsClick}
        onDownloadClick={this.handleDownloadButtonClick}
        onUpdatePaymentDetailsClick={this.handleUpdatePaymentDetailsClick}
        onInviteClick={this.handleInviteMemberClick}
        onOpenDesktopClick={this.handleOpenDesktopAppClick}
      />
    );
  }

  handleFirstInviteSentSuccessful = () => {
    this.handleClearHistoryState();

    // set optimistic render that invite member step is done, because we can get from backend not updated team
    this.setState({ isInviteSent: true });
  }

  handleShowInviteMembersModal() {
    const {
      history,
      isPaymentMethodFetched,
      showModal,
    } = this.props;
    const isSignupReferrer = history.location.state?.from?.pathname === urls.signup;

    if (isPaymentMethodFetched && isSignupReferrer) {
      showModal('INVITE_MEMBER', {
        onInviteSent: this.handleFirstInviteSentSuccessful,
        isJAMFHidden: true,
        onModalHide: this.handleClearHistoryState,
      });
    }
  }

  handleClearHistoryState = () => {
    const { history, history: { location } } = this.props;
    // to clear the location state
    const state = { ...history.location.state };
    delete state.from;
    history.replace({ ...location, state });
  }

  handleInviteMemberClick = () => {
    const { showModal, isAtLeastOneMemberInvited } = this.props;

    if (isAtLeastOneMemberInvited) {
      analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_INVITE_MEMBER_AGAIN);
    } else {
      analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_INVITE_MEMBER);
    }

    showModal('INVITE_MEMBER');
  };

  handleDownloadButtonClick = async () => {
    const { isMobile } = this.context;
    const {
      addInfoNotification,
      addDangerNotification,
      userEmail,
      sendDownloadLinkEmail,
    } = this.props;

    if (isMobile) {
      try {
        await sendDownloadLinkEmail();
        addInfoNotification({
          message: (
            <FormattedMessage
              id="dashboardPage.openDesktopAppCard.notifications.linkWasSent"
              defaultMessage="We’ve sent download link to {userEmail}, get back on your Mac and get Setapped!"
              values={{ userEmail }}
            />
          ),
          hideCloseButton: true,
        });
      } catch (error) {
        addDangerNotification({
          message: <DefaultError />,
        });
      }
      analytics.trackEvent(gaEvents.MOBILE_FLOW_CLICK_SEND_ME_LINK, { eventLabel: 'Owner' });
    } else {
      analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_DOWNLOAD);
      DashboardPage.setCardStateToCookies(DOWNLOAD_DESKTOP_APP_STEP_DONE_COOKIE_NAME);
      this.setState({ isDownloadDesktopAppStepDone: true });
    }
  };

  handleAddPaymentDetailsClick = () => {
    const { showModal } = this.props;

    analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_ADD_PAYMENT_METHOD);

    showModal('SET_PAYMENT_DETAILS', {
      title: <FormattedMessage id="dashboardPage.addPaymentDetailsModal.title" defaultMessage="Add payment details" />,
      onPaymentDetailsSaved: this.handlePaymentDetailsAdded,
    });
  };

  handlePaymentDetailsAdded = () => {
    const { addSuccessNotification, hideModal } = this.props;

    hideModal();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="dashboardPage.notifications.paymentDetailsAdded"
          defaultMessage="Payment details successfully added."
        />
      ),
    });
  };

  handleUpdatePaymentDetailsClick = () => {
    const { showModal } = this.props;

    analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_UPDATE_PAYMENT_METHOD);

    showModal('SET_PAYMENT_DETAILS', {
      title: (
        <FormattedMessage
          id="dashboardPage.updatePaymentDetailsModal.title"
          defaultMessage="Update payment details"
        />
      ),
      onPaymentDetailsSaved: this.handlePaymentDetailsUpdated,
    });
  };

  handlePaymentDetailsUpdated = () => {
    const { addSuccessNotification, hideModal } = this.props;

    hideModal();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="dashboardPage.paymentDetails.notifications.update"
          defaultMessage="Payment details successfully updated."
        />
      ),
    });
  };

  handleOpenDesktopAppClick = () => {
    analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_OPEN_APP);

    isDesktopAppAvailable({
      onSuccess: this.openDesktopApp,
      onError: this.handleDesktopAppOpeningError,
    });
  };

  openDesktopApp = () => {
    const { userEmail } = this.props;

    desktopAppHelper.openWithAuth(userEmail);
  };

  handleDesktopAppOpeningError = () => {
    const { addDangerNotification } = this.props;

    addDangerNotification({
      message: (
        <FormattedMessage
          id="dashboardPage.openDesktopAppCard.notifications.appNotInstalled"
          defaultMessage="You still haven’t launched the Setapp desktop client. Install it and sign in to proceed."
        />
      ),
    });
  };
}

DashboardPage.contextTypes = {
  isMobile: PropTypes.bool,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isAtLeastOneMemberInvited: getTeamMembers(state).length > 1,
  isTeamFetched: isTeamFetched(state),
  isUserTeamOwner: isUserTeamOwner(state),
  isPaymentMethodFetched: isPaymentMethodFetched(state),
  isSubscriptionsFetched: isSubscriptionsFetched(state),
  userEmail: getUser(state).email,
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  fetchPaymentMethod,
  showModal,
  hideModal,
  addSuccessNotification,
  addDangerNotification,
  addInfoNotification,
  sendDownloadLinkEmail,
};

export { DashboardPage as PureDashboardPage };

const EnhancedDashboardPage = compose(
  withRouter,
  withEmailAutoConfirmation,
)(DashboardPage);

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(EnhancedDashboardPage);
