// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import './step-counter.scss';

type Props = {
  current: ?number,
  total: ?number,
}

const StepCounter = ({ current, total }: Props) => (
  <div className="step-counter text_sm">
    <FormattedMessage
      id="stepCounter.title"
      defaultMessage="Step {current} of {total}"
      values={{ current, total }}
    />
  </div>
);

export default StepCounter;
