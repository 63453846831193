// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './root-reducer';
import getInitialState from './initial-state';
import type { Store } from './state-types';
import resetStoreEnhancer from './reset-store-enhancer';
import { LOGOUT } from './user/user-actions-types';

export default function configureStore(): Store {
  const middleware = [thunkMiddleware];
  const initialState = getInitialState();

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middleware),
    resetStoreEnhancer({ resetActionType: LOGOUT, preserveState: ['intl'] }),
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  ));
}
