// @flow

import { type PaymentDetails } from './payment-method-initial-state';

export const REQUEST = 'PAYMENT_METHOD_REQUEST';
export const REQUEST_SUCCESS = 'PAYMENT_METHOD_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'PAYMENT_METHOD_REQUEST_ERROR';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';

type PaymentMethodRequestAction = {|
  type: 'PAYMENT_METHOD_REQUEST',
|};

type PaymentMethodRequestSuccessAction = {|
  type: 'PAYMENT_METHOD_REQUEST_SUCCESS',
  payload: ?PaymentDetails,
|};

type PaymentMethodRequestErrorAction = {|
  type: 'PAYMENT_METHOD_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

type RemovePaymentMethodAction = {|
  type: 'REMOVE_PAYMENT_METHOD',
|};

export type PaymentMethodAction =
  | PaymentMethodRequestAction
  | PaymentMethodRequestSuccessAction
  | PaymentMethodRequestErrorAction
  | RemovePaymentMethodAction;
