// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, type RouterHistory } from 'react-router-dom';
import { ButtonClose, Button } from '@setapp/ui-kit';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import urls from 'config/urls';
import analytics, { events } from 'utils/analytics';

import YoungFriendlyLady from '../young-friendly-lady/young-friendly-lady';

import tadaEmoji from './images/tada-emoji.png';
import tadaEmoji2x from './images/tada-emoji@2x.png';


import './subscription-activated-step.scss';

type Props = {
  handleCloseClick: () => void,
  history: RouterHistory,
};

class SubscriptionActivatedStep extends PureComponent<Props> {
  render() {
    const {
      handleCloseClick,
    } = this.props;

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="subscription-activated-step__primary-content-wrapper" data-qa="subscriptionActivatedStep">
            <div className="subscription-activated-step__close-button subscription-activated-step__close-button_primary">
              <ButtonClose onClick={handleCloseClick} />
            </div>
            <h3>
              <FormattedMessage
                id="activatePage.subscriptionActivatedStep.title"
                defaultMessage="Thanks! Your payment is on its way"
              />
              {' '}
              <img
                alt=""
                src={tadaEmoji}
                srcSet={`${tadaEmoji2x} 2x`}
                className="subscription-activated-step__emoji"
              />
            </h3>
            <span className="text_lg mt-2">
              <FormattedMessage
                id="activatePage.subscriptionActivatedStep.subtitle"
                defaultMessage="Not to worry, it may take some time.  Thanks for using Setapp for Teams!"
              />
            </span>
            <div className="mt-5">
              <Button
                onClick={this.handleOkButtonClick}
                block
                data-qa="okButton"
              >
                <FormattedMessage
                  id="activatePage.subscriptionActivatedStep.okButton"
                  defaultMessage="Ok, great"
                />
              </Button>
            </div>
            <div className="mt-3">
              <Button
                onClick={this.handleManageTeamButtonClick}
                block
                variant="primary-outline"
                data-qa="manageTeamButton"
              >
                <FormattedMessage
                  id="activatePage.subscriptionActivatedStep.manageTeamButton"
                  defaultMessage="Manage my Team"
                />
              </Button>
            </div>
          </div>
        </FullscreenLayout.PrimaryContent>
        <FullscreenLayout.SecondaryContent>
          <div className="subscription-activated-step__secondary-content-wrapper">
            <div className="subscription-activated-step__close-button">
              <ButtonClose onClick={handleCloseClick} />
            </div>
            <YoungFriendlyLady />
          </div>
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  handleOkButtonClick = () => {
    const { history } = this.props;

    analytics.trackEvent(events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_OK_GREAT);

    history.push(urls.subscription);
  };

  handleManageTeamButtonClick = () => {
    const { history } = this.props;

    analytics.trackEvent(events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_MANAGE_TEAM);

    history.push(urls.team);
  };
}

export { SubscriptionActivatedStep as PureSubscriptionActivatedStep };

export default withRouter(SubscriptionActivatedStep);
