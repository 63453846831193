// @flow

import React from 'react';
import type { Node, ElementRef } from 'react';
import ReCaptcha from 'react-google-recaptcha';

import { FormFieldWrapper } from '@setapp/ui-kit';

import { CAPTCHA_SITE_KEY } from 'config/auth';

import './captcha-field.scss';

type Props = {
  errorMessage?: Node,
  setCaptchaRef: ElementRef<typeof ReCaptcha> => void,
  onChange: (string) => void,
};

const CaptchaField = (props: Props) => {
  const {
    errorMessage, setCaptchaRef, onChange,
  } = props;

  return (
    <FormFieldWrapper id="captcha" label="Captcha" helpText={errorMessage} invalid={Boolean(errorMessage)}>
      <div className="captcha-field">
        <ReCaptcha
          sitekey={CAPTCHA_SITE_KEY}
          onChange={onChange}
          ref={setCaptchaRef}
        />
      </div>
    </FormFieldWrapper>
  );
};

CaptchaField.defaultProps = {
  errorMessage: '',
};

export default CaptchaField;
