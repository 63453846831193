// @flow

import { type Jamf } from './jamf-initial-state';

export const REQUEST = 'JAMF_REQUEST';
export const REQUEST_SUCCESS = 'JAMF_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'JAMF_REQUEST_ERROR';

type JamfRequestAction = {|
  type: 'JAMF_REQUEST',
|};

type JamfRequestSuccessAction = {|
  type: 'JAMF_REQUEST_SUCCESS',
  payload: Jamf,
|};

type JamfRequestErrorAction = {|
  type: 'JAMF_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

export type JamfAction = JamfRequestAction | JamfRequestSuccessAction | JamfRequestErrorAction;
