// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import InContentNotification from 'components/shared/in-content-notification/in-content-notification';

const PaymentFailedNotification = () => (
  <InContentNotification type="danger">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.failedPayment"
      defaultMessage="We couldn’t process your payment. Retry, and if that doesn’t help, update your payment details."
    />
  </InContentNotification>
);

export default PaymentFailedNotification;
