// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import './distribute-via-jamf.scss';
import jamfIcon from './images/jamf.svg';

type Props = {
  onRedirectToJamf: () => void,
};

const DistributeViaJamf = ({ onRedirectToJamf }: Props) => (
  <div className="distribute-via-jamf">
    <p className="distribute-via-jamf__description">
      <img
        className="distribute-via-jamf__logo"
        src={jamfIcon}
        alt=""
        height="80"
        width="80"
      />
      <FormattedMessage
        id="inviteMemberModal.distributeViaJamf.description"
        defaultMessage="Jamf is an industry-standard solution for Apple device management. Use it as one of the ways of distributing Setapp among your team members."
      />
    </p>
    <Button
      block
      tag={Link}
      to={urls.jamf}
      onClick={onRedirectToJamf}
    >
      <FormattedMessage
        id="inviteMemberModal.distributeViaJamf.link"
        defaultMessage="Go to Jamf for Setapp"
      />
    </Button>
  </div>
);

export default DistributeViaJamf;
