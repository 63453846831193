import React from 'react';
import PropTypes from 'prop-types';
import { Link as Routerlink } from 'react-router-dom';

const Link = ({ children, ...linkProps }) => <Routerlink {...linkProps}>{children}</Routerlink>;

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Link;
