import React from 'react';
import PropTypes from 'prop-types';

import './info-radio-button.scss';

const InfoRadioButton = ({ children, 'aria-label': ariaLabel, ...inputProps }) => {
  const { name, value } = inputProps;

  return (
    <div className="info-radio-button">
      <input {...inputProps} className="info-radio-button__input sr-only" type="radio" id={`${name}-${value}`} />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className="info-radio-button__label" htmlFor={`${name}-${value}`} aria-label={ariaLabel}>
        {children}
      </label>
    </div>
  );
};

InfoRadioButton.propTypes = {
  children: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  qaLabel: PropTypes.string, // eslint-disable-line react/require-default-props
  'aria-label': PropTypes.string, // eslint-disable-line react/require-default-props
};

export default InfoRadioButton;
