import React from 'react';
import {
  Router, Route, Redirect, Switch,
} from 'react-router-dom';


import * as permissions from 'state/user/user-permissions';

import AppRoot from './root';
import AppRoute from './components/shared/routing/app-route';
import RedirectWithQuery from './components/shared/routing/redirect-with-query';
import RedirectAuthUser from './components/shared/access-control/redirect-auth-user';
import RedirectPpcTraffic from './components/shared/access-control/redirect-ppc-traffic';

import AppLayout from './components/layout/app-layout/app-layout';
import OuterLayout from './components/layout/outer-layout/outer-layout';

import SignUpPage from './components/signup/signup-page';
import SignUpMemberPage from './components/signup-member/signup-member';

import LoginPage from './components/login/login-container';
import NewPasswordPage from './components/new-password/new-password-container';
import RecoverPasswordPage from './components/recover-password/recover-password-page';
import SuccessfulRegistrationPage from './components/successful-registration/successful-registration-page';

import NotFoundPage from './components/not-found/not-found';

import AccountPage from './components/account-settings/account-page';
import PaymentHistoryPage from './components/payment-history/payment-history-page';
import DevicesPage from './components/devices/devices-page';
import SubscriptionPage from './components/subscription/subscription-page';
import TeamPage from './components/team/team-page';
import JamfPage from './components/jamf/jamf-page';
import DashboardPage from './components/dashboard/dashboard-page';
import ActivateSubscriptionPage from './components/activate-subscription/activate-subscription-page';
import AppsManagement from './components/apps-management/apps-management-page';

import urls from './config/urls';
import history from './utils/custom-history';

const AppRouter = () => (
  <Router history={history}>
    <Route render={(props) => (
      <AppRoot {...props}>
        <Switch>
          <RedirectWithQuery exact from={urls.root} to={urls.dashboard} />
          <AppRoute authenticated path={urls.account} component={AccountPage} layout={AppLayout} />
          <AppRoute authenticated path={urls.devices} component={DevicesPage} layout={AppLayout} />
          <AppRoute
            authenticated
            redirectNonOwner
            path={urls.dashboard}
            component={DashboardPage}
            layout={AppLayout}
          />
          <AppRoute
            authenticated
            redirectNonOwner
            path={urls.paymentHistory}
            component={PaymentHistoryPage}
            layout={AppLayout}
          />
          <AppRoute
            authenticated
            redirectNonOwner
            path={urls.subscription}
            component={SubscriptionPage}
            layout={AppLayout}
          />
          <AppRoute
            authenticated
            redirectNonOwner
            path={urls.jamf}
            component={JamfPage}
            layout={AppLayout}
          />
          <AppRoute
            authenticated
            permission={permissions.TEAM_MANAGEMENT_VIEW}
            path={urls.team}
            component={TeamPage}
            layout={AppLayout}
          />
          <AppRoute
            authenticated
            redirectNonOwner
            path={urls.appsManagement}
            component={AppsManagement}
            layout={AppLayout}
          />
          <AppRoute
            authenticated
            path={urls.successfulRegistration}
            component={SuccessfulRegistrationPage}
            layout={(props) => <OuterLayout {...props} footer={null} fullScreen />}
          />
          <AppRoute
            authenticated
            redirectNonOwner
            path={urls.activateSubscription}
            component={ActivateSubscriptionPage}
          />
          <AppRoute
            path={urls.recoverPassword}
            component={RecoverPasswordPage}
            layout={(props) => <OuterLayout {...props} header={null} />}
          />
          <AppRoute
            path={`${urls.newPassword}/:token?`}
            component={NewPasswordPage}
            layout={(props) => <OuterLayout {...props} header={null} />}
          />
          <AppRoute
            exact
            path={urls.signup}
            component={(props) => (
              <RedirectPpcTraffic>
                <SignUpPage {...props} />
              </RedirectPpcTraffic>
            )}
          />
          <AppRoute
            path={urls.signupMember}
            component={SignUpMemberPage}
            layout={(props) => <OuterLayout {...props} header={null} footer={null} fullScreen />}
          />
          <AppRoute
            path={urls.login}
            component={(props) => (
              <RedirectAuthUser>
                <LoginPage {...props} />
              </RedirectAuthUser>
            )}
            layout={(props) => <OuterLayout {...props} header={null} footer={null} fullScreen />}
          />
          <Redirect from={urls.paymentDetails} to={urls.subscription} />
          <AppRoute
            path="*"
            component={NotFoundPage}
            layout={(props) => <OuterLayout {...props} fullScreen />}
          />
        </Switch>
      </AppRoot>
    )}
    />
  </Router>
);

export default AppRouter;
