// @flow

import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  onClose: () => mixed,
}

const MissedPaymentDetails = (props: Props) => {
  const { onClose } = props;

  return (
    <>
      <div className="mt-8 mb-8">
        <FormattedHTMLMessage
          id="addSeatModal.missedPaymentDetails.description"
          defaultMessage="Sorry!<br/>Looks like your payment data is missing. Ask your team owner to add it before adding new devices."
        />
      </div>
      <Button
        block
        onClick={onClose}
      >
        <FormattedMessage
          id="addSeatModal.missedPaymentDetails.closeButtonTitle"
          defaultMessage="Ok, got it"
        />
      </Button>
    </>
  );
};

export default MissedPaymentDetails;
