// @flow

import React, { PureComponent, type Node } from 'react';
import { connect } from 'react-redux';

import { fetchSubscription } from 'state/subscription/subscription-actions';
import { fetchTeam } from 'state/team-plan/team-plan-actions';
import { showModal } from 'state/modal/modal-reducer';
import {
  getTeamInfo,
  isTeamFetched,
  isSubscriptionTrial,
} from 'state/root-reducer';
import { addDangerNotification } from 'state/notifier/notifier-actions';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import type { ModalType } from 'components/modals';

import DefaultError from '../shared/default-error/default-error';

import JamfGuide from './jamf-guide/jamf-guide';
import JamfInvitation from './jamf-invitation/jamf-invitation';
import JamfHeader from './jamf-header/jamf-header';


import './jamf-page.scss';

type Props = {
  isTeamFetched: false,
  seatsPoolCount: number,
  fetchTeam: () => Promise<any>,
  showModal: (ModalType) => void,
  fetchSubscription: () => Promise<any>,
  isSubscriptionTrial: boolean,
  addDangerNotification: ({message: Node}) => mixed,
};

class JamfPage extends PureComponent<Props> {
  componentDidMount() {
    const {
      fetchTeam,
      fetchSubscription,
      addDangerNotification,
    } = this.props;

    const dataToBeFetched = [
      fetchTeam(),
      fetchSubscription(),
    ];

    return Promise.all(dataToBeFetched)
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  render() {
    const {
      isTeamFetched,
      seatsPoolCount,
      isSubscriptionTrial,
    } = this.props;

    if (!isTeamFetched) {
      return (
        <div className="jamf-page__animated-logo">
          <AnimatedLogo animate />
        </div>
      );
    }

    return (
      <>
        <JamfHeader />
        <JamfInvitation
          seatsPoolCount={seatsPoolCount}
          onAddInvitationsClick={this.onAddInvitationClick}
          isSubscriptionTrial={isSubscriptionTrial}
        />
        <JamfGuide />
      </>
    );
  }

  onAddInvitationClick = () => {
    const { showModal } = this.props;

    showModal('PURCHASE_MEMBER_SEATS');
  }
}

export { JamfPage as PureJamfPage };

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isTeamFetched: isTeamFetched(state),
  seatsPoolCount: getTeamInfo(state).seatsPoolCount,
  isSubscriptionTrial: isSubscriptionTrial(state),
});

const mapActionsToProps = {
  fetchTeam,
  showModal,
  fetchSubscription,
  addDangerNotification,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(JamfPage);
