/* eslint-disable no-underscore-dangle */

// @flow

import React, { Component } from 'react';
import client from 'braintree-web/client';
import paypal from 'braintree-web/paypal';
import { injectIntl, defineMessages } from 'react-intl';
import type { Element, Node } from 'react';
import type { IntlShape } from 'react-intl';
import type {
  Client as BrainTreeClient,
  PayPal,
  BrainTreeError,
} from 'braintree-web';

import logger from 'utils/logger';
import { browserToServerLocale } from 'utils/intl';
import analytics, { events } from 'utils/analytics';

import type { BraintreePaymentDetailsPayload } from 'state/payment-method/payment-method-actions';
import * as paymentMethodTypes from 'state/payment-method/payment-method-types';

import wrapPaymentForm from '../payment-method-form-wrapper/payment-method-form-wrapper';

import PayPalForm from './paypal-form';


type Props = {
  paymentInfo: BraintreePaymentDetailsPayload,
  brainTreeAuthToken: string,
  setLoading: boolean => any,
  setGenericError: Node => any,
  setErrorFields: ({[string]: Node}) => any,
  onFieldChange: (string, string) => any,
  onFormSubmit: () => any,
  merchantId: string,
  isLoading: boolean,
  formError: string,
  fieldsWithError: {
    [string]: Node,
  },
  isPaymentMethodCreated: boolean,
  intl: IntlShape,
  captcha: ?Element<any>,
  userLocale: string,
  countries: {[string]: string},
};

type State = {
  needsCountry: boolean,
  isPaypalLoaded: boolean,
  payPalEmail: string,
};

const messages = defineMessages({
  braintreeInitializationError: {
    id: 'paypalForm.braintreeErrors.cantCreateClient',
    defaultMessage: 'An error occurred while loading your form. Please try again later.',
  },
  unsupportedBrowser: {
    id: 'paypalForm.braintreeErrors.browserUnsupported',
    defaultMessage: 'Sorry, PayPal doesn’t work in this browser. Add a credit card instead, or open this link in another browser.',
  },
  countryRequired: {
    id: 'paypalForm.validation.countryEmpty',
    defaultMessage: 'Country is required',
  },
  payPalAgreement: {
    id: 'paypalForm.agreement',
    defaultMessage: 'SETAPP CUSTOMER TERMS OF USE https://setapp.com/terms-of-use',
  },
  selectCountry: {
    id: 'paypalForm.braintreeErrors.needCountry',
    defaultMessage: 'Please choose your country to continue.',
  },
  tokenizationError: {
    id: 'paypalForm.braintreeErrors.unknownPayPalError',
    defaultMessage: 'An unknown PayPal error has occurred. Please try again later or contact support.',
  },
});

class BraintreePaypalForm extends Component<Props, State> {
  paypalInstance: ?PayPal = null;

  _isMounted = false;

  nonceExpirationTimeout: ?TimeoutID = null;

  state = {
    needsCountry: false,
    isPaypalLoaded: false,
    payPalEmail: '',
  };

  componentDidMount() {
    const {
      onFieldChange,
      brainTreeAuthToken,
    } = this.props;

    this._isMounted = true;

    onFieldChange('type', paymentMethodTypes.PAYPAL);

    if (brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { brainTreeAuthToken } = this.props;

    if (!prevProps.brainTreeAuthToken && brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
  }

  componentWillUnmount() {
    const { setLoading } = this.props;

    this._isMounted = false;

    this.destroyPaypalInstance();
    this.destroyNonceExpirationTimer();

    // Remove loading if unmounted when Braintree request is being processed
    setLoading(false);
  }

  render() {
    const {
      isLoading,
    } = this.props;
    const {
      needsCountry,
      isPaypalLoaded,
      payPalEmail,
    } = this.state;

    return (
      <PayPalForm
        {...this.props}
        needsCountry={needsCountry}
        email={payPalEmail}
        onFormSubmit={this.onFormSubmit}
        isLoading={isLoading || !isPaypalLoaded}
      />
    );
  }

  createBraintreeClient(authorization: string) {
    client.create({ authorization }, this.onBrainTreeClientCreated);
  }

  onBrainTreeClientCreated = (error: ?BrainTreeError, client: BrainTreeClient) => {
    const {
      setGenericError,
      intl,
    } = this.props;

    if (error) {
      logger.logError('Couldn\'t create Brain Tree client instance', error);
      setGenericError(intl.formatMessage(messages.braintreeInitializationError));

      return;
    }

    // fix for paypal initialization errors after logout
    if (this._isMounted) {
      paypal.create({ client }, this.onPaypalCreated);
    }
  };

  onPaypalCreated = (error: ?BrainTreeError, paypalInstance: PayPal) => {
    const {
      setGenericError,
      intl,
    } = this.props;

    if (error) {
      if (error.code === 'PAYPAL_BROWSER_NOT_SUPPORTED') {
        setGenericError(intl.formatMessage(messages.unsupportedBrowser));

        analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR, { eventLabel: error.code });
      } else {
        logger.logError('Can\'t create paypal form instance', error);
        setGenericError(intl.formatMessage(messages.braintreeInitializationError));
      }

      return;
    }

    if (this._isMounted) {
      this.paypalInstance = paypalInstance;
      this.enableForm();
    }
  };

  destroyPaypalInstance() {
    if (this.paypalInstance) {
      this.paypalInstance.teardown((error) => {
        if (error) {
          logger.logError('Couldn\'t destroy PayPal instance', error);
        }
      });
    }
  }

  enableForm() {
    this.setState({ isPaypalLoaded: true });
  }

  setupNonceExpirationTimer() {
    const hourInMilliseconds = 60 * 60 * 1000;

    if (!this.nonceExpirationTimeout) {
      this.nonceExpirationTimeout = setTimeout(this.destroyNonceExpirationTimer.bind(this), hourInMilliseconds);
    }
  }

  destroyNonceExpirationTimer() {
    if (this.nonceExpirationTimeout) {
      clearTimeout(this.nonceExpirationTimeout);
      this.nonceExpirationTimeout = null;
    }
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      intl,
      userLocale,
      paymentInfo,
      onFormSubmit,
      setErrorFields,
      setGenericError,
      setLoading,
      onFieldChange,
    } = this.props;

    if (this.isNonceActive()) {
      if (paymentInfo.country) {
        onFormSubmit();
      } else {
        setErrorFields({ countryEmpty: intl.formatMessage(messages.countryRequired) });
        setLoading(false);
      }

      return;
    }

    if (!this.paypalInstance) {
      throw new Error('PayPal is not initialized');
    }

    this.paypalInstance.tokenize({
      flow: 'vault',
      locale: browserToServerLocale(userLocale),
      enableShippingAddress: false,
      billingAgreementDescription: intl.formatMessage(messages.payPalAgreement),
    }, (tokenizeErr, payload) => {
      if (tokenizeErr) {
        this.handlePayPalError(tokenizeErr);

        return;
      }

      this.setupNonceExpirationTimer();

      onFieldChange('nonce', payload.nonce);
      this.setState({ payPalEmail: payload.details.email });

      if (!payload.details.countryCode) {
        this.setState({ needsCountry: true });
        setLoading(false);
        setGenericError(intl.formatMessage(messages.selectCountry));

        return;
      }

      onFieldChange('country', payload.details.countryCode);

      onFormSubmit();
    });

    setLoading(true);
  };

  handlePayPalError(error: BrainTreeError) {
    const {
      setLoading,
      setGenericError,
      intl,
    } = this.props;

    setLoading(false);

    if (error.code !== 'PAYPAL_POPUP_CLOSED') {
      setGenericError(intl.formatMessage(messages.tokenizationError));

      logger.logError('PayPal tokenization error', error);
    }
  }

  isNonceActive() {
    const { paymentInfo } = this.props;

    return Boolean(paymentInfo.nonce);
  }
}

export { BraintreePaypalForm };

export default wrapPaymentForm(injectIntl(BraintreePaypalForm));
