// @flow

import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export interface Props<Values> {
  onChange: (values: Values) => void
}

function FormikEffect<Values: {...}>({ onChange }: Props<Values>) {
  const { values } = useFormikContext<Values>();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return null;
}

export default FormikEffect;
