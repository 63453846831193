// @flow

import React, { PureComponent, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import httpStatuses from 'http-status';
import RequestError from '@setapp/request-error';

import type { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import analytics, { events } from 'utils/analytics';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import {
  getTeamInfo,
  isSubscriptionTrial,
  getSubscriptions,
  isTeamMemberLoading,
  getAdditionalSeatPlan,
  isUserTeamOwner,
  isPaymentMethodCreated,
  isInactiveSubscription,
} from 'state/root-reducer';
import { addSeatToMember, fetchTeam } from 'state/team-plan/team-plan-actions';
import { fetchSubscription, buyAdditionalSeat } from 'state/subscription/subscription-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { addSuccessNotification, addDangerNotification, addInfoNotification } from 'state/notifier/notifier-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { showModal } from 'state/modal/modal-reducer';

import urls from 'config/urls';

import InactiveSubscription from './inactive-subscription/inactive-subscription';
import MissedPaymentDetails from './missed-payment-details/missed-payment-details';
import AddSeatLimitReached from './add-seat-limit-reached/add-seat-limit-reached';
import AddSeatActivateSubscription from './add-seat-activate-subscription/add-seat-activate-subscription';
import AddSeatWithPurchase from './add-seat-with-purchase/add-seat-with-purchase';
import AddSeatFromPool from './add-seat-from-pool/add-seat-from-pool';


type Props = {
  onHide: () => any,
  onExited: () => any,
  show: boolean,
  member: TeamMember,
  isMemberLoading: boolean,
  isSubscriptionLoading: boolean,
  isSubscriptionTrial: boolean,
  isUserTeamOwner: boolean,
  isPaymentMethodCreated: boolean,
  isInactiveSubscription: boolean,
  maxAdditionalDevicesPerMember: number,
  additionalDevicesPoolCount: number,
  additionalSeatPlan: PricePlan,
  addSeatToMember: number => Promise<any>,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  addInfoNotification: ({message: Node}) => any,
  fetchTeam: () => Promise<any>,
  fetchSubscription: () => Promise<any>,
  fetchPricePlans: () => Promise<any>,
  fetchPaymentMethod: () => Promise<any>,
  buyAdditionalSeat: ({pricePlanId: number, count: number}) => Promise<any>,
  history: RouterHistory,
};

type State = {
  contentVariant: | 'fromPool'
    | 'withPurchase'
    | 'seatsLimitReached'
    | 'trialActive'
    | 'noPaymentDetailsForAdmin'
    | 'subscriptionInactiveForAdmin'
    | null,
};

class AddSeatToMemberModal extends PureComponent<Props, State> {
  state = {
    contentVariant: null,
  };

  componentDidMount() {
    const {
      fetchSubscription,
      fetchTeam,
      fetchPricePlans,
      fetchPaymentMethod,
    } = this.props;

    return Promise.all([
      fetchSubscription(),
      fetchTeam(),
      fetchPricePlans(),
      fetchPaymentMethod(),
    ])
      .then(() => {
        this.selectContentVariant();
      });
  }

  render() {
    const {
      show,
      onHide,
      onExited,
    } = this.props;
    const { contentVariant } = this.state;
    const isDataLoading = contentVariant === null;
    const isCancelButtonShown = !['noPaymentDetailsForAdmin', 'subscriptionInactiveForAdmin'].includes(contentVariant);

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={<FormattedMessage id="addDeviceModal.title" defaultMessage="Adding a device" />}
        isLoading={isDataLoading}
        withCancelButton={isCancelButtonShown}
      >
        {!isDataLoading && this.renderContentVariant()}
      </Modal>
    );
  }

  renderContentVariant() {
    const {
      onHide,
      member,
      maxAdditionalDevicesPerMember,
      isMemberLoading,
      isSubscriptionLoading,
      additionalSeatPlan,
    } = this.props;
    const { contentVariant } = this.state;

    switch (contentVariant) {
      case 'trialActive': {
        return <AddSeatActivateSubscription onConfirm={this.handleActivateSubscriptionAction} />;
      }
      case 'seatsLimitReached': {
        return <AddSeatLimitReached maxDevicesPerMember={maxAdditionalDevicesPerMember} />;
      }
      case 'withPurchase': {
        return (
          <AddSeatWithPurchase
            memberEmail={member.email}
            isSubmitDisabled={isMemberLoading || isSubscriptionLoading}
            seatPricePlan={additionalSeatPlan}
            onConfirm={this.handleAddSeatWithPurchaseClick}
          />
        );
      }
      case 'noPaymentDetailsForAdmin': {
        return <MissedPaymentDetails onClose={onHide} />;
      }
      case 'subscriptionInactiveForAdmin': {
        return <InactiveSubscription onClose={onHide} />;
      }
      default: {
        return (
          <AddSeatFromPool
            memberEmail={member.email}
            isSubmitDisabled={isMemberLoading}
            onConfirm={this.handleAddSeatFromPoolClick}
          />
        );
      }
    }
  }

  handleAddSeatFromPoolClick = () => {
    const { onHide } = this.props;

    return this.addSeatToMember()
      .catch(this.handleAddSeatError)
      .finally(onHide);
  };

  handleAddSeatWithPurchaseClick = () => {
    const SEATS_AMOUNT = 1;
    const { buyAdditionalSeat, additionalSeatPlan, onHide } = this.props;

    return buyAdditionalSeat({ pricePlanId: additionalSeatPlan.id, count: SEATS_AMOUNT })
      .then(() => this.addSeatToMember())
      .catch(this.handleAddSeatError)
      .finally(onHide);
  };

  handleAddSeatError = (error: Error | RequestError) => {
    const { addDangerNotification, addInfoNotification } = this.props;

    if (error.name === 'RequestError' && error.status === httpStatuses.FORBIDDEN) {
      addInfoNotification({
        message: (
          <FormattedMessage
            id="addSeatModal.anotherPaymentPendingError"
            defaultMessage="Please wait a bit, your previous payment is in progress."
          />
        ),
      });
    } else {
      addDangerNotification({
        message: <DefaultError />,
      });
    }
  };

  addSeatToMember() {
    const {
      member,
      addSeatToMember,
      addSuccessNotification,
    } = this.props;

    return addSeatToMember(member.id)
      .then(() => {
        addSuccessNotification({
          message: (
            <FormattedMessage
              id="addDeviceModal.deviceAddedNotification"
              defaultMessage="Extra device added"
            />
          ),
        });
        const eventLabel = member.isOwner ? 'Owner' : 'Team Member';
        analytics.trackEvent(events.TEAM_ADDITIONAL_DEVICE_PURCHASE, { eventLabel });
      });
  }

  handleActivateSubscriptionAction = () => {
    const { history } = this.props;

    history.push(urls.activateSubscription);
  };

  selectContentVariant() {
    const {
      member,
      maxAdditionalDevicesPerMember,
      additionalDevicesPoolCount,
      isSubscriptionTrial,
      isUserTeamOwner,
      isPaymentMethodCreated,
      isInactiveSubscription,
    } = this.props;

    const isMemberWithMaxAdditionalSeats = member.additionalDevicesCount >= maxAdditionalDevicesPerMember;
    const isNoAvailableSeatsInTeam = additionalDevicesPoolCount < 1;
    const isNoPaymentDetailsForAdmin = !isUserTeamOwner && !isPaymentMethodCreated;
    const isSubscriptionInactiveForAdmin = !isUserTeamOwner && isInactiveSubscription;

    if (isSubscriptionInactiveForAdmin) {
      this.setState({ contentVariant: 'subscriptionInactiveForAdmin' });
    } else if (isNoPaymentDetailsForAdmin) {
      this.setState({ contentVariant: 'noPaymentDetailsForAdmin' });
    } else if (isSubscriptionTrial) {
      this.setState({ contentVariant: 'trialActive' });
    } else if (isMemberWithMaxAdditionalSeats) {
      this.setState({ contentVariant: 'seatsLimitReached' });
    } else if (isNoAvailableSeatsInTeam) {
      this.setState({ contentVariant: 'withPurchase' });
    } else {
      this.setState({ contentVariant: 'fromPool' });
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { maxAdditionalDevicesPerMember, additionalDevicesPoolCount } = getTeamInfo(state);

  return {
    maxAdditionalDevicesPerMember,
    additionalDevicesPoolCount,
    additionalSeatPlan: getAdditionalSeatPlan(state),
    isSubscriptionLoading: getSubscriptions(state).isLoading,
    isSubscriptionTrial: isSubscriptionTrial(state),
    isMemberLoading: isTeamMemberLoading(state, ownProps.member.id),
    isUserTeamOwner: isUserTeamOwner(state),
    isPaymentMethodCreated: isPaymentMethodCreated(state),
    isInactiveSubscription: isInactiveSubscription(state),
  };
};

const mapActionsToProps = {
  addSeatToMember,
  addInfoNotification,
  addSuccessNotification,
  addDangerNotification,
  fetchTeam,
  fetchSubscription,
  fetchPricePlans,
  fetchPaymentMethod,
  buyAdditionalSeat,
  showModal,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(AddSeatToMemberModal));

export { AddSeatToMemberModal as PureAddSeatToMemberModal };
