// @flow

import { type Notification } from './notifier-initial-state';

export const ADD = 'NOTIFICATIONS_ADD';
export const CLEAR = 'NOTIFICATIONS_CLEAR';
export const REMOVE = 'NOTIFICATIONS_REMOVE';

type NotificationsAddAction = {|
  type: 'NOTIFICATIONS_ADD',
  payload: Notification,
|};

type NotificationsClearAction = {|
  type: 'NOTIFICATIONS_CLEAR',
|};

type NotificationsRemoveAction = {|
  type: 'NOTIFICATIONS_REMOVE',
  payload: string,
|};

export type NotificationsAction = NotificationsAddAction | NotificationsClearAction | NotificationsRemoveAction;
