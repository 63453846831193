// @flow

import auth from 'utils/auth';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import * as paymentProviders from 'state/payment-method/payment-providers-types';
import type { PaymentProvider } from 'state/payment-method/payment-providers-types';

import type { UserPermission } from './user-permissions';
import type { UserType } from './user-types';
import * as userTypes from './user-types';

export type User = {
  id: ?number,
  name: string,
  email: string,
  emailConfirmed: boolean,
  familyMember: ?TeamMember,
  isAuthenticated: boolean,
  paymentInfoRequired: boolean,
  pricePlanRequired: boolean,
  canSwitchToEduPlan: boolean,
  isLoading: boolean,
  isEduEmail: boolean,
  isTeamMember: boolean,
  type: UserType,
  locale: string,
  permissions: Array<UserPermission>,
  isLocaleChanged: boolean,
  registrationFlow: ?string,
  registrationParams: Object,
  trialPeriodLength: number,
  marketingSubscribed: boolean,
  paymentProvider: PaymentProvider,
  campaign: ?string,
};

export type UserState = $ReadOnly<User>;

const initialState: UserState = {
  id: null,
  name: '',
  email: '',
  emailConfirmed: false,
  familyMember: null,
  isAuthenticated: auth.isLoggedIn(),
  paymentInfoRequired: false,
  pricePlanRequired: false,
  canSwitchToEduPlan: false,
  isLoading: false,
  isEduEmail: false,
  isTeamMember: false,
  type: userTypes.REGULAR,
  locale: '',
  permissions: [],
  isLocaleChanged: false,
  registrationFlow: null,
  registrationParams: {},
  trialPeriodLength: 0,
  marketingSubscribed: false,
  paymentProvider: paymentProviders.BRAINTREE,
  campaign: null,
};

export default initialState;
