// @flow

import RequestError from '@setapp/request-error';
import type { Application } from './apps-initial-state';

export const REQUEST = 'APPS_REQUEST';
export const REQUEST_SUCCESS = 'APPS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'APPS_REQUEST_ERROR';

type AppsRequestAction = {
  type: 'APPS_REQUEST',
};

type AppsRequestSuccessAction = {
  type: 'APPS_REQUEST_SUCCESS',
  payload: Array<Application>,
};

type AppsRequestErrorAction = {
  type: 'APPS_REQUEST_ERROR',
  payload: typeof RequestError,
};

export type AppsAction = AppsRequestAction | AppsRequestSuccessAction | AppsRequestErrorAction;
