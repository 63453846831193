// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice, FormattedPeriod, FormattedTax } from 'components/shared/formatter/formatter';

type Props = {
  tax: number,
  price: number,
  currency: string,
  paidMonth: number,
};

const SubscriptionPrice = ({
  price, currency, paidMonth, tax,
}: Props) => {
  const priceFormatted = <FormattedPrice price={price} currency={currency} />;

  const periodFormatted = <FormattedPeriod paidMonth={paidMonth} />;

  if (tax === 0) {
    return (
      <FormattedMessage
        id="subscriptionInfo.priceUnknownTax"
        defaultMessage="{price} + sales tax"
        values={{
          price: priceFormatted,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="subscriptionInfo.priceWithVat"
      defaultMessage="{price}+Tax (Tax ≈ {tax})"
      values={{
        price: priceFormatted,
        tax: <FormattedTax tax={tax} currency={currency} />,
        period: periodFormatted,
      }}
    />
  );
};

export default SubscriptionPrice;
