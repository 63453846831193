// @flow

import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import { Button } from '@setapp/ui-kit';

import './action-card.scss';

type Props = {
  icon: Node,
  title: Node,
  description: Node,
  ctaCaption?: Node,
  ctaElement?: Node,
  onCtaClick?: ?() => mixed,
  checked?: boolean,
}

const ActionCard = ({
  icon,
  title,
  description,
  ctaCaption,
  onCtaClick,
  checked,
  ctaElement,
}: Props) => (
  <div className={classnames('action-card', { 'action-card_checked': checked })}>
    <img src={icon} alt="" width={80} height={80} />
    <p className="h5">
      {title}
    </p>
    <p className="action-card__description">
      {description}
    </p>
    <div className="action-card__cta">
      {ctaElement || (
        <Button
          onClick={onCtaClick}
          block
        >
          {ctaCaption}
        </Button>
      )}
    </div>
  </div>
);

ActionCard.defaultProps = {
  checked: false,
  ctaElement: null,
  ctaCaption: null,
  onCtaClick: null,
};

export default ActionCard;
