// @flow

import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import InfoField from 'components/shared/info-field/info-field';

import StatusRow from '../status-row/status-row';
import ActiveMembersRow from '../active-members-row/active-members-row';


type Props = {
  expirationDate: ?number,
  nextPaymentDate: ?number,
  activeMembers: Array<TeamMember>,
};

const STATUS_FREE = 10;

const SubscriptionFree = ({
  expirationDate,
  nextPaymentDate,
  activeMembers,
}: Props) => (
  <Fragment>
    <StatusRow
      status={STATUS_FREE}
      expirationDate={expirationDate}
      nextPaymentDate={nextPaymentDate}
    />

    <ActiveMembersRow activeMembers={activeMembers} />

    <InfoField
      lightValue
      title={<FormattedMessage id="subscriptionInfo.planTitleFree" defaultMessage="Plan" />}
      value={<FormattedMessage id="subscriptionInfo.pricePlanFree" defaultMessage="Free" />}
      qaLabel="planTitle"
    />
  </Fragment>
);

export default SubscriptionFree;
