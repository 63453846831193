// @flow

import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import RequestError from '@setapp/request-error';

import type { FormikHelpers } from 'formik';

import FormikEffect from './formik-effect';
import { getRegisteredFieldsErrors } from './utils';

import type {
  AdvancedFormProps,
  AdvancedFormRenderProps,
} from './types';

function AdvancedFormContainer<T: {...}>(props: AdvancedFormProps<T>) {
  const {
    onSubmit,
    onChange,
    mapErrorCodeToMessage = {},
    ...formikConfig
  } = props;
  const [genericError, setGenericError] = useState(null);

  const handleChange = useCallback((values: T): void => {
    if (onChange) {
      onChange(values);
    }
  }, [onChange]);

  const handleSubmit = useCallback(async (values: T, actions: FormikHelpers<T>): Promise<void> => {
    try {
      await onSubmit(values, actions);
    } catch (error) {
      if (error instanceof RequestError) {
        const genericError = error.getGenericError();
        const errors = getRegisteredFieldsErrors(values, error, mapErrorCodeToMessage);

        setGenericError(genericError);
        actions.setErrors(errors);
      }

      throw error;
    } finally {
      actions.setSubmitting(false);
    }
  }, [onSubmit]); // eslint-disable-line react-hooks/exhaustive-deps


  const { children } = formikConfig;

  return (
    /**
     * $FlowFixMe[incompatible-type]
     * Formik allows us to use asynchronous submission handler
     */
    <Formik {...formikConfig} onSubmit={handleSubmit}>
      {(formikProps) => (
        <>
          <FormikEffect onChange={handleChange} />
          {typeof children === 'function' ? children({ ...formikProps, genericError }) : children}
        </>
      )}
    </Formik>
  );
}

export default AdvancedFormContainer;

export type {
  AdvancedFormProps,
  AdvancedFormRenderProps,
};
