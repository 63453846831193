// @flow

import React from 'react';
import paymentMethodIcons from 'components/shared/payment-method/payment-method-icons';

import './supported-payment-methods.scss';

const iconStyleClass = 'supported-payment-methods__icon';

const SupportedPaymentMethods = () => (
  <>
    <span className="mr-2">{paymentMethodIcons.visa(iconStyleClass)}</span>
    <span className="mr-2">{paymentMethodIcons.mastercard(iconStyleClass)}</span>
    <span className="mr-2">{paymentMethodIcons.americanexpress(iconStyleClass)}</span>
    <span className="mr-2">{paymentMethodIcons.paypal(iconStyleClass)}</span>
    <span className="mr-2">{paymentMethodIcons.payunion(iconStyleClass)}</span>
  </>
);

export default SupportedPaymentMethods;
