// @flow

import * as events from './events';

const categories = {
  GLOBAL: 'Teams Global Actions',
  SIGNUP_PAGE: 'Teams Sign Up Page',
  PAYMENT_INFO: 'Teams Payment Info',
  ACCOUNT: 'Teams Account',
  CANCEL_SUBSCRIPTION: 'Teams Cancel Subscription',
  DEACTIVATE_DEVICE: 'Teams Deactivate Device',
  SUBSCRIPTION: 'Teams Subscription Block',
  SUCCESSFUL_REGISTRATION_PAGE: 'Teams Successful Registration Page',
  TEAM_MANAGEMENT: 'Team Management',
  WELCOME_PAGE: 'Welcome Page',
  SIDEBAR: 'Sidebar',
  ACTIVATE_SUBSCRIPTION_PAGE: 'Activation Flow',
  APPS_MANAGEMENT: 'Apps Management',
  MOBILE_FLOW: 'Mobile Flow',
  PURCHASE_INVITES_BLOCK: 'Purchase Invites Block',
  ADDITIONAL_SEATS: 'Teams Additional Seats',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [events.SIGNUP_SUCCESS]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Sign Up Success',
  },

  [events.SIGNUP_EMAIL_OPT_IN]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Email Opt-In',
  },

  [events.SUCCESSFUL_REGISTRATION_OPEN_DESKTOP]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Login to Desktop',
  },

  [events.SUCCESSFUL_REGISTRATION_PAGE_DOWNLOAD_STARTED]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Download Start',
  },

  [events.SUCCESSFUL_REGISTRATION_CLICK_DOWNLOAD]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Click Download',
  },

  [events.LOGIN_SUCCESS]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Login Success',
  },

  [events.LOGIN_ERROR]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Login Error',
  },

  [events.NEW_PASSWORD_REQUEST]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'New Password Request',
  },

  [events.NEW_PASSWORD_SET]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'New Password Set',
  },

  [events.LOGOUT_SUCCESS]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Log Out',
  },

  [events.PAYMENT_DETAILS_ADD]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Payment Details Add',
  },

  [events.PAYMENT_DETAILS_COUNTRY_SELECT]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Country Select',
  },

  [events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Payment Method Select',
  },

  [events.PAYMENT_DETAILS_FORM_ERROR]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form Error',
  },

  [events.PAYMENT_DETAILS_FORM_DISPLAYED]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form Shown',
  },

  [events.PAYMENT_METHOD_UPDATE_INFO_CLICK]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Click Update',
  },

  [events.ACCOUNT_EMAIL_CHANGED]: {
    eventCategory: categories.ACCOUNT,
    eventAction: 'Email Change',
  },

  [events.ACCOUNT_PASSWORD_CHANGED]: {
    eventCategory: categories.ACCOUNT,
    eventAction: 'Password Change',
  },

  [events.CANCEL_SUBSCRIPTION_MODAL_OPEN]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Open Dialog',
  },

  [events.CANCEL_SUBSCRIPTION_CONFIRM]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Confirm',
  },

  [events.DEVICE_DEACTIVATED]: {
    eventCategory: categories.DEACTIVATE_DEVICE,
    eventAction: 'Confirm',
  },

  [events.DEVICE_DEACTIVE_ALL_IOS_CLICK]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Disconnect All iOS Devices',
  },

  [events.DEVICE_ALL_IOS_DEACTIVATED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'All iOS Devices Disconnected',
  },

  [events.SUBSCRIPTION_PLAN_MODAL_OPEN]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Open Plan Dialog',
  },

  [events.SUBSCRIPTION_PLAN_CHANGED]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Change Plan',
  },

  [events.CROSS_AUTH_SUCCESS]: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Cross Auth Success',
  },

  [events.CROSS_AUTH_ERROR]: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Cross Auth Error',
  },

  [events.WELCOME_PAGE_CLICK_DOWNLOAD]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Download',
  },

  [events.WELCOME_PAGE_CLICK_OPEN_APP]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Open App',
  },

  [events.WELCOME_PAGE_CLICK_INVITE_MEMBER]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Invite Member',
  },

  [events.WELCOME_PAGE_CLICK_INVITE_MEMBER_AGAIN]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Invite Member Again',
  },

  [events.WELCOME_PAGE_CLICK_ADD_PAYMENT_METHOD]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Add Payment Details',
  },

  [events.WELCOME_PAGE_CLICK_UPDATE_PAYMENT_METHOD]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Update Payment Details',
  },

  [events.WELCOME_PAGE_CLICK_TAKE_SURVEY]: {
    eventCategory: categories.WELCOME_PAGE,
    eventAction: 'Click Take Survey',
  },

  [events.TEAM_INVITE_MEMBER_CLICK]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Click Invite Member',
  },

  [events.TEAM_INVITE_MEMBER_SUCCESS]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Invite Member Success',
  },

  [events.TEAM_INVITE_VIA_JAMF_CLICK]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Click Invite via JAMF',
  },

  [events.TEAM_REVOKE_INVITE_SUCCESS]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Revoke Invite Success',
  },

  [events.TEAM_ADDITIONAL_DEVICE_PURCHASE]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Additional Device Purchase',
  },

  [events.TEAM_ADDITIONAL_DEVICE_REMOVE]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Additional Device Remove',
  },

  [events.TEAM_REMOVE_MEMBER_CLICK]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Remove Member Click',
  },

  [events.TEAM_REMOVE_MEMBER_SUCCESS]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Remove Member Success',
  },

  [events.TEAM_SHARE_ADMIN_ROLE_CLICK]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Click Share Admin Role',
  },

  [events.TEAM_ADMIN_ROLE_SHARE_SUCCESS]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Admin Role Share Success',
  },

  [events.TEAM_REMOVE_ADMIN_ROLE_CLICK]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Click Remove Admin Role',
  },

  [events.TEAM_REMOVE_ADMIN_ROLE_SUCCESS]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Remove Admin Role Success',
  },


  [events.TEAM_CLICK_PURCHASE_INVITES]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Click Purchase an Invitation',
  },

  [events.TEAM_CLICK_ACTIVATE_MEMBERSHIP]: {
    eventCategory: categories.TEAM_MANAGEMENT,
    eventAction: 'Click Activate Membership',
  },

  [events.SIDEBAR_CLICK_CONTACT_SALES]: {
    eventCategory: categories.SIDEBAR,
    eventAction: 'Click Contact Sales',
  },

  [events.SIDEBAR_CLICK_DOWNLOAD]: {
    eventCategory: categories.SIDEBAR,
    eventAction: 'Click Download',
  },

  [events.ACTIVATE_SUBSCRIPTION_PAGE_PLAN_SELECTED]: {
    eventCategory: categories.ACTIVATE_SUBSCRIPTION_PAGE,
    eventAction: 'Plan Selected',
  },

  [events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_PADDLE]: {
    eventCategory: categories.ACTIVATE_SUBSCRIPTION_PAGE,
    eventAction: 'Click Paddle',
  },

  [events.ACTIVATE_SUBSCRIPTION_PAGE_SUBSCRIPTION_ACTIVATED]: {
    eventCategory: categories.ACTIVATE_SUBSCRIPTION_PAGE,
    eventAction: 'Subscription Activated',
  },

  [events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_OK_GREAT]: {
    eventCategory: categories.ACTIVATE_SUBSCRIPTION_PAGE,
    eventAction: 'Click Ok Great',
  },

  [events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_MANAGE_TEAM]: {
    eventCategory: categories.ACTIVATE_SUBSCRIPTION_PAGE,
    eventAction: 'Click Manage My Team',
  },

  [events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_GO_TO_ACCOUNT]: {
    eventCategory: categories.ACTIVATE_SUBSCRIPTION_PAGE,
    eventAction: 'Click Go To Account',
  },

  [events.APPS_MANAGEMENT_HIDE_APP]: {
    eventCategory: categories.APPS_MANAGEMENT,
    eventAction: 'Hide App',
  },

  [events.APPS_MANAGEMENT_UNHIDE_APP]: {
    eventCategory: categories.APPS_MANAGEMENT,
    eventAction: 'Unhide App',
  },

  [events.APPS_MANAGEMENT_START_SEARCH]: {
    eventCategory: categories.APPS_MANAGEMENT,
    eventAction: 'Start Search',
  },

  [events.APPS_MANAGEMENT_CLICK_SHOW_MORE]: {
    eventCategory: categories.APPS_MANAGEMENT,
    eventAction: 'Click Show More',
  },

  [events.APPS_MANAGEMENT_CLICK_LETS_START]: {
    eventCategory: categories.APPS_MANAGEMENT,
    eventAction: 'Click Let\'s Start',
  },


  [events.HEADER_NAVIGATION_CONTACT_SUPPORT]: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Click Contact Support',
  },

  [events.HEADER_NAVIGATION_MY_ACCOUNT]: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Click My Account',
  },

  [events.MOBILE_FLOW_CLICK_SEND_ME_LINK]: {
    eventCategory: categories.MOBILE_FLOW,
    eventAction: 'Click Send Me Link',
  },

  [events.MOBILE_FLOW_CLICK_DOWNLOAD_TO_THIS_DEVICE]: {
    eventCategory: categories.MOBILE_FLOW,
    eventAction: 'Click Download To This Device',
  },

  [events.PURCHASE_INVITES_BLOCK_CLICK_PURCHASE_INVITES]: {
    eventCategory: categories.PURCHASE_INVITES_BLOCK,
    eventAction: 'Click Purchase Invites',
  },

  [events.PURCHASE_INVITES_BLOCK_PURCHASE_INVITES_SUCCESS]: {
    eventCategory: categories.PURCHASE_INVITES_BLOCK,
    eventAction: 'Purchase Invites Success',
  },

  [events.PURCHASE_INVITES_BLOCK_CLICK_ACTIVATE_MEMBERSHIP]: {
    eventCategory: categories.PURCHASE_INVITES_BLOCK,
    eventAction: 'Click Activate Membership',
  },
};
