// @flow

import React from 'react';
import classnames from 'classnames';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { FormField, Button } from '@setapp/ui-kit';

import type { Element, Node } from 'react';

type Props = {|
  onFormSubmit: (SyntheticEvent<HTMLFormElement>) => any,
  onEmailChange: (SyntheticInputEvent<HTMLInputElement>) => any,
  onPasswordChange: (SyntheticInputEvent<HTMLInputElement>) => any,
  isRequestProcessing?: boolean,
  emailError?: Node,
  passwordError?: Node,
  genericError?: Node,
  email?: string,
  password?: string,
  intl: Object,
  captcha: ?Element<any>
|};

const messages = defineMessages({
  emailPlaceholder: {
    id: 'outer.login.emailPlaceholder',
    defaultMessage: 'Your email',
  },
  passwordPlaceholder: {
    id: 'outer.login.passwordPlaceholder',
    defaultMessage: 'Your password',
  },
});

const LoginForm = (props: Props) => {
  const {
    onEmailChange, onPasswordChange, onFormSubmit, intl, captcha,
  } = props;
  const {
    emailError, passwordError, genericError, isRequestProcessing, email, password,
  } = props;
  const errorClasses = classnames('form-error text-danger', {
    hidden: !genericError,
  });
  const btnClasses = classnames({
    processing: isRequestProcessing,
  });

  return (
    <form onSubmit={onFormSubmit} noValidate data-qa="loginForm">
      <FormField
        id="email"
        type="email"
        name="email"
        autoComplete="email"
        label={<FormattedMessage id="outer.login.emailLabel" defaultMessage="Email" />}
        placeholder={intl.formatMessage(messages.emailPlaceholder)}
        helpText={emailError}
        invalid={Boolean(emailError)}
        onChange={onEmailChange}
        value={email}
        autoFocus
        qaLabel="emailFormGroup"
      />
      <FormField
        id="password"
        name="password"
        type="password"
        label={<FormattedMessage id="outer.login.passwordLabel" defaultMessage="Password" />}
        placeholder={intl.formatMessage(messages.passwordPlaceholder)}
        helpText={passwordError}
        invalid={Boolean(passwordError)}
        value={password}
        email={email}
        onChange={onPasswordChange}
        qaLabel="passwordFormGroup"
      />

      {captcha}
      <p className={errorClasses}>{genericError}</p>
      <Button
        className={btnClasses}
        type="submit"
        block
        disabled={isRequestProcessing}
      >
        <FormattedMessage id="outer.login.submitBtnCaption" defaultMessage="Sign in" />
      </Button>
    </form>
  );
};

LoginForm.defaultProps = {
  isRequestProcessing: false,
  emailError: '',
  passwordError: '',
  genericError: '',
  email: '',
  password: '',
};

export { LoginForm as PureLoginForm };

export default injectIntl(LoginForm);
