// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { DeviceIcon, Button } from '@setapp/ui-kit';

import './device-item.scss';
import type { Device } from 'state/devices/devices-initial-state';

type Props = {|
  device: Device,
  onDisconnectClick: (Device) => any,
|}

class DeviceItem extends PureComponent<Props> {
  render() {
    const {
      device: {
        model,
        name,
        lastUsedDate,
      },
    } = this.props;

    return (
      <div className="devices-list-item-row">
        <div className="devices-list-item-col devices-list-item-col-description">
          <strong className="text-lg">{name}</strong>
          <div className="account-info-row" data-qa="activatedDevice">
            <div className="account-info-row-title">
              <FormattedMessage id="deviceInfo.loginDateTitle" defaultMessage="Last login:" />
            </div>
            <div className="account-info-row-value">
              <FormattedDate
                value={lastUsedDate * 1000}
                year="numeric"
                month="short"
                day="numeric"
              />
            </div>
          </div>
          <div className="devices-list-item-btn">
            <Button
              variant="danger-outline"
              size="sm"
              onClick={this.onDisconnectClick}
              data-qa="disconnectDeviceButton"
            >
              <FormattedMessage id="deviceInfo.deviceItem.disconnectBtn" defaultMessage="Disconnect device" />
            </Button>
          </div>
        </div>
        <div className="devices-list-item-col devices-list-item-col-icon">
          <DeviceIcon device={model} />
        </div>
      </div>
    );
  }

  onDisconnectClick = () => {
    const { onDisconnectClick, device } = this.props;

    onDisconnectClick(device);
  };
}

export default DeviceItem;
