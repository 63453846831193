// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import VatNoteRow from 'components/shared/vat-note-row/vat-note-row';
import { FormattedPeriod, FormattedPrice } from 'components/shared/formatter/formatter';
import InfoField from 'components/shared/info-field/info-field';

import type { SubscriptionWithAdditionalSeats } from 'state/subscription/subscription-initial-state';

import AdditionalSeatsAmountRow from '../additional-seats-amount-row/additional-seats-amount-row';
import PriceRow from '../price-row/price-row';

import NextPaymentRow from './next-payment-row/next-payment-row';


type Props = {|
  subscription: SubscriptionWithAdditionalSeats,
|};

const AdditionalSeatsInfo = (props: Props) => {
  const {
    subscription: {
      tax, pricePlan, nextPricePlan, nextPaymentDate, seatsNumber,
    },
  } = props;
  // Display next price plan info if user changed plan. It's made by design.
  const {
    price, currency, paidMonth,
  } = nextPricePlan || pricePlan;

  return (
    <>
      <AdditionalSeatsAmountRow
        additionalSeatsAmount={seatsNumber}
      />

      <InfoField
        lightValue
        title={<FormattedMessage id="subscriptionInfo.pricePerSeatRow.title" defaultMessage="Price" />}
        value={(
          <FormattedMessage
            id="subscriptionInfo.pricePerSeatRow.value"
            defaultMessage="{price} per device/{period}"
            values={{
              price: (
                <FormattedPrice
                  price={price / seatsNumber}
                  currency={currency}
                />
              ),
              period: <FormattedPeriod paidMonth={paidMonth} />,
            }}
          />
        )}
      />

      <PriceRow
        title={(
          <FormattedMessage
            id="subscriptionInfo.additionalSeats.currentPrice"
            defaultMessage="Current Price"
          />
        )}
        price={price}
        tax={tax}
        currency={currency}
        paidMonth={paidMonth}
      />

      <NextPaymentRow nextPaymentDate={nextPaymentDate} />

      <VatNoteRow />
    </>
  );
};

export default AdditionalSeatsInfo;
