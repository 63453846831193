// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  getTeamInfo,
  isFreeUser,
  isUserTeamOwner,
  isUserTeamAdmin,
} from 'state/root-reducer';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';

import SidebarNav from './sidebar-nav/sidebar-nav';


import './sidebar-navigation.scss';

type Props = {
  isFreeUser: boolean,
  isUserTeamOwner: boolean,
  isUserTeamAdmin: boolean,
  onCloseNavigation: () => void,
  companyName: string,
};

class SidebarNavigation extends Component<Props> {
  render() {
    const {
      isFreeUser,
      isUserTeamOwner,
      isUserTeamAdmin,
      onCloseNavigation,
      companyName,
    } = this.props;
    const { isMobile } = this.context;
    const navLinks = [
      {
        path: urls.dashboard,
        name: <FormattedMessage id="navigation.dashboard" defaultMessage="Dashboard" />,
        available: isUserTeamOwner,
      },
      {
        path: urls.subscription,
        name: <FormattedMessage id="navigation.subscription" defaultMessage="Account overview" />,
        available: isUserTeamOwner,
      },
      {
        path: urls.account,
        name: <FormattedMessage id="navigation.account" defaultMessage="Settings" />,
        available: true,
      },
      {
        path: urls.devices,
        name: <FormattedMessage id="navigation.devices" defaultMessage="My devices" />,
        available: true,
      },
      {
        path: urls.team,
        name: <FormattedMessage id="navigation.teamPage" defaultMessage="Team management" />,
        available: isUserTeamOwner || isUserTeamAdmin,
      },
      {
        path: urls.jamf,
        name: (
          <FormattedMessage id="navigation.jamfDistribution" defaultMessage="Jamf distribution" />
        ),
        available: isUserTeamOwner,
      },
      {
        path: urls.paymentHistory,
        name: <FormattedMessage id="navigation.paymentHistory" defaultMessage="Receipts" />,
        available: isUserTeamOwner && !isFreeUser,
      },
      {
        path: urls.appsManagement,
        name: <FormattedMessage id="navigation.appsManagement" defaultMessage="Apps management" />,
        available: isUserTeamOwner,
      },
    ];

    return (
      <SidebarNav>
        <SidebarNav.Header onCloseButtonClick={onCloseNavigation} companyName={companyName} />
        <div className="sidebar-navigation__content">
          <SidebarNav.Section>
            {navLinks.map((link) => link.available && (
              <LinkContainer key={link.path} to={link.path} onClick={onCloseNavigation}>
                <SidebarNav.Item activeClassName="sidebar-nav__active-item">
                  {link.name}
                </SidebarNav.Item>
              </LinkContainer>
            ))}
          </SidebarNav.Section>
          <SidebarNav.Footer
            isMobile={isMobile}
            showSalesLink={isUserTeamOwner && !isFreeUser}
            onDownloadClick={this.trackDownloadEvent}
            onContactSalesClick={this.trackContactSalesEvent}
          />
        </div>
      </SidebarNav>
    );
  }

  trackDownloadEvent = () => {
    analytics.trackEvent(events.SIDEBAR_CLICK_DOWNLOAD);
  };

  trackContactSalesEvent = () => {
    analytics.trackEvent(events.SIDEBAR_CLICK_CONTACT_SALES);
  }
}

SidebarNavigation.contextTypes = {
  isMobile: PropTypes.bool,
};

export { SidebarNavigation };

const mapStateToProps = (state) => ({
  isFreeUser: isFreeUser(state),
  isUserTeamOwner: isUserTeamOwner(state),
  isUserTeamAdmin: isUserTeamAdmin(state),
  companyName: getTeamInfo(state).companyName,
});

export { SidebarNavigation as PureSidebarNavigation };

export default connect(mapStateToProps)(injectIntl(withRouter(SidebarNavigation)));
