// @flow

import PropTypes from 'prop-types';
import React, { useCallback, useState, type Node } from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { FormattedMessage } from 'react-intl';

import type { Application } from 'state/apps/apps-initial-state';

import LinkButton from 'components/shared/link-button/link-button';

import analytics, { events } from 'utils/analytics';

import AppsListItem from '../apps-list-item/apps-list-item';


import './apps-list.scss';

type Props = {
  title: Node,
  apps: Array<Application>,
  hideOnEmpty?: boolean,
  onAppClick: (Application) => void,
  isAppsProcessing: boolean,
}

type Context = {
  isMobile: boolean,
}

const AppsList = (props: Props, { isMobile }: Context) => {
  const {
    title,
    apps,
    hideOnEmpty,
    onAppClick,
    isAppsProcessing,
  } = props;

  const [isShowAllApps, setIsShowAllApps] = useState<boolean>(false);
  const handleShowAll = useCallback(() => {
    setIsShowAllApps(true);
    analytics.trackEvent(events.APPS_MANAGEMENT_CLICK_SHOW_MORE);
  }, []);

  const isEmpty = !apps.length;
  if (isEmpty && hideOnEmpty) {
    return null;
  }

  const appsToShowLimit = isMobile ? 12 : 18;
  const appsToShow: Array<Application> = isShowAllApps ? apps : apps.slice(0, appsToShowLimit);
  const isShowMoreButtonVisible: boolean = apps.length > appsToShowLimit && !isShowAllApps;

  const renderEmptyState = () => (
    <div className="text_sm mt-1">
      <FormattedMessage id="appsList.emptyMessage" defaultMessage="Sorry, nothing’s here" />
    </div>
  );

  const renderApps = () => (
    <>
      <Row>
        {appsToShow.map((app: Application) => (
          <Col key={app.id} className="apps-list__app-wrapper" lg={4} sm={6}>
            <AppsListItem app={app} onAppClick={onAppClick} isAppsProcessing={isAppsProcessing} />
          </Col>
        ))}
      </Row>
      {isShowMoreButtonVisible && (
        <div className="apps-list__button-wrapper mt-7">
          <LinkButton
            theme="darkgray"
            data-qa="resendInviteButton"
            onClick={handleShowAll}
          >
            <FormattedMessage id="appsList.showMoreButton" defaultMessage="Show more" />
          </LinkButton>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="text_lg mb-1">{title}</div>
      {isEmpty ? renderEmptyState() : renderApps()}
    </>
  );
};

AppsList.defaultProps = {
  hideOnEmpty: true,
};

AppsList.contextTypes = {
  isMobile: PropTypes.bool,
};

export default AppsList;
