import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  FormattedMessage, defineMessages, injectIntl, intlShape,
} from 'react-intl';
import { FormField, Button } from '@setapp/ui-kit';

import CurrentPasswordField from 'components/shared/form/current-password-field/current-password-field';

import { getUserEmail } from 'state/root-reducer';
import { addSuccessNotification } from 'state/notifier/notifier-actions';
import { updateUser } from 'state/user/user-actions';

import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

const initialState = {
  fields: {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  },
  fieldsErrors: {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  },
  genericError: '',
};

const messages = defineMessages({
  currentPasswordRequired: {
    id: 'accountSettings.editPassword.validation.emptyPassword',
    defaultMessage: 'Password is required',
  },
  newPasswordRequired: {
    id: 'accountSettings.personalInfo.validation.emptyNewPassword',
    defaultMessage: 'Password is required',
  },
  newPasswordInvalid: {
    id: 'accountSettings.personalInfo.validation.invalidNewPassword',
    defaultMessage: 'At least 1 uppercase and lowercase letter. 8 characters minimum.',
  },
  confirmPasswordRequired: {
    id: 'accountSettings.editPassword.validation.emptyConfirmPassword',
    defaultMessage: 'Password is required',
  },
  confirmPasswordInvalid: {
    id: 'accountSettings.personalInfo.validation.invalidConfirmPassword',
    defaultMessage: 'At least 1 uppercase and lowercase letter. 8 characters minimum.',
  },
  passwordsMismatch: {
    id: 'accountSettings.editPassword.validation.mismatchPassword',
    defaultMessage: 'Passwords don\'t match',
  },
  currentPasswordLabel: {
    id: 'accountSettings.editPassword.currentPasswordLabel',
    defaultMessage: 'Current password',
  },
  currentPasswordPlaceholder: {
    id: 'accountSettings.editPassword.currentPassPlaceholder',
    defaultMessage: 'Enter current password',
  },
  newPasswordLabel: {
    id: 'accountSettings.editPassword.newPasswordLabel',
    defaultMessage: 'New password',
  },
  newPasswordPlaceholder: {
    id: 'accountSettings.editPassword.newPasswordPlaceholder',
    defaultMessage: 'Enter new password',
  },
  confirmPasswordLabel: {
    id: 'accountSettings.editPassword.confirmPasswordLabel',
    defaultMessage: 'Confirm password',
  },
  confirmPasswordPlaceholder: {
    id: 'accountSettings.editPassword.confirmPasswordPlaceholder',
    defaultMessage: 'Repeat new password',
  },
  successUpdateNotification: {
    id: 'accountSettings.editPassword.successNotification',
    defaultMessage: 'Password successfully updated.',
  },
});

class UserPassword extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  render() {
    const { intl, email, isLoading } = this.props;
    const { genericError, fields, fieldsErrors } = this.state;
    const errorClasses = classnames('form-error text-danger', {
      hidden: !genericError,
    });

    return (
      <div>
        <h5 className="h5">
          <FormattedMessage id="accountSettings.editPassword.title" defaultMessage="Change password" />
        </h5>

        <form onSubmit={this.onFormSubmit} noValidate>
          <CurrentPasswordField
            id="currentPassword"
            name="currentPassword"
            type="password"
            email={email}
            label={intl.formatMessage(messages.currentPasswordLabel)}
            placeholder={intl.formatMessage(messages.currentPasswordPlaceholder)}
            onChange={this.onPasswordChange}
            helpText={fieldsErrors.currentPassword}
            invalid={Boolean(fieldsErrors.currentPassword)}
            autoComplete="new-password"
            value={fields.currentPassword}
            showLabel
          />
          <FormField
            id="newPassword"
            name="password"
            type="password"
            label={intl.formatMessage(messages.newPasswordLabel)}
            placeholder={intl.formatMessage(messages.newPasswordPlaceholder)}
            onChange={this.onPasswordChange}
            helpText={fieldsErrors.password}
            invalid={Boolean(fieldsErrors.password)}
            autoComplete="new-password"
            value={fields.password}
            showLabel
            qaLabel="newPasswordFormGroup"
          />
          <FormField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label={intl.formatMessage(messages.confirmPasswordLabel)}
            placeholder={intl.formatMessage(messages.confirmPasswordPlaceholder)}
            onChange={this.onPasswordChange}
            helpText={fieldsErrors.confirmPassword}
            invalid={Boolean(fieldsErrors.confirmPassword)}
            autoComplete="new-password"
            value={fields.confirmPassword}
            showLabel
            qaLabel="confirmPasswordFormGroup"
          />
          <p className={errorClasses}>{genericError}</p>

          <Button
            variant="secondary-outline"
            type="submit"
            disabled={isLoading}
            data-qa="updatePasswordBtn"
          >
            <FormattedMessage id="accountSettings.editPassword.updateButton" defaultMessage="Update password" />
          </Button>
        </form>
      </div>
    );
  }

  getValidation() {
    const { intl } = this.props;

    return {
      currentPassword: {
        required: intl.formatMessage(messages.currentPasswordRequired),
      },
      password: {
        required: intl.formatMessage(messages.newPasswordRequired),
        passwordFormat: intl.formatMessage(messages.newPasswordInvalid),
      },
      confirmPassword: {
        required: intl.formatMessage(messages.confirmPasswordRequired),
        passwordFormat: intl.formatMessage(messages.confirmPasswordInvalid),
        isPasswordsMatch: {
          fields: ['password', 'confirmPassword'],
          message: intl.formatMessage(messages.passwordsMismatch),
        },
      },
    };
  }

  onPasswordChange(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
  }

  onFormSubmit(e) {
    const { fields } = this.state;
    const {
      updateUser,
      intl,
      addSuccessNotification,
    } = this.props;

    e.preventDefault();

    // eslint-disable-next-line react/no-access-state-in-setstate
    const fieldsErrors = validate(fields, this.getValidation());
    const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

    this.setState({ fieldsErrors });

    if (formHasError) {
      this.setState({ genericError: '' });

      return Promise.resolve();
    }

    const { password, currentPassword } = fields;

    return updateUser({ password, currentPassword })
      .then(() => {
        this.setState(initialState);

        addSuccessNotification({
          message: intl.formatMessage(messages.successUpdateNotification),
        });

        analytics.trackEvent(events.ACCOUNT_PASSWORD_CHANGED);
      })
      .catch((error) => {
        this.setState({ ...error.getSimplifiedErrors() });
      });
  }
}

UserPassword.propTypes = {
  updateUser: PropTypes.func.isRequired,
  addSuccessNotification: PropTypes.func.isRequired,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  intl: intlShape.isRequired,
};

UserPassword.defaultProps = {
  isLoading: false,
  email: '',
};

export { UserPassword };

const mapStateToProps = (state) => ({ email: getUserEmail(state) });

const mapActionsToProps = {
  updateUser,
  addSuccessNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(UserPassword));
