// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import ActionStep from '../action-card/action-card';

import downloadIcon from './images/icon-download.svg';


type Props = {
  onCtaClick: () => mixed,
}

type Context = {
  isMobile: boolean,
}

const DownloadStep = ({ onCtaClick }: Props, { isMobile }: Context) => {
  const getDescription = () => {
    if (isMobile) {
      return (
        <FormattedMessage
          id="dashboard.downloadStep.descriptionMobile"
          defaultMessage="Setapp is not on mobile yet. We'll send a download link and you can get Setapp on your Mac."
        />
      );
    }

    return (
      <FormattedMessage
        id="dashboard.downloadStep.description"
        defaultMessage="Install our desktop client on your Mac and get access to all the apps in a click."
      />
    );
  };

  const getCtaElement = () => {
    if (!isMobile) {
      return (
        <SetappDownloadLinkWrapper>
          <Button
            block
            href={externalUrls.downloadDesktopClient}
            onClick={onCtaClick}
          >
            <FormattedMessage
              id="dashboard.downloadStep.ctaCaption"
              defaultMessage="Download"
            />
          </Button>
        </SetappDownloadLinkWrapper>);
    }

    return null;
  };

  return (
    <ActionStep
      icon={downloadIcon}
      title={(
        <FormattedMessage
          id="dashboard.downloadStep.title"
          defaultMessage="1. Download Setapp"
        />
      )}
      description={getDescription()}
      ctaElement={getCtaElement()}
      onCtaClick={isMobile ? onCtaClick : null}
      ctaCaption={isMobile ? (
        <FormattedMessage
          id="dashboard.downloadStep.ctaCaptionMobile"
          defaultMessage="Send me a link"
        />
      ) : null}
    />
  );
};

DownloadStep.contextTypes = {
  isMobile: PropTypes.bool,
};

export default DownloadStep;
