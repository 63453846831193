// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import { connect } from 'react-redux';

import InfoRadioButton from 'components/shared/info-radio-button/info-radio-button';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import {
  getPrimarySubscription,
  getTotalAmountForCustomers,
  getAvailablePricePlans,
  getMonthlyPlan,
  getSubscriptions,
} from 'state/root-reducer';
import { fetchSubscription } from 'state/subscription/subscription-actions';
import { fetchTeam } from 'state/team-plan/team-plan-actions';

import TaxNote from 'components/shared/tax-note/tax-note';

import PricePlanItem from './plan-item/plan-item';

import './change-plan-form.scss';

type Props = {
  fetchSubscription: () => Promise<void>,
  fetchTeam: () => Promise<void>,
  pricePlans: Array<PricePlan>,
  totalAmount: number,
  selectedPlan: ?PricePlan,
  monthlyPlan: ?PricePlan,
  submitBtnTitle?: Node,
  description: Node,
  isSubmitButtonDisabled: boolean,
  onSubmit: () => any,
  onPlanChange: (?PricePlan) => void,
  nextPaymentDate: ?number,
  showNextPaymentDate: boolean,
  pricePlans: Array<PricePlan>,
  monthlyPlan: PricePlan,
  isSubscriptionProcessing: boolean,
};

class ChangePlanForm extends PureComponent<Props> {
  static defaultProps = {
    submitBtnTitle: null,
    showNextPaymentDate: false,
  };

  componentDidMount() {
    const {
      fetchSubscription,
      fetchTeam,
    } = this.props;
    const dataToBeFetched = [
      fetchSubscription(),
      fetchTeam(),
    ];

    return Promise.all(dataToBeFetched);
  }

  render() {
    const {
      selectedPlan,
      pricePlans,
      isSubmitButtonDisabled,
      isSubscriptionProcessing,
      monthlyPlan,
      submitBtnTitle,
      description,
      showNextPaymentDate,
      nextPaymentDate,
      totalAmount,
    } = this.props;

    return (
      <form onSubmit={this.onFormSubmit} noValidate>
        <fieldset className="change-plan-form__fieldset">
          <legend className="sr-only">
            <FormattedMessage
              id="changePlanModal.changePlanLegend"
              defaultMessage="Choose price plan"
            />
          </legend>

          {pricePlans
            // Needed to display more profitable plans at the top
            .sort((plan, nextPlan) => nextPlan.paidMonth - plan.paidMonth)
            .map((pricePlan) => {
              const isSelectedPlan = Boolean(selectedPlan && pricePlan.id === selectedPlan.id);

              return (
                <div className="change-plan-form__item" key={pricePlan.id}>
                  <InfoRadioButton
                    name="pricePlan"
                    value={pricePlan.id}
                    onChange={this.onChangeSelected}
                    checked={isSelectedPlan}
                    autoFocus={isSelectedPlan}
                  >
                    <PricePlanItem
                      pricePlan={pricePlan}
                      monthlyPricePlan={monthlyPlan}
                      active={isSelectedPlan}
                    />
                  </InfoRadioButton>
                </div>
              );
            })}
        </fieldset>

        <p className="change-plan__description">
          {description}
        </p>

        {totalAmount && selectedPlan && (
          <div className="change-plan__total-container">
            <FormattedMessage
              id="changePlanModal.price"
              defaultMessage="Total: {price}"
              values={{
                price: (
                  <strong>
                    <FormattedPrice
                      currency={selectedPlan.currency}
                      price={totalAmount}
                    />
                  </strong>
                ),
              }}
            />
            <div className="change-plan__price-note text_xs">
              <FormattedMessage
                id="changePlanModal.aboutTax"
                defaultMessage="+ sales tax"
              />
            </div>
          </div>
        )}

        <Button
          type="submit"
          disabled={isSubmitButtonDisabled || isSubscriptionProcessing}
          block
          className="change-plan-form__button"
        >
          {submitBtnTitle
          || (
            <FormattedMessage
              id="changePlanModal.button"
              defaultMessage="Confirm"
            />
          )}
        </Button>

        {showNextPaymentDate && nextPaymentDate && (
          <p className="change-plan__description text-center">
            <FormattedMessage
              id="changePlanModal.nextPaymentDate"
              defaultMessage="Your next payment is scheduled on {paymentDate}."
              values={{
                paymentDate: (
                  <strong>
                    <FormattedDate
                      value={nextPaymentDate * 1000}
                      year="numeric"
                      month="short"
                      day="numeric"
                    />
                  </strong>
                ),
              }}
            />
          </p>
        )}

        <div className="mt-4 text-center">
          <TaxNote />
        </div>
      </form>
    );
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { onSubmit } = this.props;

    onSubmit();
  };

  onChangeSelected = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { onPlanChange, pricePlans } = this.props;
    const planId = Number(e.currentTarget.value);
    const selectedPlan = pricePlans.find((pricePlan) => pricePlan.id === planId);

    onPlanChange(selectedPlan);
  };
}

/* istanbul ignore next */
const mapStateToProps = (state, { selectedPlan }) => ({
  nextPaymentDate: getPrimarySubscription(state).nextPaymentDate,
  totalAmount: getTotalAmountForCustomers(state, selectedPlan),
  pricePlans: getAvailablePricePlans(state),
  monthlyPlan: getMonthlyPlan(state),
  isSubscriptionProcessing: getSubscriptions(state).isLoading,
});

const mapActionsToProps = {
  fetchSubscription,
  fetchTeam,
};

export {
  ChangePlanForm as PureChangePlanForm,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ChangePlanForm);
