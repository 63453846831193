import React from 'react';
import PropTypes from 'prop-types';

import './page-title.scss';

const PageTitle = ({ children, qaLabel }) => (
  <h1 className="page-title h3" data-qa={qaLabel}>
    {children}
  </h1>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  qaLabel: PropTypes.string,
};

PageTitle.defaultProps = {
  qaLabel: undefined,
};

export default PageTitle;
