// @flow

import React from 'react';
import classNames from 'classnames';
import type { Node } from 'react';
import LinkButton from 'components/shared/link-button/link-button';

import './table-toggle.scss';

type Props = {|
  children: Node,
  className: ?string,
|};

const TableToggle = ({ children, className, ...props }: Props) => {
  const toggleClasses = classNames(
    'table-toggle',
    className,
  );

  return (
    <LinkButton className={toggleClasses} {...props}>
      {children}
    </LinkButton>
  );
};

TableToggle.defaultProps = {
  className: null,
};

export default TableToggle;
