// @flow

import initialState, { type DeviceState } from './devices-initial-state';
import * as actionTypes from './devices-action-types';

const devices = (state: DeviceState = initialState, action: actionTypes.DevicesAction): DeviceState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      return { list: action.payload, isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default devices;

export const getList = (state: DeviceState) => state.list;
export const getCount = (state: DeviceState) => state.list.length;
