import React from 'react';
import { FormattedMessage } from 'react-intl';

import './payment-history-empty-item.scss';

const PaymentHistoryEmptyItem = () => (
  <tr className="payment-history__empty-item text_md">
    <td colSpan="3">
      <FormattedMessage id="paymentHistory.table.emptyList" defaultMessage="You have no recent payments." />
    </td>
  </tr>
);

export default PaymentHistoryEmptyItem;
