// @flow

import React, { PureComponent } from 'react';

import type { Device } from 'state/devices/devices-initial-state';

import DeviceItem from './device-item';
import EmptySeatItem from './empty-seat-item';

import './devices-list.scss';

type Props = {|
  devices: Array<Device>,
  emptySeatsCount: number,
  onDisconnectClick: (Device) => any,
|};

class DevicesList extends PureComponent<Props> {
  render() {
    const {
      devices,
      emptySeatsCount,
      onDisconnectClick,
    } = this.props;

    return (
      <ul className="devices-list">
        {devices.map((device) => (
          <li key={device.id} className="devices-list__item">
            <DeviceItem
              device={device}
              onDisconnectClick={onDisconnectClick}
            />
          </li>
        ))}
        {emptySeatsCount > 0 && [...Array(emptySeatsCount)].map((e, key) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <li key={key} className="devices-list__item">
            <EmptySeatItem />
          </li>
        ))}
      </ul>
    );
  }
}

export default DevicesList;
