// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

import InfoField from 'components/shared/info-field/info-field';

type Props = {|
  highlightDate: boolean,
  nextPaymentDate: ?number,
  title?: Node,
|};

const NextPaymentRow = ({ highlightDate, nextPaymentDate, title }: Props) => {
  if (!nextPaymentDate) {
    return null;
  }

  return (
    <InfoField
      lightValue
      title={title || <FormattedMessage id="subscriptionInfo.nextPayment" defaultMessage="Next payment" />}
      value={(
        <FormattedDate
          value={nextPaymentDate * 1000}
          year="numeric"
          month="short"
          day="numeric"
        />
      )}
      valueClass={highlightDate ? 'text-danger' : ''}
      qaLabel="subscriptionNextPaymentDateText"
    />
  );
};

NextPaymentRow.defaultProps = {
  highlightDate: false,
  title: null,
};

export default NextPaymentRow;
