// @flow

import React, { useEffect } from 'react';
import type { ComponentType } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { getUser } from 'state/root-reducer';
import { confirmEmail } from 'state/user/user-actions';
import { removeQueryParams, EMAIL_CONFIRMATION_TOKEN } from 'utils/location';
import Logger from 'utils/logger';

const withEmailAutoConfirmation = <P: {}>(Component: ComponentType<P>): ComponentType<P> => (
  (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { emailConfirmed } = useSelector((state) => getUser(state));

    useEffect(() => {
      async function confirmUserEmail() {
        const query = queryString.parse(location.search);
        const tokenParam = query && query[EMAIL_CONFIRMATION_TOKEN];

        if (tokenParam) {
          try {
            await dispatch(confirmEmail(tokenParam));
          } catch (error) {
            Logger.logWarn('Email is not confirmed', error);
          } finally {
            removeQueryParams(history, EMAIL_CONFIRMATION_TOKEN);
          }
        }
      }

      if (!emailConfirmed) {
        confirmUserEmail();
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <Component {...props} />;
  }
);

export default withEmailAutoConfirmation;
