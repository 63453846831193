// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import externalUrls from 'config/external-urls';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import './sidebar-nav-footer.scss';

type Props = {
  isMobile: boolean,
  showSalesLink: boolean,
  onDownloadClick: () => void,
  onContactSalesClick: () => void,
};

const SidebarNavFooter = ({
  isMobile,
  showSalesLink,
  onDownloadClick,
  onContactSalesClick,
}: Props) => (
  <div className="sidebar-nav-footer">
    {showSalesLink && (
      <a
        className="sidebar-nav-footer__contact-sales-button"
        href={externalUrls.businessMail}
        onClick={onContactSalesClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="navigation.footer.salesLinkTitle" defaultMessage="Contact sales" />
      </a>
    )}
    {!isMobile && (
      <div className="sidebar-nav-footer__download-link">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16" width="16" className="sidebar-nav-footer__download-link-icon">
          <g fill="none" fillRule="evenodd">
            <path d="m0 0h16v16h-16z" />
            <path
              d="m7 1c-.5523 0-1 .448-1 1v5h-3.6852c-.8764 0-1.3289 1.047-.7283 1.686l5.6851 6.04c.3948.42 1.0614.42 1.4563 0l5.6859-6.039c.6007-.639.1483-1.686-.7281-1.686h-3.6857v-5.001c0-.552-.4477-1-1-1z"
              fill="#e6842e"
            />
          </g>
        </svg>
        <SetappDownloadLinkWrapper>
          <a
            className="link-outer"
            href={externalUrls.downloadDesktopClient}
            title="Download Setapp client"
            onClick={onDownloadClick}
          >
            <FormattedMessage id="navigation.footer.downloadButtonTitle" defaultMessage="Download app" />
          </a>
        </SetappDownloadLinkWrapper>
      </div>
    )}
    <div className="sidebar-nav-footer__icon">
      <AnimatedLogo hasCaption size="sm" />
    </div>
  </div>
);

export default SidebarNavFooter;
