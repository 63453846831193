// @flow

import { type Device } from './devices-initial-state';

export const REQUEST = 'DEVICES_REQUEST';
export const REQUEST_SUCCESS = 'DEVICES_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'DEVICES_REQUEST_ERROR';

type DevicesRequestAction = {|
  type: 'DEVICES_REQUEST',
|};

type DevicesRequestSuccessAction = {|
  type: 'DEVICES_REQUEST_SUCCESS',
  payload: Array<Device>,
|};

type DevicesRequestErrorAction = {|
  type: 'DEVICES_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

export type DevicesAction = DevicesRequestAction | DevicesRequestSuccessAction | DevicesRequestErrorAction;
