// @flow

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ButtonClose } from '@setapp/ui-kit';

import ChangePlanForm from 'components/shared/change-plan-form/change-plan-form';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import SupportedPaymentMethods from '../supported-payment-methods/supported-payment-methods';

import ActivatePromoBlock from './activate-promo-block/activate-promo-block';


import './choose-plan-step.scss';

type Props = {
  withStepCounter: boolean,
  totalStepsNumber: number,
  currentStepNumber: number,
  isPaymentMethodCreated: boolean,
  handleCloseClick: () => void,
  setSelectedPricePlan: PricePlan => void,
  selectedPlan: PricePlan,
  handleChangePlanSubmitClick: () => void,
  handlePaddleVerifiedClick: () => void,
};

const ChoosePlanStep = (props: Props) => {
  const {
    withStepCounter,
    totalStepsNumber,
    currentStepNumber,
    isPaymentMethodCreated,
    handleCloseClick,
    setSelectedPricePlan,
    selectedPlan,
    handleChangePlanSubmitClick,
    handlePaddleVerifiedClick,
  } = props;
  const primaryContentWrapperClasses = classNames('choose-plan-step__primary-content-wrapper', {
    'choose-plan-step__primary-content-wrapper_without-step-counter': !withStepCounter,
  });

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent
        withStepCounter={withStepCounter}
        currentStepNumber={currentStepNumber}
        totalStepsNumber={totalStepsNumber}
      >
        <div className={primaryContentWrapperClasses}>
          <div className="choose-plan-step__close-button choose-plan-step__close-button_primary">
            <ButtonClose onClick={handleCloseClick} />
          </div>
          <h3>
            <FormattedMessage
              id="activatePage.choosePlanTitle"
              defaultMessage="Choose team plan"
            />
          </h3>
          <ChangePlanForm
            onPlanChange={setSelectedPricePlan}
            onSubmit={handleChangePlanSubmitClick}
            selectedPlan={selectedPlan}
            description={(
              <FormattedMessage
                id="activatePage.changePlanForm.description"
                defaultMessage="The team plan is perfect both for teams and individuals. Add new members anytime after the first payment."
              />
            )}
            submitBtnTitle={
              isPaymentMethodCreated ? (
                <FormattedMessage
                  id="activatePage.changePlanForm.confirmButton"
                  defaultMessage="Purchase »"
                />
              ) : (
                <FormattedMessage
                  id="activatePage.changePlanForm.continueButton"
                  defaultMessage="Continue »"
                />
              )
            }
          />
          <div className="choose-plan-step__payment-cards-icons mt-2">
            <SupportedPaymentMethods />
          </div>
        </div>
      </FullscreenLayout.PrimaryContent>
      <FullscreenLayout.SecondaryContent>
        <div className="choose-plan-step__secondary-content-wrapper">
          <div className="choose-plan-step__close-button">
            <ButtonClose onClick={handleCloseClick} />
          </div>
          <ActivatePromoBlock paddleVerifiedClick={handlePaddleVerifiedClick} />
        </div>
      </FullscreenLayout.SecondaryContent>
    </FullscreenLayout>
  );
};

export default ChoosePlanStep;
