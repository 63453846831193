import React, { ComponentProps } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { parse, parseUrl, stringify } from 'query-string';

type Props = ComponentProps<typeof Redirect>;

const RedirectWithQuery = (props: Props) => {
  const {
    from,
    to,
    exact,
    strict,
    ...rest
  } = props;

  return (
    <Route
      path={from}
      exact={exact}
      strict={strict}
      render={({ location }) => {
        let redirectLocation;

        if (typeof to === 'string') {
          const parsedURL = parseUrl(to);
          const search = {
            ...parse(location.search),
            ...parsedURL.query,
          };

          redirectLocation = {
            pathname: parsedURL.url,
            search: stringify(search),
          };
        } else {
          const search = {
            ...parse(location.search),
            ...(to.search && parse(to.search)),
          };

          redirectLocation = {
            ...to,
            search: stringify(search),
          };
        }

        return (
          <Redirect
            {...rest}
            to={redirectLocation}
          />);
      }}
    />
  );
};

export default RedirectWithQuery;
