import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultError: {
    id: 'defaultError',
    defaultMessage: 'An unknown error has occurred. Please try again later or contact Setapp support team',
  },
  btnCloseAccessibilityLabel: {
    id: 'closeBtnAccessibilityLabel',
    defaultMessage: 'Close',
  },
  btnTooltipAccessibilityLabel: {
    id: 'btnTooltipAccessibilityLabel',
    defaultMessage: 'Open help text',
  },
});
