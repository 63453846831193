// @flow

import RequestError from '@setapp/request-error';

import type { PaddlePaymentDetails } from 'utils/api/paddle-payment-details-service';

export type PaymentMethodType = 'card' | 'paypal';

type PaymentCardType =
  | 'americanexpress'
  | 'dinnersclub'
  | 'discover'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'visa';

export type InvoicingInformation = {|
  company: string,
  vatId: string,
  streetName: string,
  addressDetail: string,
  buildingNumber: string,
  city: string,
  postalCode: string,
|};

type BraintreePayPalPaymentDetails = {|
  ...InvoicingInformation,
  provider: 'braintree',
  type: 'paypal',
  details: {|
    email: string,
  |},
|};

type BraintreeCardPaymentDetails = {|
  ...InvoicingInformation,
  provider: 'braintree',
  type: 'card',
  details: {|
    cardExpM: number,
    cardExpY: number,
    cardLast4: string,
    cardType: PaymentCardType,
  |},
|};

export type PaymentDetails =
  | BraintreePayPalPaymentDetails
  | BraintreeCardPaymentDetails
  | PaddlePaymentDetails;

export type PaymentDetailsState = {|
  data: ?PaymentDetails,
  isLoading: boolean,
  isFetched: boolean,
  error: ?RequestError,
|};

const initialState: PaymentDetailsState = {
  data: null,
  isLoading: false,
  isFetched: false,
  error: null,
};

export default initialState;
