// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Notification } from '@setapp/ui-kit';

import DefaultError from 'components/shared/default-error/default-error';

import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';
import { showModal } from 'state/modal/modal-reducer';

import './unconfirmed-email-notification.scss';

import type { ModalType } from 'components/modals';

type State = {
  isProcessing: boolean,
};

type Props = {
  email: string,
  resendConfirmationEmail: ({captcha: ?string}) => Promise<void>,
  showModal: (ModalType, Object) => void,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
};

class UnconfirmedEmailNotification extends PureComponent<Props, State> {
  state = {
    isProcessing: false,
  };

  render() {
    const { email } = this.props;
    const { isProcessing } = this.state;

    return (
      <Notification
        type="info"
      >
        <span className="unconfirmed-email-notification__container">
          <FormattedMessage
            id="emailConfirmation.notificationText"
            defaultMessage="Please, confirm your email by clicking the link we sent you to {email}"
            values={{
              email: <strong className="unconfirmed-email-notification__email">{email}</strong>,
            }}
          />
          <Button
            variant="primary-outline"
            size="sm"
            className="unconfirmed-email-notification__button"
            disabled={isProcessing}
            onClick={this.onResendClick}
            data-qa="resendEmailBtn"
          >
            <FormattedMessage
              id="emailConfirmation.resendEmailButton"
              defaultMessage="Resend email"
            />
          </Button>
        </span>
      </Notification>
    );
  }

  onCaptchaModalConfirm = (captcha: string) => {
    this.resendEmail(captcha);
  };

  onResendClick = () => {
    this.resendEmail();
  };

  resendEmail(captcha: ?string = null) {
    const {
      addSuccessNotification,
      addDangerNotification,
      resendConfirmationEmail,
    } = this.props;

    this.setProcessing(true);

    return resendConfirmationEmail({ captcha })
      .then(() => {
        addSuccessNotification({
          message: (
            <FormattedMessage
              id="permanentNotifications.emailConfirmation.emailResent"
              defaultMessage="Confirmation email sent."
            />
          ),
        });
      })
      .catch((error) => {
        const captchaError = error.getFieldError('captcha');
        const isCaptchaRequired = captchaError && captchaError.isRequired();

        if (isCaptchaRequired) {
          this.showCaptchaModal();
        } else {
          addDangerNotification({
            message: <DefaultError />,
          });
        }
      })
      .then(() => {
        this.setProcessing(false);
      });
  }

  showCaptchaModal() {
    const { showModal } = this.props;

    showModal('RESEND_EMAIL_CAPTCHA', {
      onConfirm: this.onCaptchaModalConfirm,
    });
  }

  setProcessing(isProcessing: boolean) {
    this.setState({ isProcessing });
  }
}

const mapActionsToProps = {
  addSuccessNotification,
  addDangerNotification,
  showModal,
};

export { UnconfirmedEmailNotification as PureUnconfirmedEmailNotification };

export default connect(null, mapActionsToProps)(UnconfirmedEmailNotification);
