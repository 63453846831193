import { SUPPORT_PAGES_LOCALE_ALIASES, DEFAULT_LOCALE } from 'config/locales';
import urls from 'config/external-urls';

import { detectLocale } from './intl';

const getLocaleAlias = () => SUPPORT_PAGES_LOCALE_ALIASES[
  detectLocale()
] || SUPPORT_PAGES_LOCALE_ALIASES[
  DEFAULT_LOCALE
];

/**
 * Returns support url with pre-filled locale
 * @returns {string}
 */
export const getSupportUrl = () => urls.support.replace('%{locale}', getLocaleAlias());

/**
 * Returns create request support url with pre-filled locale
 * @returns {string}
 */
export const getCreateSupportRequestUrl = () => urls.createSupportRequest.replace('%{locale}', getLocaleAlias());
