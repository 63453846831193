// @flow

import React from 'react';
import type { Element, Node } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { SelectField, Button } from '@setapp/ui-kit';

import type { BraintreePaymentDetailsPayload } from 'state/payment-method/payment-method-actions';

import BraintreeVerified from 'components/shared/braintree-verified/braintree-verified';

import PaymentFormNote from '../payment-form-note/payment-form-note';
import PaymentMethodFormVatContainer from '../payment-method-form-vat-container/payment-method-form-vat-container';


import './paypal-form.scss';

type Props = {
  countries: {[string]: string},
  needsCountry: boolean,

  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    [string]: Node,
  },
  onFieldChange: (string, string) => any,
  onFormSubmit: SyntheticEvent<HTMLFormElement> => any,
  isPaymentMethodCreated: boolean,
  paymentInfo: BraintreePaymentDetailsPayload,
  submitBtnTitle?: Node,
  captcha: ?Element<any>,
  email: string,
};

const PaypalForm = (props: Props) => {
  const {
    onFormSubmit,
    formError,
    isLoading,
    merchantId,
    onFieldChange,
    countries,
    needsCountry,
    isPaymentMethodCreated,
    paymentInfo,
    submitBtnTitle,
    fieldsWithError,
    captcha,
    email,
  } = props;
  const { country } = paymentInfo;
  const { countryEmpty } = fieldsWithError;

  const errorClasses = classnames('form-error text-danger paypal-form-error', {
    hidden: !formError,
  });
  const headerClasses = classnames('paypal-form-header', {
    'paypal-form-header-processing': (isLoading),
  });

  return (
    <form className="paypal-form" onSubmit={onFormSubmit} noValidate>
      <h5 className={headerClasses}>
        <FormattedMessage id="paypalForm.formTitle" defaultMessage="PayPal" />
      </h5>
      <input type="hidden" name="payment_method_nonce" />

      <div>
        {getDescription()}
        {needsCountry && (
          <SelectField
            id="country"
            name="country"
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            invalid={Boolean(countryEmpty)}
            helpText={countryEmpty}
            value={country}
            options={countries}
            label={<FormattedMessage id="paypalForm.country" defaultMessage="Country" />}
            defaultOptionLabel={<FormattedMessage id="paypalForm.countryDefault" defaultMessage="Select country…" />}
            disabled={isLoading}
          />
        )}

        <PaymentMethodFormVatContainer
          fieldsWithError={fieldsWithError}
          paymentInfo={paymentInfo}
          isLoading={isLoading}
          onFieldChange={onFieldChange}
        />

        {captcha}

        <p className={errorClasses}>{formError}</p>
      </div>

      <BraintreeVerified merchantId={merchantId} />

      <PaymentFormNote />

      <Button
        className="paypal-form-submit-btn"
        block
        type="submit"
        disabled={isLoading}
      >
        {getBtnTitle()}
      </Button>
    </form>
  );

  function getDescription() {
    if (email) {
      return (
        <p className="paypal-form-description">
          <FormattedMessage
            id="paypalForm.descriptionUpdate"
            defaultMessage="You’re logged in as {email}."
            values={{
              email: <strong>{email}</strong>,
            }}
          />
        </p>
      );
    }

    return (
      <p className="paypal-form-description">
        <FormattedMessage
          id="paypalForm.descriptionAdd"
          defaultMessage="You can subscribe to Setapp using your PayPal account."
        />
      </p>
    );
  }

  function getBtnTitle() {
    if (submitBtnTitle) {
      return submitBtnTitle;
    }

    if (!email) {
      return <FormattedMessage id="paymentDetails.paypal.submitBtnTitleProceed" defaultMessage="Proceed to PayPal" />;
    }

    if (!isPaymentMethodCreated) {
      return <FormattedMessage id="paymentDetails.paypal.submitBtnTitleAdd" defaultMessage="Add payment details" />;
    }

    return <FormattedMessage id="paymentDetails.paypal.submitBtnTitleUpdate" defaultMessage="Update payment details" />;
  }
};

PaypalForm.defaultProps = {
  submitBtnTitle: null,
};

export default PaypalForm;
