// @flow

import React from 'react';
import { injectIntl, type IntlShape } from 'react-intl';
import { Tooltip } from '@setapp/ui-kit';

type Props = {|
  intl: IntlShape,
  trigger?: string,
  position?: string,
|}

const TeamExclusiveInfo = ({ trigger, position, intl }: Props) => (
  <Tooltip
    distance="sm"
    trigger={trigger}
    position={position}
    content={intl.formatMessage({
      id: 'teamExclusiveInfo.tooltip',
      defaultMessage: 'Available only on Setapp for Teams',
    })}
    useContext
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path fill="#E0383E" fillRule="evenodd" d="M8 1c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm-.562 1.851l-3.935 5.01c-.171.216-.133.53.084.701.062.049.134.082.21.098L8 9.5l-.329 3.29c-.027.275.173.52.448.547.17.017.337-.054.443-.188l3.935-5.01c.171-.216.133-.53-.084-.701-.062-.049-.134-.082-.21-.098L8 6.5l.329-3.29c.027-.275-.173-.52-.448-.547-.17-.017-.337.054-.443.188z" />
    </svg>
  </Tooltip>
);

TeamExclusiveInfo.defaultProps = {
  trigger: 'mouseenter focus',
  position: 'bottom',
};

export { TeamExclusiveInfo as PureTeamExclusiveInfo };

export default injectIntl(TeamExclusiveInfo);
