export const DEFAULT_LOCALE = 'en-US';

export const SUPPORTED_LOCALES = [
  'en-US',
  'de-DE',
  'fr-FR',
  'pt-BR',
  'es-ES',
  'it-IT',
];

export const LOCALE_NAMES = {
  'en-US': 'English',
  'fr-FR': 'Français',
  'de-DE': 'Deutsch',
  'pt-BR': 'Português Brasileiro',
  'es-ES': 'Español',
  'it-IT': 'Italiano',
};

export const SUPPORT_PAGES_LOCALE_ALIASES = {
  'en-US': 'en-us',
  'fr-FR': 'fr-fr',
  'de-DE': 'de',
};

export const LOCALE_ALIASES = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  pt: 'pt-BR',
  es: 'es-ES',
  it: 'it-IT',
};
