import React from 'react';
import PropTypes from 'prop-types';

import './panel-box-content.scss';

const PanelBoxContent = ({ children }) => (
  <div className="panel-box-content">
    {children}
  </div>
);

PanelBoxContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelBoxContent;
