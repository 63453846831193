// @flow

import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { createSelector } from 'reselect';

import type { Action, State } from './state-types';
import type { Subscription, SubscriptionWithAdditionalSeats } from './subscription/subscription-initial-state';
import type { InvoicingInformation } from './payment-method/payment-method-initial-state';
import type { PricePlan } from './price-plans/price-plans-initial-state';
import type { Notification } from './notifier/notifier-initial-state';
import type { Invoice } from './invoices/invoices-initial-state';
import type { Device } from './devices/devices-initial-state';
import type { Application } from './apps/apps-initial-state';
import type { UserPermission } from './user/user-permissions';

import user, * as userSelectors from './user/user-reducer';
import subscriptions, * as subscriptionSelectors from './subscription/subscription-reducer';
import paymentMethod, * as paymentMethodSelectors from './payment-method/payment-method-reducer';
import notifier, * as notifierSelectors from './notifier/notifier-reducer';
import devices, * as devicesSelectors from './devices/devices-reducer';
import invoices, * as invoicesSelectors from './invoices/invoices-reducer';
import apps, * as appsSelectors from './apps/apps-reducer';
import pricePlans, * as pricePlansSelectors from './price-plans/price-plans-reducer';
import * as pricePlansKeys from './price-plans/price-plans-keys';
import jamf from './jamf/jamf-reducer';
import modalReducer from './modal/modal-reducer';

import teamPlan, * as teamPlanSelectors from './team-plan/team-plan-reducer';
import teamMembers, * as teamMembersSelectors from './team-plan/members/team-members-reducer';

const reducers = {
  apps,
  user,
  subscriptions,
  paymentMethod,
  notifier,
  devices,
  pricePlans,
  invoices,
  teamPlan,
  teamMembers,
  jamf,
  modal: modalReducer,
  intl: intlReducer,
};

export type Reducers = typeof reducers;

export default combineReducers<Reducers, Action>(reducers);

// User
export const getUser = (state: State) => state.user;
export const getUserEmail: State => string = createSelector(getUser, userSelectors.getEmail);
export const getUserId: State => ?number = createSelector(getUser, userSelectors.getId);
export const getUserPermissions: State => Array<UserPermission> = (
  createSelector(getUser, userSelectors.getPermissions)
);
export const getUserCampaign: State => ?string = createSelector(getUser, userSelectors.getCampaign);
export const isUserAuthenticated: State => boolean = createSelector(getUser, userSelectors.isAuthenticated);
export const isFreeUser: State => boolean = createSelector(getUser, userSelectors.isFree);
export const isUsersEmailConfirmed: State => boolean = createSelector(getUser, userSelectors.isEmailConfirmed);
export const isUserRegistrationCompleted: State => boolean = (
  createSelector(getUser, userSelectors.isRegistrationCompleted)
);
export const isUserPlainTeamMember: State => boolean = createSelector(getUser, userSelectors.isPlainTeamMember);
export const isUserTeamOwner: State => boolean = createSelector(getUser, userSelectors.isTeamOwner);
export const isUserTeamAdmin: State => boolean = createSelector(getUser, userSelectors.isTeamAdmin);

// Subscription
export const getSubscriptions = (state: State) => state.subscriptions;
export const isSubscriptionsFetched: State => boolean = (
  createSelector(getSubscriptions, subscriptionSelectors.isFetched)
);
export const getPrimarySubscription: State => Subscription = (
  createSelector(getSubscriptions, subscriptionSelectors.getPrimary)
);
export const getAdditionalSeatsSubscriptionInfo: State => ?SubscriptionWithAdditionalSeats = createSelector(
  getSubscriptions,
  subscriptionSelectors.getAdditionalSeatsInfo
);
export const getAllAdditionalSubscriptions: State => Array<Subscription> = (
  createSelector(getSubscriptions, subscriptionSelectors.getAllAdditional)
);
export const getAllActiveAdditionalSubscriptions: State => Array<Subscription> = createSelector(
  getSubscriptions,
  subscriptionSelectors.getAllActiveAdditional
);
export const getFirstAdditionalSubscription = (state: State) => getAllActiveAdditionalSubscriptions(state)[0];
export const isChangePlanAllowed: State => boolean = createSelector(
  getSubscriptions,
  subscriptionSelectors.isPrimaryPlanChangeAllowed,
);
export const isInactiveSubscription: State => boolean = (
  createSelector(getSubscriptions, subscriptionSelectors.isPrimaryInactive)
);
export const isSubscriptionTrial: State => boolean = (
  createSelector(getSubscriptions, subscriptionSelectors.isPrimaryTrial)
);
export const getBasicPlanSeatsCount: State => number = (
  createSelector(getSubscriptions, subscriptionSelectors.getPrimarySeatsCount)
);
export const getPrimaryPricePlan: State => ?PricePlan = createSelector(
  getSubscriptions,
  subscriptionSelectors.getPrimaryPricePlan,
);

// Payment method
export const getPaymentMethod = (state: State) => state.paymentMethod;
export const getBusinessAccountDetails: State => ?InvoicingInformation = createSelector(
  getPaymentMethod,
  paymentMethodSelectors.getBusinessAccountDetails
);
export const isPaymentMethodFetched: State => boolean = (
  createSelector(getPaymentMethod, paymentMethodSelectors.isFetched)
);
export const isPaymentMethodCreated: State => boolean = (
  createSelector(getPaymentMethod, paymentMethodSelectors.isCreated)
);
export const isPaymentDetailsLoading: State => boolean = createSelector(
  getPaymentMethod,
  (paymentDetails) => paymentDetails.isLoading,
);
export const creditCardExpiration = (state: State) => (
  paymentMethodSelectors.creditCardExpiration(getPaymentMethod(state))
);

// Price plans
export const getPricePlans = (state: State) => state.pricePlans;
export const getPricePlansList: State => Array<PricePlan> = createSelector(getPricePlans, pricePlansSelectors.getList);
export const getActivePricePlanByPriceKey = (state: State, key: pricePlansKeys.PricePlanKey) => (
  pricePlansSelectors.getFirstActiveByPriceKey(getPricePlans(state), key)
);
export const getAdditionalSeatPlan = (state: State) => (
  pricePlansSelectors.getFirstActiveByPriceKey(getPricePlans(state), pricePlansKeys.ADDITIONAL_SEAT_MONTHLY_TEAM)
);
export const getMonthlyPlan = (state: State) => getActivePricePlanByPriceKey(state, pricePlansKeys.MONTHLY);
export const getAvailablePricePlans: State => Array<PricePlan> = createSelector(
  getPricePlans,
  (state) => getPrimarySubscription(state).pricePlan.group,
  pricePlansSelectors.getActiveByGroup
);
export const getPricePerMonthPerMember = (state: State, key?: pricePlansKeys.PricePlanKey) => {
  // use primary subscription's price plan if key isn't provided
  const pricePlan = key ? getActivePricePlanByPriceKey(state, key) : getPrimarySubscription(state).pricePlan;
  const price = pricePlan.price / pricePlan.paidMonth / (pricePlan.groupMembersCount + 1); // members + owner

  return {
    currency: pricePlan.currency,
    price: Math.round(price * 100) / 100,
  };
};

// Notifications
export const getNotifier = (state: State) => state.notifier;
export const getNotification: State => Array<Notification> = createSelector(getNotifier, notifierSelectors.getList);

// Invoices
export const getInvoices = (state: State) => state.invoices;
export const getInvoicesList: State => Array<Invoice> = createSelector(getInvoices, invoicesSelectors.getList);

// Team
export const getTeamInfo = (state: State) => teamPlanSelectors.getTeamInfo(state.teamPlan);
export const getTeamMembers = (state: State) => teamPlanSelectors.getMembers(state.teamPlan);
export const getPlainTeamMembers = (state: State) => teamPlanSelectors.getPlainMembers(state.teamPlan);
export const isTeamMemberLoading = (state: State, memberId: number) => (
  teamPlanSelectors.isMemberLoading(state.teamPlan, memberId)
);
export const isTeamFetched: State => boolean = createSelector(getTeamInfo, teamPlanSelectors.isFetched);
export const getActiveTeamMembers = (state: State) => teamPlanSelectors.getActiveMembers(state.teamPlan);
export const isAnyInvitationPending: State => boolean = createSelector(
  getTeamInfo,
  teamPlanSelectors.isAnyInvitationPending,
);
export const isAnyInvitationPurchasePending: State => boolean = createSelector(
  getTeamInfo,
  teamPlanSelectors.isAnyInvitationPurchasePending,
);

// Team members
export const isAnyAdditionalSeatPaymentPending: State => boolean = createSelector(
  getTeamMembers,
  teamMembersSelectors.isAnyAdditionalSeatPaymentPending,
);
export const getTotalAmountForCustomers = (state: State, selectedPlan?: PricePlan) => {
  if (!selectedPlan) {
    return 0;
  }

  const { price, groupMembersCount } = selectedPlan;
  const membersNum = getTeamMembers(state).length;

  return membersNum * (price / (groupMembersCount + 1));
};
export const getOwnersAdditionalSeats: State => Array<Subscription> = (
  createSelector(getTeamMembers, teamMembersSelectors.getOwnersAdditionalSeats)
);
export const getCurrentUserAdditionalSeatsCount = (state: State) => {
  const isPlainMember = isUserPlainTeamMember(state);

  // this will not work for owner, because owner gets subscriptions for all team
  if (isPlainMember) {
    return getAllAdditionalSubscriptions(state).length;
  }

  return getOwnersAdditionalSeats(state).length;
};

// Jamf
export const getJamf = (state: State) => state.jamf;

// Devices
export const getDevices = (state: State) => state.devices;
export const getDevicesList: State => Array<Device> = createSelector(getDevices, devicesSelectors.getList);
export const getDeviceCount: State => number = createSelector(getDevices, devicesSelectors.getCount);

export const getSeatsCount: State => number = createSelector(
  [getBasicPlanSeatsCount, getCurrentUserAdditionalSeatsCount],
  (basicSeatsCount, usersAdditionalSeatsCount) => basicSeatsCount + usersAdditionalSeatsCount,
);

export const getEmptySeatsCount: State => number = createSelector(
  [getSeatsCount, getDeviceCount],
  (seatsCount, deviceCount) => seatsCount - deviceCount,
);

// Apps
export const getApps = (state: State) => state.apps;
export const getAppsList: State => Array<Application> = createSelector(getApps, appsSelectors.getList);
