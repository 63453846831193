import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  FormattedMessage, defineMessages, injectIntl, intlShape,
} from 'react-intl';
import { FormField, Button } from '@setapp/ui-kit';

import validate from 'utils/auth-validation';

import { updateUser } from 'state/user/user-actions';
import { getUser } from 'state/root-reducer';
import { addSuccessNotification } from 'state/notifier/notifier-actions';

import analytics, { events } from 'utils/analytics';

const messages = defineMessages({
  emailRequired: {
    id: 'accountSettings.personalInfo.validation.emptyEmail',
    defaultMessage: 'Email is required',
  },
  emailInvalid: {
    id: 'accountSettings.personalInfo.validation.invalidEmail',
    defaultMessage: 'Invalid email',
  },
  passwordRequired: {
    id: 'accountSettings.personalInfo.validation.emptyPassword',
    defaultMessage: 'Password is required',
  },
  nameLabel: {
    id: 'accountSettings.personalInfo.nameLabel',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'accountSettings.personalInfo.namePlaceholder',
    defaultMessage: 'Enter your name',
  },
  emailLabel: {
    id: 'accountSettings.personalInfo.emailLabel',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'accountSettings.personalInfo.emailPlaceholder',
    defaultMessage: 'Enter your email',
  },
  passwordLabel: {
    id: 'accountSettings.personalInfo.passwordLabel',
    defaultMessage: 'Setapp password',
  },
  passwordPlaceholder: {
    id: 'accountSettings.personalInfo.passwordPlaceholder',
    defaultMessage: 'Enter your Setapp password',
  },
  successUpdateNotification: {
    id: 'accountSettings.personalInfo.successNotification',
    defaultMessage: 'Personal information successfully updated.',
  },
});

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        currentPassword: '',
        name: props.name,
        email: props.email,
      },
      fieldsErrors: {
        name: '',
        email: '',
        currentPassword: '',
      },
      genericError: '',
      showPassword: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  render() {
    const {
      intl,
      name,
      email,
      isLoading,
    } = this.props;
    const {
      genericError,
      fieldsErrors,
      fields,
      showPassword,
    } = this.state;
    const errorClasses = classnames('form-error text-danger', {
      hidden: !genericError,
    });

    return (
      <div>
        <h5>
          <FormattedMessage
            id="accountSettings.personalInfo.title"
            defaultMessage="Personal information"
          />
        </h5>
        <form onSubmit={this.onFormSubmit} noValidate>
          <FormField
            id="name"
            name="name"
            type="text"
            defaultValue={name}
            label={intl.formatMessage(messages.nameLabel)}
            placeholder={intl.formatMessage(messages.namePlaceholder)}
            onChange={this.onFormChange}
            helpText={fieldsErrors.name}
            invalid={Boolean(fieldsErrors.name)}
            autoComplete="new-name"
            showLabel
          />
          <FormField
            id="email"
            name="email"
            type="email"
            defaultValue={email}
            label={intl.formatMessage(messages.emailLabel)}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            onChange={this.onFormChange}
            helpText={fieldsErrors.email}
            invalid={Boolean(fieldsErrors.email)}
            autoComplete="new-email"
            showLabel
          />
          {showPassword && (
            <FormField
              id="currentPasswordForEmailChange"
              name="currentPassword"
              type="password"
              value={fields.currentPassword}
              label={intl.formatMessage(messages.passwordLabel)}
              placeholder={intl.formatMessage(messages.passwordPlaceholder)}
              onChange={this.onFormChange}
              helpText={fieldsErrors.currentPassword}
              invalid={Boolean(fieldsErrors.currentPassword)}
              autoComplete="new-password"
              showLabel
            />
          )}
          <p className={errorClasses}>{genericError}</p>

          <Button
            variant="secondary-outline"
            type="submit"
            disabled={isLoading}
            data-qa="updateInformationBtn"
          >
            <FormattedMessage
              id="accountSettings.personalInfo.updateButton"
              defaultMessage="Update info"
            />
          </Button>
        </form>
      </div>
    );
  }

  getValidation() {
    const { intl } = this.props;

    return {
      email: {
        required: intl.formatMessage(messages.emailRequired),
        emailFormat: intl.formatMessage(messages.emailInvalid),
      },
      currentPassword: {
        required: intl.formatMessage(messages.passwordRequired),
      },
    };
  }

  onFormChange(e) {
    this.setFieldValue(e.target.name, e.target.value);
    const { showPassword } = this.state;

    if (e.target.name === 'email' && !showPassword) {
      this.showPasswordField();
    }
  }

  hidePasswordField() {
    this.setState({ showPassword: false });
  }

  showPasswordField() {
    this.setState({ showPassword: true });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { fields } = this.state;
    const { props } = this;
    const { email, updateUser } = this.props;

    const changedFields = ['email', 'name']
      .filter((fieldName) => fields[fieldName] !== props[fieldName])
      .reduce((result, fieldName) => ({
        ...result,
        [fieldName]: fields[fieldName],
      }), {});

    if (!Object.keys(changedFields).length) {
      this.hidePasswordField();
      this.displaySuccessNotification();

      return;
    }

    if (changedFields.email) {
      changedFields.currentPassword = fields.currentPassword;
    }

    const fieldsErrors = validate(changedFields, this.getValidation());
    const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

    this.setState({ fieldsErrors });

    if (!formHasError) {
      const oldUserEmail = email;

      updateUser(changedFields)
        .then(() => {
          if (changedFields.email) {
            analytics.trackEvent(events.ACCOUNT_EMAIL_CHANGED, { eventLabel: oldUserEmail });
            this.setFieldValue('currentPassword', '');
          }
          this.hidePasswordField();

          this.displaySuccessNotification();
        })
        .catch((error) => {
          this.setState({ ...error.getSimplifiedErrors() });
        });
    } else {
      this.setState({ genericError: '' });
    }
  }

  setFieldValue(name, value) {
    this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
  }

  displaySuccessNotification() {
    const {
      intl,
      addSuccessNotification,
    } = this.props;

    addSuccessNotification({
      message: intl.formatMessage(messages.successUpdateNotification),
    });
  }
}

UserInfo.propTypes = {
  updateUser: PropTypes.func.isRequired,
  addSuccessNotification: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  intl: intlShape.isRequired,
};

UserInfo.defaultProps = {
  name: '',
  isLoading: false,
};

export { UserInfo };

const mapStateToProps = (state) => (getUser(state));
const mapActionsToProps = {
  updateUser,
  addSuccessNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(UserInfo));
