// eslint-disable-next-line import/no-anonymous-default-export
export default {
  downloadJamfDesktopClient: 'https://go.setapp.com/download/jamf-client',
  downloadDesktopClient: `${process.env.INSTALLER_URL}/download/team-client`,
  support: 'https://support.setapp.com/hc/%{locale}',
  supportMail: 'mailto:support@setapp.com',
  businessMail: 'mailto:business@setapp.com',
  paddleHelpMail: 'mailto:help@paddle.com',
  createSupportRequest: 'https://support.setapp.com/hc/%{locale}/requests/new',
  braintreeProfile: 'https://www.braintreegateway.com/merchants/%{merchantId}/verified',
  paddle: 'https://paddle.com',
  braintreeBage: 'https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png',
  setappSite: 'https://setapp.com/',
  howItWorks: 'https://setapp.com/how-it-works',
  allApps: 'https://setapp.com/apps',
  about: 'https://setapp.com/about',
  blog: 'https://setapp.com/blog',
  macLess: 'https://setapp.com/mac-less',
  teams: 'https://setapp.com/teams',
  termsOfUse: 'https://setapp.com/terms-of-use',
  privacy: 'https://setapp.com/privacy-policy',
  cookiePolicy: 'https://setapp.com/cookie-policy',
  facebook: 'https://www.facebook.com/setapp',
  twitter: 'https://twitter.com/setapp',
  instagram: 'https://www.instagram.com/setapp/',
  youtube: 'https://www.youtube.com/channel/UCZysXYIvNhkVnfiA5MMxD2g',
  google: 'https://plus.google.com/112674418256024355452/posts',
  getStartedWithSetappBlogPost: 'https://setapp.com/how-to/5-tips-to-get-started-with-setapp',
  jamf: 'https://www.jamf.com',
  jamfGuide: 'https://support.setapp.com/hc/en-us/articles/360009998280',
  jamfConfig: 'https://store.setapp.com/team_config/v1/team_config.template.yml',
};
