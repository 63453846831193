// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeviceIcon } from '@setapp/ui-kit';

import './add-seat-activate-subscription.scss';

type Props = {
  onConfirm: () => any,
}

const AddSeatActivateSubscription = (props: Props) => {
  const { onConfirm } = props;

  return (
    <>
      <div className="add-seat-activate-subscription">
        <div className="add-seat-activate-subscription__icon">
          <DeviceIcon device="macbook" size={80} />
        </div>
        <div className="add-seat-activate-subscription__content">
          <p>
            <FormattedMessage
              id="addSeatModal.activateSubscription.seatInfo"
              defaultMessage="Please activate your membership to add another device."
            />
          </p>
        </div>
      </div>
      <Button
        block
        onClick={onConfirm}
        className="add-seat-activate-subscription__submit"
      >
        <FormattedMessage
          id="addSeatModal.activateSubscription.confirmButtonTitle"
          defaultMessage="Activate membership"
        />
      </Button>
    </>
  );
};

export default AddSeatActivateSubscription;
