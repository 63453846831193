// @flow
import { type Node, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, type RouterHistory } from 'react-router-dom';
import queryString from 'query-string';

import { isUserAuthenticated } from 'state/root-reducer';
import urls from 'config/urls';

type Props = {
  children: Node,
  isUserAuthenticated: boolean,
  history: RouterHistory,
};

const RedirectAuthUser = ({ isUserAuthenticated, history, children }: Props) => {
  const { replace, location } = history;
  const allowToNavigate = useState(!isUserAuthenticated)[0];

  const query = queryString.parse(location.search);
  useEffect(() => {
    if (!allowToNavigate) {
      replace({
        query,
        pathname: urls.root,
      });
    }
  }, [allowToNavigate, query, replace]);

  return allowToNavigate && children;
};

const mapStateToProps = (state) => ({
  isUserAuthenticated: isUserAuthenticated(state),
});

export { RedirectAuthUser as PureRedirectAuthUser };

export default connect(mapStateToProps)(withRouter(RedirectAuthUser));
