// @flow

import React from 'react';
import type { Node } from 'react';
import { Tooltip, Button } from '@setapp/ui-kit';
import classNames from 'classnames';

import './pool-info.scss';

import plusIcon from './images/plus.svg';

type Props = {
  tooltip: Node,
  title: Node,
  icon: Node,
  onAddButtonClick?: ?() => mixed,
  qaLabel: string,
}

const PoolInfo = ({
  tooltip,
  title,
  icon,
  onAddButtonClick,
  qaLabel,
}: Props) => (
  <Tooltip
    trigger="mouseenter focus"
    position="bottom"
    distance="sm"
    content={(
      <div className="pool-info__tooltip">
        {tooltip}
      </div>
    )}
    useContext
  >
    <div
      className={classNames('pool-info__content',
        {
          'pool-info__content_with-add-button': Boolean(onAddButtonClick),
        })}
    >
      <div className="pool-info__icon">
        {icon}
      </div>
      <div className="pool-info__title text_lg" data-qa={qaLabel}>
        {title}
      </div>
      {!onAddButtonClick || (
        <Button
          variant="secondary-outline"
          className="pool-info__add-button"
          onClick={onAddButtonClick}
        >
          <img
            className="pool-info__add-button-icon"
            src={plusIcon}
            alt="Plus"
            width="12px"
            height="12px"
          />
        </Button>
      )}
    </div>
  </Tooltip>
);

PoolInfo.defaultProps = {
  onAddButtonClick: null,
};

export default PoolInfo;
