// @flow

import { apiURL } from 'config/api';

import request from 'utils/request';

import { type Dispatch } from '../state-types';

import * as actionTypes from './jamf-action-types';

export const fetchPersonalKey = () => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.get(apiURL.teamPersonalKey)
    .then((data) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const revokePersonalKey = () => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(apiURL.teamPersonalKey)
    .then((data) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};
