// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';

import './link-sent.scss';

type Props = {
  userEmail: string,
};

const LinkSent = ({ userEmail }: Props) => (
  <div className="successful-registration-mobile-link-sent">
    <Row>
      <Col smOffset={2} sm={8}>
        <h3 className="successful-registration-mobile-link-sent__title">
          <FormattedMessage
            id="successfulRegistrationMobileLinkSent.title"
            defaultMessage="Link is on its way"
          />
        </h3>

        <div className="mb-5">
          <FormattedMessage
            id="successfulRegistrationMobileLinkSent.subTitle.line1"
            defaultMessage="We've sent a download link to {email}. Get back on your Mac and get Setapped!"
            values={{
              email: <strong>{userEmail}</strong>,
            }}
          />
        </div>
        <div className="mb-5">
          <FormattedMessage
            id="successfulRegistrationMobileLinkSent.subTitle.line2"
            defaultMessage="Only have your phone? Check guides on our blog."
          />
        </div>

        <Button
          block
          href={externalUrls.getStartedWithSetappBlogPost}
          className="mt-10"
          variant="primary-outline"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FormattedMessage
            id="successfulRegistrationMobileLinkSent.whatsNext"
            defaultMessage="Whats next"
          />
        </Button>
      </Col>
    </Row>
  </div>
);

export default LinkSent;
