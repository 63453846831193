// @flow

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { SubscriptionStatus } from './statuses';


export type Subscription = {|
  +id: number,
  +status: SubscriptionStatus,

  // nextPaymentDate can be null for free subscription
  +nextPaymentDate: ?number,

  // expirationDate can be null for free subscription
  +expirationDate: ?number,
  +isLoading: boolean,
  +paymentPending: boolean,
  +lastPaymentFailed: boolean,
  +pricePlan: PricePlan,
  +nextPricePlan: ?PricePlan,
  +tax: number,
|};

export type SubscriptionWithAdditionalSeats = {|
  ...Subscription,
  +seatsNumber: number,
|}

export type SubscriptionsState = {|
  +list: Array<Subscription>,
  +isLoading: boolean,
|};

const initialState: SubscriptionsState = {
  list: [],
  isLoading: false,
};

export default initialState;
