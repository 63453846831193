// @flow

import { type Node } from 'react';

import externalUrls from 'config/external-urls';

import { detect } from 'utils/browser';
import { isPpc } from 'utils/traffic-source';

type Props = {
  children: Node;
};

const RedirectPpcTraffic = ({ children }: Props) => {
  const { isMacOS, isiOS } = detect();

  if (!isMacOS && !isiOS && isPpc()) {
    window.location.replace(externalUrls.macLess);
  }

  return children;
};

export default RedirectPpcTraffic;
