// @flow

export type Device = {
  +id: number,
  +lastUsedDate: number,
  +expirationDate: number,
  +model: string,
  +name: string,
};

export type DeviceState = {
  +list: Array<Device>,
  +isLoading: boolean,
}

const initialState: DeviceState = {
  list: [],
  isLoading: false,
};

export default initialState;
