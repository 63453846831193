// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {|
  onClick: () => void,
|};

const ChangePlanButton = ({ onClick }: Props) => (
  <Button
    onClick={onClick}
    variant="secondary-outline"
    data-qa="changePlanBtn"
  >
    <FormattedMessage
      id="pricePlans.changePlanBtn"
      defaultMessage="Change Plan"
    />
  </Button>
);

export default ChangePlanButton;
