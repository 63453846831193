// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { SubscriptionStatus as SubscriptionStatusType } from 'state/subscription/statuses';

import InfoField from 'components/shared/info-field/info-field';

import SubscriptionStatus from './subscription-status/subscription-status';

type Props = {|
  status: SubscriptionStatusType,
  expirationDate: ?number,
  nextPaymentDate: ?number,
|};

const StatusRow = ({ status, expirationDate, nextPaymentDate }: Props) => (
  <InfoField
    lightValue
    title={<FormattedMessage id="subscriptionInfo.accountStatus" defaultMessage="Status" />}
    value={<SubscriptionStatus status={status} expirationDate={expirationDate} nextPaymentDate={nextPaymentDate} />}
    qaLabel="subscriptionStatusText"
  />
);

export default StatusRow;
