// @flow

import React, { type ComponentType, type ElementConfig, type Node } from 'react';
import { Route, type ContextRouter } from 'react-router-dom';

import type { UserPermission } from 'state/user/user-permissions';
import AuthenticatedSection from 'components/shared/access-control/authenticated-section';
import CrossAuthGuard from 'components/shared/access-control/cross-auth-guard';
import RedirectNonOwner from 'components/shared/access-control/redirect-non-owner';
import PermissionsGuard from 'components/shared/access-control/permissions-guard';

type LayoutProps = {|
  children: Node,
  ...ContextRouter,
|};

type Props = {|
  ...ElementConfig<typeof Route>,
  component: ComponentType<ContextRouter>,
  layout: ?ComponentType<LayoutProps>,
  authenticated: boolean,
  /**
   * @deprecated use `permission` property
   */
  redirectNonOwner: boolean,
  permission: ?UserPermission,
|};

const AppRoute = ({
  component: Component,
  layout: Layout,
  authenticated,
  redirectNonOwner,
  permission,
  ...routeProps
}: Props) => (
  <Route
    {...routeProps}
    render={(props) => {
      let children;

      if (Layout != null) {
        children = (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      } else {
        children = <Component {...props} />;
      }

      /**
       * @deprecated use PermissionsGuard component
       */
      if (redirectNonOwner) {
        children = (
          <RedirectNonOwner>
            {children}
          </RedirectNonOwner>
        );
      }

      if (authenticated) {
        return (
          <CrossAuthGuard>
            <AuthenticatedSection {...props}>
              <PermissionsGuard permission={permission}>
                {children}
              </PermissionsGuard>
            </AuthenticatedSection>
          </CrossAuthGuard>
        );
      }

      return children;
    }}
  />
);

AppRoute.defaultProps = {
  authenticated: false,
  permission: null,
  redirectNonOwner: false,
  layout: undefined,
};

export default AppRoute;
