// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionStep from '../action-card/action-card';

import downloadIcon from './images/icon-download.svg';

type Props = {
  onCtaClick: () => mixed,
}

const OpenDesktopStep = ({ onCtaClick }: Props) => (
  <ActionStep
    checked
    onCtaClick={onCtaClick}
    icon={downloadIcon}
    title={(
      <FormattedMessage
        id="dashboard.openDesktopStep.title"
        defaultMessage="1. Discover apps"
      />
    )}
    description={(
      <FormattedMessage
        id="dashboard.openDesktopStep.description"
        defaultMessage="Find apps from Setapp for any task and download the best fit in two clicks."
      />
    )}
    ctaCaption={(
      <FormattedMessage
        id="dashboard.openDesktopStep.ctaCaption"
        defaultMessage="Open App"
      />
    )}
  />
);

export default OpenDesktopStep;
