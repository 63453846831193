// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Button, SelectField } from '@setapp/ui-kit';

import type { IntlShape } from 'react-intl';
import { getPlainTeamMembers } from 'state/root-reducer';
import * as roles from 'state/team-plan/team-member-roles';
import Modal from 'components/shared/modal/modal';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import type { MemberRole } from 'state/team-plan/team-member-roles';

type Props = {
  show: boolean,
  onHide: () => void,
  onExited: () => void,
  intl: IntlShape,
  members: Array<TeamMember>,
  onRoleSubmitted: (TeamMember, MemberRole) => mixed,
};

type State = {|
  memberId: string,
  hasError: boolean,
|}

const messages = defineMessages({
  title: {
    id: 'team.shareMemberRoleModal.title',
    defaultMessage: 'Share your team manager role with a teammate',
  },
  selectLabel: {
    id: 'team.shareMemberRoleModal.selectLabel',
    defaultMessage: 'Members',
  },
  selectPlaceholder: {
    id: 'team.shareMemberRoleModal.selectPlaceholderLabel',
    defaultMessage: 'Choose a team member',
  },
});

class ShareMemberRoleModal extends PureComponent<Props, State> {
  state = {
    memberId: '',
    hasError: false,
  };

  render() {
    const {
      show, onHide, onExited, members, intl,
    } = this.props;
    const { memberId, hasError } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={intl.formatMessage(messages.title)}
      >
        <form onSubmit={this.onFormSubmit} noValidate>
          <SelectField
            showLabel
            id="member"
            name="member"
            label={intl.formatMessage(messages.selectLabel)}
            defaultOptionLabel={intl.formatMessage(messages.selectPlaceholder)}
            invalid={hasError}
            value={memberId}
            options={this.membersToOptions(members)}
            onChange={this.onMemberChanged}
          />

          <p className="mb-6">
            <FormattedMessage
              id="team.shareMemberRoleModal.description"
              defaultMessage="Manage your team member accounts and their devices."
            />
          </p>

          <Button block type="submit">
            <FormattedMessage id="team.shareMemberRoleModal.shareRoleButton" defaultMessage="Share role" />
          </Button>
          <Button
            block
            className="mt-3"
            variant="secondary-outline"
            onClick={onHide}
          >
            <FormattedMessage id="team.shareMemberRoleModal.cancelButton" defaultMessage="Cancel" />
          </Button>
        </form>
      </Modal>
    );
  }

  onMemberChanged = (event: SyntheticEvent<HTMLSelectElement>) => {
    this.setState({
      hasError: false,
      memberId: event.currentTarget.value,
    });
  }

  onFormSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    const { onHide, onRoleSubmitted, members } = this.props;
    const { memberId } = this.state;

    event.preventDefault();

    if (!memberId) {
      this.setState({ hasError: true });

      return;
    }

    const member = members.find((member) => member.id === Number(memberId));
    if (member) onRoleSubmitted(member, roles.TEAM_MANAGER);

    onHide();
  };

  // Transform Team member to select friendly value
  membersToOptions = (members: Array<TeamMember>): {[string]: string} => members
    .reduce((acc, member) => ({ ...acc, [member.id]: member.email }), {});
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  members: getPlainTeamMembers(state),
});

export { ShareMemberRoleModal as PureShareMemberRoleModal };

export default connect(mapStateToProps)(injectIntl(ShareMemberRoleModal));
