// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownItem } from '@setapp/ui-kit';

import SupportLink from 'components/shared/support-link/support-link';

import { getCreateSupportRequestUrl } from 'utils/support';

import HeaderToggler from '../header-toggler/header-toggler';


const HelpDropdown = () => (
  <Dropdown
    id="headerHelpActions"
    positionRight
    toggleElement={
      <HeaderToggler>
        <FormattedMessage id="helpDropdown.toggler" defaultMessage="Help" />
      </HeaderToggler>
    }
  >
    <DropdownItem>
      <SupportLink
        className="app-header__dropdown-item"
        text={<FormattedMessage id="helpDropdown.helpCenter" defaultMessage="Help center" />}
      />
    </DropdownItem>
    <DropdownItem>
      <a href={getCreateSupportRequestUrl()} className="app-header__dropdown-item" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="helpDropdown.contactSupport" defaultMessage="Contact support" />
      </a>
    </DropdownItem>
  </Dropdown>
);

export default HelpDropdown;
