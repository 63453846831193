// @flow
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import isAfter from 'date-fns/isAfter';
import differenceInDays from 'date-fns/differenceInCalendarDays';

import * as subscriptionStatuses from 'state/subscription/statuses';
import type { SubscriptionStatus as SubscriptionStatusType } from 'state/subscription/statuses';

import './subscription-status.scss';

type Props = {|
  status: SubscriptionStatusType,
  expirationDate: ?number,
  nextPaymentDate: ?number,
|};

const activeBar = (
  <span className="subscription-status__bar subscription-status__bar_green mr-2">
    <FormattedMessage
      id="subscriptionInfo.statuses.active"
      defaultMessage="Active"
    />
  </span>
);

const blockedBar = (
  <span className="subscription-status__bar subscription-status__bar_red mr-2">
    <FormattedMessage
      id="subscriptionInfo.statuses.blocked"
      defaultMessage="Blocked"
    />
  </span>
);

const canceledBar = (
  <span className="subscription-status__bar subscription-status__bar_gray mr-2">
    <FormattedMessage
      id="subscriptionInfo.statuses.cancelled"
      defaultMessage="Cancelled"
    />
  </span>
);

const trialBar = (
  <span className="subscription-status__bar subscription-status__bar_orange mr-2">
    <FormattedMessage
      id="subscriptionInfo.statuses.trialTitle"
      defaultMessage="Trial"
    />
  </span>
);

const pendingBar = (
  <span className="subscription-status__bar subscription-status__bar_purple mr-2">
    <FormattedMessage
      id="subscriptionInfo.statuses.pendingTitle"
      defaultMessage="Pending"
    />
  </span>
);

const SubscriptionStatus = ({ status, expirationDate, nextPaymentDate }: Props) => {
  let isSubscriptionExpiresLessThanInFiveDays = false;
  let daysTillNextPayment;

  if (nextPaymentDate != null) {
    daysTillNextPayment = differenceInDays(nextPaymentDate * 1000, Date.now());
    isSubscriptionExpiresLessThanInFiveDays = daysTillNextPayment <= 5;
  }

  switch (status) {
    case subscriptionStatuses.TRIAL: {
      return (
        <>
          {trialBar}
          <span className={classNames({ 'text-danger': isSubscriptionExpiresLessThanInFiveDays })}>
            <FormattedMessage
              id="subscriptionInfo.statuses.trial"
              defaultMessage="{daysTillNextPayment, plural, one {{daysTillNextPayment} day} other {{daysTillNextPayment} days}} of free trial left"
              values={{ daysTillNextPayment }}
            />
          </span>
        </>
      );
    }
    case subscriptionStatuses.CANCELLED: {
      const isSubscriptionExpired = expirationDate != null && isAfter(Date.now(), expirationDate * 1000);
      if (expirationDate == null || isSubscriptionExpired) {
        return canceledBar;
      }

      return (
        <>
          {activeBar}
          <FormattedMessage
            id="subscriptionInfo.statuses.activeTillDate"
            defaultMessage="till {date}"
            values={{
              date: (
                <FormattedDate
                  value={expirationDate * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              ),
            }}
          />
        </>
      );
    }
    case subscriptionStatuses.BLOCKED: {
      return blockedBar;
    }
    case subscriptionStatuses.ACTIVE: {
      if (nextPaymentDate != null) {
        return (
          <>
            {activeBar}
            <FormattedMessage
              id="subscriptionInfo.statuses.activeRenews"
              defaultMessage="Renews on {date}"
              values={{
                date: (
                  <FormattedDate
                    value={nextPaymentDate * 1000}
                    year="numeric"
                    month="short"
                    day="numeric"
                  />
                ),
              }}
            />
          </>
        );
      }

      return activeBar;
    }
    case subscriptionStatuses.NEW: {
      return pendingBar;
    }
    case subscriptionStatuses.GRACE:
    case subscriptionStatuses.FREE:
    default: {
      return activeBar;
    }
  }
};
export default SubscriptionStatus;
