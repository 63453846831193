// @flow

export type SubscriptionStatus = 1 | 2 | 3 | 4 | 5 | 6 | 10;

export const NEW = 1;
export const TRIAL = 2;
export const ACTIVE = 3;
export const CANCELLED = 4;
export const GRACE = 5;
export const BLOCKED = 6;
export const FREE = 10;
