// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';

import './setapp-downloaded.scss';

const SetappDownloaded = () => (
  <div className="successful-registration-mobile-setapp-downloaded">
    <Row>
      <Col smOffset={2} sm={8}>
        <h3 className="successful-registration-mobile-setapp-downloaded__title">
          <FormattedMessage
            id="successfulRegistrationMobileSetappDownloaded.title"
            defaultMessage="Success!"
          />
        </h3>

        <div className="mb-5">
          <FormattedMessage
            id="successfulRegistrationMobileSetappDownloaded.subTitle.line1"
            defaultMessage="Setapp is now downloaded to your device. Install it, and you're good to go."
          />
        </div>
        <div className="mb-5">
          <FormattedMessage
            id="successfulRegistrationMobileSetappDownloaded.subTitle.line2"
            defaultMessage="Meanwhile, check guides on our blog on how to get started."
          />
        </div>

        <Button
          block
          href={externalUrls.getStartedWithSetappBlogPost}
          className="mt-10"
          variant="primary-outline"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FormattedMessage
            id="successfulRegistrationMobileSetappDownloaded.whatsNext"
            defaultMessage="Whats next"
          />
        </Button>
      </Col>
    </Row>
  </div>
);

export default SetappDownloaded;
