// @flow

import React, { type Node } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { ButtonClose } from '@setapp/ui-kit';

import type { IntlShape } from 'react-intl';

import globalMessages from '../../../../../global-messages';

import './balloon.scss';

const tailPositions = {
  bottomLeft: 'balloon-bottom balloon-bottom-left',
  bottomRight: 'balloon-bottom balloon-bottom-right',
  leftBottom: 'balloon-left balloon-left-bottom',
  leftTop: 'balloon-left balloon-left-top',
  topLeft: 'balloon-top balloon-top-left',
  topRight: 'balloon-top balloon-top-right',
  rightTop: 'balloon-right balloon-right-top',
  rightBottom: 'balloon-right balloon-right-bottom',
};

type Props = {|
  children: Node,
  position: 'top' | 'bottom',
  tailPosition: $Keys<typeof tailPositions>,
  onClose: ?() => any,
  animationDuration: number,
  show: boolean,
  intl: IntlShape,
|};

const Balloon = (props: Props) => {
  const {
    children,
    onClose,
    tailPosition,
    show,
    position,
    animationDuration,
    intl,
  } = props;
  const balloonClasses = classnames('balloon', tailPositions[tailPosition]);

  return (
    <CSSTransition in={show} unmountOnExit classNames={`balloon-${position}`} timeout={animationDuration}>
      <div className={balloonClasses}>
        {onClose && (
          <div className="balloon-close-icon">
            <ButtonClose
              size="sm"
              onClick={onClose}
              ariaLabel={intl.formatMessage(globalMessages.btnCloseAccessibilityLabel)}
            />
          </div>
        )}
        {children}
      </div>
    </CSSTransition>);
};

Balloon.defaultProps = {
  tailPosition: 'bottomLeft',
  show: true,
  animationDuration: 500,
  onClose: null,
};

export { Balloon as PureBalloon };

export default injectIntl(Balloon);
