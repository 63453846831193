// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { type Location } from 'react-router-dom';
import queryString from 'query-string';


import * as pricePlanKeys from 'state/price-plans/price-plans-keys';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import mac from './images/mac.png';
import mac2x from './images/mac@2x.png';
import mac3x from './images/mac@3x.png';

import './signup-marketing-block.scss';

const basePrices = {
  [pricePlanKeys.MONTHLY]: {
    price: 9.99,
    currency: 'USD',
  },
  [pricePlanKeys.ANNUAL]: {
    price: 8.99,
    currency: 'USD',
  },
};

type Props = {
  location: Location,
};

const SignupMarketingBlock = ({ location }: Props) => {
  const query = queryString.parse(location.search);
  let priceKey = pricePlanKeys.MONTHLY;

  if (query.signupParams) {
    const { price_key: urlPriceKey } = JSON.parse(query.signupParams);

    if (pricePlanKeys.ANNUAL === urlPriceKey) {
      priceKey = pricePlanKeys.ANNUAL;
    }
  }

  return (
    <div className="signup-marketing-block">
      <div className="signup-marketing-block__content">
        <img src={mac} srcSet={`${mac2x} 2x, ${mac3x} 3x`} alt="" className="img-responsive" />
        <div className="h5 signup-marketing-block__title">
          <FormattedMessage
            id="outer.signup.marketingBlock.title"
            defaultMessage="Dozens of apps in one place. Download as many as you need."
          />
        </div>
        <div className="text_lg signup-marketing-block__feature mt-6">
          <div className="mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <path fill="#E6A873" fillRule="evenodd" d="M9.67 10.165L22.659 3.67c.37-.185.821-.035 1.007.335.072.145.096.31.067.47l-2.598 14.286c-.084.465-.383.862-.805 1.073L7.342 26.33c-.37.185-.821.035-1.007-.335-.072-.145-.096-.31-.067-.47l2.598-14.286c.084-.465.383-.862.805-1.073z" />
            </svg>
          </div>
          <FormattedMessage
            id="outer.signup.marketingBlock.features.discover"
            defaultMessage="Get any apps for the price of one"
          />
        </div>
        <div className="text_lg signup-marketing-block__feature mt-6">
          <div className="mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <path fill="#E6A873" d="M12 3.75c0-.414.34-.75.76-.75h4.48c.42 0 .76.333.76.75V15h7.499c.829 0 1.03.47.436 1.065L15.531 26.469c-.293.293-.77.291-1.062 0L4.065 16.065C3.477 15.477 3.667 15 4.5 15H12V3.75z" />
            </svg>
          </div>
          <FormattedMessage
            id="outer.signup.marketingBlock.features.update"
            defaultMessage="Find & download tools in seconds"
          />
        </div>
        <div className="text_lg signup-marketing-block__feature mt-6">
          <div className="mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <path fill="#E6A873" fillRule="evenodd" d="M26.25 10.779c0 5.157-3.75 10.314-11.25 15.471C7.5 21.093 3.75 15.936 3.75 10.779c0-7.25 9.595-7.825 11.063-3.699l.187.395.188-.395c1.467-4.126 11.062-3.59 11.062 3.699z" />
            </svg>
          </div>
          <FormattedMessage
            id="outer.signup.marketingBlock.features.apps"
            defaultMessage="All apps get updated for free. No ads"
          />
        </div>
        <div className="text_xs signup-marketing-block__note mt-5">
          <FormattedMessage
            id="outer.signup.marketingBlock.features.note"
            defaultMessage="{price}/mo/member — free invites on a team trial — a single fee"
            values={{
              price: (
                <FormattedPrice
                  currency={basePrices[priceKey].currency}
                  price={basePrices[priceKey].price}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignupMarketingBlock;
