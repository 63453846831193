// @flow

import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DefaultError from 'components/shared/default-error/default-error';

import Modal from 'components/shared/modal/modal';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import { changePricePlan } from 'state/subscription/subscription-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import {
  getPrimarySubscription,
  getPricePlans,
  getDevices,
  getActivePricePlanByPriceKey,
  isInactiveSubscription,
} from 'state/root-reducer';
import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import analytics, { events } from 'utils/analytics';

import ChangePlanForm from 'components/shared/change-plan-form/change-plan-form';

type Props = {|
  show: boolean,
  onHide: () => any,
  onExited: () => any,
  changePricePlan: ({subscriptionId: number, planId: number}) => Promise<void>,
  fetchPricePlans: () => Promise<void>,
  onSuccessfulPlanChange: () => void,
  currentPricePlan: PricePlan,
  isPlansListLoading: boolean,
  // used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  selectedPlanPriceKey: ?string,
  // eslint-disable-next-line react/require-default-props
  selectedPlan: ?PricePlan,
  mainSubscriptionId: number,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
|};

type State = {
  selectedPlan: PricePlan,
};

class ChangePlanModal extends Component<Props, State> {
  static defaultProps = {
    onSuccessfulPlanChange: () => {},
    selectedPlan: null,
  };

  constructor(props: Props) {
    super(props);
    const { currentPricePlan, selectedPlan } = props;

    this.state = {
      selectedPlan: selectedPlan || currentPricePlan,
    };
  }

  componentDidMount() {
    const { show } = this.props;

    if (show) {
      this.fetchPricePlans();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      show: nextIsModalShown,
    } = nextProps;

    const { show } = this.props;

    if (!show && nextIsModalShown) {
      this.fetchPricePlans();
    }

    this.setSelectedPlanOnPropsChange(nextProps);
  }

  render() {
    const {
      show,
      onHide,
      isPlansListLoading,
      currentPricePlan,
      onExited,
    } = this.props;
    const { selectedPlan } = this.state;
    const isCurrentPlanSelected = currentPricePlan && selectedPlan && selectedPlan.id === currentPricePlan.id;

    return (
      <Modal
        fullScreen
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={(
          <FormattedMessage
            id="pricePlans.changePlanModal.title"
            defaultMessage="Switch plan"
          />
        )}
      >
        {isPlansListLoading ? (
          <div className="text-center">
            <AnimatedLogo animate />
          </div>
        ) : (
          <ChangePlanForm
            onPlanChange={this.onPlanSelected}
            onSubmit={this.onChangePlanFormSubmit}
            selectedPlan={selectedPlan}
            isSubmitButtonDisabled={isCurrentPlanSelected}
            description={(
              <FormattedMessage
                id="pricePlans.changePlanModal.description.switch"
                defaultMessage="The team plan is perfect both for teams and individuals. Add new members anytime after the first payment."
              />
            )}
            showNextPaymentDate
          />
        )}
      </Modal>
    );
  }

  fetchPricePlans() {
    const { fetchPricePlans, addDangerNotification } = this.props;

    return fetchPricePlans()
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  onChangePlanFormSubmit = () => {
    const {
      mainSubscriptionId,
      changePricePlan,
      onSuccessfulPlanChange,
      onHide,
    } = this.props;
    const { selectedPlan } = this.state;

    return changePricePlan({
      subscriptionId: mainSubscriptionId,
      planId: selectedPlan.id,
    }).then(() => {
      analytics.trackEvent(events.SUBSCRIPTION_PLAN_CHANGED, { eventLabel: selectedPlan.priceKey });
      onSuccessfulPlanChange();
      this.showSuccessfulChangePlanNotification();
      onHide();
    }).catch(() => {
      const { addDangerNotification } = this.props;

      addDangerNotification({
        message: <DefaultError />,
      });
    });
  };

  showSuccessfulChangePlanNotification() {
    const { addSuccessNotification } = this.props;

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="pricePlans.changePlanModal.planChangedBeforeTrialStarted"
          defaultMessage="Plan successfully changed."
        />
      ),
    });
  }

  onPlanSelected = (selectedPlan: PricePlan) => {
    this.setState({ selectedPlan });
  };

  setSelectedPlanOnPropsChange(nextProps: Props) {
    const {
      selectedPlan: nextSelectedPlan,
      currentPricePlan: nextCurrentPricePlan,
    } = nextProps;
    const { selectedPlan, currentPricePlan } = this.props;

    if (selectedPlan !== nextSelectedPlan || currentPricePlan !== nextCurrentPricePlan) {
      this.setState({ selectedPlan: nextSelectedPlan || nextCurrentPricePlan });
    }
  }
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => ({
  mainSubscription: getPrimarySubscription(state),
  isInactiveSubscription: isInactiveSubscription(state),
  isPlansListLoading: getPricePlans(state).isLoading,
  isDevicesListLoading: getDevices(state).isLoading,
  selectedPlan: getActivePricePlanByPriceKey(state, ownProps.selectedPlanPriceKey),
  mainSubscriptionId: getPrimarySubscription(state).id,
});

const mapActionsToProps = {
  fetchPricePlans,
  changePricePlan,
  addDangerNotification,
  addSuccessNotification,
};

export { ChangePlanModal as PureChangePlanModal };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ChangePlanModal);
