// @flow
import Authenticator from '@setapp/customer-authenticator';
import TokenManager from '@setapp/auth-tokens-manager';
import SignUpMetadataManager from '@setapp/signup-metadata';
import { membershipApiRootV1 } from 'config/api';
import { COOKIES_ROOT_DOMAIN } from 'config/app';
import * as authConfig from 'config/auth';


const singletons = {};

export const getTokenManager = () => {
  if (!singletons.tokenManager) {
    singletons.tokenManager = new TokenManager({
      cookiesDomain: COOKIES_ROOT_DOMAIN,
      secureCookies: authConfig.SECURE_COOKIES,
      rememberPeriod: authConfig.REMEMBER_ME_PERIOD,
      accessTokenName: authConfig.ACCESS_TOKEN_COOKIE,
      refreshTokenName: authConfig.REFRESH_TOKEN_COOKIE,
    });
  }

  return singletons.tokenManager;
};

export const getAuthenticator = () => {
  if (!singletons.authenticator) {
    singletons.authenticator = new Authenticator({
      apiRoot: `${membershipApiRootV1}/teams`,
      cookiesDomain: COOKIES_ROOT_DOMAIN,
      tokenManager: getTokenManager(),
    });
  }

  return singletons.authenticator;
};

export const getMemberAuthenticator = () => {
  if (!singletons.authenticator) {
    singletons.authenticator = new Authenticator({
      apiRoot: `${membershipApiRootV1}/teams/member`,
      cookiesDomain: COOKIES_ROOT_DOMAIN,
      tokenManager: getTokenManager(),
    });
  }

  return singletons.authenticator;
};

export const getSignupMetadata = () => {
  if (!singletons.signupMetadata) {
    singletons.signupMetadata = new SignUpMetadataManager({
      cookiesDomain: COOKIES_ROOT_DOMAIN,
    });
  }

  return singletons.signupMetadata;
};
