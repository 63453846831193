import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { intlShape, injectIntl } from 'react-intl';

import { FormField } from '@setapp/ui-kit';
import messages from '../business-account-info-messages';

const PaymentMethodFormVatContainer = ({
  isLoading, onFieldChange, paymentInfo, fieldsWithError, intl,
}) => {
  const {
    vatId, company, streetName, addressDetail, buildingNumber, city, postalCode,
  } = paymentInfo;
  const {
    vatId: vatIdError,
    company: companyError,
    streetName: streetNameError,
    addressDetail: addressDetailError,
    buildingNumber: buildingNumberError,
    city: cityError,
    postalCode: postalCodeError,
  } = fieldsWithError;

  return (
    <div>
      <Row>
        <Col sm={7}>
          <FormField
            id="vat-company"
            name="company"
            invalid={Boolean(companyError)}
            helpText={companyError}
            label={intl.formatMessage(messages.company)}
            showLabel
            disabled={isLoading}
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            value={company}
          />
        </Col>

        <Col sm={5}>
          <FormField
            id="vat-number"
            name="vatId"
            label={intl.formatMessage(messages.vatNumber)}
            showLabel
            invalid={Boolean(vatIdError)}
            helpText={vatIdError}
            disabled={isLoading}
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            value={vatId}
          />
        </Col>
      </Row>

      <FormField
        id="vat-street"
        name="streetName"
        invalid={Boolean(streetNameError)}
        helpText={streetNameError}
        label={intl.formatMessage(messages.street)}
        showLabel
        disabled={isLoading}
        onChange={(e) => onFieldChange(e.target.name, e.target.value)}
        value={streetName}
      />

      <Row>
        <Col sm={7}>
          <FormField
            id="vat-address"
            name="addressDetail"
            invalid={Boolean(addressDetailError)}
            helpText={addressDetailError}
            label={intl.formatMessage(messages.address)}
            showLabel
            disabled={isLoading}
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            value={addressDetail}
          />
        </Col>

        <Col sm={5}>
          <FormField
            id="vat-building"
            name="buildingNumber"
            invalid={Boolean(buildingNumberError)}
            helpText={buildingNumberError}
            label={intl.formatMessage(messages.building)}
            showLabel
            disabled={isLoading}
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            value={buildingNumber}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={7}>
          <FormField
            id="vat-city"
            name="city"
            invalid={Boolean(cityError)}
            helpText={cityError}
            label={intl.formatMessage(messages.city)}
            showLabel
            disabled={isLoading}
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            value={city}
          />
        </Col>

        <Col sm={5}>
          <FormField
            id="vat-postal"
            name="postalCode"
            invalid={Boolean(postalCodeError)}
            helpText={postalCodeError}
            label={intl.formatMessage(messages.postalCode)}
            showLabel
            disabled={isLoading}
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            value={postalCode}
          />
        </Col>
      </Row>
    </div>
  );
};

PaymentMethodFormVatContainer.propTypes = {
  fieldsWithError: PropTypes.shape({
    vatId: PropTypes.string,
    company: PropTypes.string,
    streetName: PropTypes.string,
    addressDetail: PropTypes.string,
    buildingNumber: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
  paymentInfo: PropTypes.shape({
    vatId: PropTypes.string,
    company: PropTypes.string,
    streetName: PropTypes.string,
    addressDetail: PropTypes.string,
    buildingNumber: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export { PaymentMethodFormVatContainer as PurePaymentMethodFormVatContainer };

export default injectIntl(PaymentMethodFormVatContainer);
