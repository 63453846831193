import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import classnames from 'classnames';
import { FormField, Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';
import PasswordRequirementsWrapper from 'components/shared/password-requirements-wrapper/password-requirements-wrapper';
import { validators } from 'utils/auth-validation';

import letterIcon from './images/letter.svg';

import './signup-member-form.scss';

const messages = defineMessages({
  passwordPlaceholder: {
    id: 'outer.signupMember.passwordPlaceholder',
    defaultMessage: 'Password',
  },
});

class SignUpMemberForm extends PureComponent {
  state = {
    isPasswordHintTooltipShown: false,
  }

  render() {
    const {
      onFieldChange,
      onFormSubmit,
      password,
      passwordError,
      termsAcceptedError,
      genericError,
      isRequestProcessing,
      isPasswordInvalid,
      intl
    } = this.props;

    const {
      isPasswordHintTooltipShown,
    } = this.state;

    const errorClasses = classnames('form-error text-danger', {
      hidden: !genericError,
    });
    const btnClasses = classnames({
      processing: isRequestProcessing,
    });

    return (
      <form onSubmit={onFormSubmit} noValidate data-qa="signUpForm">
        <div className="signup-member-form__password-group">
          <PasswordRequirementsWrapper
            isShown={isPasswordHintTooltipShown || isPasswordInvalid}
            isErrorsHighlighted={isPasswordInvalid}
            isMinimumLengthUsed={validators.passwordMatchMinLength(password)}
            isLowercaseUsed={validators.passwordContainsLower(password)}
            isUppercaseUsed={validators.passwordContainsUpper(password)}
          >
            <FormField
              id="password"
              name="password"
              type="password"
              label={<FormattedMessage id="outer.signupMember.passwordLabel" defaultMessage="Password" />}
              placeholder={intl.formatMessage(messages.passwordPlaceholder)}
              helpText={passwordError}
              invalid={Boolean(passwordError)}
              value={password}
              onChange={onFieldChange}
              qaLabel="passwordFormGroup"
              onFocus={this.showPasswordHintPopup}
              onBlur={this.hidePasswordHintPopup}
            />
          </PasswordRequirementsWrapper>
        </div>
        <div className="signup-member-form__banner text_sm">
          <img className="mr-4" src={letterIcon} alt="Letter" />
          <FormattedMessage
            id="outer.signupMember.banner"
            defaultMessage="55% of Setappians find new apps from our emails: “So many helpful tools and tips & tricks, thanks!”"
          />
        </div>
        <FormGroup>
          <Checkbox name="marketingSubscribed" onChange={onFieldChange}>
            <span className="signup-form__note">
              <FormattedMessage
                id="outer.signupMember.subscribeCaption"
                defaultMessage="I’d like to get pro tips for Mac and iOS apps and news."
              />
            </span>
          </Checkbox>
        </FormGroup>
        <FormGroup validationState={termsAcceptedError ? 'error' : 'success'}>
          <Checkbox name="termsAccepted" onChange={onFieldChange}>
            <span className="signup-form__note">
              <FormattedMessage
                id="outer.signupMember.acceptPolicyCaption"
                defaultMessage="Yes, I accept the {termsOfService}, {privacyPolicy}, and {cookiePolicy}."
                values={{
                  termsOfService: (
                    <a href={externalUrls.termsOfUse} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage
                        id="outer.signupMember.termsOfUseLinkLabel"
                        defaultMessage="Terms&nbsp;of&nbsp;Service"
                      />
                    </a>
                  ),
                  privacyPolicy: (
                    <a href={externalUrls.privacy} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage
                        id="outer.signupMember.privacyPolicyLinkLabel"
                        defaultMessage="Privacy&nbsp;Policy"
                      />
                    </a>
                  ),
                  cookiePolicy: (
                    <a href={externalUrls.cookiePolicy} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage
                        id="outer.signupMember.cookiePolicyLinkLabel"
                        defaultMessage="Cookie&nbsp;Policy"
                      />
                    </a>
                  ),
                }}
              />
            </span>
          </Checkbox>
          {termsAcceptedError && <HelpBlock>{termsAcceptedError}</HelpBlock>}
        </FormGroup>
        <p className={errorClasses}>{genericError}</p>
        <Button
          className={btnClasses}
          type="submit"
          disabled={isRequestProcessing}
          block
        >
          <FormattedMessage id="outer.signupMember.submitBtnCaption" defaultMessage="Join the team" />
        </Button>
      </form>
    );
  }

  showPasswordHintPopup = () => {
    this.setState({ isPasswordHintTooltipShown: true });
  }

  hidePasswordHintPopup = () => {
    this.setState({ isPasswordHintTooltipShown: false });
  }
}

SignUpMemberForm.propTypes = {
  isRequestProcessing: PropTypes.bool,
  isPasswordInvalid: PropTypes.bool,
  password: PropTypes.string,
  passwordError: PropTypes.node,
  termsAcceptedError: PropTypes.node,
  genericError: PropTypes.string,
  intl: intlShape.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

SignUpMemberForm.defaultProps = {
  isRequestProcessing: false,
  isPasswordInvalid: false,
  password: '',
  passwordError: null,
  termsAcceptedError: null,
  genericError: '',
};

export { SignUpMemberForm as PureSignUpMemberForm };

export default injectIntl(SignUpMemberForm);
