// @flow

import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import * as subscriptionStatuses from 'state/subscription/statuses';
import { getTotalAmountForCustomers } from 'state/root-reducer';
import VatNoteRow from 'components/shared/vat-note-row/vat-note-row';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import ActiveMembersRow from '../active-members-row/active-members-row';


type Props = {|
  price: number,
  currency: string,
  paidMonth: number,
  nextPaymentDate: ?number,
  expirationDate: ?number,
  tax: number,
  activeMembers: Array<TeamMember>,
  paidMonth: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

class SubscriptionNew extends PureComponent<Props> {
  render() {
    const {
      price,
      currency,
      paidMonth,
      nextPaymentDate,
      pricePerMemberPerMonth,
      expirationDate,
      tax,
      activeMembers,
    } = this.props;

    return (
      <Fragment>
        <StatusRow
          status={subscriptionStatuses.NEW}
          expirationDate={expirationDate}
          nextPaymentDate={nextPaymentDate}
        />
        <ActiveMembersRow activeMembers={activeMembers} />

        <PlanRow paidMonth={paidMonth} pricePerMemberPerMonth={pricePerMemberPerMonth} />

        <PriceRow price={price} currency={currency} paidMonth={paidMonth} tax={tax} />

        {tax === 0 && <VatNoteRow />}
      </Fragment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state, { nextPricePlan }) => ({
  nextPriceSum: getTotalAmountForCustomers(state, nextPricePlan),
});

export { SubscriptionNew as PureSubscriptionNew };

export default connect(mapStateToProps)(SubscriptionNew);
