// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  isOwner: boolean,
  onClose: () => mixed,
  onUpdatePaymentDetails?: () => mixed,
}

const Title = (
  <FormattedMessage
    id="expiredPaymentCard.title"
    defaultMessage="Your card has expired"
  />
);

const ExpiredPaymentCard = (props: Props) => {
  const { isOwner, onUpdatePaymentDetails, onClose } = props;

  if (isOwner) {
    return (
      <>
        <div className="mt-8 mb-8" data-qa="dialogDescription">
          <FormattedMessage
            id="expiredPaymentCard.owner.description"
            defaultMessage="Please update your payment details or link a new card."
          />
        </div>
        <Button
          block
          onClick={onUpdatePaymentDetails}
        >
          <FormattedMessage
            id="expiredPaymentCard.owner.ctaButtonTitle"
            defaultMessage="Update details"
          />
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="mt-8 mb-8" data-qa="dialogDescription">
        <FormattedMessage
          id="expiredPaymentCard.admin.description"
          defaultMessage="Please ask your team owner to update the payment details or link a new card."
        />
      </div>
      <Button
        block
        onClick={onClose}
      >
        <FormattedMessage
          id="expiredPaymentCard.admin.closeButtonTitle"
          defaultMessage="Ok, got it"
        />
      </Button>
    </>
  );
};

ExpiredPaymentCard.Title = Title;

ExpiredPaymentCard.defaultProps = {
  onUpdatePaymentDetails: undefined,
};

export default ExpiredPaymentCard;
