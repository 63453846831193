// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionStep from '../action-card/action-card';

import paymentIcon from './images/icon-payment.svg';

type Props = {
  onCtaClick: () => mixed,
};

const AddPaymentStep = ({ onCtaClick }: Props) => (
  <ActionStep
    icon={paymentIcon}
    onCtaClick={onCtaClick}
    title={(
      <FormattedMessage
        id="dashboard.addPaymentStep.title"
        defaultMessage="3. Add payment details"
      />
    )}
    description={(
      <FormattedMessage
        id="dashboard.addPaymentStep.description"
        defaultMessage="Add billing data for fast subscribing after your trial ends. You can cancel anytime."
      />
    )}
    ctaCaption={(
      <FormattedMessage
        id="dashboard.addPaymentStep.ctaCaption"
        defaultMessage="Add"
      />
    )}
  />
);

export default AddPaymentStep;
