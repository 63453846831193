// @flow

import React from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';

import messages from 'components/shared/braintree-payment-form/business-account-info-messages';
import InfoButtonWithTooltip from 'components/shared/info-button/info-button-with-tooltip';

import type { InvoicingInformation } from 'state/payment-method/payment-method-initial-state';

import './business-account-details.scss';

type Props = {
  data: InvoicingInformation,
  intl: IntlShape,
};

const BusinessAccountDetails = (props: Props) => {
  const {
    data: {
      vatId, company, streetName, addressDetail, buildingNumber, city, postalCode,
    }, intl,
  } = props;

  const data = [
    { title: intl.formatMessage(messages.vatNumber), value: vatId },
    { title: intl.formatMessage(messages.company), value: company },
    { title: intl.formatMessage(messages.street), value: streetName },
    { title: intl.formatMessage(messages.address), value: addressDetail },
    { title: intl.formatMessage(messages.building), value: buildingNumber },
    { title: intl.formatMessage(messages.city), value: city },
    { title: intl.formatMessage(messages.postalCode), value: postalCode },
  ].filter((item) => item.value);

  if (!data.length) {
    return null;
  }

  return (
    <div className="business-account-details">
      <span className="business-account-details__note text_sm">
        <FormattedMessage
          id="paymentDetails.businessAccountDetails.title"
          defaultMessage="Invoicing info included"
        />
      </span>
      <InfoButtonWithTooltip>
        {data.map((item) => (
          <div className="business-account-details__item text_sm" key={item.title}>
            <div className="business-account-details__title">
              {item.title}
              :
            </div>
            <div className="business-account-details__value">{item.value}</div>
          </div>
        ))}
      </InfoButtonWithTooltip>
    </div>
  );
};

export { BusinessAccountDetails as PureBusinessAccountDetails };

export default injectIntl(BusinessAccountDetails);
