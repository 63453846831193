// @flow

import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import Script from 'react-load-script';
import classNames from 'classnames';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import DefaultError from 'components/shared/default-error/default-error';
import PaddleVerified from 'components/shared/paddle-verified/paddle-verified';

import { getUser, getPaymentMethod } from 'state/root-reducer';
import { createPaddlePaymentDetails, updatePaddlePaymentDetails } from 'state/payment-method/payment-method-actions';
import type {
  PaymentDetailsPayload,
  UpdatePaymentDetailsPayload,
} from 'utils/api/paddle-payment-details-service';
import type { PaymentDetailsState } from 'state/payment-method/payment-method-initial-state';

import PaddleConfigService, { type PaddleFormConfig } from 'utils/api/paddle-config-service';
import analytics, { events } from 'utils/analytics';
import healthMetrics from 'utils/health-metrics';

import UpdateBillingInfoNote from './update-billing-info-note/update-billing-info-note';
import trackPaddleEvent from './paddle-checkout-events-tracker';


import './paddle-payment-form.scss';

const PADDLE_SDK_URL = 'https://cdn.paddle.com/paddle/paddle.js';

type Props = {|
  userLocale: string,
  userEmail: string,
  onSuccessSubmit: () => mixed,
  createPaymentDetails: (PaymentDetailsPayload) => Promise<mixed>,
  updatePaymentDetails: (UpdatePaymentDetailsPayload) => Promise<mixed>,
  isPaymentDetailsLoading: boolean,
  paymentMethod: PaymentDetailsState,
  footer?: Node,
  updateBillingNote?: Node,
|};

type State = {|
  formConfig: ?PaddleFormConfig,
  paddleUILoaded: boolean,
  formError: Node,
  paddleFormLoadingTimeStart: ?number,
|};

// Only fields used by our logic are described
type PaddleCheckoutData = {
  checkout: {
    id: string,
  },
};

class PaddlePaymentForm extends Component<Props, State> {
  state = {
    formConfig: null,
    paddleUILoaded: false,
    formError: null,
    paddleFormLoadingTimeStart: null,
  };

  paddleConfigService: PaddleConfigService;

  static defaultProps = {
    footer: null,
    updateBillingNote: null,
  };

  constructor(props: Props) {
    super(props);

    this.paddleConfigService = new PaddleConfigService();
  }

  componentDidMount() {
    this.sendFormDisplayedAnalyticsEvent();
    this.setState({ paddleFormLoadingTimeStart: new Date().getTime() });
    this.fetchFormConfig();
  }

  componentDidUpdate(prevProps: Props, prevState: State): * {
    const { paddleUILoaded: prevPaddleUILoaded } = prevState;
    const { paddleUILoaded } = this.state;

    if (!prevPaddleUILoaded && paddleUILoaded) {
      this.sendFormLoadedTimeEvent();
    }
  }

  render() {
    const {
      isPaymentDetailsLoading,
      paymentMethod,
      footer,
      updateBillingNote,
    } = this.props;
    const { formConfig, paddleUILoaded, formError } = this.state;
    const isDataLoading = !paddleUILoaded || isPaymentDetailsLoading;
    const showUpdateBillingNote = !isDataLoading && paymentMethod.data;

    const containerClasses = classNames([
      'paddle-form__container',
      { 'paddle-form__container_hidden': isDataLoading },
    ]);

    return (
      <div className="paddle-form">
        {(isDataLoading) && (
          <div className="paddle-form__loading">
            <AnimatedLogo animate />
          </div>
        )}

        {showUpdateBillingNote && (
          updateBillingNote || <UpdateBillingInfoNote />
        )}

        <div className={containerClasses} />

        {footer || (
          <div className="paddle-form__verified-container">
            <PaddleVerified />
          </div>
        )}

        {formError && (
          <div className="mt-3 text-danger">
            {formError}
          </div>
        )}

        {formConfig && (
          <Script
            url={PADDLE_SDK_URL}
            onLoad={this.handlePaddleScriptLoaded}
            onError={this.handlePaddleScriptLoadingError}
          />
        )}
      </div>
    );
  }

  sendFormLoadedTimeEvent = () => {
    const { paddleFormLoadingTimeStart } = this.state;
    const now = new Date().getTime();

    if (!paddleFormLoadingTimeStart) {
      return;
    }

    const formLoadingDuration = now - paddleFormLoadingTimeStart;

    healthMetrics.trackTiming({ name: 'paddle_form_load_time', value: formLoadingDuration });
  };

  handlePaddleScriptLoaded = () => {
    this.initPaddleSDK();
    this.openPaddleCheckout();
  };

  handlePaddleScriptLoadingError = () => {
    this.showFormLoadingError();
  };

  handlePaddleUILoaded = () => {
    this.setState({ paddleUILoaded: true });
  };

  handlePaddleCheckoutComplete = (checkoutData: PaddleCheckoutData) => {
    const {
      onSuccessSubmit,
      createPaymentDetails,
      updatePaymentDetails,
      paymentMethod,
    } = this.props;

    const paymentDetailsPromise = paymentMethod.data
      ? updatePaymentDetails({ subscriptionId: checkoutData.checkout.id })
      : createPaymentDetails({ checkoutId: checkoutData.checkout.id });

    return paymentDetailsPromise
      .then(() => {
        if (onSuccessSubmit) {
          onSuccessSubmit();
        }
      })
      .catch((error) => {
        const [firstError] = error.getAll();

        this.setState({ formError: firstError.message });
      });
  };

  fetchFormConfig() {
    return this.paddleConfigService.fetchConfig()
      .then((config) => {
        this.setState({ formConfig: config });
      })
      .catch(() => {
        this.showFormLoadingError();
      });
  }

  initPaddleSDK() {
    const { formConfig } = this.state;

    if (!formConfig) {
      throw new Error('Form config is not fetched');
    }

    if (process.env.ENVIRONMENT !== 'production') {
      window.Paddle.Environment.set('sandbox');
    }

    window.Paddle.Setup({
      vendor: formConfig.vendorId,
      eventCallback: trackPaddleEvent,
    });
  }

  openPaddleCheckout() {
    const { userLocale, userEmail } = this.props;
    const { formConfig } = this.state;

    if (!formConfig) {
      throw new Error('Form config is not fetched');
    }

    const [paddleLocale] = userLocale.split('-');

    window.Paddle.Checkout.open({
      override: formConfig.checkoutUrl,
      method: 'inline',
      email: userEmail,
      locale: paddleLocale,
      frameTarget: 'paddle-form__container',
      frameInitialHeight: 366,
      frameStyle: 'width: 100%; min-width:312px; background-color: transparent; border: none;',
      loadCallback: this.handlePaddleUILoaded,
      successCallback: this.handlePaddleCheckoutComplete,
    });
  }

  showFormLoadingError() {
    this.setState({
      formError: <DefaultError />,
    });
  }

  sendFormDisplayedAnalyticsEvent() {
    analytics.trackEvent(events.PAYMENT_DETAILS_FORM_DISPLAYED, { eventLabel: 'Paddle' });
  }
}

export { PaddlePaymentForm as PurePaddlePaymentForm };

const mapActionsToProps = {
  createPaymentDetails: createPaddlePaymentDetails,
  updatePaymentDetails: updatePaddlePaymentDetails,
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
  const user = getUser(state);

  return {
    userLocale: user.locale,
    userEmail: user.email,
    paymentMethod: getPaymentMethod(state),
    isPaymentDetailsLoading: getPaymentMethod(state).isLoading,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(PaddlePaymentForm);
