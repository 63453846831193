// @flow

import React, { Fragment } from 'react';

import VatNoteRow from 'components/shared/vat-note-row/vat-note-row';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import PlanRow from '../plan-row/plan-row';
import StatusRow from '../status-row/status-row';
import PriceRow from '../price-row/price-row';
import ActiveMembersRow from '../active-members-row/active-members-row';

type Props = {|
  expirationDate: ?number,
  nextPaymentDate: ?number,
  currency: string,
  paidMonth: number,
  totalAmount: number,
  tax: number,
  activeMembers: Array<TeamMember>,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

const STATUS_CANCELED = 4;

const SubscriptionCancelled = (props: Props) => {
  const {
    expirationDate,
    nextPaymentDate,
    currency,
    paidMonth,
    pricePerMemberPerMonth,
    totalAmount,
    tax,
    activeMembers,
  } = props;

  return (
    <Fragment>
      <StatusRow
        status={STATUS_CANCELED}
        expirationDate={expirationDate}
        nextPaymentDate={nextPaymentDate}
      />

      <ActiveMembersRow activeMembers={activeMembers} />

      <PlanRow paidMonth={paidMonth} pricePerMemberPerMonth={pricePerMemberPerMonth} />

      <PriceRow paidMonth={paidMonth} currency={currency} price={totalAmount} tax={tax} />

      {tax === 0 && <VatNoteRow />}
    </Fragment>
  );
};

export default SubscriptionCancelled;
