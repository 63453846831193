// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import InContentNotification from 'components/shared/in-content-notification/in-content-notification';

const StatusBlockedNotification = () => (
  <InContentNotification type="danger">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.blocked"
      defaultMessage="Your subscription is paused. Renew it by adding valid payment details."
    />
  </InContentNotification>
);

export default StatusBlockedNotification;
