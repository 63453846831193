// @flow

import React from 'react';
import classnames from 'classnames';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import type { Node, Element } from 'react';
import type { IntlShape } from 'react-intl';

import BraintreeVerified from 'components/shared/braintree-verified/braintree-verified';

import type { BraintreePaymentDetailsPayload } from 'state/payment-method/payment-method-actions';

import PaymentMethodFormVatContainer from '../payment-method-form-vat-container/payment-method-form-vat-container';
import PaymentFormNote from '../payment-form-note/payment-form-note';

import BraintreeFormField from './braintree-field/braintree-field';
import fieldsMessages from './form-fields-messages';

import './credit-card-form.scss';

type Props = {
  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    [string]: Node,
  },
  onFieldChange: (string, string) => any,
  onFormSubmit: SyntheticEvent<HTMLFormElement> => any,
  isPaymentMethodCreated: boolean,
  paymentInfo: BraintreePaymentDetailsPayload,
  submitBtnTitle?: Node,
  intl: IntlShape,
  captcha: ?Element<any>,
};

const CreditCardForm = (props: Props) => {
  const {
      onFormSubmit,
      fieldsWithError,
      paymentInfo,
      isLoading,
      onFieldChange,
      formError,
      merchantId,
      isPaymentMethodCreated,
      intl,
      submitBtnTitle,
      captcha,
    } = props,
    errorClasses = classnames('form-error text-danger credit-card-form-error', {
      hidden: !formError,
    }),
    headerClasses = classnames('credit-card-form-header', {
      'credit-card-form-header-processing': (isLoading),
    }),
    {
      number: numberError,
      expirationDate: expirationDateError,
      cvv: cvvError,
    } = fieldsWithError;

  return (
    <form className="credit-card-form" onSubmit={onFormSubmit} noValidate>
      <h5 className={headerClasses}>
        <FormattedMessage id="creditCardForm.formTitle" defaultMessage="Credit card" />
      </h5>
      <input type="hidden" name="payment_method_nonce" />

      <BraintreeFormField
        id="number"
        name="number"
        invalid={Boolean(numberError)}
        helpText={numberError}
        disabled={isLoading}
        label={intl.formatMessage(fieldsMessages.cardNumber)}
        showLabel
      />

      <Row>
        <Col sm={6}>
          <BraintreeFormField
            id="expirationDate"
            name="expirationDate"
            invalid={Boolean(expirationDateError)}
            helpText={expirationDateError}
            disabled={isLoading}
            label={intl.formatMessage(fieldsMessages.cardExpirationDate)}
            placeholder={intl.formatMessage(fieldsMessages.cardExpirationPlaceholder)}
            showLabel
          />
        </Col>

        <Col sm={6}>
          <BraintreeFormField
            id="cvv"
            name="cvv"
            invalid={Boolean(cvvError)}
            helpText={cvvError}
            disabled={isLoading}
            label={intl.formatMessage(fieldsMessages.cardCvv)}
            showLabel
          />
        </Col>
      </Row>

      <PaymentMethodFormVatContainer
        fieldsWithError={fieldsWithError}
        paymentInfo={paymentInfo}
        isLoading={isLoading}
        onFieldChange={onFieldChange}
      />

      {captcha}

      <p className={errorClasses}>{formError}</p>

      <BraintreeVerified merchantId={merchantId} />

      <PaymentFormNote />

      <Button
        className="credit-card-form-submit-btn"
        type="submit"
        block
        disabled={isLoading}
      >
        {getBtnTitle()}
      </Button>
    </form>
  );

  function getBtnTitle() {
    if (submitBtnTitle) {
      return submitBtnTitle;
    }

    if (isPaymentMethodCreated) {
      return (
        <FormattedMessage
          id="paymentDetails.creditCard.submitBtnTitleUpdate"
          defaultMessage="Update payment details"
        />
      );
    }

    return <FormattedMessage id="paymentDetails.creditCard.submitBtnTitleAdd" defaultMessage="Add payment details" />;
  }
};

CreditCardForm.defaultProps = {
  submitBtnTitle: null,
};

export { CreditCardForm as PureCreditCardForm };

export default injectIntl(CreditCardForm);
