// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sentry: {
    dsn: 'https://741c8a436cfb467998bef519b8c96719@o36975.ingest.sentry.io/5285381',
    allowUrls: [
      `${window.location.host}${process.env.BASE_PATH}/static/js`,
    ],
    release: process.env.VERSION,
    environment: process.env.ENVIRONMENT,
    ignoreErrors: [
      'document.getElementsByClassName("docs-homescreen-gb-container")[0].style',
      'undefined is not an object (evaluating \'divPos.x.toString\')',
      'document.getElementById(\'adwired\').getBoundingClientRect',
      // Google Re-CAPTCHA: https://github.com/google/recaptcha/issues/269
      'Non-Error promise rejection captured with value: Timeout',
      // Some nasty Chrome extension
      'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
      // Looks like Evernote web clipper errors
      'There is no clipping info for given tab',
    ],
    tags: {
      commitHash: process.env.COMMIT_HASH || '',
    },
  },
};
