// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownItem } from '@setapp/ui-kit';
import TableToggle from 'components/shared/table-toggle/table-toggle';
import PermissionsAccessControl from 'components/shared/access-control/permissions-access-control';
import * as statuses from 'state/team-plan/team-member-statuses';
import * as roles from 'state/team-plan/team-member-roles';
import * as permissions from 'state/user/user-permissions';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import type { MemberRole } from 'state/team-plan/team-member-roles';

type Props = {
  member: TeamMember,
  onAddSeatToMemberAction: (TeamMember) => mixed,
  onRemoveSeatFromMemberAction: (TeamMember) => mixed,
  showRemoveMemberModal: (TeamMember) => mixed,
  revokeInvite: (TeamMember) => mixed,
  addMemberRole: (TeamMember, MemberRole) => mixed | Promise<mixed>,
  removeMemberRole: (TeamMember) => mixed | Promise<mixed>,
};

const ManageMemberDropdown = ({
  member,
  onAddSeatToMemberAction,
  onRemoveSeatFromMemberAction,
  showRemoveMemberModal,
  revokeInvite,
  addMemberRole,
  removeMemberRole,
}: Props) => {
  /**
   * Owner can't be an Admin and Admin/Owner can share Admin role only with Active member
   */
  const isAddMemberRoleButtonAvailable = member.role !== roles.TEAM_OWNER && member.status === statuses.ACTIVE;

  /**
   * Remove Member Button doesn't available for the current user and Owner can't be removed
   */
  const isRemoveMemberButtonAvailable = !(member.isCurrentUser || member.isOwner);

  return (
    <Dropdown
      id="manageMemberDropdown"
      positionRight
      toggleElement={
        <TableToggle>
          <FormattedMessage id="teamMemberActions.manageToggle" defaultMessage="Manage" />
        </TableToggle>
      }
    >
      <PermissionsAccessControl
        perform={[permissions.TEAM_MEMBER_ADDITIONAL_SEAT_ADD, permissions.TEAM_ADDITIONAL_SEAT_PURCHASE]}
      >
        {member.status === statuses.ACTIVE && (
          <DropdownItem
            onClick={() => onAddSeatToMemberAction(member)}
            data-qa="addSeatToMemberButton"
          >
            <FormattedMessage id="teamMemberActions.addDeviceButtonTitle" defaultMessage="Add device" />
          </DropdownItem>
        )}
      </PermissionsAccessControl>
      <PermissionsAccessControl perform={permissions.TEAM_MEMBER_ADDITIONAL_SEAT_REMOVE}>
        {member.additionalDevicesCount >= 1 && (
          <DropdownItem
            onClick={() => onRemoveSeatFromMemberAction(member)}
            data-qa="removeDeviceButton"
          >
            <FormattedMessage id="teamMemberActions.removeDeviceButton" defaultMessage="Remove device" />
          </DropdownItem>
        )}
      </PermissionsAccessControl>
      <PermissionsAccessControl perform={permissions.TEAM_ROLE_SHARE_TEAM_MANAGEMENT}>
        {isAddMemberRoleButtonAvailable && (
          member.role === null ? (
            <DropdownItem
              onClick={() => addMemberRole(member, roles.TEAM_MANAGER)}
              data-qa="addMemberRoleButton"
            >
              <FormattedMessage id="teamMemberActions.shareRoleButton" defaultMessage="Share admin role" />
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={() => removeMemberRole(member)}
              data-qa="removeMemberRoleButton"
            >
              <FormattedMessage id="teamMemberActions.removeRoleButton" defaultMessage="Remove admin role" />
            </DropdownItem>
          )
        )}
      </PermissionsAccessControl>
      <PermissionsAccessControl perform={permissions.TEAM_MEMBER_REMOVE}>
        {isRemoveMemberButtonAvailable && (
          member.status === statuses.ACTIVE ? (
            <div>
              <DropdownItem divider />
              <DropdownItem
                data-qa="removeMemberButton"
                onClick={() => showRemoveMemberModal(member)}
                danger
              >
                <FormattedMessage id="teamMemberActions.removeMemberButton" defaultMessage="Remove member" />
              </DropdownItem>
            </div>
          ) : (
            <DropdownItem
              data-qa="revokeInviteButton"
              onClick={() => revokeInvite(member)}
              danger
            >
              <FormattedMessage id="teamMemberActions.revokeInviteButton" defaultMessage="Revoke invite" />
            </DropdownItem>
          )
        )}
      </PermissionsAccessControl>
    </Dropdown>
  );
};

export default ManageMemberDropdown;
