// @flow

import { type InvoiceStatus } from './invoice-statuses';

export type Invoice = {|
  id: string,
  paymentDate: number,
  currency: string,
  amount: number,
  taxAmount: number,
  status: InvoiceStatus,
|};

export type InvoiceState = {
  +list: Array<Invoice>,
  +isLoading: boolean,
}

const initialState: InvoiceState = {
  list: [],
  isLoading: false,
};

export default initialState;
