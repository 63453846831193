// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  onConfirm(): mixed,
  onClose(): mixed,
  isOwner: boolean,
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
}

const PurchaseSeatsActivateSubscription = (props: Props) => {
  const {
    pricePerMonthPerMember,
    isOwner,
    onConfirm,
    onClose,
  } = props;

  if (isOwner) {
    return (
      <>
        <div className="mt-8 mb-8" data-qa="dialogDescription">
          <FormattedMessage
            id="purchaseSeats.activateSubscription.owner.description"
            defaultMessage="You’ve reached the limit of invitations on a free trial. Activate your Setapp membership and invite new members for {pricePerMember}/mo each."
            values={{
              pricePerMember: (
                <FormattedPrice
                  price={pricePerMonthPerMember.price}
                  currency={pricePerMonthPerMember.currency}
                />
              ),
            }}
          />
        </div>
        <Button
          block
          onClick={onConfirm}
        >
          <FormattedMessage
            id="purchaseSeats.activateSubscription.owner.confirmButtonTitle"
            defaultMessage="Activate membership"
          />
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="mt-8 mb-8" data-qa="dialogDescription">
        <FormattedMessage
          id="purchaseSeats.activateSubscription.admin.description"
          defaultMessage="Please ask your team owner to activate your Setapp membership first."
        />
      </div>
      <Button block onClick={onClose}>
        <FormattedMessage
          id="purchaseSeats.activateSubscription.admin.confirmButtonTitle"
          defaultMessage="Ok, got it"
        />
      </Button>
    </>
  );
};

export default PurchaseSeatsActivateSubscription;
