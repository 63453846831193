// @flow

import React from 'react';
import type { Node, ElementConfig } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormFieldWrapper } from '@setapp/ui-kit';

import './braintree-field.scss';

const VALID_FIELD_NAMES = ['number', 'cvv', 'expirationDate'];

type Props = {|
  id: string,
  label: string,
  placeholder?: string,
  name: 'number' | 'cvv' | 'expirationDate',
  disabled?: boolean,
  errorMessage?: Node,
  ...ElementConfig<typeof FormFieldWrapper>,
|};

type Context = {
  brainTreeFields: {
    focusedField: 'number' | 'cvv' | 'expirationDate',
    isInitialized: boolean,
  },
};

const BraintreeFormField = (props: Props, context: Context) => {
  const {
    id, placeholder, disabled, name, ...wrapperProps
  } = props;
  const { brainTreeFields: { isInitialized, focusedField } } = context;

  const fieldClasses = classNames(
    'form-control braintree-field',
    { 'braintree-field_disabled': disabled },
    { 'braintree-field_focus': focusedField === name },
  );

  return (
    <FormFieldWrapper {...wrapperProps} id={id}>
      <div id={id} className={fieldClasses}>
        {placeholder && !isInitialized && <span>{placeholder}</span>}
      </div>
    </FormFieldWrapper>
  );
};

BraintreeFormField.defaultProps = {
  disabled: false,
  errorMessage: '',
  placeholder: '',
};

BraintreeFormField.contextTypes = {
  brainTreeFields: PropTypes.shape({
    focusedField: PropTypes.oneOf(VALID_FIELD_NAMES),
    isInitialized: PropTypes.bool.isRequired,
  }).isRequired,
};

export default BraintreeFormField;
