// @flow

import React, { PureComponent } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Portal } from 'react-overlays';

import { detect } from 'utils/browser';

import Balloon from './balloon/balloon';
import AutoDownload from './auto-download/auto-download';
import defaultIcon from './images/icon-default.svg';
import downloadSafariIcon from './images/icon-download-safari.svg';
import downloadFirefoxIcon from './images/icon-download-firefox.svg';

import './auto-download-section.scss';

const balloonIconsSet = {
  chrome: defaultIcon,
  safari: downloadSafariIcon,
  firefox: downloadFirefoxIcon,
};

type Props = {|
  customUrl: ?string,
  onStart: ?() => mixed,
|};

type State = {|
  isBalloonShown: boolean,
  browser: string,
|};

class AutoDownloadSection extends PureComponent<Props, State> {
  static defaultProps = {
    customUrl: null,
    onStart: null,
  };

  state = {
    isBalloonShown: false,
    browser: detect().name,
  };

  render() {
    const { browser, isBalloonShown } = this.state;
    const isBrowserWithBalloon = Boolean(balloonIconsSet[browser]);
    const { customUrl } = this.props;

    const tailPosition = browser === 'chrome' ? 'bottomLeft' : 'topRight';

    return (
      <div>
        <AutoDownload customUrl={customUrl} onAutoDownloadStart={this.onAutoDownloadStart} />

        {isBrowserWithBalloon && (
          <Portal>
            <div
              className={`auto-download-section auto-download-section-${browser}`}
            >
              <Balloon
                position={browser === 'chrome' ? 'bottom' : 'top'}
                tailPosition={tailPosition}
                onClose={this.closeBalloon}
                show={isBalloonShown}
              >
                <div className="auto-download-content">
                  <img
                    src={balloonIconsSet[browser]}
                    className={`auto-download-icon auto-download-icon-${browser}`}
                    alt=""
                  />
                  <p>
                    <FormattedHTMLMessage
                      id="successfulRegistration.balloonText"
                      defaultMessage="Your download is right here.</br>Go ahead and launch it."
                    />
                  </p>
                </div>
              </Balloon>
            </div>
          </Portal>
        )}
      </div>
    );
  }

  onAutoDownloadStart = () => {
    const { onStart } = this.props;

    if (onStart) {
      onStart();
    }

    this.setState({ isBalloonShown: true });
  };

  closeBalloon = () => {
    this.setState({ isBalloonShown: false });
  };
}

export default AutoDownloadSection;
