// @flow

import PropTypes from 'prop-types';
import React from 'react';
import {
  defineMessages,
  injectIntl,
  type IntlShape,
} from 'react-intl';
import classNames from 'classnames';

import type { Application } from 'state/apps/apps-initial-state';

import TeamExclusiveInfo from 'components/shared/team-exclusive-info/team-exclusive-info';

import KeyCode from 'utils/key-codes';

import AppVisibilityStatus from './app-visibility-status/app-visibility-status';


import './apps-list-item.scss';

const messages = defineMessages({
  appIconAltText: {
    id: 'appsList.app.icon.altText',
    defaultMessage: 'App icon',
  },
});

type Props = {|
  app: Application,
  onAppClick: (app: Application) => void,
  isAppsProcessing: boolean,
  intl: IntlShape,
|}

type Context = {|
  isMobile: boolean,
|}

const AppsListItem = (props: Props, { isMobile }: Context) => {
  const {
    app,
    intl,
    onAppClick,
    isAppsProcessing,
  } = props;

  const appStatusClasses = classNames(
    'apps-list-item__status',
    { 'apps-list-item__status_hidden': !isMobile }
  );
  const appClasses = classNames(
    'apps-list-item__app',
    { 'apps-list-item__app_disabled': isAppsProcessing }
  );

  const renderTeamExclusiveIcon = () => (
    <span className="apps-list-item__exclusive">
      <TeamExclusiveInfo />
    </span>
  );

  return (
    <div
      className={appClasses}
      role="button"
      tabIndex={0}
      onClick={() => onAppClick(app)}
      onKeyUp={(event) => {
        // Coding for the keyboard is important for users with physical disabilities who cannot use a mouse.
        if (event.keyCode === KeyCode.ENTER) {
          onAppClick(app);
        }
      }}
    >
      <div className="apps-list-item__icon">
        <img src={app.icon} alt={intl.formatMessage(messages.appIconAltText)} width={92} height={92} />
      </div>
      <div className="apps-list-item__content">
        <div className="apps-list-item__title h5">
          {app.exclusive === 'team' && renderTeamExclusiveIcon()}
          {app.name}
        </div>
        <p className="apps-list-item__description">{app.shortDescription}</p>
        <div className={appStatusClasses}>
          <AppVisibilityStatus isRestricted={app.isRestricted} />
        </div>
      </div>
    </div>
  );
};

AppsListItem.contextTypes = {
  isMobile: PropTypes.bool,
};

export { AppsListItem as PureAppsListItem };

export default injectIntl(AppsListItem);
