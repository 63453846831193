// @flow

import React, { Fragment } from 'react';

import VatNoteRow from 'components/shared/vat-note-row/vat-note-row';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import ActiveMembersRow from '../active-members-row/active-members-row';

type Props = {|
  price: number,
  currency: string,
  paidMonth: number,
  nextPaymentDate: ?number,
  expirationDate: ?number,
  tax: number,
  activeMembers: Array<TeamMember>,
  paidMonth: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

const STATUS_GRACE = 5;

const SubscriptionGrace = (props: Props) => {
  const {
    price,
    currency,
    paidMonth,
    nextPaymentDate,
    pricePerMemberPerMonth,
    expirationDate,
    tax,
    activeMembers,
  } = props;

  return (
    <Fragment>
      <StatusRow
        status={STATUS_GRACE}
        expirationDate={expirationDate}
        nextPaymentDate={nextPaymentDate}
      />

      <ActiveMembersRow activeMembers={activeMembers} />

      <PlanRow paidMonth={paidMonth} pricePerMemberPerMonth={pricePerMemberPerMonth} />

      <PriceRow price={price} currency={currency} paidMonth={paidMonth} tax={tax} />

      {tax === 0 && <VatNoteRow />}
    </Fragment>
  );
};

export default SubscriptionGrace;
