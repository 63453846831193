// @flow

import React from 'react';
import formatDate from 'date-fns/format';

import paymentMethodIcons from 'components/shared/payment-method/payment-method-icons';

import PaymentMethodPresentation from '../payment-method-presentation/payment-method-presentation';

type Props = {
  cardType: | 'americanexpress'
    | 'dinnersclub'
    | 'discover'
    | 'jcb'
    | 'maestro'
    | 'mastercard'
    | 'visa'
    | 'payunion',
  cardLast4: string,
  cardExpM: number,
  cardExpY: number,
};

const CreditCardInfo = (props: Props) => {
  const {
    cardType, cardLast4, cardExpY, cardExpM,
  } = props;
  const cardIcon = paymentMethodIcons[cardType] ? paymentMethodIcons[cardType]() : paymentMethodIcons.default();
  const expires = formatDate(new Date(cardExpY, cardExpM - 1), 'MM/yyyy');

  return (
    <PaymentMethodPresentation
      details={`**** **** **** ${cardLast4}`}
      expires={expires}
      logo={cardIcon}
    />
  );
};

export default CreditCardInfo;
