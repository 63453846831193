// @flow

import type { PricePlanKey } from './price-plans-keys';

export type PricePlan = {
  +id: number,
  +paidMonth: number,
  +price: number,
  +priceKey: PricePlanKey,
  +currency: string,
  +active: boolean,
  +group: string,
  +seatsCount: number,
  +maxSeatsCount: number,
  +groupMembersCount: number,
};

export type PricePlanState = {
  +list: Array<PricePlan>,
  +isLoading: boolean,
}

const initialState: PricePlanState = {
  list: [],
  isLoading: false,
};

export default initialState;
