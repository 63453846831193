// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import TrialAdminLimit from './trial-admin-limit/trial-admin-limit';
import TrialOwnerLimit from './trial-owner-limit/trial-owner-limit';
import PaidLimit from './paid-limit/paid-limit';

type Props = {
  isTrial: boolean,
  isOwner: boolean,
  onActivate(): mixed,
  onClose(): mixed,
  onPurchase(): mixed,
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
}

const Title = <FormattedMessage id="inviteMemberForm.invitationsLimit.title" defaultMessage="Invitations limit" />;

const InvitationsLimit = (props: Props) => {
  const {
    isTrial,
    isOwner,
    onClose,
    onActivate,
    onPurchase,
    pricePerMonthPerMember,
  } = props;

  if (isOwner && isTrial) {
    return (
      <TrialOwnerLimit
        pricePerMonthPerMember={pricePerMonthPerMember}
        onClick={onActivate}
      />
    );
  }

  if (isTrial) {
    return <TrialAdminLimit onClick={onClose} />;
  }

  return (
    <PaidLimit
      pricePerMonthPerMember={pricePerMonthPerMember}
      onClick={onPurchase}
    />
  );
};

InvitationsLimit.Title = Title;

export default InvitationsLimit;
