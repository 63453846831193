// @flow

import React from 'react';
import { Button } from '@setapp/ui-kit';

import './back-button.scss';

type Props = {
  onClick: () => void,
}

const BackButton = ({ onClick }: Props) => (
  <div className="back-button">
    <Button variant="link" onClick={onClick}>
      <span className="back-button__content">
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12">
          <path d="M5.5 2l-4 4 4 4" stroke="#9D9CA2" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span className="ml-3">Back</span>
      </span>
    </Button>
  </div>
);

export default BackButton;
