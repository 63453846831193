// @flow

import React from 'react';
import classNames from 'classnames';
import type { Node } from 'react';

import './header-toggler.scss';

type Props = {
  children: Node,
  className: ?string,
};

const HeaderToggler = ({ children, className, ...props }: Props) => {
  const toggleClasses = classNames(
    'btn header-toggle text_sm',
    className,
  );

  return (
    <button
      {...props}
      type="button"
      className={toggleClasses}
    >
      {children}
    </button>
  );
};

HeaderToggler.defaultProps = {
  className: null,
};

export default HeaderToggler;
