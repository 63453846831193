// @flow

import React from 'react';
import { connect } from 'react-redux';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import {
  getPricePerMonthPerMember,
  getTeamMembers,
  getTotalAmountForCustomers,
} from 'state/root-reducer';

import SubscriptionTrial from './subscription-trial/subscription-trial';
import SubscriptionActive from './subscription-active/subscription-active';
import SubscriptionNew from './subscription-new/subscription-new';
import SubscriptionCancelled from './subscription-cancelled/subscription-cancelled';
import SubscriptionGrace from './subscription-grace/subscription-grace';
import SubscriptionBlocked from './subscription-blocked/subscription-blocked';
import SubscriptionFree from './subscription-free/subscription-free';

type Props = {|
  subscription: Subscription,
  isPaymentMethodCreated: boolean,
  activeMembers: Array<TeamMember>,
  members: Array<TeamMember>,
  totalAmount: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

const SubscriptionInfo = (props: Props) => {
  const {
    subscription,
    isPaymentMethodCreated,
    activeMembers,
    pricePerMemberPerMonth,
    members,
    totalAmount,
  } = props;
  const { pricePlan } = subscription;

  if (!pricePlan && !subscription.nextPricePlan) {
    return null;
  }

  const subscriptionInfoByStatus = {
    [subscriptionStatuses.TRIAL]: SubscriptionTrial,
    [subscriptionStatuses.ACTIVE]: SubscriptionActive,
    [subscriptionStatuses.CANCELLED]: SubscriptionCancelled,
    [subscriptionStatuses.NEW]: SubscriptionNew,
    [subscriptionStatuses.GRACE]: SubscriptionGrace,
    [subscriptionStatuses.BLOCKED]: SubscriptionBlocked,
    [subscriptionStatuses.FREE]: SubscriptionFree,
  };

  if (!subscription.status) {
    return null;
  }

  const SubscriptionInfoDetails = subscriptionInfoByStatus[subscription.status];

  if (!SubscriptionInfoDetails) {
    return null;
  }

  return (
    <SubscriptionInfoDetails
      tax={subscription.tax}
      status={subscription.status}
      expirationDate={subscription.expirationDate}
      nextPaymentDate={subscription.nextPaymentDate}
      priceKey={pricePlan.priceKey}
      price={pricePlan.price}
      currency={pricePlan.currency}
      paidMonth={pricePlan.paidMonth}
      groupMembersCount={pricePlan.groupMembersCount}
      members={members}
      activeMembers={activeMembers}
      isPaymentMethodCreated={isPaymentMethodCreated}
      nextPricePlan={subscription.nextPricePlan}
      pricePerMemberPerMonth={pricePerMemberPerMonth}
      totalAmount={totalAmount}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state, { priceKey, subscription }) => ({
  pricePerMemberPerMonth: getPricePerMonthPerMember(state, priceKey),
  members: getTeamMembers(state),
  totalAmount: getTotalAmountForCustomers(state, subscription.pricePlan),
});

export { SubscriptionInfo as PureSubscriptionInfo };

export default connect(mapStateToProps)(SubscriptionInfo);
