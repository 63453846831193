// @flow

import React from 'react';

type Props = {|
  title: any
|};

const OuterPageMessage = ({ title }: Props) => (
  <div style={{ margin: 'auto' }} className="h4 text-center">
    {title}
  </div>
);

export default OuterPageMessage;
