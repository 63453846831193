// @flow

import React from 'react';

import paymentMethodIcons from 'components/shared/payment-method/payment-method-icons';

import PaymentMethodPresentation from '../payment-method-presentation/payment-method-presentation';

type Props = {
  email?: string,
}

const PayPalInfo = ({ email }: Props) => (
  <PaymentMethodPresentation
    details={email}
    logo={paymentMethodIcons.paypal()}
  />
);

PayPalInfo.defaultProps = {
  email: '',
};

export default PayPalInfo;
