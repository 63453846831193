// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  onClick(): mixed,
}

const TrialAdminLimit = ({ onClick }: Props) => (
  <>
    <div className="mt-8 mb-8">
      <FormattedMessage
        id="inviteMemberModal.invitationsLimit.trialAdminLimit.description"
        defaultMessage="You’ve reached the limit of invitations. Please ask your team owner to activate your Setapp membership."
      />
    </div>
    <Button block onClick={onClick}>
      <FormattedMessage
        id="inviteMemberModal.invitationsLimit.trialAdminLimit.button"
        defaultMessage="Ok, got it"
      />
    </Button>
  </>
);

export default TrialAdminLimit;
