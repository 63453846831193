// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { detect } from 'utils/browser';
import analytics, { events } from 'utils/analytics/index';

import ImmediatelyDownloadLinkSection from './immediately-download-link-section/immediately-download-link-section';
import AutoDownloadSection from './auto-download-section/auto-download-section';

import './successful-registration-default.scss';
import setappIcon from './images/setapp-icon.svg';
import downloadSetappIcon from './images/download-setapp-icon.svg';
import setappInstallerIcon from './images/setapp-installer-icon.svg';

type Props = {
  onDirectDownloadClick: () => mixed,
  onAutoDownloadStart?: () => mixed,
  location: Location,
};

type State = {
  isSetappDownloaded: boolean,
  browser: string,
};

class SuccessfulRegistrationDefault extends PureComponent <Props, State> {
  state = {
    isSetappDownloaded: false,
    browser: detect().name,
  };

  static defaultProps = {
    onAutoDownloadStart: undefined,
  };

  render() {
    const {
      onAutoDownloadStart,
    } = this.props;
    const { isSetappDownloaded } = this.state;

    return (
      <div className="container">
        <div className="successful-registration-default">
          <div className="successful-registration-default__content">
            <img
              src={setappIcon}
              alt="Setapp icon"
              className="successful-registration-default__setapp-icon"
              width={160}
              height={160}
            />
            <h3 className="successful-registration-default__title">
              { this.getTitleFormattedMessage() }
            </h3>
            { this.getMainContentVariant() }
          </div>
          <img
            className="successful-registration-default__illustration"
            src={isSetappDownloaded ? setappInstallerIcon : downloadSetappIcon}
            alt="Install Setapp"
          />
        </div>
        {isSetappDownloaded && <AutoDownloadSection onStart={onAutoDownloadStart} />}
      </div>
    );
  }

  handleDownloadButtonClick = () => {
    analytics.trackEvent(events.SUCCESSFUL_REGISTRATION_CLICK_DOWNLOAD);
    this.setState({ isSetappDownloaded: true });
  }

  getTitleFormattedMessage() {
    const { location } = this.props;
    const {
      isSetappDownloaded,
      browser,
    } = this.state;
    const { invite } = queryString.parse(location.search);

    if (isSetappDownloaded) {
      switch (browser) {
        case 'chrome':
          return (
            <FormattedMessage
              id="successfulRegistrationDefault.appDownloadedTitle.bottomLeft"
              defaultMessage="Click the downloaded file in the bottom left corner to install Setapp"
            />);
        case 'safari':
        case 'firefox':
          return (
            <FormattedMessage
              id="successfulRegistrationDefault.appDownloadedTitle.topRight"
              defaultMessage="Click the downloaded file in the top right corner to install Setapp"
            />);
        default:
          return (
            <FormattedMessage
              id="successfulRegistrationDefault.appDownloadedTitle"
              defaultMessage="Click the downloaded file to install Setapp"
            />);
      }
    } else if (invite) {
      return (
        <FormattedMessage
          id="successfulRegistrationByInvite.title"
          defaultMessage="You already have an account on Setapp"
        />
      );
    } else {
      return (
        <FormattedMessage
          id="successfulRegistrationDefault.title"
          defaultMessage="Let’s download Setapp for Teams for your Mac"
        />);
    }
  }

  getMainContentVariant() {
    const {
      onDirectDownloadClick,
      location,
    } = this.props;
    const { isSetappDownloaded } = this.state;

    if (isSetappDownloaded) {
      return (
        <p className="mt-6 text_md">
          <ImmediatelyDownloadLinkSection onDirectDownloadClick={onDirectDownloadClick} />
        </p>
      );
    }

    const { invite } = queryString.parse(location.search);

    return (
      <>
        {invite && (
          <p className="mt-6 text_md">
            <FormattedMessage
              id="successfulRegistrationByInvite.info"
              defaultMessage="Download and install the Setapp app to continue."
            />
          </p>
        )}
        <Button
          className="successful-registration-default__download-btn"
          onClick={this.handleDownloadButtonClick}
        >
          <FormattedMessage
            id="successfulRegistrationDefault.downloadButton"
            defaultMessage="Download Setapp"
          />
        </Button>
      </>
    );
  }
}

export { SuccessfulRegistrationDefault as PureSuccessfulRegistrationDefault };

export default withRouter(SuccessfulRegistrationDefault);
