// @flow

import { v4 as uuidv4 } from 'uuid';

import { type Dispatch } from '../state-types';
import * as actionTypes from './notifier-action-types';
import { type Notification } from './notifier-initial-state';

// Options that can be passed to "add<type>Notification" method
export type NotificationOptions = $Diff<Notification, {
  id: mixed,
  type: mixed,
}>;

export function removeNotification(id: string) {
  return {
    type: actionTypes.REMOVE,
    payload: id,
  };
}

/**
 * @param {Object} notification - Notification object.
 * @param {string} [notification.type = 'info'] - type of notification, one of actionTypes.
 * @param {any} [notification.message] - text which would be displayed in popup.
 * @param {number} [notification.delay = 7000] - timeout in milliseconds before closing popup.
 * @param {boolean} [notification.hideCloseButton] set true if need to hide close button in notification.
 * @param {boolean} [notification.permanent = false] notification won't be removed automatically if true
 * @returns {string} notification ID
 */
export const addNotification = (notification: $Diff<Notification, {id: mixed}>) => (dispatch: Dispatch) => {
  const notificationId = uuidv4();

  dispatch({
    type: actionTypes.ADD,
    payload: { ...notification, id: notificationId },
  });

  if (!notification.permanent) {
    const defaultDelay = 7000;

    setTimeout(() => {
      dispatch(removeNotification(notificationId));
    }, notification.delay || defaultDelay);
  }

  return notificationId;
};

export function addInfoNotification(options: NotificationOptions) {
  return addNotification({ ...options, type: 'info' });
}

export function addWarningNotification(options: NotificationOptions) {
  return addNotification({ ...options, type: 'warning' });
}

export function addDangerNotification(options: NotificationOptions) {
  return addNotification({ ...options, type: 'danger' });
}

export function addSuccessNotification(options: NotificationOptions) {
  return addNotification({ ...options, type: 'success' });
}

export function clearNotifications() {
  return {
    type: actionTypes.CLEAR,
  };
}
