// @flow

/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';
import threeDSecure from 'braintree-web/three-d-secure';
import { defineMessages, injectIntl } from 'react-intl';

import type { Node, Element } from 'react';
import type { IntlShape } from 'react-intl';
import type {
  Client as BrainTreeClient,
  HostedFields,
  HostedFieldsState,
  HostedFieldsTokenizePayload,
  BrainTreeError,
  ThreeDSecure,
  ThreeDSecureVerificationPayload,
} from 'braintree-web';

import type { BraintreePaymentDetailsPayload } from 'state/payment-method/payment-method-actions';
import * as paymentMethodTypes from 'state/payment-method/payment-method-types';

import logger from 'utils/logger';

import wrapPaymentForm from '../payment-method-form-wrapper/payment-method-form-wrapper';

import CreditCardForm from './credit-card-form';
import fieldsMessages from './form-fields-messages';

type Props = {
  paymentInfo: BraintreePaymentDetailsPayload,
  brainTreeAuthToken: string,
  threeDSecureRequired: boolean,
  setLoading: boolean => any,
  setGenericError: Node => any,
  setErrorFields: ({[string]: Node}) => any,
  onFieldChange: (string, string) => any,
  onFormSubmit: () => any,
  merchantId: string,
  isLoading: boolean,
  formError: string,
  fieldsWithError: {
    [string]: Node,
  },
  isPaymentMethodCreated: boolean,
  intl: IntlShape,
  captcha: ?Element<any>,
};

type State = {
  isInitialized: boolean,
  focusedField: ?string,
};

const messages = defineMessages({
  cantCreateClient: {
    id: 'creditCardForm.braintreeErrors.cantCreateClient',
    defaultMessage: 'An error occurred while loading your form. Please try again later.',
  },
  unknownHostedFieldsError: {
    id: 'creditCardForm.braintreeErrors.unknownHostedFieldsError',
    defaultMessage: 'An unknown error has occurred. Please try again later or contact support.',
  },
  numberEmpty: {
    id: 'creditCardForm.validation.numberEmpty',
    defaultMessage: 'Credit card number is required',
  },
  numberInvalid: {
    id: 'creditCardForm.validation.numberInvalid',
    defaultMessage: 'Invalid credit card number',
  },
  expirationDateEmpty: {
    id: 'creditCardForm.validation.expirationDateEmpty',
    defaultMessage: 'Date is required',
  },
  expirationDateInvalid: {
    id: 'creditCardForm.validation.expirationDateInvalid',
    defaultMessage: 'Invalid date',
  },
  cvvEmpty: {
    id: 'creditCardForm.validation.cvvEmpty',
    defaultMessage: 'CVV is required',
  },
  cvvInvalid: {
    id: 'creditCardForm.validation.cvvInvalid',
    defaultMessage: 'Invalid CVV',
  },
});

class BrainTreeCreditCardForm extends Component<Props, State> {
  state = {
    isInitialized: false,
    focusedField: null,
  };

  _isMounted = false;

  hostedFields: ?HostedFields = null;

  threeDSecure: ?ThreeDSecure = null;

  getChildContext() {
    const { focusedField, isInitialized } = this.state;

    return {
      brainTreeFields: {
        focusedField,
        isInitialized,
      },
    };
  }

  componentDidMount() {
    const { brainTreeAuthToken, onFieldChange } = this.props;

    if (brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
    this._isMounted = true;

    onFieldChange('type', paymentMethodTypes.CREDIT_CARD);
  }

  componentDidUpdate(prevProps: Props) {
    const { brainTreeAuthToken } = this.props;

    if (!prevProps.brainTreeAuthToken && brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
  }

  componentWillUnmount() {
    const { setLoading } = this.props;
    this._isMounted = false;

    this.destroyBrainTreeComponents();

    // Remove loading if unmounted when Braintree request is being processed
    setLoading(false);
  }

  render() {
    const { isLoading } = this.props;
    const { isInitialized } = this.state;

    return (
      <CreditCardForm
        {...this.props}
        onFormSubmit={this.onFormSubmit}
        isLoading={isLoading || !isInitialized}
      />
    );
  }

  createBraintreeClient(authorization: string) {
    client.create({ authorization }, this.onBrainTreeClientCreated);
  }

  onBrainTreeClientCreated = (error: ?BrainTreeError, client: BrainTreeClient) => {
    const { setGenericError, intl } = this.props;

    if (error) {
      setGenericError(intl.formatMessage(messages.cantCreateClient));
      logger.logError('Couldn\'t create Brain Tree client instance', error);

      return;
    }

    // fix for hosted fields initialization errors after logout
    if (!this._isMounted) {
      return;
    }

    this.createHostedFields(client);
    this.createThreeDSecure(client);
  };

  createHostedFields(client: BrainTreeClient) {
    const { intl } = this.props;

    return hostedFields.create({
      client,
      styles: {
        input: {
          color: '#26262b',
          'font-size': '16px',
          'font-family': 'Avenir, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif',
        },
      },
      fields: {
        number: {
          selector: '#number',
        },
        cvv: {
          selector: '#cvv',
        },
        expirationDate: {
          selector: '#expirationDate',
          placeholder: intl.formatMessage(fieldsMessages.cardExpirationPlaceholder),
        },
      },
    }, this.onHostedFieldsCreated);
  }

  onHostedFieldsCreated = (error: ?BrainTreeError, hostedFieldsInstance: HostedFields) => {
    const { setGenericError, intl } = this.props;

    if (error) {
      /**
       * The "HOSTED_FIELDS_TIMEOUT" error occurs after 60-sec timeout when the component is unmounted
       * before hosted fields initialization is finished. There's no ability to cancel hosted fields initialization.
       * This is a Braintree SDK issue so we don't display and log the error in such case.
       */
      const shouldDisplayError = !(error.code === 'HOSTED_FIELDS_TIMEOUT' && !this._isMounted);

      if (shouldDisplayError) {
        setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
        logger.logError('Can\'t create hosted fields instance', error);
      }

      return;
    }

    this.hostedFields = hostedFieldsInstance;

    if (this.threeDSecure) {
      this.setUpHostedFieldsBehaviour(hostedFieldsInstance);
      this.enableForm();
    }
  };

  createThreeDSecure(client: BrainTreeClient) {
    return threeDSecure.create({
      client,
      version: 2,
    }, this.onThreeDSecureCreated);
  }

  onThreeDSecureCreated = (error: ?BrainTreeError, threeDSecure: ThreeDSecure) => {
    const { setGenericError, intl } = this.props;

    if (error) {
      setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
      logger.logError('Can\'t create Braintree 3D secure instance', error);
    }

    this.threeDSecure = threeDSecure;

    if (this.hostedFields) {
      this.setUpHostedFieldsBehaviour(hostedFields);
      this.enableForm();
    }
  };

  destroyBrainTreeComponents() {
    if (this.hostedFields) {
      this.hostedFields.teardown(this.onHostedFieldsDestroyed);
    }

    if (this.threeDSecure) {
      this.threeDSecure.teardown(this.onThreeDSecureDestroyed);
    }
  }

  onHostedFieldsDestroyed(error: ?BrainTreeError) {
    if (error) {
      logger.logError('Couldn\'t destroy Braintree hosted fields', error);
    }
  }

  onThreeDSecureDestroyed(error: ?BrainTreeError) {
    if (error) {
      logger.logError('Couldn\'t destroy Braintree 3D secure object', error);
    }
  }

  enableForm() {
    this.setState({ isInitialized: true });
  }

  setUpHostedFieldsBehaviour(fields: HostedFields) {
    fields.on('focus', this.onHostedFieldsFocus);
    fields.on('blur', this.onHostedFieldsBlur);
    fields.on('inputSubmitRequest', this.savePaymentMethod);
  }

  onHostedFieldsFocus = (event: HostedFieldsState) => {
    this.setState({ focusedField: event.emittedBy });
  };

  onHostedFieldsBlur = () => {
    this.setState({ focusedField: null });
  };

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.savePaymentMethod();
  };

  savePaymentMethod = () => {
    const { threeDSecureRequired, setLoading } = this.props;

    if (!this.hostedFields) {
      throw new Error('Hosted fields are not initialized');
    }

    this.hostedFields.tokenize((err, payload) => {
      if (err) {
        this.handleHostedFieldsError(err);

        return;
      }

      if (!threeDSecureRequired) {
        this.saveNonceAndSavePaymentMethod(payload.nonce);
      } else {
        this.doThreeDSecureVerification(payload);
      }
    });

    setLoading(true);
  };

  doThreeDSecureVerification(hostedFieldsPayload: HostedFieldsTokenizePayload) {
    if (!this.threeDSecure) {
      throw new Error('3D secure is not initialized');
    }

    this.threeDSecure.verifyCard({
      amount: 1,
      nonce: hostedFieldsPayload.nonce,
      bin: hostedFieldsPayload.details.bin,
      onLookupComplete(data, next) {
        next();
      },
    }, this.onThreeDSecureVerificationEnd);
  }

  onThreeDSecureVerificationEnd = (err: ?BrainTreeError, payload: ThreeDSecureVerificationPayload) => {
    const { setLoading, setGenericError, intl } = this.props;

    if (err) {
      setLoading(false);
      setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));

      return;
    }

    this.saveNonceAndSavePaymentMethod(payload.nonce);
  };

  saveNonceAndSavePaymentMethod(nonce: string) {
    const { onFieldChange, onFormSubmit } = this.props;

    onFieldChange('nonce', nonce);
    onFormSubmit();
  }

  handleHostedFieldsError(error: BrainTreeError) {
    const { setLoading, setGenericError, intl } = this.props;
    const fieldsValidityErrors = ['HOSTED_FIELDS_FIELDS_EMPTY', 'HOSTED_FIELDS_FIELDS_INVALID'];

    setLoading(false);

    if (fieldsValidityErrors.indexOf(error.code) !== -1) {
      this.handleHostedFieldsValidityError();
    } else {
      setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
      logger.logError('Hosted fields tokenization error', error);
    }
  }

  handleHostedFieldsValidityError() {
    if (!this.hostedFields) {
      throw new Error('Hosted fields are not initialized');
    }

    const { intl, setErrorFields } = this.props;
    const errorFields = {},
      { fields } = this.hostedFields.getState();

    Object.keys(fields).forEach((id) => {
      const hasError = !fields[id].isValid;

      if (hasError) {
        errorFields[id] = fields[id].isEmpty ? intl.formatMessage(messages[`${id}Empty`]) : intl.formatMessage(messages[`${id}Invalid`]);
      }
    });

    setErrorFields(errorFields);
  }
}

BrainTreeCreditCardForm.childContextTypes = {
  brainTreeFields: PropTypes.shape({
    focusedField: PropTypes.string,
    isInitialized: PropTypes.bool,
  }),
};

export { BrainTreeCreditCardForm as PureBrainTreeCreditCardForm };

export default wrapPaymentForm(injectIntl(BrainTreeCreditCardForm));
