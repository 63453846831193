// @flow

import RequestError from '@setapp/request-error';

import type { FormikErrors } from 'formik';

// eslint-disable-next-line import/prefer-default-export
export function getRegisteredFieldsErrors<Values: {...}>(
  fields: Values,
  requestError: RequestError,
  mapper: {[string]: React$Node},
): FormikErrors<Values> {
  const responseErrors = requestError.getSimplifiedErrors();

  return Object.keys(fields).reduce((fieldsErrors, fieldName) => {
    if (!responseErrors.fieldsErrors || !responseErrors.fieldsErrors[fieldName]) {
      return fieldsErrors;
    }

    const messageOrErrorCode = responseErrors.fieldsErrors[fieldName];
    const message = mapper[messageOrErrorCode] || messageOrErrorCode;
    const meta = requestError.getFieldMeta(fieldName);

    return {
      ...fieldsErrors,
      [fieldName]: { message, meta },
    };
  }, {});
}
