// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import InfoButtonWithTooltip from 'components/shared/info-button/info-button-with-tooltip';

import type { MemberRole } from 'state/team-plan/team-member-roles';
import * as roles from 'state/team-plan/team-member-roles';

import './member-role-title.scss';

type Props = {
  role: MemberRole,
};

const getRoleTitle = (role: MemberRole) => {
  switch (role) {
    case roles.TEAM_OWNER:
      return {
        title: <FormattedMessage id="teamMemberRole.role.team_owner.title" defaultMessage="Owner" />,
      };
    case roles.TEAM_MANAGER:
      return {
        title: <FormattedMessage id="teamMemberRole.role.team_manager.title" defaultMessage="Admin" />,
        tooltip: (
          <FormattedMessage
            id="teamMemberRole.role.team_manager.tooltip"
            defaultMessage="A member of the Team who can add or remove other members and change the number of extra devices on the Team."
          />
        ),
      };
    default:
      return {
        title: <FormattedMessage id="teamMemberRole.role.team_member.title" defaultMessage="Member" />,
      };
  }
};

const MemberRoleTitle = ({ role }: Props) => {
  const roleTitle = getRoleTitle(role);

  return (
    <div>
      <span className="mr-3" data-qa="memberRoleTitle">
        {roleTitle.title}
      </span>
      {roleTitle.tooltip && (
        <InfoButtonWithTooltip>
          <span className="member-role-title__tooltip-content text_sm">
            {roleTitle.tooltip}
          </span>
        </InfoButtonWithTooltip>)}
    </div>
  );
};

export default MemberRoleTitle;
