// @flow

import {
  useEffect,
  useState,
  useCallback,
  type Node,
} from 'react';
import { connect } from 'react-redux';
import { withRouter, type RouterHistory } from 'react-router-dom';

import { getUser, isUserPlainTeamMember } from 'state/root-reducer';
import { fetchUser } from 'state/user/user-actions';

import urls from 'config/urls';
import { MEMBERSHIP_WEB_ROOT } from 'config/app';

type Props = {
  userId: number,
  isUserFetched: boolean,
  isUserAuthenticated: boolean,
  isTeamMember: boolean,
  isUserPlainTeamMember: boolean,
  history: RouterHistory,
  children: Node,
  fetchUser: () => Promise<mixed>,
}

const AuthenticatedSection = (props: Props) => {
  const {
    children,
    history,
    userId,
    isUserFetched,
    isUserAuthenticated,
    isTeamMember,
    isUserPlainTeamMember,
    fetchUser,
  } = props;
  const { location, replace } = history;
  // We need to know if we can immediately show the page to avoid blinking on remount
  const initialAccessGranted = isUserFetched && isUserAuthenticated && isTeamMember;
  const [isAccessGranted, setAccessGranted] = useState(initialAccessGranted);

  const redirectToLoginPage = useCallback(() => {
    replace({
      pathname: urls.login,
      search: location.search,
      state: {
        nextLocation: location,
      },
    });
  }, [location, replace]);

  useEffect(() => {
    if (!isUserAuthenticated) {
      redirectToLoginPage();
    } else if (!isUserFetched) {
      // Fetch profile only on initial load. Otherwise it should already be fetched by the one of auth actions
      fetchUser().catch(() => redirectToLoginPage());
    } else if (!isTeamMember) {
      // Members must be redirected to the customer account
      window.location.replace(MEMBERSHIP_WEB_ROOT + location.pathname + location.search);
    } else {
      setAccessGranted(true);
    }
  }, [
    userId,
    isUserFetched,
    isUserAuthenticated,
    isTeamMember,
    isUserPlainTeamMember,
    fetchUser,
    redirectToLoginPage,
    location,
    replace,
  ]);

  // TODO: show loading after migration to react-router@4
  return isAccessGranted && children;
};

const mapStateToProps = (state) => {
  const {
    id,
    isAuthenticated,
    isTeamMember,
  } = getUser(state);

  return {
    isTeamMember,
    userId: id,
    isUserFetched: Boolean(id),
    isUserAuthenticated: isAuthenticated,
    isUserPlainTeamMember: isUserPlainTeamMember(state),
  };
};

const mapActionsToProps = { fetchUser };

export { AuthenticatedSection as PureAuthenticatedSection };

export default connect(mapStateToProps, mapActionsToProps)(withRouter(AuthenticatedSection));
