import React from 'react';
import { FormattedMessage } from 'react-intl';

import './tax-note.scss';

const TaxNote = () => (
  <span className="tax-note">
    <FormattedMessage
      id="taxNote.label"
      defaultMessage="Tax may apply, depending on your country of residence."
    />
  </span>
);

export default TaxNote;
