import React from 'react';
import PropTypes from 'prop-types';

import './sidebar-nav-section.scss';

const SidebarNavSection = ({ children }) => (
  <ul className="sidebar-nav__section">
    {children}
  </ul>
);

SidebarNavSection.propTypes = {
  children: PropTypes.node,
};

SidebarNavSection.defaultProps = {
  children: null,
};

export default SidebarNavSection;
