// @flow

import React, { type Node, PureComponent } from 'react';
import { connect } from 'react-redux';
import BraintreePaymentForm from 'components/shared/braintree-payment-form/payment-form';
import PaddlePaymentForm from 'components/shared/paddle-payment-form/paddle-payment-form';
import type { PaymentMethodType } from 'state/payment-method/payment-method-initial-state';
import { getUser } from 'state/root-reducer';

type Props = {|
  provider: 'paddle' | 'braintree',
  onSuccessSubmit: ?() => Promise<mixed>,
  braintreeFormOptions: ?$Shape<{
    paymentMethod: PaymentMethodType,
    submitBtnTitle: Node,
    loadingText: Node,
  }>,
  paddleFormOptions?: ?$Shape<{
    footer: Node,
    updateBillingNote: Node,
  }>,
|};

class PaymentForm extends PureComponent<Props> {
  static defaultProps = {
    braintreeFormOptions: null,
    paddleFormOptions: null,
  };

  render() {
    const {
      provider,
      braintreeFormOptions,
      onSuccessSubmit,
      paddleFormOptions,
    } = this.props;

    if (provider === 'paddle') {
      return <PaddlePaymentForm {...paddleFormOptions} onSuccessSubmit={onSuccessSubmit} />;
    }

    return <BraintreePaymentForm {...braintreeFormOptions} onSuccessSubmit={onSuccessSubmit} />;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  provider: getUser(state).paymentProvider,
});

export { PaymentForm as PurePaymentForm };

export default connect(mapStateToProps)(PaymentForm);
