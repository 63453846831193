// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import external from 'config/external-urls';

import './update-billing-info-note.scss';

const UpdateBillingInfoNote = () => (
  <span className="update-billing-info-note text_sm">
    <FormattedMessage
      id="updateBillingInfoNote.label"
      defaultMessage="If you need to add or update your billing information, please {contactLink} for help."
      values={{
        contactLink: (
          <a
            href={`${external.paddleHelpMail}?subject=Request%20for%20change%20in%20payment%20details%20%5BSetapp%20account%5D`}
            className="update-billing-info-note__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="updateBillingInfoNote.supportLink"
              defaultMessage="contact support"
            />
          </a>
        ),
      }}
    />
  </span>
);

export default UpdateBillingInfoNote;
