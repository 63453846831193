// @flow

import React from 'react';
import { render } from 'react-dom';
import queryString from 'query-string';
import { Modal } from '@setapp/ui-kit';

import logger from 'utils/logger';
import { getSignupMetadata } from 'utils/service-locators';

import loggerConfig from 'config/logger';

import ReactApp from './app';

class Application {
  initialize() {
    if (process.env.NODE_ENV === 'production') {
      logger.initialize(loggerConfig);
    }

    // TODO: Remove hack after Setapp for teams campaign finish
    this.fixInvalidCampaign();

    this.saveSignupMetadata();
    this.render();
  }

  saveSignupMetadata() {
    const impactRadiusClickId = queryString.parse(window.location.search).clickid;
    const signupMetadata = getSignupMetadata();

    if (impactRadiusClickId) {
      signupMetadata.save({ impactRadiusClickId });
    }
  }

  fixInvalidCampaign() {
    const { origin, pathname, search } = window.location;
    const query = queryString.parse(search);

    Object.keys(query).forEach((param) => {
      if (param.indexOf('campaign:') > -1) {
        const [name, value] = param.split(':');

        delete query[param];
        query[name] = value;

        window.location.assign(`${origin}${pathname}?${queryString.stringify(query)}`);
      }
    });
  }

  render() {
    Modal.setAppElement('#root');

    render(
      <ReactApp />,
      // $FlowFixMe
      document.getElementById('root'),
    );
  }
}

// Create application instance only out of unit testing env
if (process.env.NODE_ENV !== 'test') {
  const application = new Application();
  application.initialize();
}
export default Application;
