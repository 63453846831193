// @flow

import React, { type Node, PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import type { ModalType } from 'components/modals';

import type { PaymentDetails as PaymentDetailsType, InvoicingInformation } from 'state/payment-method/payment-method-initial-state';
import { showModal, hideModal } from 'state/modal/modal-reducer';
import { addSuccessNotification } from 'state/notifier/notifier-actions';

import analytics, { events } from 'utils/analytics';

import PanelBox from 'components/shared/panel-box/panel-box';

import BusinessAccountDetails from './business-account-details/business-account-details';
import PaymentMethodDetails from './payment-method-details/payment-method-details';
import CardInfoNotification from './card-info-notification/card-info-notification';


import './payment-details-view.scss';

type Props = {|
  paymentMethod: PaymentDetailsType,
  businessAccountDetails: InvoicingInformation,
  creditCardExpiration: {|
    isExpired: boolean,
    isAboutToExpire: boolean,
  |},
  addSuccessNotification: (Node | ?{}) => mixed,
  showModal: (ModalType, {
    title: Node,
    onPaymentDetailsSaved: () => mixed,
  }) => mixed,
  hideModal: () => mixed,
  isSubscriptionTrial: boolean,
  nextPaymentDate: number,
|};

class PaymentDetails extends PureComponent<Props> {
  render() {
    const {
      paymentMethod,
      businessAccountDetails,
      creditCardExpiration,
      isSubscriptionTrial,
      nextPaymentDate,
    } = this.props;
    const cardIsValid = !creditCardExpiration.isExpired && !creditCardExpiration.isAboutToExpire;

    return (
      <div className="payment-details">
        <PanelBox dark={cardIsValid}>
          <PanelBox.Content>
            <div className="payment-details__title text_lg">
              <FormattedMessage id="paymentDetails.title" defaultMessage="Payment details" />
            </div>

            <PaymentMethodDetails paymentMethod={paymentMethod} />

            <div className="payment-details__status">
              <CardInfoNotification
                creditCardExpiration={creditCardExpiration}
                isSubscriptionTrial={isSubscriptionTrial}
                nextPaymentDate={nextPaymentDate}
              />
            </div>

            <BusinessAccountDetails data={businessAccountDetails} />

            <Button
              variant="link"
              onClick={this.onUpdateClick}
              data-qa="updatePaymentDetailsButton"
            >
              <FormattedMessage id="paymentDetails.updateLink" defaultMessage="Update payment details" />
            </Button>
          </PanelBox.Content>
        </PanelBox>
      </div>
    );
  }

  onUpdateClick = () => {
    const { showModal } = this.props;

    analytics.trackEvent(events.PAYMENT_METHOD_UPDATE_INFO_CLICK);
    showModal('SET_PAYMENT_DETAILS', {
      title: (
        <FormattedMessage
          id="paymentDetails.updateModal.title"
          defaultMessage="Update payment details"
        />
      ),
      onPaymentDetailsSaved: this.onPaymentDetailsUpdateSuccess,
    });
  };

  onPaymentDetailsUpdateSuccess = () => {
    const { addSuccessNotification, hideModal } = this.props;

    hideModal();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="paymentDetails.notifications.update"
          defaultMessage="Payment details successfully updated."
        />
      ),
    });
  };
}

/* istanbul ignore next */
const mapActionsToProps = {
  addSuccessNotification,
  hideModal,
  showModal,
};

export { PaymentDetails as PurePaymentDetails };

export default connect(null, mapActionsToProps)(PaymentDetails);
