// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Button } from '@setapp/ui-kit';

import Cookies from 'js-cookie';
import externalUrls from 'config/external-urls';
import { COOKIES_ROOT_DOMAIN } from 'config/app';

import './cookie-banner.scss';

const ACCEPTED_COOKIE_NAME = 'cookieAccepted';

type State = {
  showCookieBanner: boolean,
};

type Props = {
  position: 'top' | 'bottom',
};

class CookieBanner extends PureComponent<Props, State> {
  static defaultProps = {
    position: 'bottom',
  };

  state = {
    showCookieBanner: !Cookies.get(ACCEPTED_COOKIE_NAME),
  };

  render() {
    const { showCookieBanner } = this.state;
    const { position } = this.props;

    if (!showCookieBanner) {
      return null;
    }

    const rootClass = classnames('cookie-banner', `cookie-banner_${position}`);

    return (
      <div className={rootClass} role="alert">
        <div className="cookie-banner__text text_xs">
          <FormattedMessage
            id="cookieNotification.text"
            defaultMessage="Setapp uses cookies to personalize your experience on our website. By continuing to use this site, you agree to our {cookiePolicyLink}."
            values={{
              cookiePolicyLink: (
                <a
                  className="link"
                  href={externalUrls.cookiePolicy}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage
                    id="cookieBanner.cookieLink"
                    defaultMessage="cookie policy"
                  />
                </a>
              ),
            }}
          />
        </div>

        <div className="mt-1 ml-3">
          <Button
            className="cookie-banner__button"
            variant="light"
            size="sm"
            onClick={this.onConfirmCookie}
          >
            <FormattedMessage
              id="cookieBanner.confirmBtn"
              defaultMessage="Okay"
            />
          </Button>
        </div>
      </div>
    );
  }

  onConfirmCookie = () => {
    Cookies.set(ACCEPTED_COOKIE_NAME, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 5 });
    this.setState({ showCookieBanner: false });
  }
}

export default CookieBanner;
