// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  onClick(): mixed,
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
}

const TrialOwnerLimit = ({ pricePerMonthPerMember, onClick }: Props) => (
  <>
    <div className="mt-8 mb-8">
      <FormattedMessage
        id="inviteMemberModal.invitationsLimit.trialOwnerLimit.description"
        defaultMessage="You’ve reached the limit of free invitations. Please activate your Setapp membership to invite more members."
        values={{
          pricePerMember: (
            <FormattedPrice
              price={pricePerMonthPerMember.price}
              currency={pricePerMonthPerMember.currency}
            />
          ),
        }}
      />
    </div>
    <Button block onClick={onClick}>
      <FormattedMessage
        id="inviteMemberModal.invitationsLimit.trialOwnerLimit.button"
        defaultMessage="Activate membership"
      />
    </Button>
  </>
);

export default TrialOwnerLimit;
