// @flow

import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';

import type { RouterHistory } from 'react-router-dom';
import {
  fetchSubscription,
  activateSubscription,
} from 'state/subscription/subscription-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchTeam } from 'state/team-plan/team-plan-actions';
import { addDangerNotification } from 'state/notifier/notifier-actions';
import {
  getPrimaryPricePlan,
  getPrimarySubscription,
  isPaymentMethodCreated,
  isPaymentMethodFetched,
  isTeamFetched,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';

import DefaultError from 'components/shared/default-error/default-error';

import urls from 'config/urls';
import analytics, { events } from 'utils/analytics';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import SubscriptionActivatedStep from './subscription-activated-step/subscription-activated-step';
import SubscriptionAlreadyActiveStep from './subscription-already-active-step/subscription-already-active-step';
import AddPaymentDetailsStep from './add-payment-details-step/add-payment-details-step';
import ChoosePlanStep from './choose-plan-step/choose-plan-step';


type Props = {
  primarySubscription: Subscription,
  fetchPaymentMethod: () => Promise<void>,
  fetchSubscription: () => Promise<void>,
  fetchPricePlans: () => Promise<void>,
  activateSubscription: (number) => Promise<void>,
  fetchTeam: () => Promise<void>,
  isPaymentMethodFetched: boolean,
  isTeamFetched: boolean,
  addDangerNotification: (Node | {message: Node}) => void,
  isPaymentMethodCreated: boolean,
  history: RouterHistory,
  currentPricePlan: PricePlan,
};

type StepName = 'choosePlanStep' | 'addPaymentDetailsStep' | 'subscriptionActivated' | 'subscriptionAlreadyActive';

type State = {
  currentStep: StepName,
  selectedPlan: PricePlan,
};

class ActivateSubscriptionPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { currentPricePlan } = props;

    this.state = {
      selectedPlan: currentPricePlan,
      currentStep: 'choosePlanStep',
    };
  }

  componentDidMount() {
    const {
      fetchPaymentMethod,
      fetchSubscription,
      fetchPricePlans,
      fetchTeam,
      addDangerNotification,
    } = this.props;

    return Promise.all([
      fetchPaymentMethod(),
      fetchSubscription(),
      fetchPricePlans(),
      fetchTeam(),
    ])
      .catch(() => {
        addDangerNotification({ message: <DefaultError /> });
      });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      currentPricePlan,
      primarySubscription,
    } = this.props;
    const isPrimarySubscriptionFetched = !prevProps.primarySubscription && primarySubscription;

    if (isPrimarySubscriptionFetched) {
      const isSubscriptionNotTrial = primarySubscription.status !== subscriptionStatuses.TRIAL;

      if (isSubscriptionNotTrial) {
        this.setCurrentStep('subscriptionAlreadyActive');
      }
    }

    if (currentPricePlan !== prevProps.currentPricePlan) {
      this.setSelectedPricePlan(currentPricePlan);
    }
  }

  render() {
    const {
      isPaymentMethodCreated,
      isPaymentMethodFetched,
      isTeamFetched,
      primarySubscription,
    } = this.props;
    const {
      currentStep,
      selectedPlan,
    } = this.state;
    const isDataFetching = !isPaymentMethodFetched || !primarySubscription || !isTeamFetched;

    if (isDataFetching) {
      return <FullscreenLayoutLoading />;
    }

    switch (currentStep) {
      case 'choosePlanStep': {
        return (
          <ChoosePlanStep
            withStepCounter={!isPaymentMethodCreated}
            totalStepsNumber={2}
            currentStepNumber={1}
            isPaymentMethodCreated={isPaymentMethodCreated}
            handleCloseClick={this.handleCloseClick}
            setSelectedPricePlan={this.setSelectedPricePlan}
            selectedPlan={selectedPlan}
            handleChangePlanSubmitClick={this.handleChangePlanSubmitClick}
            handlePaddleVerifiedClick={this.handlePaddleVerifiedClick}
          />
        );
      }
      case 'addPaymentDetailsStep': {
        return (
          <AddPaymentDetailsStep
            withStepCounter
            totalStepsNumber={2}
            currentStepNumber={2}
            handleCloseClick={this.handleCloseClick}
            activateSubscription={this.activateSubscription}
            selectedPlan={selectedPlan}
            handlePaddleVerifiedClick={this.handlePaddleVerifiedClick}
          />
        );
      }
      case 'subscriptionActivated': {
        return (
          <SubscriptionActivatedStep
            handleCloseClick={this.handleCloseClick}
          />
        );
      }
      default: {
        return (
          <SubscriptionAlreadyActiveStep
            handleCloseClick={this.handleCloseClick}
          />
        );
      }
    }
  }

  setCurrentStep = (currentStep: StepName) => {
    this.setState({ currentStep });
  };

  handleCloseClick = () => {
    const { history } = this.props;
    const hasPreviousPage = window.history.length > 2;

    if (hasPreviousPage) {
      history.goBack();
    } else {
      history.push(urls.subscription);
    }
  };

  handlePaddleVerifiedClick() {
    analytics.trackEvent(events.ACTIVATE_SUBSCRIPTION_PAGE_CLICK_PADDLE);
  }

  handleChangePlanSubmitClick = () => {
    const { isPaymentMethodCreated } = this.props;

    analytics.trackEvent(events.ACTIVATE_SUBSCRIPTION_PAGE_PLAN_SELECTED, {
      eventLabel: isPaymentMethodCreated ? 'withPaymentDetails' : 'withoutPaymentDetails',
    });

    if (isPaymentMethodCreated) {
      this.activateSubscription();
    } else {
      this.setCurrentStep('addPaymentDetailsStep');
    }
  };

  setSelectedPricePlan = (selectedPlan: PricePlan) => {
    this.setState({ selectedPlan });
  };

  activateSubscription = () => {
    const {
      activateSubscription,
      addDangerNotification,
    } = this.props;

    const { selectedPlan } = this.state;

    return activateSubscription(selectedPlan.id)
      .then(() => {
        this.setCurrentStep('subscriptionActivated');

        analytics.trackEvent(events.ACTIVATE_SUBSCRIPTION_PAGE_SUBSCRIPTION_ACTIVATED);
      })
      .catch(() => {
        const { history } = this.props;
        addDangerNotification({
          message: <DefaultError />,
        });

        history.push(urls.subscription);
      });
  };
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
  isPaymentMethodFetched: isPaymentMethodFetched(state),
  isTeamFetched: isTeamFetched(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  currentPricePlan: getPrimaryPricePlan(state),
});

const mapActionsToProps = {
  fetchSubscription,
  fetchPaymentMethod,
  fetchPricePlans,
  fetchTeam,
  activateSubscription,
  addDangerNotification,
};

export { ActivateSubscriptionPage as PureActivateSubscriptionPage };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivateSubscriptionPage);
