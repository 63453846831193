// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Notification } from '@setapp/ui-kit';

import './payment-pending-notification.scss';

const PaymentPendingNotification = () => {
  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <Notification
      type="warning"
    >
      <span className="payment-pending-notification__container">
        <FormattedMessage
          id="paymentPendingNotification.text"
          defaultMessage="One or more payments are pending. Please refresh this page to see updated statuses."
        />
        <Button
          variant="primary-outline"
          className="payment-pending-notification__button"
          size="sm"
          onClick={handleRefreshClick}
        >
          <FormattedMessage
            id="paymentPendingNotification.actionButtonTitle"
            defaultMessage="Refresh"
          />
        </Button>
      </span>
    </Notification>
  );
};

export default PaymentPendingNotification;
