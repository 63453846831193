// @flow

import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import { getInvoicesList } from 'state/root-reducer';
import { fetchInvoices } from 'state/invoices/invoices-actions';
import { addDangerNotification } from 'state/notifier/notifier-actions';
import type { Invoice } from 'state/invoices/invoices-initial-state';

import DefaultError from 'components/shared/default-error/default-error';
import PageTitle from 'components/shared/page-title/page-title';
import PageDescription from 'components/shared/page-description/page-description';
import withEmailAutoConfirmation from 'components/shared/with-email-auto-confirmation/with-email-auto-confirmation';

import PaymentHistoryList from './payment-history-list/payment-history-list';


type Props = {
  fetchInvoices: () => Promise<void>,
  addDangerNotification: ({message: Node}) => void,
  invoices: Array<Invoice>,
};

class PaymentHistoryPage extends Component <Props> {
  componentDidMount() {
    const {
      fetchInvoices,
      addDangerNotification,
    } = this.props;

    return fetchInvoices()
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  render() {
    const { invoices } = this.props;

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="paymentHistory.title" defaultMessage="Receipts" />
        </PageTitle>
        <PageDescription>
          <FormattedMessage
            id="paymentHistory.subtitle"
            defaultMessage="Here's a list of all your successful payments with taxes. You can download the invoices if you need to."
          />
        </PageDescription>
        <Row>
          <Col md={10} lg={8}>
            <PaymentHistoryList invoices={invoices} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: getInvoicesList(state),
});

export { PaymentHistoryPage as PurePaymentHistoryPage };

const mapActionsToProps = {
  fetchInvoices,
  addDangerNotification,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withEmailAutoConfirmation(PaymentHistoryPage));
