// @flow

import { createSelector } from 'reselect';

import * as pricePlanGroupTypes from '../price-plans/price-plan-group-types';
import type { PricePlan } from '../price-plans/price-plans-initial-state';
import type { Subscription, SubscriptionsState, SubscriptionWithAdditionalSeats } from './subscription-initial-state';

import initialState from './subscription-initial-state';
import * as actionTypes from './subscription-action-types';
import * as subscriptionStatuses from './statuses';

const subscriptions = (
  state: SubscriptionsState = initialState,
  action: actionTypes.SubscriptionsAction
): SubscriptionsState => {
  switch (action.type) {
    // TODO: describe UPDATE_REQUEST separately when the single subscription loading state is implemented
    case actionTypes.UPDATE_REQUEST:
    case actionTypes.ORDER_REQUEST:
    case actionTypes.LIST_REQUEST: {
      return {
        ...state,
        isLoading: !action.meta.silent,
      };
    }

    case actionTypes.LIST_REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      return { list: action.payload, isLoading: false };
    }

    case actionTypes.ORDER_REQUEST_SUCCESS: {
      return {
        list: state.list.concat(action.payload),
        isLoading: false,
      };
    }

    case actionTypes.UPDATE_REQUEST_SUCCESS: {
      return {
        ...state,
        // TODO: remove loading from the subscription when the single subscription loading state is implemented
        isLoading: false,
        list: state.list
          .map((subscription) => (subscription.id === action.payload.id ? action.payload : subscription)),
      };
    }

    case actionTypes.UPDATE_TEAM_SUBSCRIPTION: {
      const isTeamSubscription = (subscription) => (
        subscription.pricePlan && pricePlanGroupTypes.TEAM === subscription.pricePlan.group
      );
      const newList = state.list
        .map((subscription) => (isTeamSubscription(subscription) ? action.payload : subscription));

      return { ...state, list: newList };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default subscriptions;

/*
 * Single subscription selectors
 */

export const isInactive = (state: Subscription) => [
  subscriptionStatuses.CANCELLED,
  subscriptionStatuses.BLOCKED,
].indexOf(state.status) !== -1;

export const isActive = (state: Subscription) => !isInactive(state);

export const isChangePlanAllowed = (state: Subscription) => [
  subscriptionStatuses.TRIAL,
  subscriptionStatuses.ACTIVE,
  subscriptionStatuses.GRACE,
  subscriptionStatuses.BLOCKED,
  subscriptionStatuses.CANCELLED,
].indexOf(state.status) !== -1 && !state.paymentPending;

export const isTrial = (state: Subscription) => state.status === subscriptionStatuses.TRIAL;

/*
 * List selectors
 */
export const getList = (state: SubscriptionsState) => state.list;

export const isFetched = (state: SubscriptionsState) => Boolean(getList(state).length);

export const filterByGroup = (subscriptions: Array<Subscription>, group: string): Array<Subscription> => subscriptions
  .filter((subscription) => subscription.pricePlan && subscription.pricePlan.group === group);

export const getTotalPrice = (subscriptions: Array<Subscription>) => subscriptions.reduce((previous, current) => {
  if (!current.pricePlan || !current.pricePlan.price) {
    return previous;
  }

  return previous + current.pricePlan.price;
}, 0);

export const getTotalSeatsNumber = (subscriptions: Array<Subscription> | Subscription) => {
  const subscriptionsArray = Array.isArray(subscriptions) ? subscriptions : [subscriptions];

  return subscriptionsArray
    .reduce((seatsNumber, subscription) => seatsNumber + subscription.pricePlan.seatsCount, 0);
};

export const getRegular = (state: SubscriptionsState) => filterByGroup(getList(state), pricePlanGroupTypes.REGULAR)[0];

export const getTeam = (state: SubscriptionsState) => filterByGroup(getList(state), pricePlanGroupTypes.TEAM)[0];

export const getPrimary = (state: SubscriptionsState) => getRegular(state) || getTeam(state) || initialState.list[0];

export const getAllAdditional = (state: SubscriptionsState) => {
  const additionalSubscriptions = filterByGroup(getList(state), pricePlanGroupTypes.ADDITIONAL_SEAT);

  return additionalSubscriptions.length ? additionalSubscriptions : initialState.list;
};

export const getAllActiveAdditional = (state: SubscriptionsState): Array<Subscription> => {
  const activeAdditionalSubscriptions = getAllAdditional(state).filter(isActive);

  return activeAdditionalSubscriptions.length ? activeAdditionalSubscriptions : [];
};

export const isPrimaryInactive: SubscriptionsState => boolean = createSelector(
  getPrimary,
  (subscription) => Boolean(subscription) && isInactive(subscription)
);

export const isPrimaryTrial: SubscriptionsState => boolean = createSelector(
  getPrimary,
  (subscription) => Boolean(subscription) && isTrial(subscription)
);

export const isPrimaryPlanChangeAllowed: SubscriptionsState => boolean = createSelector(
  getPrimary,
  (subscription) => Boolean(subscription) && isChangePlanAllowed(subscription)
);

export const getPrimarySeatsCount: SubscriptionsState => number = createSelector(
  getPrimary,
  (subscription) => (subscription ? getTotalSeatsNumber(subscription) : 0)
);

export const getActiveAdditionalSeatsNumber = (
  state: SubscriptionsState
) => getTotalSeatsNumber(getAllActiveAdditional(state));

export const getActiveAdditionalSeatsPrice = (
  state: SubscriptionsState
) => getTotalPrice(getAllActiveAdditional(state));

export const getAdditionalSeatsInfo = (state: SubscriptionsState): ?SubscriptionWithAdditionalSeats => {
  const [initialData] = getAllActiveAdditional(state);

  if (!initialData) {
    return null;
  }

  return {
    ...initialData,
    seatsNumber: getActiveAdditionalSeatsNumber(state),
    pricePlan: {
      ...initialData.pricePlan,
      price: getActiveAdditionalSeatsPrice(state),
    },
  };
};

export const getPrimaryPricePlan: SubscriptionsState => ?PricePlan = createSelector(
  getPrimary,
  (primarySubscription) => (primarySubscription ? primarySubscription.pricePlan : null)
);
