// @flow

import { type Subscription } from '../subscription/subscription-initial-state';
import { type TeamMember, type TeamInfo } from './team-plan-initial-state';

export const REQUEST = 'TEAM_PLAN_REQUEST';
export const REQUEST_SUCCESS = 'TEAM_PLAN_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'TEAM_PLAN_REQUEST_ERROR';
export const REMOVE_MEMBER = 'TEAM_PLAN_REMOVE_MEMBER';
export const ADD_MEMBER = 'TEAM_ADD_MEMBER';
export const INVITE_MEMBERS = 'TEAM_INVITE_MEMBERS';
export const PURCHASE_INVITATIONS = 'TEAM_PURCHASE_INVITATIONS';

// Member-related actions.
export const ADD_DEVICE_TO_MEMBER = 'TEAM_ADD_DEVICE_TO_MEMBER';
export const REMOVE_DEVICE_FROM_MEMBER = 'TEAM_REMOVE_DEVICE_FROM_MEMBER';
export const MODIFY_MEMBER_REQUEST = 'TEAM_MODIFY_MEMBER_REQUEST';
export const MODIFY_MEMBER_SUCCESS = 'TEAM_MODIFY_MEMBER_SUCCESS';
export const MODIFY_MEMBER_ERROR = 'TEAM_MODIFY_MEMBER_ERROR';

type TeamPlanRequestAction = {|
  type: 'TEAM_PLAN_REQUEST',
|};

type TeamPlanRequestSuccessAction = {|
  type: 'TEAM_PLAN_REQUEST_SUCCESS',
  payload?: TeamInfo & { members: Array<TeamMember> },
|};

type TeamPlanRequestErrorAction = {|
  type: 'TEAM_PLAN_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

type TeamPlanRemoveMemberAction = {|
  type: 'TEAM_PLAN_REMOVE_MEMBER',
  payload: number,
|};

type TeamAddMemberAction = {|
  type: 'TEAM_ADD_MEMBER',
  payload: TeamMember,
|};

type TeamInviteMembersAction = {|
  type: 'TEAM_INVITE_MEMBERS',
|};

type TeamInviteMembersPurchaseAction = {|
  type: 'TEAM_PURCHASE_INVITATIONS',
|};

// Member-related actions type.

type TeamAddDeviceToMemberAction = {|
  type: 'TEAM_ADD_DEVICE_TO_MEMBER',
  payload: {
    memberId: number,
    additionalSeat: Subscription,
  },
|};

type TeamRemoveDeviceFromMemberAction = {|
  type: 'TEAM_REMOVE_DEVICE_FROM_MEMBER',
  payload: {
    memberId: number,
    subscriptionId: number
  },
|};

type TeamModifyMemberRequestAction = {|
  type: 'TEAM_MODIFY_MEMBER_REQUEST',
  payload: number,
|};

type TeamModifyMemberRequestSuccessAction = {|
  type: 'TEAM_MODIFY_MEMBER_SUCCESS',
  payload: number,
|};

type TeamModifyMemberRequestErrorAction = {|
  type: 'TEAM_MODIFY_MEMBER_ERROR',
  payload: {
    memberId: number,
    error: mixed,
  },
|};

export type TeamPlanAction =
  | TeamPlanRequestAction
  | TeamPlanRequestSuccessAction
  | TeamPlanRequestErrorAction
  | TeamPlanRemoveMemberAction
  | TeamAddMemberAction
  | TeamInviteMembersAction
  | TeamInviteMembersPurchaseAction
  // Member-related actions type.
  | TeamAddDeviceToMemberAction
  | TeamRemoveDeviceFromMemberAction
  | TeamModifyMemberRequestAction
  | TeamModifyMemberRequestSuccessAction
  | TeamModifyMemberRequestErrorAction;
