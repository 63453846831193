// @flow

import APPCUES_ID from 'config/appcuess';


type AppcuesParams = {
  userId: ?number;
}
class Appcues {
  isInitialized: boolean = false;

  userId: ?number = null;

  initialize({ userId = null }: AppcuesParams = {}) {
    this.userId = userId;
    this.createScript();
  }

  updatePage() {
    if (this.isInitialized) {
      window.Appcues.page();
    }
  }

  createScript() {
    const script = document.createElement('script');

    script.src = `//fast.appcues.com/${APPCUES_ID}.js`;
    if (document.body) {
      document.body.append(script);
    }

    script.onload = () => {
      this.onLoadScript();
    };

    script.onerror = () => {
      this.isInitialized = false;
    };
  }

  onLoadScript() {
    this.isInitialized = true;
    this.updatePage();
    window.Appcues.identify(this.userId);
  }
}

export default new Appcues();
