import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const FormattedPeriod = ({ paidMonth }) => (
  <FormattedMessage
    id="formatted.price"
    defaultMessage="{monthsNumber, plural, one {month} =12 {year} other {{monthsNumber} months}}"
    values={{ monthsNumber: paidMonth }}
  />
);

FormattedPeriod.propTypes = {
  paidMonth: PropTypes.number,
};

FormattedPeriod.defaultProps = {
  paidMonth: 1,
};

// eslint-disable-next-line react/style-prop-object
const FormattedPrice = ({ currency, price }) => <FormattedNumber value={price} style="currency" currency={currency} minimumFractionDigits={2} />;
FormattedPrice.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
};

FormattedPrice.defaultProps = {
  price: 0,
  currency: 'USD',
};

// eslint-disable-next-line react/style-prop-object
const FormattedTax = ({ currency, tax }) => <FormattedNumber value={tax} style="currency" currency={currency} />;
FormattedTax.propTypes = {
  tax: PropTypes.number,
  currency: PropTypes.string,
};

FormattedTax.defaultProps = {
  tax: 0,
  currency: 'USD',
};

export { FormattedPeriod, FormattedPrice, FormattedTax };
