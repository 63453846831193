// @flow

import type { Subscription } from 'state/subscription/subscription-initial-state';
import type { TeamStatus } from 'state/team-plan/team-plan-statuses';

import type { MemberStatus } from './team-member-statuses';
import type { MemberRole } from './team-member-roles';

export type TeamMember = {
  id: number,
  email: string,
  name: string,
  avatarUrl: string,
  status: MemberStatus,
  isCurrentUser: boolean,
  isOwner: boolean,
  role: ?MemberRole,
  additionalDevicesCount: number,
  additionalSeats: Array<Subscription>,
};

export type TeamInfo = {
  id: ?number,
  status: ?TeamStatus,
  seatsCount: number,
  switchAmount: number,
  isLoading: boolean,
  maxAdditionalDevicesPerMember: number,
  seatsPoolCount: number,
  additionalDevicesPoolCount: number,
  seatPrice: number,
  companyName: string,
  isAnyInvitationPending: boolean,
  isAnyInvitationPurchasePending: boolean,
};

export type TeamInfoState = $ReadOnly<TeamInfo>;

export type TeamState = {
  +info: TeamInfoState,
  +members: Array<TeamMember>,
  +membersLoading: Array<$PropertyType<TeamMember, 'id'>>,
};

const initialState: TeamInfo = {
  id: null,
  status: null,
  seatsCount: 0,
  switchAmount: 0,
  isLoading: false,
  maxAdditionalDevicesPerMember: 0,
  seatsPoolCount: 0,
  additionalDevicesPoolCount: 0,
  seatPrice: 0,
  companyName: '',
  isAnyInvitationPending: false,
  isAnyInvitationPurchasePending: false,
};

export default initialState;
