// @flow

import React, { PureComponent } from 'react';

import type { SubscriptionStatus } from 'state/subscription/statuses';
import * as subscriptionStatuses from 'state/subscription/statuses';
import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';

import ActivateSubscriptionNow from './activate-subscription-now/activate-subscription-now';
import ChangePlanButton from './change-plan-button/change-plan-button';
import RetryChargeContainer from './retry-charge-container/retry-charge-container';
import ReactivateSubscription from './reactivate-subscription/reactivate-subscription';

type Props = {|
  status: SubscriptionStatus,
  onChangePlanClick: () => void,
  onActivateSubscriptionClick: () => void,
  onReactivateSubscriptionClick: () => Promise<void>,
  onRetryChargeClick: () => Promise<void>,
  isPaymentMethodCreated: boolean,
|};

class SubscriptionAction extends PureComponent<Props> {
  render() {
    const {
      status,
      onChangePlanClick,
      onActivateSubscriptionClick,
      onReactivateSubscriptionClick,
      onRetryChargeClick,
      isPaymentMethodCreated,
    } = this.props;

    switch (status) {
      case subscriptionStatuses.TRIAL: {
        return (
          <AccountControlButtons>
            <ActivateSubscriptionNow onClick={onActivateSubscriptionClick} />
          </AccountControlButtons>
        );
      }

      case subscriptionStatuses.GRACE:
      case subscriptionStatuses.BLOCKED: {
        return (
          <AccountControlButtons>
            <ChangePlanButton onClick={onChangePlanClick} />
            {isPaymentMethodCreated && (
              <RetryChargeContainer onClick={onRetryChargeClick} />
            )}
          </AccountControlButtons>
        );
      }

      case subscriptionStatuses.ACTIVE: {
        return (
          <AccountControlButtons>
            <ChangePlanButton onClick={onChangePlanClick} />
          </AccountControlButtons>
        );
      }

      case subscriptionStatuses.CANCELLED: {
        return (
          <AccountControlButtons>
            <ReactivateSubscription
              isPaymentMethodCreated={isPaymentMethodCreated}
              onReactivateSubscriptionClick={onReactivateSubscriptionClick}
            />
            <ChangePlanButton onClick={onChangePlanClick} />
          </AccountControlButtons>
        );
      }

      default: {
        return null;
      }
    }
  }
}

export default SubscriptionAction;
