// @flow

import React from 'react';
import { connect } from 'react-redux';

import { getTeamInfo } from 'state/root-reducer';

import ProfileDropdown from './profile-dropdown/profile-dropdown';
import HelpDropdown from './help-dropdown/help-dropdown';

import './app-header.scss';

type Props = {
  companyName: string,
  onOpenSidebarClick: () => void,
};

const AppHeader = ({ companyName, onOpenSidebarClick }: Props) => (
  <nav className="app-header">
    <div className="app-header__content">
      <div className="app-header__content-left-side">
        <button
          type="button"
          className="app-header__open-navigation-button"
          onClick={onOpenSidebarClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="#26262b">
              <rect height="4" rx="2" width="20" y="12" />
              <rect height="4" rx="2" width="20" y="4" />
            </g>
          </svg>
        </button>
        <div className="app-header__company-name h6">
          {companyName}
        </div>
      </div>
      <div>
        <ProfileDropdown />
        <div className="app-header__dropdown-item-divider" />
        <HelpDropdown />
      </div>
    </div>
  </nav>
);

export { AppHeader as PureAppHeader };

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  companyName: getTeamInfo(state).companyName,
});

export default connect(
  mapStateToProps,
)(AppHeader);
