// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionStep from '../action-card/action-card';

import paymentIcon from './images/icon-payment.svg';

type Props = {
  onCtaClick: () => mixed,
}

const UpdatePaymentStep = ({ onCtaClick }: Props) => (
  <ActionStep
    checked
    onCtaClick={onCtaClick}
    icon={paymentIcon}
    title={(
      <FormattedMessage
        id="dashboard.updatePaymentStep.title"
        defaultMessage="3. Edit payment details"
      />
    )}
    description={(
      <FormattedMessage
        id="dashboard.updatePaymentStep.description"
        defaultMessage="Update your billing details if changed. Rest assured, we keep them safe."
      />
    )}
    ctaCaption={(
      <FormattedMessage
        id="dashboard.updatePaymentStep.ctaCaption"
        defaultMessage="Update"
      />
    )}
  />
);

export default UpdatePaymentStep;
