// @flow

import React from 'react';
import classNames from 'classnames';

import type { Node } from 'react';

import './info-field.scss';

type Props = {
  title: Node,
  value: Node,
  vertical?: boolean,
  qaLabel?: string,
  lightValue?: boolean,
}

const InfoField = ({
  title,
  value,
  vertical,
  qaLabel,
  lightValue,
}: Props) => {
  const fieldClasses = classNames('info-field', {
    'info-field_horizontal': !vertical,
  });
  const valueClasses = classNames('info-field__value', {
    'info-field__value_bold': !lightValue,
  });

  return (
    <div className={fieldClasses} data-qa={qaLabel}>
      <div className="info-field__title">
        {title}
        :
      </div>
      <div className={valueClasses}>{value}</div>
    </div>
  );
};

InfoField.defaultProps = {
  qaLabel: '',
  vertical: false,
  lightValue: false,
};

export default InfoField;
