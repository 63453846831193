// @flow
import { type Node, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, type RouterHistory } from 'react-router-dom';
import queryString from 'query-string';

import { isUserTeamOwner } from 'state/root-reducer';
import urls from 'config/urls';

type Props = {
  children: Node,
  isUserTeamOwner: boolean,
  history: RouterHistory,
};

/**
 * @deprecated use PermissionsGuard component
 */
const RedirectNonOwner = ({ isUserTeamOwner, history, children }: Props) => {
  const { replace, location } = history;
  const allowToNavigate = useState(isUserTeamOwner)[0];

  const query = queryString.parse(location.search);
  useEffect(() => {
    if (!allowToNavigate) {
      replace({
        query,
        pathname: urls.devices,
      });
    }
  }, [allowToNavigate, query, replace]);

  return allowToNavigate && children;
};

const mapStateToProps = (state) => ({
  isUserTeamOwner: isUserTeamOwner(state),
});

export { RedirectNonOwner as PureRedirectNonOwner };

export default connect(mapStateToProps)(withRouter(RedirectNonOwner));
