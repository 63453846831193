// @flow

import React from 'react';
import classnames from 'classnames';

import type { Node } from 'react';

import './input-tag.scss';

type Props = {
  valid: boolean,
  invalid: boolean,
  children: Node,
  onRemove: () => mixed,
};

function Tag(props: Props) {
  const {
    children,
    valid,
    invalid,
    onRemove,
  } = props;
  const classes = classnames(
    'input-tag',
    {
      'input-tag_valid': valid,
      'input-tag_invalid': invalid,
    }
  );

  return (
    <span className={classes}>
      <span className="input-tag__content">
        {children}
      </span>
      <button
        type="button"
        className="input-tag__remove"
        onClick={onRemove}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fillRule="evenodd">
            <path d="m0 0h16v16h-16z" />
            <path
              className="input-tag__close-icon"
              d="m12.2426407 5.87867966c.5868986-.58689863.5857672-1.53555309 0-2.12132035-.5857673-.58576725-1.5344217-.58689863-2.1213204 0l-2.1213203 2.12132035-2.12132034-2.12132035c-.58576726-.58576725-1.53442172-.58689863-2.12132035 0s-.58576725 1.53555309 0 2.12132035l2.12132035 2.12132034-2.12132035 2.1213203c-.58689863.5868987-.58576725 1.5355531 0 2.1213204.58576726.5857672 1.53442172.5868986 2.12132035 0l2.12132034-2.1213204 2.1213203 2.1213204c.5857673.5857672 1.5344218.5868986 2.1213204 0s.5857672-1.5355531 0-2.1213204l-2.1213204-2.1213203z"
              fill="#26262b"
              fillRule="nonzero"
            />
          </g>
        </svg>
      </button>
    </span>
  );
}

Tag.defaultProps = {
  valid: false,
  invalid: false,
  onRemove: () => {},
};

export default Tag;
