// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import './activate-note.scss';

const ActivateNote = () => (
  <p className="activate-note__container text_xs">
    <FormattedMessage
      id="activateNote"
      defaultMessage="After adding payment details your team account will be activated and charged."
    />
  </p>
);

export default ActivateNote;
