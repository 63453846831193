// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import PoolInfo from '../pool-info';

import memberIcon from './images/member.svg';

type Props = {
  count: number,
  isSubscriptionTrial: boolean,
  onAddButtonClick?: ?() => mixed,
};

const MembersPoolInfo = ({ count, isSubscriptionTrial, onAddButtonClick }: Props) => (
  <PoolInfo
    tooltip={(
      isSubscriptionTrial ? (
        <FormattedMessage
          id="membersPoolInfo.trialTooltip"
          defaultMessage="This is how many members you can invite on a trial period."
        />
      ) : (
        <FormattedMessage
          id="membersPoolInfo.tooltip"
          defaultMessage="This is how many teammates you can invite at no extra cost."
        />
      )
    )}
    title={(
      <FormattedMessage
        id="membersPoolInfo.title"
        defaultMessage="Invites: {invitesInPool}"
        values={{
          invitesInPool: <strong>{count}</strong>,
        }}
      />
    )}
    icon={<img src={memberIcon} alt="" width={20} />}
    qaLabel="membersPoolTitle"
    onAddButtonClick={onAddButtonClick}
  />
);

MembersPoolInfo.defaultProps = {
  onAddButtonClick: null,
};

export default MembersPoolInfo;
