// @flow

import React, { type Node, PureComponent } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import subDays from 'date-fns/subDays';
import isAfter from 'date-fns/isAfter';

import { COOKIES_ROOT_DOMAIN } from 'config/app';

import {
  getUserEmail,
  isUsersEmailConfirmed,
  getPrimarySubscription,
  isSubscriptionsFetched,
  isUserPlainTeamMember,
  isAnyAdditionalSeatPaymentPending,
  isTeamFetched,
  isAnyInvitationPending,
  isAnyInvitationPurchasePending,
} from 'state/root-reducer';
import { resendConfirmationEmail } from 'state/user/user-actions';
import { fetchSubscription } from 'state/subscription/subscription-actions';
import { fetchTeam } from 'state/team-plan/team-plan-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { addDangerNotification } from 'state/notifier/notifier-actions';

import DefaultError from 'components/shared/default-error/default-error';

import UpdateInvitationsAmountNotification from './update-invitations-amount-notification/update-invitations-amount-notification';
import UpdateMembersListNotification from './update-members-list-notification/update-members-list-notification';
import PaymentPendingNotification from './payment-pending-notification/payment-pending-notification';
import ActivateSubscriptionNotification from './activate-subscription-notification/activate-subscription-notification';
import UnconfirmedEmailNotification from './unconfirmed-email-notification/unconfirmed-email-notification';


const ACTIVATE_SUBSCRIPTION_SKIPPED_COOKIE_NAME = 'activateSubscriptionSkipped';

type Props = {|
  email?: string,
  isUsersEmailConfirmed?: boolean,
  isSubscriptionsFetched: boolean,
  isTeamFetched: boolean,
  isAnyAdditionalSeatPaymentPending: boolean,
  isAnyInvitationPending: boolean,
  isAnyInvitationPurchasePending: boolean,
  isUserPlainTeamMember: boolean,
  fetchSubscription: () => mixed,
  fetchTeam: () => mixed,
  resendConfirmationEmail: () => Promise<mixed>,
  primarySubscription: Subscription,
  addDangerNotification: ({message: Node}) => mixed,
|};

type State = {
  isActivateSubscriptionSkipped: boolean,
};


class PermanentNotificationContainer extends PureComponent <Props, State> {
  state = {
    isActivateSubscriptionSkipped: Boolean(Cookies.get(ACTIVATE_SUBSCRIPTION_SKIPPED_COOKIE_NAME)),
  };

  static defaultProps = {
    email: '',
    isUsersEmailConfirmed: true,
  };

  componentDidMount() {
    const {
      fetchSubscription,
      fetchTeam,
      addDangerNotification,
      isUserPlainTeamMember,
    } = this.props;
    const dataToBeFetched = [
      fetchSubscription(),
    ];

    if (!isUserPlainTeamMember) {
      dataToBeFetched.push(fetchTeam());
    }

    return Promise.all(dataToBeFetched)
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  render() {
    const {
      email,
      isUsersEmailConfirmed,
      resendConfirmationEmail,
      primarySubscription,
      isSubscriptionsFetched,
      isTeamFetched,
      isUserPlainTeamMember,
      isAnyAdditionalSeatPaymentPending,
      isAnyInvitationPending,
      isAnyInvitationPurchasePending,
    } = this.props;
    const {
      isActivateSubscriptionSkipped,
    } = this.state;

    if (isSubscriptionsFetched && isTeamFetched && !isUserPlainTeamMember) {
      const fiveDaysTillPaymentDate = subDays((primarySubscription.nextPaymentDate || 0) * 1000, 5);
      const showActivateSubscriptionNotification = isAfter(Date.now(), fiveDaysTillPaymentDate)
        && primarySubscription.status === subscriptionStatuses.TRIAL
        && !isActivateSubscriptionSkipped;

      if (showActivateSubscriptionNotification) {
        return (
          <ActivateSubscriptionNotification
            onHide={this.hideActivateSubscriptionNotification}
          />
        );
      }

      if (isAnyAdditionalSeatPaymentPending) {
        return <PaymentPendingNotification />;
      }

      if (isAnyInvitationPending) {
        return <UpdateMembersListNotification />;
      }

      if (isAnyInvitationPurchasePending) {
        return <UpdateInvitationsAmountNotification />;
      }

      if (!isUsersEmailConfirmed) {
        return (
          <UnconfirmedEmailNotification
            resendConfirmationEmail={resendConfirmationEmail}
            email={email}
          />
        );
      }
    }

    return null;
  }

  skipSwitchPlanNotification(cookieName: string) {
    Cookies.set(cookieName, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 5 });
  }

  hideActivateSubscriptionNotification = () => {
    this.skipSwitchPlanNotification(ACTIVATE_SUBSCRIPTION_SKIPPED_COOKIE_NAME);
    this.setState({ isActivateSubscriptionSkipped: true });
  };
}

const mapActionsToProps = {
  resendConfirmationEmail,
  fetchSubscription,
  fetchTeam,
  addDangerNotification,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  email: getUserEmail(state),
  isUsersEmailConfirmed: isUsersEmailConfirmed(state),
  primarySubscription: getPrimarySubscription(state),
  isSubscriptionsFetched: isSubscriptionsFetched(state),
  isTeamFetched: isTeamFetched(state),
  isUserPlainTeamMember: isUserPlainTeamMember(state),
  isAnyAdditionalSeatPaymentPending: isAnyAdditionalSeatPaymentPending(state),
  isAnyInvitationPending: isAnyInvitationPending(state),
  isAnyInvitationPurchasePending: isAnyInvitationPurchasePending(state),
});

export { PermanentNotificationContainer as PurePermanentNotificationContainer };
export default connect(mapStateToProps, mapActionsToProps)(PermanentNotificationContainer);
