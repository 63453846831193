// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  onClick: () => void,
};

const ActivateSubscriptionNow = ({ onClick }: Props) => (
  <Button
    onClick={onClick}
    className="mr-3"
    data-qa="activateSubscriptionBtn"
  >
    <FormattedMessage
      id="subscriptionInfo.subscriptionInfo.activateNow.buttonCaption"
      defaultMessage="Choose Plan & Activate"
    />
  </Button>
);

export default ActivateSubscriptionNow;
