// @flow

import React from 'react';
import { Button } from '@setapp/ui-kit';

import { FormattedMessage } from 'react-intl';
import externalUrls from 'config/external-urls';

const GoToJamfGuide = () => (
  <>
    <p>
      <FormattedMessage
        id="jamfGuide.guide.description"
        defaultMessage="Learn how to create a distribution package, use the config file, and start deploying Setapp on your team’s Macs."
      />
    </p>
    <Button
      variant="link"
      rel="noopener noreferrer"
      target="_blank"
      className="jamf-guide__button"
      href={externalUrls.jamfGuide}
    >
      <FormattedMessage
        id="jamfGuide.guide.link"
        defaultMessage="Distributing Setapp for Teams using Jamf"
      />
    </Button>
  </>
);

export default GoToJamfGuide;
