// @flow

import { type Node } from 'react';
import { connect } from 'react-redux';

import { getUserPermissions } from 'state/root-reducer';
import type { UserPermission } from 'state/user/user-permissions';

type Props = {
  children: Node,
  perform: UserPermission | Array<UserPermission>,
  userPermissions: Array<UserPermission>,
};

const PermissionsAccessControl = ({ userPermissions, perform, children }: Props) => {
  const permissions = Array.isArray(perform) ? perform : [perform];

  return permissions.every((permission) => userPermissions.includes(permission)) ? children : null;
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  userPermissions: getUserPermissions(state),
});

export { PermissionsAccessControl as PurePermissionsAccessControl };

export default connect(mapStateToProps)(PermissionsAccessControl);
