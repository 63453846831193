// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { Subscription } from 'state/subscription/subscription-initial-state';

import './additional-seat-count.scss';

type Props = {
  additionalSeats: Array<Subscription>
};

const AdditionalSeatCount = ({ additionalSeats }: Props) => {
  if (additionalSeats.length === 0) {
    return '-';
  }

  const pendingSeats = additionalSeats.filter((subscription) => subscription.paymentPending).length;
  const failedSeats = additionalSeats.filter((subscription) => subscription.lastPaymentFailed).length;
  const activeSeats = additionalSeats.length - pendingSeats - failedSeats;

  return (
    <div>
      {activeSeats}
      {pendingSeats > 0 && (
        <>
          {' '}
          <span className="additional-seat-count-status_pending">
            <FormattedMessage
              id="teamManagement.devicesColumn.pendingSeats"
              defaultMessage="+{pendingSeatsNumber} {pendingSeatsNumber, plural, one {payment} other {payments}} pending"
              values={{ pendingSeatsNumber: pendingSeats }}
            />
          </span>
        </>
      )}
      {failedSeats > 0 && (
        <>
          {' '}
          <span className="additional-seat-count-status_failed">
            <FormattedMessage
              id="teamManagement.devicesColumn.failedSeats"
              defaultMessage="+{failedSeatsNumber} {failedSeatsNumber, plural, one {payment} other {payments}} failed"
              values={{ failedSeatsNumber: failedSeats }}
            />
          </span>
        </>
      )}
    </div>
  );
};

export default AdditionalSeatCount;
