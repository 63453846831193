// @flow

import React from 'react';

import type { PaymentDetails as PaymentDetailsType, InvoicingInformation } from 'state/payment-method/payment-method-initial-state';

import PaymentDetails from './payment-details-view/payment-details-view';
import AddPaymentDetails from './add-payment-details/add-payment-details';

type Props = {|
  paymentMethod: ?PaymentDetailsType,
  businessAccountDetails: InvoicingInformation,
  creditCardExpiration: {|
    isExpired: boolean,
    isAboutToExpire: boolean,
  |},
  isSubscriptionTrial: ?boolean,
  isPaymentMethodCreated: ?boolean,
  onAddClick: () => mixed,
  nextPaymentDate: ?number,
|}

const PaymentInfo = ({
  paymentMethod,
  businessAccountDetails,
  isPaymentMethodCreated,
  creditCardExpiration,
  isSubscriptionTrial,
  onAddClick,
  nextPaymentDate,
}: Props) => (
  isPaymentMethodCreated ? (
    <PaymentDetails
      creditCardExpiration={creditCardExpiration}
      paymentMethod={paymentMethod}
      businessAccountDetails={businessAccountDetails}
      isSubscriptionTrial={isSubscriptionTrial}
      nextPaymentDate={nextPaymentDate}
    />
  ) : (
    <AddPaymentDetails
      showDescriptionBlock={isSubscriptionTrial}
      onAddClick={onAddClick}
    />
  )
);

export default PaymentInfo;
