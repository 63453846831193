// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import type { Node } from 'react';
import type { RouterHistory, Location } from 'react-router-dom';

import { login } from 'state/user/user-actions';

import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

import urls from 'config/urls';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import FormContainer from 'components/shared/form/form-container/form-container';

import LoginMarketingBlock from './login-marketing-block/login-marketing-block';
import LoginForm from './login-form/login-form';

import './login-container.scss';

type Props = {
  login: Function,
  location: Location,
  history: RouterHistory,
};

type FormFields = {
  [string]: any,
};

type FormFieldsErrors = {
  [string]: Node,
}

class LoginFormContainer extends Component<Props> {
  render() {
    const {
      location,
      history: { location: { pathname: previousPage } },
    } = this.props;
    const email = queryString.parse(location.search).email || '';

    return (
      <div className="login-container">
        <Row className="login-container__content">
          <Col sm={12} lg={5}>
            <div className="login-container__form-wrapper">
              <AnimatedLogo hasCaption size="sm" />
              <h1 className="h4 login-container__title">
                <FormattedMessage id="outer.login.title" defaultMessage="Welcome to Setapp for Teams" />
              </h1>
              <div className="login-container__signup-block">
                <FormattedMessage
                  id="outer.login.signUpLinkText"
                  defaultMessage="Don't have an account? {signUpLink}"
                  values={{ signUpLink: <Link className="link-outer" to={urls.signup}><FormattedMessage id="outer.login.signUpLinkLabel" defaultMessage="Sign up" /></Link> }}
                />
              </div>
              <FormContainer
                initialValues={{ email, password: '' }}
                onSubmit={this.onFormSubmit}
                validate={this.validateForm}
              >
                {({
                  fields,
                  fieldsErrors,
                  formError,
                  isProcessing,
                  onSubmit,
                  onFieldChange,
                  captcha,
                }) => (
                  <LoginForm
                    email={fields.email}
                    password={fields.password}
                    emailError={fieldsErrors.email}
                    passwordError={fieldsErrors.password}
                    onEmailChange={onFieldChange}
                    onPasswordChange={onFieldChange}
                    genericError={formError}
                    isRequestProcessing={isProcessing}
                    onFormSubmit={onSubmit}
                    captcha={captcha}
                  />
                )}
              </FormContainer>
              <div className="login-container__forgot-password-wrapper">
                <Link
                  to={{
                    pathname: urls.recoverPassword,
                    query: email ? { email } : {},
                    state: { previousPage },
                  }}
                >
                  <FormattedMessage id="outer.login.forgotLinkTitle" defaultMessage="Forgot password?" />
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={7} className="login-container__marketing-block-wrapper">
            <LoginMarketingBlock />
          </Col>
        </Row>
      </div>
    );
  }

  validateForm(fields: $Shape<FormFields>): FormFieldsErrors {
    return validate(fields, {
      email: {
        required: (
          <FormattedMessage
            id="outer.login.validation.emptyEmail"
            defaultMessage="Email is required"
          />
        ),
        emailFormat: (
          <FormattedMessage
            id="outer.login.validation.invalidEmail"
            defaultMessage="Invalid email"
          />
        ),
      },
      password: {
        required: (
          <FormattedMessage
            id="outer.login.validation.emptyPassword"
            defaultMessage="Password is required"
          />
        ),
      },
    });
  }

  onFormSubmit = (fields: $Shape<FormFields>) => this.loginUser(fields);

  async loginUser(data: $Shape<FormFields>): Promise<void> {
    const {
      location,
      login,
      history,
    } = this.props;
    /**
     * $FlowFixMe 'state' key was removed from Location type for some reason
     * @see https://github.com/flow-typed/flow-typed/issues/3742
     * @see https://github.com/flow-typed/flow-typed/pull/3772
     */
    const { nextLocation } = location?.state ?? {};

    try {
      await login(data);

      analytics.trackEvent(events.LOGIN_SUCCESS);
      history.push(nextLocation || urls.root);
    } catch (error) {
      analytics.trackEvent(events.LOGIN_ERROR);

      throw error;
    }
  }
}

export { LoginFormContainer };

export default connect(null, { login })(LoginFormContainer);
