// @flow

import React from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import externalUrls from 'config/external-urls';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import './install-setapp.scss';

const InstallSetapp = () => (
  <>
    <p>
      <FormattedMessage
        id="jamfGuide.installSetapp.description"
        defaultMessage="Get Setapp as a writable DMG image. Use it to create your custom distribution package."
      />
    </p>
    <SetappDownloadLinkWrapper>
      <Button
        className="install-setapp__download-link"
        variant="primary-outline"
        href={externalUrls.downloadJamfDesktopClient}
      >
        <FormattedMessage
          id="jamfGuide.installSetapp.downloadLink"
          defaultMessage="Download DMG"
        />
      </Button>
    </SetappDownloadLinkWrapper>
  </>
);

export default InstallSetapp;
