// @flow

export type Jamf = {
  key: string,
};

export type JamfState = Jamf & {
  isLoading: boolean,
};

const initialState: JamfState = {
  key: '',
  isLoading: false,
};

export default initialState;
