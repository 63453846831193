// @flow

import request from 'utils/request';

import { apiURL } from 'config/api';

import type { Dispatch } from '../state-types';

import * as actionTypes from './apps-action-types';


export function fetchApps() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.applications)
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}

export function setRestrictedApplications(ids: Array<number>) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.post(`${apiURL.applications}/actions/set_restricted`, { body: { ids } })
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}
