import { defineMessages } from 'react-intl';

export default defineMessages({
  cardNumber: {
    id: 'creditCardForm.ccNumber',
    defaultMessage: 'Card number',
  },
  cardCvv: {
    id: 'creditCardForm.ccCVV',
    defaultMessage: 'CVC/CVV',
  },
  cardExpirationDate: {
    id: 'creditCardForm.ccExpDate',
    defaultMessage: 'Expiration date',
  },

  cardExpirationPlaceholder: {
    id: 'creditCardForm.expirationDatePlaceholder',
    defaultMessage: 'MM / YY',
  },
});
