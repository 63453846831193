// @flow

import React, { PureComponent, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Tabs } from '@setapp/ui-kit';

import type { RouterHistory } from 'react-router-dom';

import urls from 'config/urls';

import analytics from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';
import emailsValidate from 'utils/emails-validation';

import ExpiredPaymentCard from 'components/shared/expired-payment-card/expired-payment-card';
import MissedPaymentDetails from 'components/shared/missed-payment-details/missed-payment-details';
import Modal from 'components/shared/modal/modal';
import AdvancedFormContainer from 'components/shared/form/advanced-form-container/advanced-form-container';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import PaymentForm from 'components/shared/payment-form/payment-form';
import PurchaseInvitations from 'components/shared/purchase-invitations/purchase-invitations';

import { addMemberSeat, fetchTeam, inviteMembers } from 'state/team-plan/team-plan-actions';
import {
  getTeamInfo,
  getPrimaryPricePlan,
  getSubscriptions,
  isSubscriptionTrial,
  isSubscriptionsFetched,
  creditCardExpiration,
  getPricePerMonthPerMember,
  isUserTeamOwner,
  isPaymentMethodCreated,
  isInactiveSubscription,
} from 'state/root-reducer';
import { fetchSubscription } from 'state/subscription/subscription-actions';
import { addSuccessNotification, addDangerNotification } from 'state/notifier/notifier-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import PaidLimit from './invitations-limit/paid-limit/paid-limit';
import InviteMembersFormDescription from './invite-members-form-description/invite-members-form-description';
import InvitationsLimit from './invitations-limit/invitations-limit';
import InactiveSubscription from './inactive-subscription/inactive-subscription';
import DistributeViaJamf from './distribute-via-jamf/distribute-via-jamf';
import InviteMembersForm from './invite-members-form/invite-members-form';


import './invite-member-modal.scss';

const INVITE_TAB = 'invite_tab';
const JAMF_TAB = 'jamf_tab';
const DEFAULT_TITLE = <FormattedMessage id="inviteMemberModal.title" defaultMessage="Inviting members" />;
const EMAILS_MAX_LENGTH = 50;

const contentVariants = Object.freeze({
  INVITE_MEMBER_FORM: 'inviteMemberForm',
  OUT_OF_FREE_SEATS: 'outOfFreeSeats',
  NO_PAYMENT_DETAILS: 'noPaymentDetails',
  WITH_DISTRIBUTION_METHODS: 'withDistributionMethods',
  SUBSCRIPTION_INACTIVE_FOR_ADMIN: 'subscriptionInactiveForAdmin',
  CARD_EXPIRED: 'cardExpired',
  PURCHASE_INVITES: 'purchaseInvites',
  ADD_PAYMENT_DETAILS_FOR_OWNER: 'addPaymentDetailsForOwner',
  UPDATE_CARD_FOR_OWNER: 'updateCardForOwner',
});

type Props = {
  activeKey: 'invite_tab' | 'jamf_tab',
  show: boolean,
  onHide: () => void,
  onExited: () => void,
  onModalHide: () => void,
  onInviteSent: ?() => mixed,
  inviteMembers: ({emails: string[]}) => Promise<void>,
  isSubscriptionsFetched: boolean,
  isSubscriptionTrial: boolean,
  isJAMFHidden: boolean,
  seatsPoolCount: number,
  fetchTeam: () => Promise<void>,
  fetchSubscription: () => Promise<void>,
  fetchPaymentMethod: () => Promise<any>,
  isSubscriptionProcessing: boolean,
  isUserTeamOwner: boolean,
  isPaymentMethodCreated: boolean,
  isInactiveSubscription: boolean,
  primaryPricePlan: PricePlan,
  addMemberSeat: (number) => Promise<any>,
  creditCardExpiration: {|
    isExpired: boolean,
    isAboutToExpire: boolean,
  |},
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  history: RouterHistory,
  pricePerMonthPerMember: {
    price: number,
    currency: string,
  },
};

type FormFields = {
  emails: string[],
};

type FormFieldsErrors = {
  emails?: {
    message: Node,
    meta?: string[],
  },
}

type State = {
  contentVariant: ?$Values<typeof contentVariants>,
  previousContentVariant: ?$Values<typeof contentVariants>,
  modalTitle: Node,
  modalWithBackButton: boolean,
};

class InviteMemberModal extends PureComponent<Props, State> {
  // store data as class property to prevent re-render on changes
  previousFieldsData: FormFields = { emails: [] };

  errorCodeMap = {
    'emails.used_by_members': <FormattedMessage id="inviteMemberModal.form.validation.alreadyUsed" defaultMessage="This member is already a part of Setapp" />,
    'emails.used_by_customers': <FormattedMessage id="inviteMemberModal.form.validation.alreadyUsed" defaultMessage="This member is already a part of Setapp" />,
  }

  static defaultProps = {
    activeKey: INVITE_TAB,
  };

  static validateForm(fields: FormFields): FormFieldsErrors {
    return emailsValidate(fields, {
      emails: {
        required: {
          message: <FormattedMessage id="inviteMemberModal.form.validation.emptyEmail" defaultMessage="Emails are required" />,
        },
        format: {
          message: <FormattedMessage id="inviteMemberModal.form.validation.invalidFormat" defaultMessage="Sorry, we got confused. This doesn’t look like an email" />,
        },
        duplicate: {
          message: <FormattedMessage id="inviteMemberModal.form.validation.duplicate" defaultMessage="You’ve already entered this email" />,
        },
        maxLength: {
          message: (
            <FormattedMessage
              id="inviteMemberModal.form.validation.maxLength"
              defaultMessage="Sorry, you can send only {size} invitations at a time"
              values={{ size: EMAILS_MAX_LENGTH }}
            />
          ),
          size: EMAILS_MAX_LENGTH,
        },
      },
    });
  }

  state = {
    contentVariant: null,
    previousContentVariant: null,
    modalTitle: null,
    modalWithBackButton: false,
  };

  componentDidMount() {
    const {
      isSubscriptionsFetched,
      fetchTeam,
      fetchSubscription,
      fetchPaymentMethod,
    } = this.props;

    const dataToBeFetched = [
      fetchTeam(),
      fetchPaymentMethod(),
    ];

    if (!isSubscriptionsFetched) {
      dataToBeFetched.push(fetchSubscription());
    }

    return Promise.all(dataToBeFetched).then(() => {
      this.selectContentVariant();
    });
  }

  render() {
    const {
      show,
      onExited,
    } = this.props;
    const { contentVariant, modalTitle, modalWithBackButton } = this.state;
    const isDataLoading = contentVariant === null;

    return (
      <Modal
        fullScreen
        show={show}
        onHide={this.handleHideModal}
        onExited={onExited}
        onGoBack={this.onGoBack}
        isLoading={isDataLoading}
        withCancelButton={this.isCancelButtonVisible()}
        withBackButton={modalWithBackButton}
        title={modalTitle}
      >
        {!isDataLoading && this.renderContentVariant()}
      </Modal>
    );
  }

  renderContentVariant() {
    const {
      onHide,
      isSubscriptionTrial,
      isUserTeamOwner,
      pricePerMonthPerMember,
      addMemberSeat,
      addSuccessNotification,
      addDangerNotification,
    } = this.props;
    const { contentVariant } = this.state;

    switch (contentVariant) {
      case contentVariants.INVITE_MEMBER_FORM:
        return this.getInviteMembersForm();
      case contentVariants.OUT_OF_FREE_SEATS:
        return (
          <InvitationsLimit
            pricePerMonthPerMember={pricePerMonthPerMember}
            isTrial={isSubscriptionTrial}
            isOwner={isUserTeamOwner}
            onClose={onHide}
            onActivate={this.onActivateClick}
            onPurchase={this.onShowPurchaseInvites}
          />
        );
      case contentVariants.NO_PAYMENT_DETAILS:
        return (
          <MissedPaymentDetails
            isOwner={isUserTeamOwner}
            onAddPaymentDetails={this.onAddPaymentDetails}
            onClose={onHide}
          />
        );
      case contentVariants.CARD_EXPIRED:
        return (
          <ExpiredPaymentCard
            isOwner={isUserTeamOwner}
            onUpdatePaymentDetails={this.onUpdatePaymentDetails}
            onClose={onHide}
          />
        );
      case contentVariants.SUBSCRIPTION_INACTIVE_FOR_ADMIN:
        return <InactiveSubscription onClose={onHide} />;
      case contentVariants.ADD_PAYMENT_DETAILS_FOR_OWNER:
        return <PaymentForm onSuccessSubmit={this.onPaymentDetailsAdded} />;
      case contentVariants.UPDATE_CARD_FOR_OWNER:
        return <PaymentForm onSuccessSubmit={this.onPaymentDetailsUpdated} />;
      case contentVariants.PURCHASE_INVITES:
        return (
          <PurchaseInvitations
            isProcessing={false}
            pricePerMonthPerMember={pricePerMonthPerMember}
            addMemberSeat={addMemberSeat}
            addSuccessNotification={addSuccessNotification}
            addDangerNotification={addDangerNotification}
            onPurchaseFinish={this.onPurchaseInvitesFinish}
            onPurchaseSuccess={this.onPurchaseSuccess}
            onPurchaseSubmit={this.onPurchaseInvitesSubmit}
          />
        );
      default:
        return this.getDistributionMethods();
    }
  }

  handleHideModal = () => {
    const { onHide, onModalHide } = this.props;
    if (onModalHide) {
      onModalHide();
    }
    onHide();
  }

  getDistributionMethods = () => {
    const {
      isSubscriptionProcessing,
      isSubscriptionTrial,
      isJAMFHidden,
      isUserTeamOwner,
      seatsPoolCount,
      primaryPricePlan,
      pricePerMonthPerMember,
      activeKey,
    } = this.props;
    const isSubscriptionFetched = isSubscriptionProcessing && !primaryPricePlan;

    const isOutOfSeatsForPaidOwner = isUserTeamOwner && !seatsPoolCount && !isSubscriptionTrial;
    const inviteTabContent = isOutOfSeatsForPaidOwner
      ? (
        <PaidLimit
          pricePerMonthPerMember={pricePerMonthPerMember}
          onClick={this.onShowPurchaseInvites}
        />
      )
      : this.getInviteMembersForm();

    if (isJAMFHidden) {
      return isSubscriptionFetched
        ? (
          <div className="text-center">
            <AnimatedLogo animate />
          </div>
        )
        : <div className="mt-5">{inviteTabContent}</div>;
    }

    return (
      <Tabs
        defaultActiveKey={activeKey}
        tabsId="invite-member"
        tabs={[{
          key: INVITE_TAB,
          title: (
            <span className="invite-member-modal__tab-title text_lg">
              <FormattedMessage
                id="inviteMemberModal.tabTitle.email"
                defaultMessage="Invite via email"
              />
            </span>
          ),
          content: (
            <div className="invite-member-modal__tab-content">
              {isSubscriptionFetched ? (
                <div className="text-center">
                  <AnimatedLogo animate />
                </div>
              ) : inviteTabContent}
            </div>
          ),
        }, {
          key: JAMF_TAB,
          title: (
            <span className="invite-member-modal__tab-title text_lg">
              <FormattedMessage
                id="inviteMemberModal.tabTitle.jamf"
                defaultMessage="Distribute via Jamf"
              />
            </span>
          ),
          content: (
            <div className="invite-member-modal__tab-content">
              <DistributeViaJamf onRedirectToJamf={this.onRedirectToJamf} />
            </div>
          ),
        }]}
      />
    );
  }

  getInviteMembersForm = () => {
    const { seatsPoolCount } = this.props;

    return (
      <AdvancedFormContainer
        initialValues={this.previousFieldsData}
        onChange={this.onFormChange}
        onSubmit={this.onFormSubmit}
        validate={InviteMemberModal.validateForm}
        mapErrorCodeToMessage={this.errorCodeMap}
      >
        {({
          values,
          errors,
          genericError,
          isSubmitting,
          isValid,
          handleSubmit,
          setFieldValue,
        }) => {
          const freeSeatsLeft = seatsPoolCount - values.emails.length;
          const isSubmitDisabled = isSubmitting || !isValid || freeSeatsLeft < 0 || values.emails.length === 0;

          return (
            <InviteMembersForm
              emails={values.emails}
              emailError={errors?.emails?.message}
              formError={genericError}
              disabled={isSubmitDisabled}
              invalidEmails={errors?.emails?.meta}
              onSubmit={handleSubmit}
              onCustomFieldChange={setFieldValue}
              description={this.getInviteFormDescription(freeSeatsLeft)}
              submitTitle={this.getInviteFormSubmitTitle()}
            />
          );
        }}
      </AdvancedFormContainer>
    );
  };

  getInviteFormSubmitTitle = () => (
    <FormattedMessage
      id="inviteMemberModal.submit"
      defaultMessage="Send invitations"
    />
  );

  getInviteFormDescription = (freeSeatsLeft: number) => {
    const {
      seatsPoolCount,
      isUserTeamOwner,
      isSubscriptionTrial,
      creditCardExpiration,
      isPaymentMethodCreated,
    } = this.props;

    const { isExpired } = creditCardExpiration;

    return (
      <InviteMembersFormDescription
        freeSeatsLeft={freeSeatsLeft}
        seatsPoolCount={seatsPoolCount}
        isOwner={isUserTeamOwner}
        isTrial={isSubscriptionTrial}
        isCreditCardExpired={isExpired}
        isPaymentMethodCreated={isPaymentMethodCreated}
        onActivateClick={this.onActivateClick}
        onAddPaymentDetails={this.onAddPaymentDetails}
        onUpdatePaymentDetails={this.onUpdatePaymentDetails}
        onShowPurchaseInvites={this.onShowPurchaseInvites}
      />
    );
  };

  isCancelButtonVisible = () => {
    const { contentVariant } = this.state;

    return ![
      contentVariants.NO_PAYMENT_DETAILS,
      contentVariants.CARD_EXPIRED,
      contentVariants.SUBSCRIPTION_INACTIVE_FOR_ADMIN,
      contentVariants.ADD_PAYMENT_DETAILS_FOR_OWNER,
      contentVariants.UPDATE_CARD_FOR_OWNER,
    ].includes(contentVariant);
  };

  onActivateClick = () => {
    const { history } = this.props;

    history.push(urls.activateSubscription);

    analytics.trackEvent(gaEvents.TEAM_CLICK_ACTIVATE_MEMBERSHIP);
  };

  onAddPaymentDetails = () => {
    this.setState({
      contentVariant: contentVariants.ADD_PAYMENT_DETAILS_FOR_OWNER,
      modalTitle: (
        <FormattedMessage
          id="inviteMemberModal.addPaymentDetailsModal.title"
          defaultMessage="Add payment details"
        />
      ),
    });
  };

  onUpdatePaymentDetails = () => {
    this.setState({
      contentVariant: contentVariants.UPDATE_CARD_FOR_OWNER,
      modalTitle: (
        <FormattedMessage
          id="inviteMemberModal.updatePaymentDetailsModal.title"
          defaultMessage="Update payment details"
        />
      ),
    });
  };

  onPaymentDetailsAdded = () => {
    const { addSuccessNotification, onHide } = this.props;

    onHide();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="inviteMemberModal.notifications.paymentDetailsAdded"
          defaultMessage="Payment details successfully added."
        />
      ),
    });
  };

  onPaymentDetailsUpdated = () => {
    const { addSuccessNotification, onHide } = this.props;

    onHide();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="inviteMemberModal.notifications.paymentDetailsUpdated"
          defaultMessage="Payment details successfully updated."
        />
      ),
    });
  };

  onFormChange = (fields: FormFields) => {
    this.previousFieldsData = fields;
  };

  onFormSubmit = (fields: FormFields) => this.sendInvite(fields);

  async sendInvite(fields: FormFields): Promise<void> {
    const { emails } = fields;
    const { inviteMembers } = this.props;

    await inviteMembers({ emails });
    this.onSendInviteSuccess(emails.length);
  }

  onSendInviteSuccess = (emailsCount: number) => {
    const {
      onHide,
      addSuccessNotification,
      onInviteSent,
    } = this.props;

    if (onInviteSent) {
      onInviteSent();
    }

    analytics.trackEvent(gaEvents.TEAM_INVITE_MEMBER_SUCCESS, {
      eventLabel: 'With Available Invites',
      eventValue: emailsCount,
    });

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="inviteMemberModal.invitationSending.succeed"
          defaultMessage="Invitations were sent successfully"
        />
      ),
    });

    onHide();
  };

  onRedirectToJamf = () => {
    analytics.trackEvent(gaEvents.TEAM_INVITE_VIA_JAMF_CLICK);
  };

  onShowPurchaseInvites = () => {
    const {
      creditCardExpiration,
      isPaymentMethodCreated,
    } = this.props;

    const { isExpired } = creditCardExpiration;

    const isNoPaymentDetails = !isPaymentMethodCreated;

    if (isNoPaymentDetails) {
      this.setState((prevState) => ({
        contentVariant: contentVariants.NO_PAYMENT_DETAILS,
        previousContentVariant: prevState.contentVariant,
        modalTitle: MissedPaymentDetails.Title,
        modalWithBackButton: true,
      }));
    } else if (isExpired) {
      this.setState((prevState) => ({
        contentVariant: contentVariants.CARD_EXPIRED,
        previousContentVariant: prevState.contentVariant,
        modalTitle: ExpiredPaymentCard.Title,
        modalWithBackButton: true,
      }));
    } else {
      this.setState((prevState) => ({
        contentVariant: contentVariants.PURCHASE_INVITES,
        previousContentVariant: prevState.contentVariant,
        modalTitle: <FormattedMessage id="inviteMemberModal.title.purchaseInvitationsVariant" defaultMessage="Purchase invitations" />,
        modalWithBackButton: true,
      }));
    }

    analytics.trackEvent(gaEvents.TEAM_CLICK_PURCHASE_INVITES);
  };

  onPurchaseInvitesSubmit = (seatsAmount: number) => {
    analytics.trackEvent(gaEvents.PURCHASE_INVITES_BLOCK_CLICK_PURCHASE_INVITES, {
      eventLabel: seatsAmount,
    });
  };

  onPurchaseSuccess = () => {
    analytics.trackEvent(gaEvents.PURCHASE_INVITES_BLOCK_PURCHASE_INVITES_SUCCESS);
  };

  onPurchaseInvitesFinish = () => {
    const { onHide } = this.props;
    onHide();
  };

  onGoBack = () => {
    this.setState((prevState) => ({
      contentVariant: prevState.previousContentVariant,
      previousContentVariant: null,
      modalTitle: DEFAULT_TITLE,
      modalWithBackButton: false,
    }));
  };

  selectContentVariant() {
    const {
      seatsPoolCount,
      isUserTeamOwner,
      isSubscriptionTrial,
      creditCardExpiration,
      isPaymentMethodCreated,
      isInactiveSubscription,
    } = this.props;

    const isAdmin = !isUserTeamOwner;
    const { isExpired } = creditCardExpiration;

    const isOutOfFreeSeatsForTrial = !seatsPoolCount && isSubscriptionTrial;
    const isOutOfFreeSeatsForPaidAdmin = !seatsPoolCount && !isSubscriptionTrial && isAdmin && isPaymentMethodCreated
      && !isExpired;

    const isNoPaymentDetailsForAdmin = !isPaymentMethodCreated && !seatsPoolCount;
    const isCardExpiredForAdmin = isExpired && !seatsPoolCount && isPaymentMethodCreated;
    const isSubscriptionInactiveForAdmin = isAdmin && isInactiveSubscription;

    const newState: State = { ...this.state, modalTitle: DEFAULT_TITLE };

    if (isOutOfFreeSeatsForTrial || isOutOfFreeSeatsForPaidAdmin) {
      newState.contentVariant = contentVariants.OUT_OF_FREE_SEATS;
      newState.modalTitle = InvitationsLimit.Title;
    } else if (isSubscriptionInactiveForAdmin) {
      newState.contentVariant = contentVariants.SUBSCRIPTION_INACTIVE_FOR_ADMIN;
    } else if (isNoPaymentDetailsForAdmin) {
      newState.contentVariant = contentVariants.NO_PAYMENT_DETAILS;
      newState.modalTitle = MissedPaymentDetails.Title;
    } else if (isCardExpiredForAdmin) {
      newState.contentVariant = contentVariants.CARD_EXPIRED;
      newState.modalTitle = ExpiredPaymentCard.Title;
    } else if (isAdmin) {
      newState.contentVariant = contentVariants.INVITE_MEMBER_FORM;
    } else {
      newState.contentVariant = contentVariants.WITH_DISTRIBUTION_METHODS;
      newState.modalTitle = seatsPoolCount ? DEFAULT_TITLE : InvitationsLimit.Title;
    }

    this.setState(newState);
  }
}

const mapStateToProps = (state) => ({
  isSubscriptionsFetched: isSubscriptionsFetched(state),
  isSubscriptionTrial: isSubscriptionTrial(state),
  seatsPoolCount: getTeamInfo(state).seatsPoolCount,
  isSubscriptionProcessing: getSubscriptions(state).isLoading,
  primaryPricePlan: getPrimaryPricePlan(state),
  creditCardExpiration: creditCardExpiration(state),
  pricePerMonthPerMember: getPricePerMonthPerMember(state),
  isUserTeamOwner: isUserTeamOwner(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isInactiveSubscription: isInactiveSubscription(state),
});

const mapActionsToProps = {
  fetchTeam,
  fetchSubscription,
  inviteMembers,
  fetchPaymentMethod,
  addMemberSeat,
  addSuccessNotification,
  addDangerNotification,
};

export {
  INVITE_TAB,
  JAMF_TAB,
  InviteMemberModal as PureInviteMemberModal,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(InviteMemberModal));
