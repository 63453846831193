// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeviceIcon } from '@setapp/ui-kit';

import { getCreateSupportRequestUrl } from 'utils/support';


import './add-seat-limit-reached.scss';

type Props = {
  maxDevicesPerMember: number,
}

const AddSeatLimitReached = (props: Props) => {
  const { maxDevicesPerMember } = props;

  return (
    <>
      <div className="add-seat-limit-reached">
        <div className="add-seat-limit-reached__icon">
          <DeviceIcon device="macbook" size={80} />
        </div>
        <div className="add-seat-limit-reached__content">
          <p>
            <FormattedMessage
              id="addSeatModal.maxDevices.seatInfo"
              defaultMessage="You have already reached the limit of {maxDevicesPerMember} extra {maxDevicesPerMember, plural, one {device} other {devices}} for this member. Please contact us for a custom plan."
              values={{
                maxDevicesPerMember,
              }}
            />
          </p>
        </div>
      </div>
      <Button
        block
        href={getCreateSupportRequestUrl()}
        target="_blank"
        rel="noopener noreferrer"
        className="add-seat-limit-reached__submit"
      >
        <FormattedMessage
          id="addSeatModal.maxDevices.supportLinkTitle"
          defaultMessage="Contact support"
        />
      </Button>
    </>
  );
};

export default AddSeatLimitReached;
