// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import urls from 'config/urls';

type Props = {
  seatsPerMember: number,
};


const AdditionalSeatsEmpty = ({ seatsPerMember }: Props) => (
  <FormattedMessage
    id="subscriptionInfo.additionalSeats.empty"
    defaultMessage="Your team members have no extra devices now. Add up to {seatsPerMember} for every teammate in {teamManagementLink} section."
    values={{
      teamManagementLink: (
        <Link className="link-outer" to={urls.team}>
          <FormattedMessage id="subscriptionInfo.additionalSeats.teamManagementLink" defaultMessage="Team Management" />
        </Link>
      ),
      seatsPerMember,
    }}
  />
);

export default AdditionalSeatsEmpty;
