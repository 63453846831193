// @flow

import initialState from './apps-initial-state';
import * as actionTypes from './apps-action-types';
import type { AppsAction } from './apps-action-types';
import type { Application, ApplicationsState } from './apps-initial-state';

const apps = (state: ApplicationsState = initialState, action: AppsAction): ApplicationsState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { list: [...state.list], isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { list: [...action.payload], isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { list: [...state.list], isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export const getList = (state: ApplicationsState): Array<Application> => state.list;

export default apps;
