// @flow

import { detect as detectBrowser } from 'detect-browser';

type BrowserInfo = {
  name: string,
  version: string,
  os: string,
  isMacOS: boolean,
  isiOS: boolean,
};

export function detect(): BrowserInfo {
  let browserInfo = {};
  const browser = detectBrowser();

  if (browser) {
    browserInfo = {
      name: browser.name,
      version: browser.version,
      os: browser.os,
      isMacOS: browser.os.toLowerCase().includes('mac'),
      isiOS: browser.os.toLowerCase().includes('ios'),
    };
  }

  return browserInfo;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  detect,
};
