// @flow

import queryString from 'query-string';
import { type RouterHistory } from 'react-router-dom';

export const isHTTPS = () => window.location.protocol === 'https:';

/**
 * Returns root cookie domain for application. E.g. .example.com for subdomain.example.com, other.example.com, etc.
 */
export const getCookiesRootDomain = () => window.location.hostname.split('.').slice(-2).join('.');

/**
 * @param {...string} params
 */
export const removeQueryParams = (history: RouterHistory, ...params: Array<string>) => {
  const { location } = history;
  const query = queryString.parse(location.search);

  params.forEach((param) => delete query[param]);
  location.search = queryString.stringify(query);

  /**
   * $FlowFixMe probably a bug in Flow.
   * replace() receives LocationShape with optional "hash" but history.location always has "hash" and it's a string
   */
  history.replace(location);
};

export const EMAIL_CONFIRMATION_TOKEN = 'email_confirmation_token';
