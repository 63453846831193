// @flow

import request from 'utils/request';

import { apiURL } from 'config/api';

import { type Dispatch } from '../state-types';

import * as actionTypes from './price-plans-action-types';


export function fetchPricePlans() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.pricePlans)
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { fetchPricePlans };
