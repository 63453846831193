// @flow

import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import queryString from 'query-string';
import { Button } from '@setapp/ui-kit';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import analytics from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';

import PageTitle from 'components/shared/page-title/page-title';

import { getUserEmail, getUserId } from 'state/root-reducer';

import './survey-view.scss';

type Props = {
  email: string,
  userId: number,
};

class SurveyView extends PureComponent <Props> {
  typeformEmbed = createRef<?Element>();

  render() {
    const { email, userId } = this.props;
    const hiddenInputsQuery = queryString.stringify({ email, userId });

    return (
      <>
        <PageTitle>
          <FormattedMessage id="dashboard.page.title" defaultMessage="Dashboard" />
        </PageTitle>
        <Row>
          <Col lg={7} data-qa="surveyBlock">
            <div className="h5">
              <FormattedMessage
                id="dashboard.page.subtitle"
                defaultMessage="Hello, Setappian!"
              />
            </div>
            <div>
              <FormattedMessage
                id="dashboard.page.text.line1"
                defaultMessage="We’re currently working on this section. To help us make it the best it can be, we’d love to hear what data on your team using Setapp you want to see here."
              />
            </div>
            <br />
            <div>
              <FormattedMessage
                id="dashboard.page.text.line3"
                defaultMessage="This will help us create a better experience for you and other team owners and will take less than 2 minutes, we promise."
              />
            </div>
            <Button onClick={this.showSurvey} className="survey-view__open-popup-button">
              <FormattedMessage
                id="dashboard.page.showSurveyButton.title"
                defaultMessage="Take Survey"
              />
            </Button>
            <div>
              <FormattedMessage
                id="dashboard.page.text.line4"
                defaultMessage="Thanks for your help!"
              />
            </div>
          </Col>
        </Row>
        <ReactTypeformEmbed
          popup
          autoOpen={false}
          url={`https://yonick.typeform.com/to/SbTFR9?${hiddenInputsQuery}`}
          style={{
            width: 0,
            height: 0,
          }}
          ref={this.typeformEmbed}
        />
      </>
    );
  }

  showSurvey = () => {
    analytics.trackEvent(gaEvents.WELCOME_PAGE_CLICK_TAKE_SURVEY);
    // $FlowFixMe
    this.typeformEmbed.current.typeform.open();
  };
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  email: getUserEmail(state),
  userId: getUserId(state),
});

export { SurveyView as PureSurveyView };

export default connect(mapStateToProps)(SurveyView);
