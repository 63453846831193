// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import type { RouterHistory, Location } from 'react-router-dom';
import queryString from 'query-string';

import type { Node } from 'react';

import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import PanelBox from 'components/shared/panel-box/panel-box';
import PaymentView from 'components/subscription/payment-details/payment-details-container';

import {
  fetchSubscription,
  reactivateSubscription,
  retryCharge,
} from 'state/subscription/subscription-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchUser } from 'state/user/user-actions';
import { fetchTeam } from 'state/team-plan/team-plan-actions';
import {
  addSuccessNotification,
  addDangerNotification,
} from 'state/notifier/notifier-actions';
import {
  getPrimarySubscription,
  getAdditionalSeatsSubscriptionInfo,
  getPaymentMethod,
  isPaymentMethodCreated,
  getUserEmail,
  getUserId,
  isChangePlanAllowed,
  isInactiveSubscription,
  creditCardExpiration,
  isUserPlainTeamMember,
  isUserTeamOwner,
  isSubscriptionTrial,
  getBusinessAccountDetails,
  getTeamInfo,
  getActiveTeamMembers,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';

import analytics, { events } from 'utils/analytics';
import { removeQueryParams } from 'utils/location';

import urls from 'config/urls';

import './subscription-page.scss';
import type { Subscription, SubscriptionWithAdditionalSeats } from 'state/subscription/subscription-initial-state';
import type { ModalType } from 'components/modals';
import { showModal, hideModal } from 'state/modal/modal-reducer';
import type { PaymentDetailsState, InvoicingInformation } from 'state/payment-method/payment-method-initial-state';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import withEmailAutoConfirmation from '../shared/with-email-auto-confirmation/with-email-auto-confirmation';
import AdditionalSeats from './subscription-info/additional-seats/additional-seats';
import SubscriptionInfo from './subscription-info/subscription-info';
import SubscriptionNotification from './subscription-notification/subscription-notification';
import SubscriptionAction from './subscription-action/subscription-action';
import CancelSubscriptionButton from './cancel-subscription-button/cancel-subscription-button';
// TODO: use react-typeform-embed
import Survey, { surveyList } from './survey/survey';

type Props = {
  fetchSubscription: () => Promise<any>,
  fetchPricePlans: () => Promise<mixed>,
  fetchPaymentMethod: () => Promise<any>,
  fetchUser: () => Promise<void>,
  reactivateSubscription: ({subscriptionId: number}) => Promise<void>,
  retryCharge: () => Promise<void>,
  fetchTeam: () => Promise<void>,
  showModal: (ModalType, Object) => mixed,
  hideModal: () => mixed,
  history: RouterHistory,

  mainSubscription: ?Subscription,
  additionalSubscription: ?SubscriptionWithAdditionalSeats,
  maxSeatsPerMember: number,

  email: string,
  userId: number,
  isPaymentMethodCreated: boolean,
  paymentMethod: PaymentDetailsState,
  businessAccountDetails: InvoicingInformation,
  isSubscriptionTrial: ?boolean,
  creditCardExpiration: {|
    isExpired: boolean,
    isAboutToExpire: boolean,
  |},

  isUserTeamOwner: boolean,
  activeMembers: Array<TeamMember>,

  location: Location,
  isChangePlanAllowed: boolean,

  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
};

type State = {
  showSurvey: boolean,
};

class SubscriptionPage extends Component<Props, State> {
  state = {
    showSurvey: false,
  };

  componentDidMount() {
    const {
      isUserTeamOwner,
      fetchSubscription,
      fetchPaymentMethod,
      fetchTeam,
      fetchPricePlans,
      addDangerNotification,
    } = this.props;
    const dataToBeFetched = [
      fetchSubscription(),
      fetchPricePlans(),
      fetchPaymentMethod(),
    ];

    if (isUserTeamOwner) {
      dataToBeFetched.push(fetchTeam());
    }

    return Promise.all(dataToBeFetched)
      .then(() => {
        this.showChangePlanModalIfRequired();
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const newQuery = queryString.parse(newProps.location.search);


    if (!query.show_change_plan_modal && newQuery.show_change_plan_modal) {
      this.showChangePlanModal();
    }
  }

  render() {
    const {
      retryCharge,
      email,
      userId,
      mainSubscription,
      isPaymentMethodCreated,
      creditCardExpiration,
      paymentMethod,
      businessAccountDetails,
      isSubscriptionTrial,
      activeMembers,
      maxSeatsPerMember,
      additionalSubscription,
    } = this.props;

    if (!mainSubscription || !activeMembers.length) {
      return (
        <div className="subscription-page__animated-logo-container">
          <AnimatedLogo animate />
        </div>
      );
    }

    // Display next price plan info if user changed plan. It's made by design.
    const { showSurvey } = this.state;

    return (
      <>
        <PageTitle>
          <FormattedMessage id="subscriptionInfo.title" defaultMessage="Account overview" />
        </PageTitle>
        <Row>
          <Col lg={7}>
            <PanelBox>
              <PanelBox.Content>
                <SubscriptionInfo
                  subscription={mainSubscription}
                  isPaymentMethodCreated={isPaymentMethodCreated}
                  activeMembers={activeMembers}
                />

                <SubscriptionNotification
                  status={mainSubscription.status}
                  lastPaymentFailed={mainSubscription.lastPaymentFailed}
                  expirationDate={mainSubscription.expirationDate}
                  paymentPending={mainSubscription.paymentPending}
                  nextPaymentDate={mainSubscription.nextPaymentDate}
                  nextPricePlan={mainSubscription.nextPricePlan}
                />

                <SubscriptionAction
                  status={mainSubscription.status}
                  isPaymentMethodCreated={isPaymentMethodCreated}
                  onRetryChargeClick={retryCharge}
                  onReactivateSubscriptionClick={this.reactivateSubscription}
                  onActivateSubscriptionClick={this.handleActivateButtonClick}
                  onChangePlanClick={this.showChangePlanModal}
                />
              </PanelBox.Content>
            </PanelBox>

            {showSurvey && (
              <Survey
                surveyId={surveyList.subscriptionCancel}
                hiddenInputs={{
                  email,
                  user_id: userId,
                }}
              />
            )}
          </Col>
          <Col lg={5}>
            <div className="subscription-page__payment-container">
              <PaymentView
                paymentMethod={paymentMethod.data}
                businessAccountDetails={businessAccountDetails}
                creditCardExpiration={creditCardExpiration}
                isSubscriptionTrial={isSubscriptionTrial}
                nextPaymentDate={mainSubscription.nextPaymentDate}
                isPaymentMethodCreated={isPaymentMethodCreated}
                onAddClick={this.handleAddPaymentDetailsClick}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="subscription-page__section-delimiter" />
          </Col>
          <Col lg={7}>
            <AdditionalSeats subscription={additionalSubscription} maxSeatsPerMember={maxSeatsPerMember} />
          </Col>
        </Row>
        {this.isCancelSubscriptionSectionShown() && (
          <Row>
            <Col xs={12}>
              <div className="subscription-page__section-delimiter" />
            </Col>
            <Col lg={7}>
              <div className="subscription-page__cancel-subscription-description">
                <FormattedMessage
                  id="subscriptionPage.cancelSubscriptionSection.description"
                  defaultMessage="After canceling, you'll be able to use Setapp until the end of the current billing period. If you have extra devices, please note that they won't be renewed."
                />
              </div>
              <CancelSubscriptionButton onClick={this.showCancelSubscriptionModal} />
            </Col>
          </Row>
        )}
      </>
    );
  }

  handleAddPaymentDetailsClick = () => {
    const { showModal } = this.props;

    showModal('SET_PAYMENT_DETAILS', {
      title: <FormattedMessage id="subscriptionInfo.addPaymentDetailsModal.title" defaultMessage="Add payment details" />,
      onPaymentDetailsSaved: this.handlePaymentDetailsAdded,
    });
  };

  handlePaymentDetailsAdded = () => {
    const { addSuccessNotification, hideModal } = this.props;

    hideModal();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="subscriptionInfo.notifications.paymentDetailsAdded"
          defaultMessage="Payment details successfully added."
        />
      ),
    });
  };

  reactivateSubscription = () => {
    const { mainSubscription, reactivateSubscription } = this.props;

    if (!mainSubscription) {
      throw new Error('Main subscription is not fetched');
    }

    return reactivateSubscription({ subscriptionId: mainSubscription.id });
  };

  showCancelSubscriptionModal = () => {
    const { showModal } = this.props;

    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_MODAL_OPEN);
    showModal('CANCEL_SUBSCRIPTION', { onSubscriptionCanceled: this.showSurvey });
  };

  showSurvey = () => {
    this.setState({ showSurvey: true });
  };

  showChangePlanModal = () => {
    const { showModal, location, mainSubscription } = this.props;
    const query = queryString.parse(location.search);
    const { selected_plan: selectedPlanType } = query;

    if (!mainSubscription) {
      return;
    }

    const { nextPricePlan, pricePlan } = mainSubscription;
    // Display next price plan info if user changed plan. It's made by design.
    const pricePlanToDisplay = nextPricePlan || pricePlan;

    analytics.trackEvent(events.SUBSCRIPTION_PLAN_MODAL_OPEN);

    showModal('CHANGE_PLAN', {
      onModalExited: this.onChangePlanModalExited,
      currentPricePlan: pricePlanToDisplay,
      selectedPlanPriceKey: selectedPlanType || pricePlanToDisplay.priceKey,
      onPlanChange: this.onSuccessfulPlanChange,
    });
  };

  onChangePlanModalExited = () => {
    const { history } = this.props;
    /*
    query params are removed om modal exited (when it's completely hidden) instead of on hide (when it's disappearing)
    because of selected plan is switched back the to current during fade out animation
     */
    removeQueryParams(history, 'show_change_plan_modal', 'selected_plan');
  };

  onSuccessfulPlanChange = () => {
    const { fetchUser } = this.props;

    return fetchUser();
  };

  showChangePlanModalIfRequired() {
    const { isChangePlanAllowed, location } = this.props;
    const query = queryString.parse(location.search);
    const { show_change_plan_modal: showChangePlanModal } = query;

    if (isChangePlanAllowed && showChangePlanModal) {
      this.showChangePlanModal();
    }
  }

  handleActivateButtonClick = () => {
    const { history } = this.props;

    history.push(urls.activateSubscription);
  };

  isCancelSubscriptionSectionShown() {
    const { mainSubscription, isPaymentMethodCreated } = this.props;

    if (!mainSubscription) return false;

    const isProperStatus = [
      subscriptionStatuses.NEW,
      subscriptionStatuses.TRIAL,
      subscriptionStatuses.BLOCKED,
      subscriptionStatuses.GRACE,
      subscriptionStatuses.ACTIVE,
    ].includes(mainSubscription.status);

    return isProperStatus && isPaymentMethodCreated && !mainSubscription.paymentPending;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  mainSubscription: getPrimarySubscription(state),
  additionalSubscription: getAdditionalSeatsSubscriptionInfo(state),
  email: getUserEmail(state),
  userId: getUserId(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isInactiveSubscription: isInactiveSubscription(state),
  isChangePlanAllowed: isChangePlanAllowed(state),
  creditCardExpiration: creditCardExpiration(state),
  isUserPlainTeamMember: isUserPlainTeamMember(state),
  isUserTeamOwner: isUserTeamOwner(state),
  isSubscriptionTrial: isSubscriptionTrial(state),
  paymentMethod: getPaymentMethod(state),
  businessAccountDetails: getBusinessAccountDetails(state),
  activeMembers: getActiveTeamMembers(state),
  maxSeatsPerMember: getTeamInfo(state).maxAdditionalDevicesPerMember,
});

const mapActionsToProps = {
  fetchSubscription,
  fetchPricePlans,
  fetchPaymentMethod,
  fetchUser,
  reactivateSubscription,
  retryCharge,
  fetchTeam,
  showModal,
  hideModal,
  addSuccessNotification,
  addDangerNotification,
};

export { SubscriptionPage as PureSubscriptionPage };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withEmailAutoConfirmation(SubscriptionPage));
