import React from 'react';
import { FormattedMessage } from 'react-intl';

import './payment-form-note.scss';

const PaymentFormNote = () => (
  <div className="payment-form-note text_sm">
    <div>
      <FormattedMessage
        id="addPaymentMethod.details"
        defaultMessage="We’ll use the details you provided to charge the fee automatically, on a recurring basis. There are no additional payments or in-app purchases."
      />
    </div>
    <div>
      <FormattedMessage
        id="addPaymentMethod.note"
        defaultMessage="NOTE: Sales taxes may apply, depending on your country of residence."
      />
    </div>
  </div>
);

export default PaymentFormNote;
