// @flow

import React, { type Node } from 'react';
import { FormattedMessage } from 'react-intl';

import InfoField from 'components/shared/info-field/info-field';

import PlanRowValue from './plan-row-value/plan-row-value';

type Props = {
  title?: Node,
  paidMonth: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
};

const PlanRow = ({ paidMonth, pricePerMemberPerMonth, title }: Props) => (
  <InfoField
    lightValue
    title={title || <FormattedMessage id="subscriptionInfo.planTitle" defaultMessage="Plan" />}
    value={<PlanRowValue paidMonth={paidMonth} pricePerMemberPerMonth={pricePerMemberPerMonth} />}
    qaLabel="planTitle"
  />
);

PlanRow.defaultProps = {
  title: null,
};

export default PlanRow;
