// @flow

export type PricePlanKey =
  'team_monthly'
  | 'team_annual'
  | 'additional_seat_monthly'
  | 'team_additional_seat_monthly';

export const MONTHLY = 'team_monthly';
export const ANNUAL = 'team_annual';
export const ADDITIONAL_SEAT_MONTHLY = 'additional_seat_monthly';
export const ADDITIONAL_SEAT_MONTHLY_TEAM = 'team_additional_seat_monthly';
