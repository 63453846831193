// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import VatNoteRow from 'components/shared/vat-note-row/vat-note-row';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';

import StatusRow from '../status-row/status-row';
import ActiveMembersRow from '../active-members-row/active-members-row';
import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';

type Props = {|
  currency: string,
  totalAmount: number,
  nextPaymentDate: ?number,
  expirationDate: ?number,
  tax: number,
  activeMembers: Array<TeamMember>,
  paidMonth: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

const SubscriptionTrial = (props: Props) => {
  const {
    currency,
    totalAmount,
    nextPaymentDate,
    expirationDate,
    tax,
    activeMembers,
    paidMonth,
    pricePerMemberPerMonth,
  } = props;

  return (
    <div>
      <StatusRow
        status={subscriptionStatuses.TRIAL}
        expirationDate={expirationDate}
        nextPaymentDate={nextPaymentDate}
      />

      <ActiveMembersRow activeMembers={activeMembers} />

      <PlanRow paidMonth={paidMonth} pricePerMemberPerMonth={pricePerMemberPerMonth} />

      <PriceRow
        title={<FormattedMessage id="subscriptionInfo.trialPriceTitle" defaultMessage="Price after trial" />}
        paidMonth={paidMonth}
        currency={currency}
        price={totalAmount}
        tax={tax}
      />

      {tax === 0 && <VatNoteRow />}
    </div>
  );
};

export default SubscriptionTrial;
