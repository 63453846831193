// @flow

import request from 'utils/request';
import { apiURL } from 'config/api';

export type PaymentDetailsPayload = {|
  checkoutId: string,
|};

export type UpdatePaymentDetailsPayload = {|
  subscriptionId: string,
|};

export type PaddlePaymentDetails = {|
  provider: 'paddle',
  type: null,
  details: null,
  company: string,
  vatId: string,
  streetName: string,
  addressDetail: string,
  buildingNumber: string,
  city: string,
  postalCode: string,
|};

class PaddlePaymentDetailsService {
  createPaymentDetails(paymentDetails: PaymentDetailsPayload): Promise<PaddlePaymentDetails> {
    return request.post(apiURL.paddlePaymentDetails, {
      body: paymentDetails,
    });
  }

  updatePaymentDetails(paymentDetails: UpdatePaymentDetailsPayload): Promise<PaddlePaymentDetails> {
    return request.patch(apiURL.paddlePaymentDetails, {
      body: paymentDetails,
    });
  }
}

export default PaddlePaymentDetailsService;
