// @flow

export type ModalState = {
  modalType: ?string,
  modalProps: ?Object,
  isVisible: boolean,
};

const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const REMOVE_MODAL = 'REMOVE_MODAL';

type ShowModalAction = {
  type: 'SHOW_MODAL',
  payload: {
    modalType: string,
    modalProps?: Object,
  },
};
type HideModalAction = {type: 'HIDE_MODAL'};
type RemoveModalAction = {type: 'REMOVE_MODAL'};

export type ModalAction = | ShowModalAction | HideModalAction | RemoveModalAction;

export const initialState: ModalState = {
  modalType: null,
  modalProps: null,
  isVisible: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: ModalState = initialState, action: ModalAction): ModalState => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
        isVisible: true,
      };
    }

    case HIDE_MODAL: {
      return {
        ...state,
        isVisible: false,
      };
    }

    case REMOVE_MODAL: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export const showModal = (modalType: string, modalProps?: {}): ShowModalAction => ({
  type: 'SHOW_MODAL',
  payload: {
    modalType,
    modalProps,
  },
});

export const hideModal = (): HideModalAction => ({
  type: 'HIDE_MODAL',
});

export const removeModal = (): RemoveModalAction => ({
  type: 'REMOVE_MODAL',
});
