// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import type { RouterHistory } from 'react-router-dom';

import { Button, Notification } from '@setapp/ui-kit';

import { showModal } from 'state/modal/modal-reducer';

import urls from 'config/urls';

import './activate-subscription-notification.scss';

type Props = {
  onHide: () => any,
  history: RouterHistory,
};

const ActivateSubscriptionNotification = ({ onHide, history }: Props) => {
  const handleActivateClick = () => {
    history.push(urls.activateSubscription);
  };

  return (
    <Notification
      type="success"
      onClose={onHide}
    >
      <span className="activate-subscription-notification__container">
        <FormattedMessage
          id="activateSubscriptionNotification.notificationText"
          defaultMessage="Your trial period is ending soon. Please add your payment details to keep using Setapp."
        />
        <Button
          variant="primary-outline"
          className="activate-subscription-notification__button"
          size="sm"
          onClick={handleActivateClick}
        >
          <FormattedMessage
            id="activateSubscriptionNotification.activateButton"
            defaultMessage="Activate"
          />
        </Button>
      </span>
    </Notification>
  );
};

export { ActivateSubscriptionNotification as PureActivateSubscriptionNotification };

export default connect(null, { showModal })(withRouter(ActivateSubscriptionNotification));
