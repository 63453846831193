// @flow

import isEmail from 'validator/lib/isEmail';

type Validators<T> = {|
  required(T): boolean,
  format(T): T,
  duplicate(T): T,
  maxLength(T, size: number): boolean,
|}

type Fields<T> = {|
  [string]: T
|}

type Rules<T> = {|
  [string]: {
    [key: $Keys<Validators<T>>]: {|
      message: React$Node,
      size?: number,
    |}
  }
|}

type Result<T> = {|
  [string]: {
    message: React$Node,
    meta?: T,
  }
|}

const validators: Validators<string[]> = {
  required(values) {
    return values.length > 0;
  },

  format(values) {
    return values.filter((value) => !isEmail(value));
  },

  duplicate(values) {
    return values.filter((item, index) => values.indexOf(item) !== index);
  },

  maxLength(values, size) {
    return values.length <= size;
  },
};

export default function emailsValidate<T: string[]>(fields: Fields<T>, rules: Rules<T>): Result<T> {
  const validateRule = (fieldName: string) => {
    if (!(fieldName in rules)) return null;

    return Object.keys(rules[fieldName] || [])
      .reduce((error, rule) => {
        const fieldValue = fields[fieldName];
        const { message, size } = rules[fieldName][rule];

        let result;
        if (rule === 'maxLength') {
          const validatorFunc = validators[rule];

          if (size === undefined || size === null) throw new Error('[Emails Validation]: "size" field is required');

          result = validatorFunc(fieldValue, size);
        } else {
          const validatorFunc = validators[rule];
          result = validatorFunc(fieldValue);
        }

        if (Array.isArray(result)) {
          return result.length === 0 ? error : { message, meta: result };
        }

        return result ? error : { message };
      }, null);
  };

  return Object.keys(fields)
    .reduce((errors, fieldName) => {
      const result = validateRule(fieldName);

      return result === null ? errors : {
        ...errors,
        [fieldName]: result,
      };
    }, {});
}
