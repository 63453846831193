// @flow

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const SIGNUP_EMAIL_OPT_IN = 'SIGNUP_EMAIL_OPT_IN';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SET = 'NEW_PASSWORD_SET';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SUCCESSFUL_REGISTRATION_PAGE_DOWNLOAD_STARTED = 'SUCCESSFUL_REGISTRATION_PAGE_DOWNLOAD_STARTED';
export const SUCCESSFUL_REGISTRATION_OPEN_DESKTOP = 'SUCCESSFUL_REGISTRATION_OPEN_DESKTOP';
export const SUCCESSFUL_REGISTRATION_CLICK_DOWNLOAD = 'SUCCESSFUL_REGISTRATION_CLICK_DOWNLOAD';

export const WELCOME_PAGE_CLICK_DOWNLOAD = 'WELCOME_PAGE_CLICK_DOWNLOAD';
export const WELCOME_PAGE_CLICK_OPEN_APP = 'WELCOME_PAGE_CLICK_OPEN_APP';
export const WELCOME_PAGE_CLICK_INVITE_MEMBER = 'WELCOME_PAGE_CLICK_INVITE_MEMBER';
export const WELCOME_PAGE_CLICK_INVITE_MEMBER_AGAIN = 'WELCOME_PAGE_CLICK_INVITE_MEMBER_AGAIN';
export const WELCOME_PAGE_CLICK_ADD_PAYMENT_METHOD = 'WELCOME_PAGE_CLICK_ADD_PAYMENT_METHOD';
export const WELCOME_PAGE_CLICK_UPDATE_PAYMENT_METHOD = 'WELCOME_PAGE_CLICK_UPDATE_PAYMENT_METHOD';
export const WELCOME_PAGE_CLICK_TAKE_SURVEY = 'WELCOME_PAGE_CLICK_TAKE_SURVEY';

export const PAYMENT_DETAILS_FORM_DISPLAYED = 'PAYMENT_DETAILS_FORM_DISPLAYED';
export const PAYMENT_DETAILS_PAYMENT_METHOD_SELECT = 'PAYMENT_DETAILS_PAYMENT_METHOD_SELECT';
export const PAYMENT_DETAILS_COUNTRY_SELECT = 'PAYMENT_DETAILS_COUNTRY_SELECT';
export const PAYMENT_DETAILS_ADD = 'PAYMENT_DETAILS_ADD';
export const PAYMENT_DETAILS_FORM_ERROR = 'PAYMENT_DETAILS_FORM_ERROR';
export const PAYMENT_METHOD_UPDATE_INFO_CLICK = 'PAYMENT_METHOD_UPDATE_INFO_CLICK';

export const ACCOUNT_PASSWORD_CHANGED = 'ACCOUNT_PASSWORD_CHANGED';
export const ACCOUNT_EMAIL_CHANGED = 'ACCOUNT_EMAIL_CHANGED';

export const CANCEL_SUBSCRIPTION_MODAL_OPEN = 'CANCEL_SUBSCRIPTION_MODAL_OPEN';
export const CANCEL_SUBSCRIPTION_CONFIRM = 'CANCEL_SUBSCRIPTION_CONFIRM';

export const SUBSCRIPTION_PLAN_MODAL_OPEN = 'SUBSCRIPTION_PLAN_MODAL_OPEN';
export const SUBSCRIPTION_PLAN_CHANGED = 'SUBSCRIPTION_PLAN_CHANGED';

export const DEVICE_DEACTIVATED = 'DEVICE_DEACTIVATED';

export const DEVICE_ALL_IOS_DEACTIVATED = 'DEVICE_ALL_IOS_DEACTIVATED';
export const DEVICE_DEACTIVE_ALL_IOS_CLICK = 'DEVICE_DEACTIVE_ALL_IOS_CLICK';

export const CROSS_AUTH_SUCCESS = 'CROSS_AUTH_SUCCESS';
export const CROSS_AUTH_ERROR = 'CROSS_AUTH_ERROR';

export const TEAM_INVITE_MEMBER_CLICK = 'TEAM_INVITE_MEMBER_CLICK';
export const TEAM_INVITE_MEMBER_SUCCESS = 'TEAM_INVITE_MEMBER_SUCCESS';
export const TEAM_INVITE_VIA_JAMF_CLICK = 'TEAM_INVITE_VIA_JAMF_CLICK';
export const TEAM_REVOKE_INVITE_SUCCESS = 'TEAM_REVOKE_INVITE_SUCCESS';
export const TEAM_ADDITIONAL_DEVICE_PURCHASE = 'TEAM_ADDITIONAL_DEVICE_PURCHASE';
export const TEAM_ADDITIONAL_DEVICE_REMOVE = 'TEAM_ADDITIONAL_DEVICE_REMOVE';
export const TEAM_REMOVE_MEMBER_CLICK = 'TEAM_REMOVE_MEMBER_CLICK';
export const TEAM_REMOVE_MEMBER_SUCCESS = 'TEAM_REMOVE_MEMBER_SUCCESS';
export const TEAM_SHARE_ADMIN_ROLE_CLICK = 'TEAM_SHARE_ADMIN_ROLE_CLICK';
export const TEAM_ADMIN_ROLE_SHARE_SUCCESS = 'TEAM_ADMIN_ROLE_SHARE_SUCCESS';
export const TEAM_REMOVE_ADMIN_ROLE_CLICK = 'TEAM_REMOVE_ADMIN_ROLE_CLICK';
export const TEAM_REMOVE_ADMIN_ROLE_SUCCESS = 'TEAM_REMOVE_ADMIN_ROLE_SUCCESS';
export const TEAM_CLICK_PURCHASE_INVITES = 'TEAM_CLICK_PURCHASE_INVITES';
export const TEAM_CLICK_ACTIVATE_MEMBERSHIP = 'TEAM_CLICK_ACTIVATE_MEMBERSHIP';

export const SIDEBAR_CLICK_DOWNLOAD = 'SIDEBAR_CLICK_DOWNLOAD';
export const SIDEBAR_CLICK_CONTACT_SALES = 'SIDEBAR_CLICK_CONTACT_SALES';

export const ACTIVATE_SUBSCRIPTION_PAGE_PLAN_SELECTED = 'ACTIVATE_SUBSCRIPTION_PAGE_PLAN_SELECTED';
export const ACTIVATE_SUBSCRIPTION_PAGE_CLICK_PADDLE = 'ACTIVATE_SUBSCRIPTION_PAGE_CLICK_PADDLE';
export const ACTIVATE_SUBSCRIPTION_PAGE_SUBSCRIPTION_ACTIVATED = 'ACTIVATE_SUBSCRIPTION_PAGE_SUBSCRIPTION_ACTIVATED';
export const ACTIVATE_SUBSCRIPTION_PAGE_CLICK_OK_GREAT = 'ACTIVATE_SUBSCRIPTION_PAGE_CLICK_OK_GREAT';
export const ACTIVATE_SUBSCRIPTION_PAGE_CLICK_MANAGE_TEAM = 'ACTIVATE_SUBSCRIPTION_PAGE_CLICK_MANAGE_TEAM';
export const ACTIVATE_SUBSCRIPTION_PAGE_CLICK_GO_TO_ACCOUNT = 'ACTIVATE_SUBSCRIPTION_PAGE_CLICK_GO_TO_ACCOUNT';

export const APPS_MANAGEMENT_HIDE_APP = 'APPS_MANAGEMENT_HIDE_APP';
export const APPS_MANAGEMENT_UNHIDE_APP = 'APPS_MANAGEMENT_UNHIDE_APP';
export const APPS_MANAGEMENT_START_SEARCH = 'APPS_MANAGEMENT_START_SEARCH';
export const APPS_MANAGEMENT_CLICK_SHOW_MORE = 'APPS_MANAGEMENT_CLICK_SHOW_MORE';
export const APPS_MANAGEMENT_CLICK_LETS_START = 'APPS_MANAGEMENT_CLICK_LETS_START';

export const HEADER_NAVIGATION_CONTACT_SUPPORT = 'HEADER_NAVIGATION_CONTACT_SUPPORT';
export const HEADER_NAVIGATION_MY_ACCOUNT = 'HEADER_NAVIGATION_MY_ACCOUNT';

export const MOBILE_FLOW_CLICK_SEND_ME_LINK = 'MOBILE_FLOW_CLICK_SEND_ME_LINK';
export const MOBILE_FLOW_CLICK_DOWNLOAD_TO_THIS_DEVICE = 'MOBILE_FLOW_CLICK_DOWNLOAD_TO_THIS_DEVICE';

export const PURCHASE_INVITES_BLOCK_CLICK_PURCHASE_INVITES = 'PURCHASE_INVITES_BLOCK_CLICK_PURCHASE_INVITES';
export const PURCHASE_INVITES_BLOCK_PURCHASE_INVITES_SUCCESS = 'PURCHASE_INVITES_BLOCK_PURCHASE_INVITES_SUCCESS';
export const PURCHASE_INVITES_BLOCK_CLICK_ACTIVATE_MEMBERSHIP = 'PURCHASE_INVITES_BLOCK_CLICK_ACTIVATE_MEMBERSHIP';
