// @flow

/* eslint-disable react/jsx-key */

import React from 'react';

import { useTable, useSortBy } from 'react-table';
import type { Node } from 'react';
import classnames from 'classnames';

import './table.scss';

export type CellProps = ({row: {original: any}, cell: {value: any}});
export type Cell = (CellProps) => Node;
export type HeaderProps = {data: Array<any>};
export type Header = string | (HeaderProps) => Node;
export type Column = {
  id?: string,
  accessor?: string | (any) => string | number,
  columns?: Array<Column>,
  show?: boolean | (any) => boolean,
  Header?: Header,
  Cell?: Cell,
};
type Props = {
  columns: Array<Column>,
  data: Array<any>,
  isSortable: boolean,
};

const Table = ({ columns, data, isSortable }: Props) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, (isSortable && useSortBy));

  return (
    <table {...getTableProps()} className="teams-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const headerClasses = classnames('teams-table__header text_sm', {
                'teams-table__header_sorted': column.canSort,
                'teams-table__header_desc-sorted': column.isSortedDesc,
              });
              // Default react-table title is not human-readable so we decide to remove it
              // eslint-disable-next-line no-unused-vars
              const { title, ...headerProps } = column.getHeaderProps(column.canSort && column.getSortByToggleProps());

              return (
                <th
                  {...headerProps}
                  className={headerClasses}
                >
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row) => prepareRow(row) || (
          <tr {...row.getRowProps()} className="teams-table__row">
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()} className="teams-table__cell">
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
