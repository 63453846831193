// @flow

import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { getPricePerMonthPerMember } from 'state/root-reducer';

import PricePlanDetails from './price-plan-descriptions/price-plan-details';


import './plan-item.scss';

type Props = {|
  active: boolean,
  pricePlan: PricePlan,
  monthlyPricePlan: ?PricePlan,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
  monthlyPricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

const PricePlanItem = ({
  active,
  pricePlan,
  monthlyPricePlan,
  monthlyPricePerMemberPerMonth,
  pricePerMemberPerMonth,
}: Props) => {
  const radioContainerClasses = classnames(
    'price-plan-item',
    { 'price-plan-item_active': active }
  );

  const calculateDiscount = (regularPrice, price) => Math.round(((regularPrice - price) / regularPrice) * 100);

  const renderedPrice = (
    <>
      <FormattedPrice currency={pricePerMemberPerMonth.currency} price={pricePerMemberPerMonth.price} />
      <span className="ml-1">
        <FormattedMessage
          id="changePlanModal.priceDescription"
          defaultMessage="/mo/ member"
        />
      </span>
    </>
  );

  return (
    <div className={radioContainerClasses}>
      <div className="price-plan-item__text-container">
        <span className="text_xl">
          <PricePlanTitle priceKey={pricePlan.priceKey} />
        </span>
        {monthlyPricePlan && pricePlan && (monthlyPricePerMemberPerMonth.price > pricePerMemberPerMonth.price) && (
          <span className="price-plan-item__label text_xs">
            <FormattedMessage
              id="pricePlans.priceLabel.discount"
              defaultMessage="Save {discount}%"
              values={{
                discount: calculateDiscount(monthlyPricePerMemberPerMonth.price, pricePerMemberPerMonth.price),
              }}
            />
          </span>
        )}
        <div className="price-plan-item__price-container text_lg hidden-sm hidden-md hidden-lg">
          {renderedPrice}
        </div>
        <div className="mt-2 text_md">
          <PricePlanDetails pricePlan={pricePlan} />
        </div>
      </div>
      <div className="price-plan-item__price-container mt-1 text_lg hidden-xs">
        {renderedPrice}
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state, { pricePlan, monthlyPricePlan = {} }) => ({
  pricePerMemberPerMonth: getPricePerMonthPerMember(state, pricePlan.priceKey),
  monthlyPricePerMemberPerMonth: getPricePerMonthPerMember(state, monthlyPricePlan.priceKey),
});

export {
  PricePlanItem as PurePricePlanItem,
};

export default connect(mapStateToProps)(PricePlanItem);
