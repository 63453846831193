// @flow

import httpStatuses from 'http-status';

import { apiURL } from 'config/api';
import request from 'utils/request';
import type { Dispatch } from 'state/state-types';

import { fetchSubscription } from '../subscription/subscription-actions';

import * as actionTypes from './team-plan-action-types';
import type { MemberRole } from './team-member-roles';

export const fetchTeam = () => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.get(apiURL.team)
    .then((data) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const inviteMembers = (data: {emails: string[]}) => {
  const { emails } = data;

  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    try {
      await request.post(apiURL.familyMembersInviteMany, { body: { emails } });

      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });
      dispatch({
        type: actionTypes.INVITE_MEMBERS,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      throw error;
    }
  };
};

export const resendInvite = (id: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(`${apiURL.familyMembers}/${id}/resend_invite`)
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const removeMember = (id: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.delete(`${apiURL.teamMembers}/${id}`)
    .catch((error) => {
      // threat 404 error as success for case when a member was removed from other tab or browser
      if (error.status === httpStatuses.NOT_FOUND) {
        return Promise.resolve();
      }

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    })
    .then(() => dispatch(fetchTeam()))
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });

      dispatch({
        type: actionTypes.REMOVE_MEMBER,
        payload: id,
      });
    })
    .then(() => dispatch(fetchSubscription()));
};

export const addSeatToMember = (memberId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.MODIFY_MEMBER_REQUEST,
    payload: memberId,
  });

  try {
    const additionalSeat = await request.post(apiURL.teamMembersAddDevice(memberId));

    dispatch({
      type: actionTypes.ADD_DEVICE_TO_MEMBER,
      payload: {
        memberId,
        additionalSeat,
      },
    });
    dispatch({
      type: actionTypes.MODIFY_MEMBER_SUCCESS,
      payload: memberId,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.MODIFY_MEMBER_ERROR,
      payload: {
        memberId,
        error,
      },
    });

    throw error;
  }
};

export const removeSeatFromMember = (memberId: number, subscriptionId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.MODIFY_MEMBER_REQUEST,
    payload: memberId,
  });

  try {
    await request.delete(apiURL.teamMembersRemoveDevice(memberId, subscriptionId));

    dispatch({
      type: actionTypes.REMOVE_DEVICE_FROM_MEMBER,
      payload: { memberId, subscriptionId },
    });
    dispatch({
      type: actionTypes.MODIFY_MEMBER_SUCCESS,
      payload: memberId,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.MODIFY_MEMBER_ERROR,
      payload: {
        memberId,
        error,
      },
    });

    throw error;
  }
};

export const addMemberSeat = (seatsAmount: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(apiURL.teamMembersSeat, { body: { seatsAmount } })
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });
      dispatch({
        type: actionTypes.PURCHASE_INVITATIONS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

/**
 * @param  {number} memberId
 * @param  {MemberRole} [role] - Member role, set `null` to remove role.
 */
export const shareMemberRole = (memberId: number, role: ?MemberRole = null) => async (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  try {
    await request.patch(apiURL.teamMembersAddRole(memberId), { body: { role } });

    dispatch({
      type: actionTypes.REQUEST_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.REQUEST_ERROR,
      payload: error,
      error: true,
    });

    throw error;
  }
};
