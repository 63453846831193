import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NotificationsContainer from 'components/shared/notification/notifications-container';
import Header from 'components/layout/outer-layout/header-navigation/header-navigation';
import Footer from 'components/layout/app-layout/footer/footer';
import CookieBanner from 'components/shared/banners/cookie-banner/cookie-banner';

import './outer-layout.scss';

const OuterLayout = ({
  header,
  children,
  footer,
  fullScreen,
}) => {
  const contentClasses = classNames([
    'outer-layout__main',
    'container-fluid',
    {
      'outer-layout__main_without-header-and-footer': !header && !footer,
      'outer-layout__main_full-screen': fullScreen,
    }, // TODO: try to set for all layout, not only for main
  ]);

  const notificationClasses = classNames([
    'outer-layout__notifications',
    {
      'outer-layout__notifications_with-header': Boolean(header),
      // notifications have to be aligned with the header which uses container class as well
      container: Boolean(header),
    },
  ]);

  return (
    <div className="outer-layout__container">
      <div className="outer-layout outer-layout_light">
        <div className={notificationClasses}>
          <div className="outer-layout__notifications-container">
            <NotificationsContainer />
          </div>
        </div>

        {header && (
          <header className="outer-layout__header">
            {header}
          </header>
        )}

        <main className={contentClasses}>
          {children}
        </main>

        {footer && (
          <footer className="outer-layout__footer">
            <div className="outer-layout__footer-content container-fluid">
              {footer}
            </div>
          </footer>
        )}
      </div>
      <CookieBanner position="bottom" />
    </div>
  );
};

OuterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  fullScreen: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

OuterLayout.defaultProps = {
  header: <Header />,
  footer: <Footer />,
  fullScreen: false,
};

export default OuterLayout;
