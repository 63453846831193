import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './sidebar-nav-item.scss';

const SidebarNavItem = ({
  children, active, external, className, activeClassName, onClick, href, ...linkProps
}) => {
  const anchorClasses = classNames(
    'sidebar-nav__link text_md',
    { 'sidebar-nav__link_active': active },
    { 'sidebar-nav__link_external': external },
    className,
    { [activeClassName]: active },
  );

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <li role="presentation" className="sidebar-nav__item">
      <a {...linkProps} href={href} className={anchorClasses} onClick={handleClick}>
        {children}
      </a>
    </li>
  );
};

SidebarNavItem.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  external: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarNavItem.defaultProps = {
  active: false,
  external: false,
  className: '',
  activeClassName: '',
  onClick: null,
};

export default SidebarNavItem;
