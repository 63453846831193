// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  defineMessages,
} from 'react-intl';
import ReCaptcha from 'react-google-recaptcha';
import { FormField } from '@setapp/ui-kit';

import type { Node, ElementRef } from 'react';
import type { IntlShape } from 'react-intl';
import OuterForm from 'components/shared/outer/outer-form/outer-form';
import OuterFormFooter from 'components/shared/outer/form-footer';
import CaptchaField from 'components/shared/form/captcha-field/captcha-field';


const messages = defineMessages({
  formTitle: {
    id: 'outer.recoverPassword.title',
    defaultMessage: 'Password recovery',
  },
  formSubTitle: {
    id: 'outer.recoverPassword.subTitle',
    defaultMessage: 'Enter your email address and we\'ll send you a link to reset your password.',
  },
  successTitle: {
    id: 'outer.recoverPassword.titleSuccess',
    defaultMessage: 'Alright, please check your email.',
  },
  emailLabel: {
    id: 'outer.recoverPassword.emailLabel',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'outer.recoverPassword.emailPlaceholder',
    defaultMessage: 'Enter your email',
  },
  submitBtnCaption: {
    id: 'outer.recoverPassword.submitBtnCaption',
    defaultMessage: 'Reset password',
  },
});

type Props = {
  onFormSubmit: SyntheticEvent<HTMLFormElement> => any,
  onEmailChange: SyntheticKeyboardEvent<HTMLInputElement> => any,
  isRequestProcessing: boolean,
  isRequestConfirmed: boolean,
  previousPage: string,
  emailError: Node,
  genericError: Node,
  email: string,
  intl: IntlShape,
  showCaptcha: boolean,
  onCaptchaChange: string => void,
  setCaptchaRef: ElementRef<typeof ReCaptcha>,
  captchaError: Node,
};

const RecoverPasswordForm = (props: Props) => {
  const {
    onFormSubmit,
    genericError,
    emailError,
    onEmailChange,
    isRequestProcessing,
    isRequestConfirmed,
    email,
    previousPage,
    intl,
    showCaptcha,
    onCaptchaChange,
    captchaError,
    setCaptchaRef,
  } = props;

  const title = isRequestConfirmed
    ? intl.formatMessage(messages.successTitle)
    : intl.formatMessage(messages.formTitle);
  const subTitle = isRequestConfirmed ? (
    <FormattedHTMLMessage
      id="outer.recoverPassword.subTitleSuccess"
      defaultMessage="The link to reset your password is on its way to <strong>{email}</strong> if we have an account registered with this email"
      values={{ email }}
    />
  ) : intl.formatMessage(messages.formSubTitle);

  return (
    <OuterForm
      title={title}
      subTitle={subTitle}
      submitBtnCaption={intl.formatMessage(messages.submitBtnCaption)}
      hasLogo
      footer={(
        <OuterFormFooter>
          <Link className="link-outer" to={previousPage}>
            <FormattedMessage id="outer.recoverPassword.backToPreviousPage" defaultMessage="Go back" />
          </Link>
        </OuterFormFooter>
      )}
      onSubmit={onFormSubmit}
      errorMessage={genericError}
      isSubmitProcessing={isRequestProcessing}
      hideSubmit={isRequestConfirmed}
      qaLabel="forgotPasswordForm"
    >
      {!isRequestConfirmed && (
        <FormField
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          label={intl.formatMessage(messages.emailLabel)}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          value={email}
          helpText={emailError}
          invalid={Boolean(emailError)}
          onChange={onEmailChange}
          autoFocus
        />
      )}
      {!isRequestConfirmed && showCaptcha && (
        <CaptchaField onChange={onCaptchaChange} errorMessage={captchaError} setCaptchaRef={setCaptchaRef} />
      )}
    </OuterForm>
  );
};

export { RecoverPasswordForm as PureRecoverPasswordForm };

export default injectIntl(RecoverPasswordForm);
