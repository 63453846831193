// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import isBefore from 'date-fns/isBefore';
import subDays from 'date-fns/subDays';

import InfoButtonWithTooltip from 'components/shared/info-button/info-button-with-tooltip';

import type { MemberStatus } from 'state/team-plan/team-member-statuses';
import * as statuses from 'state/team-plan/team-member-statuses';

import './member-status-title.scss';

type Props = {
  status: MemberStatus,
  invitationDate: number,
};

const dateOneWeekAgo = subDays(Date.now(), 7);

const MemberStatusTitle = ({ status, invitationDate }: Props) => {
  const pendingMemberSignupMoreThanWeek = isBefore(invitationDate, dateOneWeekAgo);
  const statusTitles = {
    [statuses.ACTIVE]: {
      title: <FormattedMessage id="teamMemberStatus.status.active.title" defaultMessage="Active" />,
      tooltip: (
        <FormattedMessage
          id="teamMemberStatus.status.active.tooltip"
          defaultMessage="Member successfully joined the team."
        />
      ),
    },
    [statuses.PENDING]: {
      title: <FormattedMessage id="teamMemberStatus.status.pending.title" defaultMessage="Pending activation" />,
      tooltip: pendingMemberSignupMoreThanWeek ? (
        <FormattedMessage
          id="teamMemberStatus.status.pending.moreThanWeek.tooltip"
          defaultMessage="It has been over 7 days since you sent this invitation. Consider sending a follow-up."
        />
      ) : (
        <FormattedMessage
          id="teamMemberStatus.status.pending.tooltip"
          defaultMessage="This member did not accept the invitation via email yet."
        />
      ),
    },
    [statuses.PAYMENT_PENDING]: {
      title: <FormattedMessage id="teamMemberStatus.status.paymentPending.title" defaultMessage="Payment pending" />,
      tooltip: (
        <FormattedMessage
          id="teamMemberStatus.status.paymentPending.tooltip"
          defaultMessage="The payment for this member account is still pending. Please check your payment details if the problem persists."
        />
      ),
    },
  };
  const statusTitleClasses = classnames('member-status-title', {
    'member-status-title_green': status === statuses.ACTIVE,
    'member-status-title_red': status === statuses.PENDING && pendingMemberSignupMoreThanWeek,
  });

  return (
    <div>
      <span className={statusTitleClasses}>
        {statusTitles[status].title}
      </span>
      <InfoButtonWithTooltip>
        <span className="member-status-title__tooltip-content text_sm">
          {statusTitles[status].tooltip}
        </span>
      </InfoButtonWithTooltip>
    </div>
  );
};

export default MemberStatusTitle;
