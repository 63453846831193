// @flow

import { addLocaleData } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import type { SignUpMetadata } from '@setapp/signup-metadata';

import auth from 'utils/auth';
import $ from 'utils/request';
import logger from 'utils/logger';
import {
  setCookieLocale, serverToBrowserLocale, browserToServerLocale, loadLocaleData,
} from 'utils/intl';
import { getSignupMetadata } from 'utils/service-locators';
import analytics, { events } from 'utils/analytics';

import { addDangerNotification } from 'state/notifier/notifier-actions';
import { apiURL } from 'config/api';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from 'config/locales';

import type { Dispatch } from '../state-types';

import type { User } from './user-initial-state';
import * as types from './user-actions-types';

type SignUpData = SignUpMetadata & {
  companyName?: string,
  email: string,
  password: string,
  marketingSubscribed?: boolean,
  termsAccepted?: boolean,
};

const signUpMetadataManager = getSignupMetadata();

export const setUILocale = (locale: string) => async (dispatch: Dispatch) => {
  const isLocaleSupported = SUPPORTED_LOCALES.indexOf(locale) !== -1;
  const nextLocale = isLocaleSupported ? locale : DEFAULT_LOCALE;

  const [localeData, localeMessages] = await loadLocaleData(nextLocale);
  addLocaleData(localeData);

  dispatch(updateIntl({
    locale: nextLocale,
    messages: localeMessages,
  }));

  setCookieLocale(nextLocale);
};

export const setUserInfo = (data: $Shape<User>) => (dispatch: Dispatch) => {
  if (data.id) {
    logger.setUserData({ id: data.id.toString() });
  }

  let assimilatedLocale;

  if (data.locale) {
    assimilatedLocale = serverToBrowserLocale(data.locale);

    dispatch(setUILocale(assimilatedLocale));
  }

  dispatch({
    type: types.SET_USER_INFO,
    payload: assimilatedLocale ? { ...data, locale: assimilatedLocale } : data,
  });
};

// TODO: remove from exports and create specific actions for changing user info and password
export const updateUser = (data: $Shape<User>) => (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: types.REQUEST,
  });

  return $.patch(apiURL.account, { body: data })
    .then((data) => {
      dispatch({
        type: types.REQUEST_SUCCESS,
      });
      dispatch(setUserInfo({ ...data }));
    })
    .catch((error) => {
      dispatch({
        type: types.REQUEST_ERROR,
      });

      return Promise.reject(error);
    });
};

export const setPaymentInfo = () => ({
  type: types.SET_USER_PAYMENT_INFO,
  payload: false,
});

export const fetchUser = () => (dispatch: Dispatch) => {
  dispatch({
    type: types.REQUEST,
  });

  return $.get(apiURL.account)
    .then((data) => {
      dispatch({
        type: types.REQUEST_SUCCESS,
      });
      dispatch(setUserInfo(data));
    })
    .catch((error) => {
      dispatch({
        type: types.REQUEST_ERROR,
        payload: error,
      });
      dispatch(addDangerNotification({
        message: error.getSimplifiedErrors().genericError,
      }));

      return Promise.reject(error);
    });
};

export const logout = () => (dispatch: Dispatch) => auth.logout()
  .then(() => {
    dispatch({
      type: types.LOGOUT,
    });

    logger.unsetUserData();
  });

type LoginData = {
  email: string,
  password: string,
  captcha?: string
}

export const login = (loginData: LoginData) => (dispatch: Dispatch) => auth.login(loginData)
  .then(() => dispatch(fetchUser()))
  .then(() => dispatch({ type: types.LOGIN }));

export const loginWithOneTimeToken = (accessToken: string) => (dispatch: Dispatch) => (
  auth.loginWithOneTimeToken(accessToken)
    .then(() => dispatch(fetchUser()))
    .then(() => {
      dispatch({ type: types.LOGIN });
      analytics.trackEvent(events.CROSS_AUTH_SUCCESS);
    })
    .catch((error) => {
      analytics.trackEvent(events.CROSS_AUTH_ERROR);

      return Promise.reject(error);
    })
);

export const signUp = (userData: SignUpData) => (dispatch: Dispatch) => {
  // Must be here because signup metadata is cleared after successful signup
  const { inviteId } = signUpMetadataManager.getAll();

  return auth.signup(userData)
    .then(() => dispatch(fetchUser()))
    // Should be called after fetching user's profile to have user info in analytics events
    .then(() => {
      analytics.trackEvent(events.SIGNUP_SUCCESS, { token: inviteId });

      if (userData.marketingSubscribed) {
        analytics.trackEvent(events.SIGNUP_EMAIL_OPT_IN);
      }
    })
    .then(() => dispatch({ type: types.LOGIN }));
};

export const signUpMember = (userData: SignUpData) => (dispatch: Dispatch) => {
  // Must be here because signup metadata is cleared after successful signup
  const { inviteId } = signUpMetadataManager.getAll();

  return auth.signupMember(userData)
    .then(() => dispatch(fetchUser()))
    // Should be called after fetching user's profile to have user info in analytics events
    .then(() => {
      analytics.trackEvent(events.SIGNUP_SUCCESS, { token: inviteId });

      if (userData.marketingSubscribed) {
        analytics.trackEvent(events.SIGNUP_EMAIL_OPT_IN);
      }
    })
    .then(() => dispatch({ type: types.LOGIN }));
};

export const resetPassword = (newPassword: string, resetToken: string) => (dispatch: Dispatch) => (
  auth.resetPassword(newPassword, resetToken)
    .then(() => dispatch(fetchUser()))
    .then(() => dispatch({ type: types.LOGIN }))
);

export const confirmEmail = (emailConfirmationToken: string) => (dispatch: Dispatch) => (
  $.post(apiURL.confirmEmail, { body: { emailConfirmationToken } })
    .then((response) => dispatch(setUserInfo(response)))
);

export const resendConfirmationEmail = ({ captcha }: {captcha?: ?string} = {}) => () => {
  const requestOptions = captcha ? { body: { captcha } } : {};

  return $.post(apiURL.resendConfirmationEmail, requestOptions);
};

export const updateLocale = (locale: string) => (dispatch: Dispatch) => (
  dispatch(updateUser({ locale: browserToServerLocale(locale) }))
    .then(() => {
      // for detecting locale change after full app reset caused by IntlProvider
      dispatch(setUserInfo({ isLocaleChanged: true }));
    })
);

export const unsetLocaleChangedFlag = () => (dispatch: Dispatch) => {
  dispatch(setUserInfo({ isLocaleChanged: false }));
};

export const sendDownloadLinkEmail = () => () => $.post(apiURL.sendDownloadLinkEmail);
