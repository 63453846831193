// @flow

import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Notification, Button } from '@setapp/ui-kit';

import './invite-members-form-description.scss';

type Props = {
  freeSeatsLeft: number,
  seatsPoolCount: number,
  isOwner: boolean,
  isTrial: boolean,
  isCreditCardExpired: boolean,
  isPaymentMethodCreated: boolean,
  onActivateClick(): mixed,
  onAddPaymentDetails(): mixed,
  onUpdatePaymentDetails(): mixed,
  onShowPurchaseInvites(): mixed,
}

const InviteMembersFormDescription = (props: Props) => {
  const {
    isOwner,
    isTrial,
    isCreditCardExpired,
    isPaymentMethodCreated,
    freeSeatsLeft,
    seatsPoolCount,
    onActivateClick,
    onAddPaymentDetails,
    onUpdatePaymentDetails,
    onShowPurchaseInvites,
  } = props;

  const isOutOfSeatsLimit = freeSeatsLeft < 0;
  const isSeatsLimitReached = freeSeatsLeft === 0;

  const isPaidOutOfLimitedSeats = !isTrial && isOutOfSeatsLimit;

  const isTrialOwnerOutOfLimitedSeats = isTrial && isOutOfSeatsLimit && isOwner;
  const isTrialAdminOutOfLimitedSeats = isTrial && isOutOfSeatsLimit && !isOwner;

  if (isSeatsLimitReached) {
    return (
      <FormattedMessage
        id="inviteMemberModal.description.limit"
        defaultMessage="You've reached the limit of free invitations."
      />
    );
  }

  if (isTrialOwnerOutOfLimitedSeats) {
    return (
      <Notification type="info">
        <FormattedMessage
          id="inviteMemberModal.description.paid.trial.owner"
          defaultMessage="You've reached the number of free invitations. Please {activateLink} to invite more members."
          values={{
            activateLink: (
              <Button
                variant="link"
                onClick={onActivateClick}
              >
                <FormattedMessage
                  id="inviteMemberModal.description.paid.trial.cta"
                  defaultMessage="activate membership"
                />
              </Button>
            ),
          }}
        />
      </Notification>
    );
  }

  if (isTrialAdminOutOfLimitedSeats) {
    return (
      <Notification type="info">
        <FormattedMessage
          id="inviteMemberModal.description.paid.trial.admin"
          defaultMessage="You've reached the number of free invitations. Please ask your team owner to activate your Setapp membership."
        />
      </Notification>
    );
  }

  if (isPaidOutOfLimitedSeats) {
    const outOfLimitInvitationNumber = Math.abs(freeSeatsLeft);
    let additionalMessage = null;

    if (!isPaymentMethodCreated && isOwner) {
      additionalMessage = (
        <FormattedMessage
          id="inviteMemberModal.description.charge.paid.addCreditCard"
          defaultMessage="Please {addCardLink} to purchase {outOfLimitInvitationNumber} more invitations. "
          values={{
            outOfLimitInvitationNumber,
            addCardLink: (
              <Button
                className="invite-members-form-description__cta-link"
                variant="link"
                onClick={onAddPaymentDetails}
              >
                <FormattedMessage
                  id="inviteMemberModal.description.charge.paid.addCreditCard.cta"
                  defaultMessage="add credit card"
                />
              </Button>
            ),
          }}
        />
      );
    } else if (isCreditCardExpired && isOwner) {
      additionalMessage = (
        <FormattedMessage
          id="inviteMemberModal.description.charge.paid.updateCreditCard"
          defaultMessage="Please {updateCardLink} to purchase {outOfLimitInvitationNumber} more invitations."
          values={{
            outOfLimitInvitationNumber,
            updateCardLink: (
              <Button
                className="invite-members-form-description__cta-link"
                variant="link"
                onClick={onUpdatePaymentDetails}
              >
                <FormattedMessage
                  id="inviteMemberModal.description.charge.paid.updateCreditCard.cta"
                  defaultMessage="update credit card details"
                />
              </Button>
            ),
          }}
        />
      );
    } else {
      additionalMessage = (
        <FormattedMessage
          id="inviteMemberModal.description.charge.paid.purchaseInvites"
          defaultMessage="Purchase {outOfLimitInvitationNumber} more invitations to invite all members. {purchaseLink}"
          values={{
            outOfLimitInvitationNumber,
            purchaseLink: (
              <Button
                className="invite-members-form-description__cta-link"
                variant="link"
                onClick={onShowPurchaseInvites}
              >
                <FormattedMessage
                  id="inviteMemberModal.description.charge.paid.cta"
                  defaultMessage="Purchase an invitation"
                />
              </Button>
            ),
          }}
        />
      );
    }

    return (
      <Notification type="info">
        <FormattedMessage
          id="inviteMemberModal.description.charge.paid"
          defaultMessage="You can invite only {freeInvitationNumber} members for free."
          values={{ freeInvitationNumber: seatsPoolCount }}
        />
        {' '}
        {additionalMessage}
      </Notification>
    );
  }

  return (
    <FormattedHTMLMessage
      id="inviteMemberModal.description.free"
      defaultMessage="You can invite <strong>{freeSeatsLeftNumber}</strong> members for free."
      values={{ freeSeatsLeftNumber: freeSeatsLeft }}
    />
  );
};

export default React.memo<Props>(InviteMembersFormDescription);
