// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import urls from 'config/urls';
import { getUserPermissions } from 'state/root-reducer';
import type { UserPermission } from 'state/user/user-permissions';

type Props = {
  children: Node,
  permission: ?UserPermission,
  userPermissions: Array<UserPermission>,
};

const PermissionsGuard = (props: Props) => {
  const { userPermissions, permission, children } = props;
  const { search } = useLocation();

  if (!permission) return children;

  const redirectLocation = {
    search,
    pathname: urls.devices,
  };

  return userPermissions.includes(permission) ? children : <Redirect to={redirectLocation} />;
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  userPermissions: getUserPermissions(state),
});

export { PermissionsGuard as PurePermissionsGuard };

export default connect(mapStateToProps)(PermissionsGuard);
