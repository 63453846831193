// @flow

import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import DefaultError from 'components/shared/default-error/default-error';

import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';

type Props = {|
  onClick: () => Promise<void>,
  addSuccessNotification: (Node | ?{}) => mixed,
  addDangerNotification: (Node | ?{}) => mixed,
|};

class RetryChargeContainer extends Component<Props> {
  render() {
    return (
      <Button
        onClick={this.makeCharge}
      >
        <FormattedMessage
          id="subscriptionInfo.statusNotifications.retryChargeButton"
          defaultMessage="Retry charge"
        />
      </Button>
    );
  }

  makeCharge = () => {
    const { onClick } = this.props;

    return onClick()
      .then(this.onRetryChargeSuccess)
      .catch(this.onRetryChargeError);
  };

  onRetryChargeSuccess = () => {
    const { addSuccessNotification } = this.props;

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="subscriptionInfo.retryCharge.pendingPayment"
          defaultMessage="Your payment is pending."
        />
      ),
    });
  };

  onRetryChargeError = () => {
    const { addDangerNotification } = this.props;

    addDangerNotification({ message: <DefaultError /> });
  };
}

const mapActionsToProps = {
  addSuccessNotification,
  addDangerNotification,
};

export { RetryChargeContainer as PureRetryChargeContainer };

export default connect(null, mapActionsToProps)(RetryChargeContainer);
