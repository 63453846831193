// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import RequestError from '@setapp/request-error';

import type { Node, ComponentType } from 'react';
import FormContainer from 'components/shared/form/form-container/form-container';

import { saveBraintreePaymentMethod } from 'state/payment-method/payment-method-actions';
import {
  getPaymentMethod,
  isPaymentMethodCreated,
  isPaymentDetailsLoading,
  getUser,
} from 'state/root-reducer';

import type { BraintreePaymentDetailsPayload } from 'state/payment-method/payment-method-actions';
import type { PaymentDetails } from 'state/payment-method/payment-method-initial-state';

import type { PaymentFormConfig } from '../payment-form-config-type';

type Props = {
  formConfig: PaymentFormConfig,
  paymentMethod: PaymentDetails,
  isPaymentMethodCreated: boolean,
  savePaymentMethod: (BraintreePaymentDetailsPayload) => Promise<void>,
  onSuccessSubmit?: ?() => any,
  onFailedSubmit?: ?(RequestError) => any,
  submitBtnTitle: Node,
  userLocale: string,
  isFormConfigLoading: boolean,
  isPaymentDetailsLoading: boolean,
  initializationError: Node,
};

const wrapComponent = (WrappedComponent: ComponentType<any>): ComponentType<any> => {
  class PaymentMethodFormWrapper extends Component<Props> {
    static defaultProps = {
      onSuccessSubmit: null,
      onFailedSubmit: null,
    };

    render() {
      const {
        formConfig: {
          token,
          threeDSecureRequired,
          additional: {
            merchantId,
          },
          countries,
        },
        paymentMethod: {
          company,
          vatId,
          streetName,
          addressDetail,
          buildingNumber,
          city,
          postalCode,
        },
        isPaymentMethodCreated,
        userLocale,
        submitBtnTitle,
        isFormConfigLoading,
        isPaymentDetailsLoading,
        initializationError,
      } = this.props;

      return (
        <FormContainer
          onSubmit={this.submitForm}
          initialValues={{
            company,
            vatId,
            streetName,
            addressDetail,
            buildingNumber,
            city,
            postalCode,
            nonce: '',
          }}
        >
          {
            ({
              fields,
              fieldsErrors,
              formError,
              formContainer,
              captcha,
              isProcessing: isFormProcessing,
            }) => (
              <WrappedComponent
                merchantId={merchantId}
                threeDSecureRequired={threeDSecureRequired}
                isLoading={isFormConfigLoading || isPaymentDetailsLoading || isFormProcessing}
                setLoading={formContainer.setProcessing}
                fieldsWithError={fieldsErrors}
                formError={initializationError || formError}
                setGenericError={formContainer.setFormError}
                setErrorFields={formContainer.setFieldsErrors}
                onFieldChange={formContainer.setField}
                onFormSubmit={formContainer.submitForm}
                brainTreeAuthToken={token}
                countries={countries}
                paymentInfo={fields}
                isPaymentMethodCreated={isPaymentMethodCreated}
                userLocale={userLocale}
                submitBtnTitle={submitBtnTitle}
                captcha={captcha}
              />
            )
          }
        </FormContainer>
      );
    }

    submitForm = (fields: BraintreePaymentDetailsPayload) => {
      const { savePaymentMethod } = this.props;

      return savePaymentMethod(fields)
        .then(() => {
          const { onSuccessSubmit } = this.props;

          if (onSuccessSubmit) {
            onSuccessSubmit();
          }
        })
        .catch((error: RequestError) => {
          const { onFailedSubmit } = this.props;

          if (onFailedSubmit) {
            onFailedSubmit(error);
          }

          throw error;
        });
    }
  }

  return PaymentMethodFormWrapper;
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  paymentMethod: getPaymentMethod(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isPaymentDetailsLoading: isPaymentDetailsLoading(state),
  userLocale: getUser(state).locale,
});

const mapActionsToProps = {
  savePaymentMethod: saveBraintreePaymentMethod,
};

export const wrapWithoutStore = (WrappedComponent: ComponentType<any>) => wrapComponent(WrappedComponent);

// eslint-disable-next-line import/no-anonymous-default-export
export default (WrappedComponent: ComponentType<any>) => (
  connect(mapStateToProps, mapActionsToProps)(wrapComponent(WrappedComponent))
);
