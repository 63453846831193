// @flow

import React, {
  useState,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { FormFieldWrapper } from '@setapp/ui-kit';
import { useUpdateEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import type { Node } from 'react';

import KeyCode from 'utils/key-codes';

import Tag from './input-tag/input-tag';

import './tags-input.scss';

type CustomTagRendererProps = {
  value: string,
  onRemove: () => mixed,
}

type TagInnerValue = {
  id: string,
  value: string,
}

type Props = {
  value: string[],
  label: Node,
  showLabel: boolean,
  helpText: Node,
  valid: boolean,
  invalid: boolean,
  placeholder: string,
  tokenSeparators: string[],
  validValues: string[],
  invalidValues: string[],
  renderTag: ?(CustomTagRendererProps) => Node,
  onChange: (string[]) => mixed,
  qaLabel: string,
}

function TagsInput(props: Props) {
  const {
    value,
    label,
    showLabel,
    helpText,
    valid,
    invalid,
    placeholder,
    tokenSeparators,
    validValues,
    invalidValues,
    renderTag,
    onChange,
    qaLabel,
  } = props;
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const measureInputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<TagInnerValue[]>(mapValuesToInnerValues(value));
  const [inputWidth, setInputWidth] = React.useState(0);

  const tagsValue = useMemo(() => tags.map((tag) => tag.value), [tags]);

  useUpdateEffect(() => {
    onChange(tagsValue);
  }, [tagsValue]);

  useLayoutEffect(() => {
    setInputWidth(measureInputRef.current?.scrollWidth ?? 0);
    scrollToBottom();
  }, [inputValue]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [tags]);

  function scrollToBottom() {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current?.scrollHeight;
    }
  }

  function handleChange(event: SyntheticEvent<HTMLTextAreaElement>) {
    const { value } = event.currentTarget;

    setInputValue(value);
    triggerChange(value);
  }

  function handlePaste(event: SyntheticClipboardEvent<HTMLTextAreaElement>) {
    const paste: string = event.clipboardData.getData('text');
    triggerChange(paste);
  }

  function handleKeyDown(event: SyntheticKeyboardEvent<HTMLTextAreaElement>) {
    if (event.keyCode === KeyCode.ENTER) {
      event.preventDefault();
      addTag(inputValue);
    }

    if (event.keyCode === KeyCode.BACKSPACE && !inputValue && tags.length) {
      event.preventDefault();
      deleteTag(tags[tags.length - 1].id);
    }
  }

  function handleBlur(event: SyntheticFocusEvent<HTMLTextAreaElement>) {
    event.preventDefault();
    addTag(inputValue);
  }

  function triggerChange(value?: string) {
    const patchValues = value ? getSeparatedContent(value, tokenSeparators) : null;

    if (patchValues) {
      addTags(patchValues);
    }
  }

  function addTags(value: string[]) {
    if (value.length > 0) {
      const tagsEntity = mapValuesToInnerValues(value);
      setTags((tags) => tags.concat(tagsEntity));
    }

    setInputValue('');
  }

  function addTag(value: string) {
    const tag = value.trim();
    const tagEntity = mapValueToInnerValue(tag);

    if (tag) {
      setInputValue('');
      setTags((tags) => [...tags, tagEntity]);
    }
  }

  function deleteTag(tagId: string) {
    setTags(tags.filter((tag) => tag.id !== tagId));
  }

  function mapValueToInnerValue(value: string): TagInnerValue {
    return { id: uuidv4(), value };
  }

  function mapValuesToInnerValues(value: string[]): TagInnerValue[] {
    return value.map(mapValueToInnerValue);
  }

  const inputPlaceholder = tags.length === 0 ? placeholder : null;

  const tagNodes = useMemo(() => tags.map(({ id, value }, index) => {
    const onRemove = () => deleteTag(id);

    if (typeof renderTag === 'function') {
      return renderTag({ value, onRemove });
    }

    const isValid = validValues.includes(value) && tagsValue.lastIndexOf(value) === index;
    const isInvalid = invalidValues.includes(value) && tagsValue.lastIndexOf(value) === index;

    return (
      <Tag
        key={id}
        onRemove={onRemove}
        valid={isValid}
        invalid={isInvalid}
      >
        {value}
      </Tag>
    );
  }), [tags, tagsValue, validValues, invalidValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormFieldWrapper
      label={label}
      showLabel={showLabel}
      valid={valid}
      invalid={invalid}
      helpText={helpText}
    >
      <div
        className="form-control tags-input"
        ref={containerRef}
      >
        <div className="tags-input__container">
          {tagNodes}

          <textarea
            style={{ width: inputWidth }}
            className="tags-input__textarea"
            rows="1"
            cols="0"
            spellCheck="false"
            autoComplete="false"
            autoCapitalize="off"
            autoCorrect="off"
            placeholder={inputPlaceholder}
            value={inputValue}
            ref={inputRef}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            onBlur={handleBlur}
            data-qa={qaLabel}
          />

          <span ref={measureInputRef} className="sr-only" aria-hidden>
            {inputValue}
            &nbsp;
          </span>
        </div>
      </div>
    </FormFieldWrapper>
  );
}

TagsInput.defaultProps = {
  value: [],
  label: null,
  showLabel: false,
  helpText: null,
  valid: false,
  invalid: false,
  placeholder: '',
  renderTag: null,
  tokenSeparators: [],
  validValues: [],
  invalidValues: [],
  onChange: () => {},
};

function getSeparatedContent(content: string, separators: string[]): ?string[] {
  if (!separators || !separators.length) {
    return null;
  }

  let match = false;

  function separate(str: string, [token, ...restTokens]: string[]) {
    if (!token) {
      return [str];
    }

    const list = str.split(token);
    match = match || list.length > 1;

    return list
      .reduce((prevList, unitStr) => [...prevList, ...separate(unitStr, restTokens)], [])
      .filter((unit) => unit);
  }

  const list = separate(content, separators);

  return match ? list : null;
}

TagsInput.Tag = Tag;

export default TagsInput;
