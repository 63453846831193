// @flow

import React, { type Node, Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { showModal, hideModal } from 'state/modal/modal-reducer';

import DefaultError from 'components/shared/default-error/default-error';

import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';
import type { ModalType } from 'components/modals';

type Props = {
  isPaymentMethodCreated: boolean,
  onReactivateSubscriptionClick: () => Promise<any>,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  showModal: (ModalType, Object) => mixed,
  hideModal: () => mixed,
};

class ReactivateSubscription extends Component<Props> {
  render() {
    return (
      <Button
        onClick={this.onReactivateButtonClick}
        qaLabel="reactivateBtn"
      >
        <FormattedMessage
          id="subscriptionInfo.reactivateCancelled.btnReactivate"
          defaultMessage="Renew subscription"
        />
      </Button>
    );
  }

  reactivate = () => {
    const { onReactivateSubscriptionClick } = this.props;

    onReactivateSubscriptionClick()
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onReactivateButtonClick = () => {
    const {
      isPaymentMethodCreated,
      showModal,
    } = this.props;

    if (isPaymentMethodCreated) {
      this.reactivate();
    } else {
      showModal('SET_PAYMENT_DETAILS', {
        title: (
          <FormattedMessage
            id="subscriptionInfo.reactivateCancelled.addPaymentDetailsModalTitle"
            defaultMessage="Add payment details"
          />
        ),
        onPaymentDetailsSaved: this.handlePaymentDetailsAdded,
      });
    }
  };

  handlePaymentDetailsAdded = () => {
    const {
      addSuccessNotification,
      hideModal,
    } = this.props;

    hideModal();

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="subscriptionInfo.notifications.paymentDetailsAdded"
          defaultMessage="Payment details successfully added."
        />
      ),
    });
  };

  onError = () => {
    const {
      addDangerNotification,
      hideModal,
    } = this.props;

    hideModal();

    addDangerNotification({
      message: <DefaultError />,
    });
  };

  onSuccess = () => {
    const { addSuccessNotification } = this.props;

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="subscriptionInfo.reactivateCancelled.reactivateSuccess"
          defaultMessage="Your subscription is active again!"
        />
      ),
    });
  }
}

const mapActionsToProps = {
  addSuccessNotification,
  addDangerNotification,
  hideModal,
  showModal,
};

export { ReactivateSubscription as PureReactivateSubscription };

export default connect(null, mapActionsToProps)(ReactivateSubscription);
