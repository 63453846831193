// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, DeviceIconUnused } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import { removeSeatFromMember } from 'state/team-plan/team-plan-actions';
import { addSuccessNotification, addDangerNotification } from 'state/notifier/notifier-actions';
import { isTeamMemberLoading } from 'state/root-reducer';

import './remove-seat-from-member.scss';

type Props = {
  onHide: () => any,
  onExited: () => any,
  removeSeatFromMember: (number, number) => Promise<any>,
  show: boolean,
  isMemberLoading: boolean,
  member: TeamMember,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
};

class RemoveSeatFromMemberModal extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      onExited,
      member,
      isMemberLoading,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={<FormattedMessage id="removeDeviceModal.title" defaultMessage="Removing a device" />}
        withCancelButton
      >
        <div className="remove-seat-from-member">
          <div className="remove-seat-from-member__icon">
            <DeviceIconUnused size={80} />
          </div>
          <div className="remove-seat-from-member__content">
            <p>
              <FormattedMessage
                id="removeDeviceModal.disconnectionInfo"
                defaultMessage="A device will be disconnected from {email} and moved to the list of your available devices."
                values={{
                  email: <strong>{member.email}</strong>,
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="removeDeviceModal.disconnectionNote"
                defaultMessage="The member will be signed out from all of the connected devices."
              />
            </p>
          </div>
        </div>
        <Button
          block
          onClick={this.handleRemoveDeviceClick}
          disabled={isMemberLoading}
        >
          <FormattedMessage
            id="removeDeviceModal.confirmButtonTitle"
            defaultMessage="Remove device"
          />
        </Button>
      </Modal>
    );
  }

  handleRemoveDeviceClick = () => {
    const {
      removeSeatFromMember,
      member,
      onHide,
      addSuccessNotification,
      addDangerNotification,
    } = this.props;

    return removeSeatFromMember(member.id, member.additionalSeats[0].id)
      .then(() => {
        addSuccessNotification({
          message: (
            <FormattedMessage
              id="removeDeviceModal.deviceRemovedNotification"
              defaultMessage="Extra device removed"
            />
          ),
        });
        const eventLabel = member.isOwner ? 'Owner' : 'Team Member';
        analytics.trackEvent(events.TEAM_ADDITIONAL_DEVICE_REMOVE, { eventLabel });
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      })
      .finally(onHide);
  };
}

const mapStateToProps = (state, ownProps) => ({
  isMemberLoading: isTeamMemberLoading(state, ownProps.member.id),
});

const mapActionsToProps = {
  removeSeatFromMember,
  addSuccessNotification,
  addDangerNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(RemoveSeatFromMemberModal);

export { RemoveSeatFromMemberModal as PureRemoveSeatFromMemberModal };
