// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkButton from 'components/shared/link-button/link-button';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import * as statuses from 'state/team-plan/team-member-statuses';

type Props = {
  member: TeamMember,
  onResendInviteClick: (TeamMember) => Promise<void>,
  isProcessing: boolean,
};

const MemberQuickAction = ({ member, isProcessing, onResendInviteClick }: Props) => (
  member.status === statuses.PENDING ? (
    <LinkButton
      disabled={isProcessing}
      onClick={onResendInviteClick}
      data-qa="resendInviteButton"
    >
      <FormattedMessage id="teamMemberQuickActions.resendInviteButton" defaultMessage="Resend invitation" />
    </LinkButton>
  ) : null
);

export default MemberQuickAction;
