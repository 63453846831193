// @flow

import type { Element } from 'react';

export type Notification = {
  id: string,
  message: string | Element<any>,
  type?: 'info' | 'warning' | 'danger' | 'success',
  permanent?: boolean,
  delay?: number,
  hideCloseButton?: boolean,
};

export type NotifierState = {
  list: Array<Notification>,
}

const initialState: NotifierState = {
  list: [],
};

export default initialState;
