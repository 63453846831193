// @flow

import initialState, { type InvoiceState } from './invoices-initial-state';
import * as actionTypes from './invoices-action-types';

const invoices = (state: InvoiceState = initialState, action: actionTypes.InvoicesAction): InvoiceState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { list: [...action.payload], isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default invoices;

export const getList = (state: InvoiceState) => state.list;
