// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  onClick: () => void,
};

const CancelSubscriptionButton = ({ onClick }: Props) => (
  <Button
    variant="secondary-outline"
    onClick={onClick}
    data-qa="cancelSubscriptionBtn"
  >
    <FormattedMessage id="subscriptionInfo.btnCancel" defaultMessage="Cancel subscription" />
  </Button>
);

export default CancelSubscriptionButton;
