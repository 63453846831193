// @flow

import React, { PureComponent, type Node } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import PageTitle from 'components/shared/page-title/page-title';
import PageDescription from 'components/shared/page-description/page-description';
import DefaultError from 'components/shared/default-error/default-error';
import DevicesPoolInfo from 'components/shared/pool-info/devices-pool-info/devices-pool-info';
import MembersPoolInfo from 'components/shared/pool-info/members-pool-info/members-pool-info';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import PermissionsAccessControl from 'components/shared/access-control/permissions-access-control';

import type { ModalType } from 'components/modals';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';
import type { MemberRole } from 'state/team-plan/team-member-roles';

import * as permissions from 'state/user/user-permissions';
import { showModal } from 'state/modal/modal-reducer';
import { fetchSubscription } from 'state/subscription/subscription-actions';

import {
  resendInvite,
  removeMember,
  fetchTeam,
  shareMemberRole,
} from 'state/team-plan/team-plan-actions';
import {
  getTeamInfo,
  getTeamMembers,
  isTeamFetched,
  isUserTeamOwner,
  isSubscriptionTrial,
  getPlainTeamMembers,
} from 'state/root-reducer';
import {
  addDangerNotification,
  addSuccessNotification,
} from 'state/notifier/notifier-actions';

import analytics, { events } from 'utils/analytics';
import * as gaEvents from 'utils/analytics/events';

import withEmailAutoConfirmation from 'components/shared/with-email-auto-confirmation/with-email-auto-confirmation';

import ShareRoleButton from './share-role-button/share-role-button';
import MembersList from './members-list/members-list';

import './team-page.scss';

type Props = {|
  isUserTeamOwner: boolean,
  isTeamProcessing: boolean,
  members: Array<TeamMember>,
  resendInvite: (number) => Promise<void>,
  removeMember: (number) => Promise<void>,
  plainMembers: Array<TeamMember>,
  shareMemberRole: (number, ?MemberRole) => Promise<void>,
  maxAdditionalDevicesPerMember: number,
  seatsPoolCount: number,
  additionalDevicesPoolCount: number,
  showModal: (ModalType, Object) => void,
  isTeamFetched: boolean,
  fetchTeam: () => Promise<void>,
  fetchSubscription: () => Promise<any>,
  isSubscriptionTrial: boolean,
  addDangerNotification: ({message: Node}) => mixed,
  addSuccessNotification: ({message: Node}) => mixed,
|};

class TeamPage extends PureComponent<Props> {
  componentDidMount() {
    const {
      fetchTeam,
      fetchSubscription,
      addDangerNotification,
    } = this.props;

    const dataToBeFetched = [
      fetchTeam(),
      fetchSubscription(),
    ];

    return Promise.all(dataToBeFetched)
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  render() {
    const {
      isTeamProcessing,
      members,
      maxAdditionalDevicesPerMember,
      seatsPoolCount,
      additionalDevicesPoolCount,
      isTeamFetched,
      isSubscriptionTrial,
      plainMembers,
    } = this.props;

    if (!isTeamFetched) {
      return <div className="team-page__animated-logo"><AnimatedLogo animate /></div>;
    }

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="team.pageTitle" defaultMessage="Team management" />
        </PageTitle>
        <PageDescription>
          <FormattedMessage
            id="team.pageSubtitle"
            defaultMessage="Manage your team member accounts and their devices. Every member can get up to {maxAdditionalDevicesPerMember} extra devices besides the default one."
            values={{ maxAdditionalDevicesPerMember }}
          />
        </PageDescription>
        <div className="team-page__table-description">
          <div className="team-page__team-pools-container">
            <MembersPoolInfo
              count={seatsPoolCount}
              isSubscriptionTrial={isSubscriptionTrial}
              onAddButtonClick={this.handleAddInvitesAction}
            />
            <DevicesPoolInfo count={additionalDevicesPoolCount} />
          </div>
          <div className="team-page__member-buttons-container">
            <PermissionsAccessControl perform={permissions.TEAM_ROLE_SHARE_TEAM_MANAGEMENT}>
              <ShareRoleButton
                variant="secondary-outline"
                type="button"
                className="mr-4"
                data-qa="shareRoleBtn"
                disabled={plainMembers.length === 0}
                tooltipContent={
                  <FormattedMessage
                    id="team.shareRoleButton.tooltip"
                    defaultMessage="Want to make your teammate an admin? First, add someone to your team."
                  />
                }
                onClick={this.showShareMemberRoleModal}
              >
                <FormattedMessage
                  id="team.shareRoleButton.title"
                  defaultMessage="Share admin role"
                />
              </ShareRoleButton>
            </PermissionsAccessControl>
            <PermissionsAccessControl perform={permissions.TEAM_MEMBER_INVITE_WITH_PURCHASE}>
              <Button
                type="button"
                data-qa="inviteMemberBtn"
                onClick={this.showInviteMemberModal}
              >
                <FormattedMessage
                  id="team.inviteButton"
                  defaultMessage="Invite member"
                />
              </Button>
            </PermissionsAccessControl>
          </div>
        </div>
        <div className="team-page__table-container">
          <MembersList
            members={members}
            resendInvite={this.resendInvite}
            revokeInvite={this.revokeInvite}
            addMemberRole={this.onAddAdminRole}
            removeMemberRole={this.onRemoveAdminRole}
            showRemoveMemberModal={this.showRemoveMemberModal}
            isProcessing={isTeamProcessing}
            onAddSeatToMemberAction={this.handleAddSeatToMemberAction}
            onRemoveSeatFromMemberAction={this.handleRemoveSeatFromMemberAction}
          />
        </div>
      </div>
    );
  }

  showShareMemberRoleModal = () => {
    const { showModal } = this.props;

    showModal('SHARE_MEMBER_ROLE', { onRoleSubmitted: this.addAdminRole });
    analytics.trackEvent(gaEvents.TEAM_SHARE_ADMIN_ROLE_CLICK, { eventLabel: 'Top Button' });
  }

  onAddAdminRole = async (member: TeamMember, role: MemberRole) => {
    analytics.trackEvent(gaEvents.TEAM_SHARE_ADMIN_ROLE_CLICK, { eventLabel: 'Manage' });
    await this.addAdminRole(member, role);
  }

  onRemoveAdminRole = async (member: TeamMember) => {
    analytics.trackEvent(gaEvents.TEAM_REMOVE_ADMIN_ROLE_CLICK);
    await this.removeAdminRole(member);
  }

  showInviteMemberModal = () => {
    const { showModal, seatsPoolCount } = this.props;
    const gaLabel = !seatsPoolCount ? 'Without Available Invites' : 'With Available Invites';

    analytics.trackEvent(gaEvents.TEAM_INVITE_MEMBER_CLICK, { eventLabel: gaLabel });

    showModal('INVITE_MEMBER');
  };

  addAdminRole = async ({ id }: TeamMember, role: MemberRole) => {
    const {
      shareMemberRole, addDangerNotification, addSuccessNotification, fetchTeam,
    } = this.props;

    try {
      await shareMemberRole(id, role);
      await fetchTeam();
      addSuccessNotification({
        message: (
          <FormattedMessage
            id="team.memberItem.addRoleToMember.succeed"
            defaultMessage="This member is now able to manage your team"
          />
        ),
      });

      analytics.trackEvent(gaEvents.TEAM_ADMIN_ROLE_SHARE_SUCCESS);
    } catch {
      addDangerNotification({
        message: <DefaultError />,
      });
    }
  }

  removeAdminRole = async ({ id }: TeamMember) => {
    const {
      shareMemberRole, addDangerNotification, addSuccessNotification, fetchTeam,
    } = this.props;

    try {
      await shareMemberRole(id, null);
      await fetchTeam();
      addSuccessNotification({
        message: (
          <FormattedMessage
            id="team.memberItem.removeRoleFromMember.succeed"
            defaultMessage="The admin role was removed from this member"
          />
        ),
      });

      analytics.trackEvent(gaEvents.TEAM_REMOVE_ADMIN_ROLE_SUCCESS);
    } catch {
      addDangerNotification({
        message: <DefaultError />,
      });
    }
  }

  resendInvite = ({ id, email }: TeamMember) => {
    const {
      resendInvite,
      addSuccessNotification,
      addDangerNotification,
    } = this.props;

    return resendInvite(id)
      .then(() => {
        addSuccessNotification({
          message: (
            <FormattedMessage
              id="team.memberItem.invitationResending.succeed"
              defaultMessage="Invitation resent to {email}"
              values={{ email }}
            />
          ),
        });
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  };

  removeMember = (teamMemberId: number, onSuccess: () => void) => {
    const {
      removeMember,
      addDangerNotification,
    } = this.props;

    return removeMember(teamMemberId)
      .then(onSuccess)
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  };

  revokeInvite = ({ id, email, name }: TeamMember) => this.removeMember(id, () => {
    const {
      addSuccessNotification,
    } = this.props;

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="team.memberItem.revokeInvite.succeed"
          defaultMessage="Invite to {title} successfully revoked."
          values={{ title: name || email }}
        />
      ),
    });
    analytics.trackEvent(events.TEAM_REVOKE_INVITE_SUCCESS);
  });

  onRemoveMemberSubmit = ({ name, email, id }: TeamMember) => this.removeMember(id, () => {
    const {
      addSuccessNotification,
    } = this.props;

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="team.removeTeamMember.successNotification"
          defaultMessage="{title} removed."
          values={{ title: name || email }}
        />
      ),
    });
    analytics.trackEvent(events.TEAM_REMOVE_MEMBER_SUCCESS, { eventLabel: id });
  });

  showRemoveMemberModal = (member: TeamMember) => {
    const { showModal } = this.props;

    showModal('REMOVE_TEAM_MEMBER', { member, onRemoveMemberSubmit: this.onRemoveMemberSubmit.bind(this, member) });
    analytics.trackEvent(events.TEAM_REMOVE_MEMBER_CLICK);
  };

  handleAddSeatToMemberAction = (member: TeamMember) => {
    const { showModal } = this.props;

    showModal('ADD_SEAT_TO_MEMBER', { member });
  };

  handleRemoveSeatFromMemberAction = (member: TeamMember) => {
    const { showModal } = this.props;

    showModal('REMOVE_SEAT_FROM_MEMBER', { member });
  };

  handleAddInvitesAction = () => {
    const { showModal } = this.props;

    showModal('PURCHASE_MEMBER_SEATS');
  };
}

TeamPage.contextTypes = {
  addDangerNotification: PropTypes.func,
  addSuccessNotification: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  members: getTeamMembers(state),
  isUserTeamOwner: isUserTeamOwner(state),
  isTeamProcessing: getTeamInfo(state).isLoading,
  plainMembers: getPlainTeamMembers(state),
  maxAdditionalDevicesPerMember: getTeamInfo(state).maxAdditionalDevicesPerMember,
  seatsPoolCount: getTeamInfo(state).seatsPoolCount,
  additionalDevicesPoolCount: getTeamInfo(state).additionalDevicesPoolCount,
  isTeamFetched: isTeamFetched(state),
  isSubscriptionTrial: isSubscriptionTrial(state),
});

const mapActionsToProps = {
  resendInvite,
  removeMember,
  showModal,
  fetchTeam,
  shareMemberRole,
  fetchSubscription,
  addDangerNotification,
  addSuccessNotification,
};

export { TeamPage as PureTeamPage };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withEmailAutoConfirmation(TeamPage));
