// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import InfoButtonWithTooltip from 'components/shared/info-button/info-button-with-tooltip';

import './active-members-count.scss';

type Props = {|
  activeMembers: Array<TeamMember>,
  withTooltip: ?boolean,
|};

const ActiveMembersCount = ({ activeMembers, withTooltip }: Props) => {
  const activeMembersCount = activeMembers.length;

  if (!withTooltip) {
    return activeMembersCount;
  }

  return (
    <>
      <span>
        {activeMembersCount}
      </span>
      <span className="ml-3">
        <InfoButtonWithTooltip>
          <span className="active-members-count__tooltip-content text_sm">
            <FormattedMessage
              id="subscriptionInfo.membersInTeam.hint"
              defaultMessage="You are charged for this number of members."
            />
          </span>
        </InfoButtonWithTooltip>
      </span>
    </>
  );
};

ActiveMembersCount.defaultProps = {
  withTooltip: true,
};

export default ActiveMembersCount;
