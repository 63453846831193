// @flow

import request from 'utils/request';
import { apiURL } from 'config/api';

export type PaddleFormConfig = {
  vendorId: number,
  checkoutUrl: string,
};

export default class PaddleConfigService {
  fetchConfig(): Promise<PaddleFormConfig> {
    return request.get(apiURL.paddleFormConfig);
  }
}
