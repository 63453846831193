// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { Button } from '@setapp/ui-kit';

import MembersPoolInfo from 'components/shared/pool-info/members-pool-info/members-pool-info';

import './jamf-invitation.scss';

import urls from 'config/urls';

type Props = {
  seatsPoolCount: number,
  onAddInvitationsClick: () => any,
  isSubscriptionTrial: boolean,
};

const JamfInvitation = (props: Props) => {
  const {
    seatsPoolCount,
    onAddInvitationsClick,
    isSubscriptionTrial,
  } = props;

  return (
    <div className="jamf-invitation">
      <div className="jamf-invitation__info-container">
        <MembersPoolInfo
          count={seatsPoolCount}
          isSubscriptionTrial={isSubscriptionTrial}
        />
      </div>
      <Row>
        <Col md={12} lg={8} className="jamf-invitation__description mt-9 mb-6">
          <FormattedMessage
            id="jamfPage.invitationDescription"
            defaultMessage="Get the amount of invitations you need and distribute them among your team members via email or Jamf. Visit your {teamPageLink} section to take control of your invitations and devices."
            values={{
              teamPageLink: (
                <Button
                  tag={Link}
                  variant="link"
                  to={urls.team}
                  className="jamf-invitation__link"
                >
                  <FormattedMessage
                    id="jamfPage.invitationDescription.linkToTeamPage"
                    defaultMessage="Team management"
                  />
                </Button>
              ),
            }}
          />
        </Col>
      </Row>
      <Button
        onClick={onAddInvitationsClick}
      >
        <FormattedMessage
          id="jamfPage.addInvitationsButton"
          defaultMessage="Purchase invites"
        />
      </Button>
    </div>
  );
};

export default JamfInvitation;
