// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionStep from '../action-card/action-card';

import inviteMemberIcon from './images/icon-invite-member.svg';

type Props = {
  checked: boolean,
  onCtaClick: () => mixed,
};

const InviteStep = ({ checked, onCtaClick }: Props) => (
  <ActionStep
    checked={checked}
    onCtaClick={onCtaClick}
    icon={inviteMemberIcon}
    title={(
      <FormattedMessage
        id="dashboard.inviteStep.title"
        defaultMessage="2. Invite teammates"
      />
    )}
    description={(
      <FormattedMessage
        id="dashboard.inviteStep.description"
        defaultMessage="Add members to the Team and let them try all the apps from Setapp."
      />
    )}
    ctaCaption={(
      <FormattedMessage
        id="dashboard.inviteStep.ctaCaption"
        defaultMessage="Invite Member"
      />
    )}
  />
);

export default InviteStep;
