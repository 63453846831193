// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as pricePlanKeys from 'state/price-plans/price-plans-keys';
import type { PricePlanKey } from 'state/price-plans/price-plans-keys';

type Props = {
  priceKey: PricePlanKey,
}

const PricePlanTitle = ({ priceKey }: Props) => {
  switch (priceKey) {
    case pricePlanKeys.MONTHLY:
    case pricePlanKeys.ADDITIONAL_SEAT_MONTHLY_TEAM:
    case pricePlanKeys.ADDITIONAL_SEAT_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.monthly"
          defaultMessage="Monthly"
        />
      );
    }

    case pricePlanKeys.ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.annual"
          defaultMessage="Annual"
        />);
    }

    default: {
      return (
        <FormattedMessage
          id="pricePlans.title.custom"
          defaultMessage="Custom"
        />
      );
    }
  }
};

export default PricePlanTitle;
