// @flow

import { NOT_FOUND } from 'http-status';

import { apiURL } from 'config/api';

import request from 'utils/request';
import analytics, { events } from 'utils/analytics';

import { type Dispatch } from '../state-types';

import * as actionTypes from './devices-action-types';


export function fetchDevices() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.devices)
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });
      });
  };
}

export function deactivateDevice(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.delete(`${apiURL.devices}/${id}`)
      .catch((error) => {
        // behave like success deactivation when device has already been deactivated
        if (error.status !== NOT_FOUND) {
          throw error;
        }
      })
      .then(() => {
        analytics.trackEvent(events.DEVICE_DEACTIVATED);

        return dispatch(fetchDevices());
      }).catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}

export function deactiveAllIosDevices() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.post(apiURL.deactiveAllIosDevices)
      .then(() => {
        analytics.trackEvent(events.DEVICE_ALL_IOS_DEACTIVATED);

        return dispatch(fetchDevices());
      }).catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}
