// @flow

import React, { Component } from 'react';
import { Button } from '@setapp/ui-kit';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import './app-initialization-error.scss';

class AppInitializationError extends Component<{}> {
  render() {
    return (
      <div className="app-initialization-error">
        <AnimatedLogo hasCaption />
        <br />
        <p className="text-danger lead">
          Something failed to load, give it another try.
        </p>
        <Button onClick={this.onReloadClick}>Reload page</Button>
      </div>
    );
  }

  onReloadClick = () => {
    window.location.reload();
  }
}

export default AppInitializationError;
