// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { RouterHistory } from 'react-router-dom';
import modalComponents from 'components/modals';
import { hideModal, removeModal } from 'state/modal/modal-reducer';

import type { ModalState } from 'state/modal/modal-reducer';

type Props = {
  hideModal: () => void,
  removeModal: () => void,
  modal: ModalState,
  history: RouterHistory,
};

class ModalRoot extends Component<Props> {
  stopRouterListening: () => void;

  componentDidMount() {
    const { history } = this.props;
    this.stopRouterListening = history.listen(this.onHistoryChange);
  }

  componentWillUnmount() {
    this.stopRouterListening();
  }

  render() {
    const { hideModal, modal, removeModal } = this.props;
    const { isVisible, modalType, modalProps } = modal;

    if (!modalType) {
      return null;
    }

    const Modal = modalComponents[modalType];

    if (!Modal) {
      throw new Error(`Unknown modal type "${modalType}"`);
    }

    return (
      <Modal
        {...modalProps}
        show={isVisible}
        onHide={hideModal}
        onExited={removeModal}
      />
    );
  }

  onHistoryChange = () => {
    const { hideModal, modal } = this.props;
    if (modal.isVisible) {
      hideModal();
    }
  };
}

export { ModalRoot as PureModalRoot };

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { hideModal, removeModal })(withRouter(ModalRoot));
