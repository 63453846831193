// @flow

import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import { Button } from '@setapp/ui-kit';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import './outer-form.scss';

type Props = {
  title: Node,
  subTitle?: Node,
  submitBtnCaption: Node,
  footer?: Node,
  onSubmit: SyntheticEvent<HTMLFormElement> => any,
  isSubmitProcessing?: boolean,
  errorMessage?: Node,
  children?: Node,
  hideSubmit?: boolean,
  qaLabel?: string,
  hasLogo?: boolean,
};

const OuterForm = (props: Props) => {
  const {
    title,
    subTitle,
    submitBtnCaption,
    footer,
    onSubmit,
    errorMessage,
    isSubmitProcessing,
    qaLabel,
    hasLogo,
    children,
    hideSubmit,
  } = props;
  const errorClasses = classnames('form-error text-danger', {
    hidden: !errorMessage,
  });
  const btnClasses = classnames({
    processing: isSubmitProcessing,
    hidden: hideSubmit,
  });
  const formWrapperClasses = classnames(
    'outer-form__wrapper',
    {
      'outer-form__wrapper_with-logo': hasLogo,
    },
  );

  return (
    <div className="outer-form__container outer-form__container_wide">
      {hasLogo && (
        <div className="text-center">
          <AnimatedLogo animate={isSubmitProcessing} hasCaption />
        </div>
      )}

      <div className={formWrapperClasses}>
        <form className="outer-form" onSubmit={onSubmit} noValidate data-qa={qaLabel}>
          <h1 className="h5 text-center">{title}</h1>
          {subTitle && <p className="text-center mb-6">{subTitle}</p>}

          {children}

          <p className={errorClasses}>{errorMessage}</p>
          <Button
            block
            className={btnClasses}
            type="submit"
            disabled={isSubmitProcessing}
          >
            {submitBtnCaption}
          </Button>
        </form>
        {footer}
      </div>
    </div>
  );
};

OuterForm.defaultProps = {
  subTitle: null,
  hideSubmit: false,
  hasLogo: false,
  footer: null,
  isSubmitProcessing: false,
  errorMessage: null,
  children: null,
  qaLabel: '',
};

export default OuterForm;
