import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import './password-requirements-wrapper.scss';

const messages = defineMessages({
  lengthRule: {
    id: 'passwordRequirementsPopup.lengthRule',
    defaultMessage: '8 characters minimum.',
  },
  uppercaseRule: {
    id: 'passwordRequirementsPopup.uppercaseRule',
    defaultMessage: 'At least 1 uppercase letter.',
  },
  lowercaseRule: {
    id: 'passwordRequirementsPopup.lowercaseRule',
    defaultMessage: 'At least 1 lowercase letter.',
  },
});

export type Props = {
  isShown: boolean;
  isErrorsHighlighted: boolean;
  isMinimumLengthUsed: boolean;
  isLowercaseUsed: boolean;
  isUppercaseUsed: boolean;
  children: ReactNode;
  intl?: IntlShape;
}

const PasswordRequirementsWrapper = ({
  isShown,
  isErrorsHighlighted,
  isMinimumLengthUsed,
  isLowercaseUsed,
  isUppercaseUsed,
  children,
  intl,
}: Props) => {
  const lengthValidityClassnames = classnames(
    'password-requirements-wrapper__item',
    {
      'password-requirements-wrapper__item_valid': isMinimumLengthUsed,
      'password-requirements-wrapper__item_invalid': isErrorsHighlighted && !isMinimumLengthUsed,
    },
  );

  const uppercaseValidityClassnames = classnames(
    'password-requirements-wrapper__item',
    {
      'password-requirements-wrapper__item_valid': isUppercaseUsed,
      'password-requirements-wrapper__item_invalid': isErrorsHighlighted && !isUppercaseUsed,
    },
  );

  const lowercaseValidityClassnames = classnames(
    'password-requirements-wrapper__item',
    {
      'password-requirements-wrapper__item_valid': isLowercaseUsed,
      'password-requirements-wrapper__item_invalid': isErrorsHighlighted && !isLowercaseUsed,
    },
  );

  const listClasses = classnames(
    'password-requirements-wrapper__tooltip',
    {
      'password-requirements-wrapper__tooltip_active': isShown,
    },
  );

  return (
    <div className="password-requirements-wrapper">
      {children}
      <div className={listClasses}>
        <ul className="password-requirements-wrapper__list">
          <li className={lengthValidityClassnames}>
            {intl.formatMessage(messages.lengthRule)}
          </li>
          <li className={uppercaseValidityClassnames}>
            {intl.formatMessage(messages.uppercaseRule)}
          </li>
          <li className={lowercaseValidityClassnames}>
            {intl.formatMessage(messages.lowercaseRule)}
          </li>
        </ul>
      </div>
    </div>
  );
};

export { PasswordRequirementsWrapper as PurePasswordRequirementsWrapper };

export default injectIntl(PasswordRequirementsWrapper);
