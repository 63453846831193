// @flow

import ResendEmailCaptchaModal from './resend-email-captcha/resend-email-captcha-modal';
import InviteMemberModal from './invite-member/invite-member-modal';
import CancelSubscriptionModal from './cancel-subscription/cancel-subscription-modal';
import RemoveTeamMemberModal from './remove-team-member/remove-team-member';
import AddSeatToMember from './add-seat-to-member/add-seat-to-member-modal';
import RemoveSeatFromMemberModal from './remove-seat-from-member/remove-seat-from-member';
import PurchaseMemberSeatsModal from './purchase-member-seats/purchase-member-seats';
import RevokeJamfKey from './revoke-jamf-key/revoke-jamf-key';
import DisconnectDeviceModal from './disconnect-device/disconnect-device-modal';
import PaymentDetailsModal from './payment-details/payment-details';
import ChangePlanModal from './change-plan/change-plan-modal';
import ShareMemberRoleModal from './share-member-role/share-member-role-modal';

export type ModalType =
  | 'RESEND_EMAIL_CAPTCHA'
  | 'CANCEL_SUBSCRIPTION'
  | 'REMOVE_TEAM_MEMBER'
  | 'INVITE_MEMBER'
  | 'ADD_SEAT_TO_MEMBER'
  | 'REMOVE_SEAT_FROM_MEMBER'
  | 'PURCHASE_MEMBER_SEATS'
  | 'REVOKE_JAMF_KEY'
  | 'DISCONNECT_DEVICE'
  | 'SET_PAYMENT_DETAILS'
  | 'CHANGE_PLAN'
  | 'SHARE_MEMBER_ROLE';

const modalComponentsByModalType = {
  RESEND_EMAIL_CAPTCHA: ResendEmailCaptchaModal,
  INVITE_MEMBER: InviteMemberModal,
  CANCEL_SUBSCRIPTION: CancelSubscriptionModal,
  REMOVE_TEAM_MEMBER: RemoveTeamMemberModal,
  ADD_SEAT_TO_MEMBER: AddSeatToMember,
  REMOVE_SEAT_FROM_MEMBER: RemoveSeatFromMemberModal,
  PURCHASE_MEMBER_SEATS: PurchaseMemberSeatsModal,
  REVOKE_JAMF_KEY: RevokeJamfKey,
  DISCONNECT_DEVICE: DisconnectDeviceModal,
  SET_PAYMENT_DETAILS: PaymentDetailsModal,
  CHANGE_PLAN: ChangePlanModal,
  SHARE_MEMBER_ROLE: ShareMemberRoleModal,
};

export default modalComponentsByModalType;
