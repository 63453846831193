// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import httpStatuses from 'http-status';
import queryString from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';

import type { Node } from 'react';
import type { RouterHistory, Location } from 'react-router-dom';

import { isUserAuthenticated } from 'state/root-reducer';
import { signUpMember } from 'state/user/user-actions';
import validate from 'utils/auth-validation';
import auth from 'utils/auth';
import urls from 'config/urls';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import FormContainer from 'components/shared/form/form-container/form-container';
import PasswordErrorMessage from 'components/shared/password-error-message/password-error-message';

import SignupMemberForm from './signup-member-form/signup-member-form';
import SignupMemberTokenError from './signup-member-token-error/signup-member-token-error';

import './signup-member.scss';

type Props = {
  signUpMember: Function,
  history: RouterHistory,
  location: Location,
  isUserAuthenticated: boolean,
};

type State = {
  isInviteTokenExpired: boolean,
  isInviteErrorFailed: boolean,
  isPasswordInvalid: boolean,
  isLoading: boolean,
  email: string,
  companyName: string,
  inviteId: string,
}

type FormFields = {
  [string]: any,
};

type FormFieldsErrors = {
  [string]: Node,
}

class SignUpMemberPage extends Component<Props, State> {
  state = {
    isInviteTokenExpired: false,
    isInviteErrorFailed: false,
    isPasswordInvalid: false,
    isLoading: false,
    email: '',
    companyName: '',
    inviteId: '',
  };

  componentDidMount(): void {
    const { location } = this.props;
    const { invite } = queryString.parse(location.search);

    if (!invite) {
      this.showNoInviteState();

      return;
    }

    this.checkInvitation(invite);
  }

  render() {
    const {
      isLoading,
      isInviteErrorFailed,
      isInviteTokenExpired,
      isPasswordInvalid,
      email,
      companyName,
      inviteId,
    } = this.state;

    if (isInviteErrorFailed) {
      const {
        location,
        isUserAuthenticated,
      } = this.props;

      if (isUserAuthenticated) {
        return (
          <Redirect to={`${urls.successfulRegistration}${location.search}`} />
        );
      }

      return <SignupMemberTokenError isInviteTokenExpired={isInviteTokenExpired} />;
    }

    if (isLoading || !email || !inviteId) {
      return (
        <div style={{ margin: 'auto' }}>
          <AnimatedLogo animate hasCaption />
        </div>
      );
    }

    return (
      <div className="signup-member">
        <div className="container signup-member__logo-container">
          <AnimatedLogo hasCaption size="sm" />
        </div>
        <div className="signup-member__form-wrapper">
          <h1 className="h4 signup-member__title">
            <FormattedMessage id="outer.signupMember.title" defaultMessage="One more thing, create a password" />
          </h1>
          <div className="signup-member__signup-block">
            <FormattedHTMLMessage
              id="outer.signupMember.formDescription"
              defaultMessage="You’ll be joining {companyName} as <strong>{email}</strong>"
              values={{
                companyName,
                email,
              }}
            />
          </div>
          <FormContainer
            initialValues={{
              email,
              password: '',
              inviteId,
              marketingSubscribed: false,
              termsAccepted: false,
            }}
            onSubmit={this.onFormSubmit}
            validate={this.validateForm}
          >
            {({
              fields,
              fieldsErrors,
              formError,
              isProcessing,
              onSubmit,
              onFieldChange,
            }) => (
              <SignupMemberForm
                onFieldChange={(event) => {
                  this.setState({ isPasswordInvalid: false });
                  onFieldChange(event);
                }}
                password={fields.password}
                marketingSubscribed={fields.marketingSubscribed}
                termsAccepted={fields.termsAccepted}
                passwordError={fieldsErrors.password}
                termsAcceptedError={fieldsErrors.termsAccepted}
                genericError={fieldsErrors.email || formError}
                isRequestProcessing={isProcessing}
                isPasswordInvalid={isPasswordInvalid}
                onFormSubmit={onSubmit}
              />
            )}
          </FormContainer>
        </div>
      </div>
    );
  }

  checkInvitation(invite?: string) {
    return auth.validateInvitationToken(invite)
      .then((invite: {
        id: string,
        email: string,
        companyName: string,
      }) => {
        this.setState({
          email: invite.email,
          companyName: invite.companyName,
          inviteId: invite.id,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });

        const failedState = {
          isLoading: false,
          isInviteErrorFailed: true,
        };

        if (e.status === httpStatuses.NOT_FOUND) {
          this.setState({
            ...failedState,
            isInviteTokenExpired: true,
          });
        } else {
          this.setState(failedState);
        }
      });
  }

  showNoInviteState() {
    this.setState({
      isLoading: false,
      isInviteErrorFailed: true,
      isInviteTokenExpired: true,
    });
  }

  validateForm = (fields: $Shape<FormFields>): FormFieldsErrors => {
    const errors = validate(fields, {
      password: {
        required: true,
        passwordFormat: true,
        passwordContainsBothLowerAndUpper: true,
      },
      termsAccepted: {
        required: (
          <FormattedMessage
            id="outer.signupMember.validation.emptyPolicy"
            defaultMessage="Please agree with policies to continue"
          />
        ),
      },
    });

    if (errors.password) {
      this.setState({ isPasswordInvalid: true });
    }

    return {
      ...errors,
      password: errors.password && <PasswordErrorMessage password={fields.password} />,
    };
  }

  onFormSubmit = (fields: $Shape<FormFields>) => this.signUpUser(fields);

  async signUpUser(data: $Shape<FormFields>): Promise<void> {
    const {
      signUpMember,
      history,
    } = this.props;

    await signUpMember(data);
    history.push(urls.successfulRegistration);
  }
}

export { SignUpMemberPage as PureSignUpMemberPage };

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isUserAuthenticated: isUserAuthenticated(state),
});

export default connect(mapStateToProps, { signUpMember })(withRouter(SignUpMemberPage));
