// @flow

export const apiRoot = process.env.API_ROOT;
export const membershipApiRoot = process.env.MEMBERSHIP_API_ROOT;
// $FlowFixMe
export const apiRootV1 = `${apiRoot}/v1`;
// $FlowFixMe
export const membershipApiRootV1 = `${membershipApiRoot}/v1`;

export const apiURL = {
  account: `${membershipApiRootV1}/me`,
  signup: `${membershipApiRootV1}/teams/me`,
  signupMember: `${membershipApiRootV1}/teams/member/me`,
  resendConfirmationEmail: `${membershipApiRootV1}/me/resend_confirmation_email`,
  confirmEmail: `${membershipApiRootV1}/me/confirm_email`,
  auth: `${membershipApiRootV1}/auth`,
  logout: `${membershipApiRootV1}/logout`,
  token: `${membershipApiRootV1}/token`,
  resumeSubscription: (subscriptionId: number) => `${membershipApiRootV1}/subscriptions/${subscriptionId}/resume`,
  cancelSubscription: (subscriptionId: number) => `${membershipApiRootV1}/subscriptions/${subscriptionId}/cancel`,
  subscriptions: `${membershipApiRootV1}/subscriptions`,
  retryPayment: `${membershipApiRootV1}/subscriptions/actions/retry_payment`,
  invitation: `${membershipApiRootV1}/teams/invites`,
  paymentConfig: `${membershipApiRootV1}/payment_form_config`,
  paddleFormConfig: `${membershipApiRootV1}/payment_form_config/paddle`,
  payment: `${membershipApiRootV1}/payment_info`,
  paddlePaymentDetails: `${membershipApiRootV1}/payment_info/paddle`,
  requestPasswordReset: `${membershipApiRootV1}/password/request`,
  restorePass: `${membershipApiRootV1}/password/reset`,
  usersInvitations: `${membershipApiRootV1}/me/invites`,
  crossAuthToken: `${membershipApiRootV1}/me/cross_auth_token`,
  loginCrossAuthToken: `${membershipApiRootV1}/login_by_access_token`,
  devices: `${membershipApiRootV1}/devices`,
  deactiveAllIosDevices: `${membershipApiRootV1}/devices/disconnect_all_ios_devices`,
  referralInfo: `${membershipApiRootV1}/referral/referral_info`,
  applications: `${membershipApiRootV1}/apps_access/apps`,
  bundleInfo: `${membershipApiRootV1}/bundles/`,
  pricePlans: `${membershipApiRootV1}/price_plans`,
  changePricePlan: (subscriptionId: number) => `${membershipApiRootV1}/subscriptions/${subscriptionId}/next_price_plan`,
  invoices: `${membershipApiRootV1}/invoices`,
  downloadInvoice: `${membershipApiRootV1}/invoices/%{invoiceId}/download?token=%{token}`,
  pushIdentifier: `${membershipApiRootV1}/me/push_identifier`,
  familyMembers: `${membershipApiRootV1}/family/members`,
  familyMembersInviteMany: `${membershipApiRootV1}/family/members/actions/invite_many`,
  sendDownloadLinkEmail: `${membershipApiRootV1}/teams/send_download_email`,
  teamAdditionalSeat: `${membershipApiRootV1}/teams/purchase_additional_seats`,
  teamPayment: `${membershipApiRootV1}/teams/payment_info`,
  featureFlags: `${membershipApiRootV1}/feature_flags`,

  // Teams endpoints
  team: `${apiRootV1}/team`,
  teamMembers: `${apiRootV1}/team/members`,
  teamMembersAddDevice: (memberId: number) => `${apiRootV1}/team/members/${memberId}/additional_seats`,
  teamMembersRemoveDevice: (memberId: number, subscriptionId: number) => `${apiRootV1}/team/members/${memberId}/additional_seats/${subscriptionId}`,
  teamPersonalKey: `${apiRootV1}/team/key`,
  teamMembersSeat: `${apiRootV1}/team/actions/purchase_member_seats`,
  teamMembersAddRole: (memberId: number) => `${apiRootV1}/team/members/${memberId}/role`,
};
