// @flow

import React, { type Node } from 'react';

type Props = {|
  children: Node,
|};

const FullscreenLayoutSecondaryContent = (props: Props) => {
  const { children } = props;

  return (
    <div className="fullscreen-layout__secondary-content">
      <div className="fullscreen-layout__sticky">
        <div className="fullscreen-layout__secondary-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FullscreenLayoutSecondaryContent;
