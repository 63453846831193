// @flow

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { RouterHistory } from 'react-router-dom';

import desktopAppHelper from 'utils/desktop-app-helper';
import analytics, { events } from 'utils/analytics/index';

import { getUser } from 'state/root-reducer';

import urls from 'config/urls';

import SuccessfulRegistrationDefault from './successful-registration-default/successful-registration-default';
import SuccessfulRegistrationFromDesktop
  from './successful-registration-from-desktop-app/successful-registration-from-desktop-app';
import SuccessfulRegistrationMobile from './successful-registration-mobile/successful-registration-for-mobile';


import './successful-registration-page.scss';

type Props = {
  userEmail: string,
  flowParams: {[string]: any},
  history: RouterHistory,
};

type State = {
  isDesktopOpened: boolean,
  isTokenRequestProcessing: boolean,
  isFromDesktop: boolean,
};

class SuccessfulRegistrationPage extends PureComponent<Props, State> {
  static defaultProps = {
    flowParams: {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isDesktopOpened: false,
      isTokenRequestProcessing: false,
      isFromDesktop: desktopAppHelper.hasOpenedCurrentSession(),
    };
  }

  render() {
    return (
      <div className="successful-registration">
        {this.getContentVariant()}
      </div>
    );
  }

  // TODO: decide if we have to remove any of this flows
  getContentVariant = () => {
    const { userEmail } = this.props;
    const {
      isFromDesktop,
      isTokenRequestProcessing,
      isDesktopOpened,
    } = this.state;
    const { isMobile } = this.context;

    if (isMobile) {
      return (
        <SuccessfulRegistrationMobile userEmail={userEmail} />
      );
    }

    if (isFromDesktop) {
      return (
        <SuccessfulRegistrationFromDesktop
          isTokenRequestProcessing={isTokenRequestProcessing}
          isDesktopOpened={isDesktopOpened}
          onOpenSetappClick={this.onOpenSetappClick}
        />);
    }

    return (
      <SuccessfulRegistrationDefault
        onDirectDownloadClick={this.onDirectDownloadClick}
      />
    );
  };

  onDirectDownloadClick() {
    analytics.trackEvent(events.SUCCESSFUL_REGISTRATION_CLICK_DOWNLOAD);
  }

  onOpenSetappClick = () => {
    const { isDesktopOpened } = this.state;
    const { history } = this.props;

    if (isDesktopOpened) {
      history.push(urls.account);

      return;
    }

    this.openDesktopClient();
  };

  openDesktopClient() {
    const { userEmail } = this.props;

    this.setState({ isTokenRequestProcessing: true });

    return desktopAppHelper.openWithAuth(userEmail)
      .then(() => {
        this.setState({
          isTokenRequestProcessing: false,
          isDesktopOpened: true,
        });

        desktopAppHelper.removeSession();

        analytics.trackEvent(events.SUCCESSFUL_REGISTRATION_OPEN_DESKTOP);
      })
      .catch(() => {
        desktopAppHelper.removeSession();

        this.setState({ isTokenRequestProcessing: false });
      });
  }
}

SuccessfulRegistrationPage.contextTypes = {
  isMobile: PropTypes.bool,
};

export { SuccessfulRegistrationPage as PureSuccessfulRegistrationPage };

const mapStateToProps = (state) => ({
  userEmail: getUser(state).email,
});

export default connect(mapStateToProps)(SuccessfulRegistrationPage);
