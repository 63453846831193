// @flow

import React, { Component } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import './card-info-notification.scss';

type Props = {
  creditCardExpiration: {
    isExpired: boolean,
    isAboutToExpire: boolean,
  },
  nextPaymentDate: ?number,
  isSubscriptionTrial: boolean,
};

class CardInfoNotification extends Component<Props> {
  render() {
    return (
      <div className="card-info-notification">
        {this.getNotification()}
      </div>
    );
  }

  getNotification() {
    const {
      creditCardExpiration: {
        isExpired,
        isAboutToExpire,
      },
      isSubscriptionTrial,
      nextPaymentDate,
    } = this.props;

    if (isExpired) {
      return (
        <span className="card-info-notification__content card-info-notification__content_danger text_sm">
          <FormattedMessage
            id="paymentDetails.notifications.cardExpired"
            defaultMessage="Your card has expired! To keep using Setapp, update your payment details."
          />
        </span>
      );
    }

    if (isAboutToExpire) {
      return (
        <span className="card-info-notification__content card-info-notification__content_danger text_sm">
          <FormattedMessage
            id="paymentDetails.notifications.cardExpiresInTwoWeeks"
            defaultMessage="Seems like your card will expire soon. Update your payment details to keep using Setapp."
          />
        </span>
      );
    }

    if (isSubscriptionTrial && nextPaymentDate) {
      return (
        <span className="card-info-notification__content text_sm">
          <FormattedMessage
            id="paymentDetails.notifications.subscriptionTrial.cardAdded"
            defaultMessage="Rest assured, your payment data is safe. You're on a free trial until {trialEndDate}. At the end of it, we'll send an email notification before charging you."
            values={{
              trialEndDate: (
                <FormattedDate
                  value={nextPaymentDate * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              ),
            }}
          />
        </span>
      );
    }

    return (
      <span className="card-info-notification__content text_sm">
        <FormattedMessage
          id="paymentDetails.notifications.subscriptionActive.cardAdded"
          defaultMessage="Rest assured, your data is safe and secure. Update your credit card details if they have changed."
        />
      </span>
    );
  }
}

export default CardInfoNotification;
