import React from 'react';
import InlineSvg from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { getCreateSupportRequestUrl } from 'utils/support';

import helpLogo from './help-center.svg';


const HelpIconLink = () => (
  <a href={getCreateSupportRequestUrl()} className="icon-link" target="_blank" rel="noopener noreferrer">
    <span className="icon-link__svg">
      <InlineSvg src={helpLogo} />
    </span>
    <p className="icon-link__text">
      <FormattedMessage id="linkIcons.helpCenter" defaultMessage="Visit Help Center" />
    </p>
  </a>
);

export default HelpIconLink;
