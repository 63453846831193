// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import './remove-team-member.scss';

type Props = {
  onRemoveMemberSubmit: () => Promise<void>,
  member: TeamMember,
  onHide: () => void,
  onExited: () => void,
  show: boolean,
};

class RemoveTeamMember extends PureComponent<Props> {
  render() {
    const {
      onHide,
      onExited,
      show,
      member,
    } = this.props;

    return (
      <Modal
        onHide={onHide}
        onExited={onExited}
        show={show}
        title={<FormattedMessage id="removeTeamMember.title" defaultMessage="Removing a member" />}
      >
        <div className="remove-member-modal__content">
          <FormattedMessage
            id="removeTeamMember"
            defaultMessage="{email} will be removed from your team. Use the newly available spot by inviting another member instead."
            values={{
              email: <strong>{member.email}</strong>,
            }}
          />
        </div>

        <Button
          block
          disabled={false}
          onClick={this.onRemoveClick}
        >
          <FormattedMessage id="removeTeamMember.submitBtnTitle" defaultMessage="Remove member" />
        </Button>

        <Button
          variant="secondary-outline"
          className="mt-3"
          block
          onClick={this.onCancelClick}
        >
          <FormattedMessage
            id="removeTeamMember.cancelBtnTitle"
            defaultMessage="Cancel"
          />
        </Button>
      </Modal>
    );
  }

  onRemoveClick = () => {
    const { onHide, onRemoveMemberSubmit } = this.props;

    return onRemoveMemberSubmit()
      .then(onHide);
  };

  onCancelClick = () => {
    const { onHide } = this.props;

    onHide();
  };
}

export default RemoveTeamMember;
