// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DeviceIcon } from '@setapp/ui-kit';

import type { SubscriptionWithAdditionalSeats } from 'state/subscription/subscription-initial-state';
import urls from 'config/urls';

import AdditionalSeatsInfo from './additional-seats-info';
import AdditionalInfoEmpty from './additional-seats-empty';


import './additional-seats.scss';

type Props = {|
  subscription: ?SubscriptionWithAdditionalSeats,
  maxSeatsPerMember: number,
|};

const AdditionalSeats = (props: Props) => {
  const { subscription, maxSeatsPerMember } = props;

  return (
    <div className="additional-seats" data-qa="additionalSeatsBlock">
      <div className="additional-seats__header">
        <DeviceIcon device="macbook" size={24} className="additional-seats__header-icon" />
        <div className="text_lg">
          <FormattedMessage
            id="subscriptionInfo.additionalSeats.title"
            defaultMessage="Extra devices"
          />
        </div>
      </div>
      {subscription ? (
        <>
          <p className="additional-seats__header-subtitle">
            <FormattedMessage
              id="subscriptionInfo.additionalSeats.titleNote"
              defaultMessage="Manage your team's extra devices in {teamManagementLink} section"
              values={{
                teamManagementLink: (
                  <Link className="link-outer" to={urls.team}>
                    <FormattedMessage id="subscriptionInfo.additionalSeats.teamManagementLink" defaultMessage="Team management" />
                  </Link>
                ),
              }}
            />
          </p>
          <AdditionalSeatsInfo subscription={subscription} />
        </>
      ) : (
        <p className="additional-seats__header-subtitle">
          <AdditionalInfoEmpty seatsPerMember={maxSeatsPerMember} />
        </p>
      )}
    </div>
  );
};

export default AdditionalSeats;
