// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import paypalLogo from './images/paypal-logo.svg';
import creditCard from './images/credit-card-logo.svg';

type Props = {
  type: 'card' | 'paypal',
};

const PaymentMethodTabContent = ({ type }: Props) => {
  if (type === 'card') {
    return (
      <>
        <img src={creditCard} alt="" />
        <span className="sr-only">
          <FormattedMessage id="paymentDetails.creditCard.altTab" defaultMessage="Payment method credit card" />
        </span>
      </>
    );
  }

  return (
    <>
      <img src={paypalLogo} alt="" />
      <span className="sr-only">
        <FormattedMessage id="paymentDetails.paypal.altTab" defaultMessage="Payment method PayPal" />
      </span>
    </>
  );
};

export default PaymentMethodTabContent;
