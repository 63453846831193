// @flow

import Fuse from 'fuse.js';

// Option descriptions could be found by link https://fusejs.io/api/options.html#basic-options
type Options = {
  isCaseSensitive?: boolean,
  includeScore?: boolean,
  includeMatches?: boolean,
  minMatchCharLength?: number,
  shouldSort?: boolean,
  findAllMatches?: boolean,
  keys?: $ReadOnlyArray<any>,
  location?: number,
  threshold?: number,
  distance?: number,
  useExtendedSearch?: boolean,
  getFn?: (obj: any, path: string) => any,
}
type Item = Object;
type Collection = Array<Item>;

export type FuseResultMatch = {
  indices: Array<[number, number]>,
  key?: string,
  refIndex?: number,
  value?: string,
}

type FuseResults = Array<{
  item: Item,
  refIndex: number,
  score?: number,
  matches?: Array<FuseResultMatch>,
}>;

interface FuseInterface {
  search(string): FuseResults;
  setCollection(Collection): void;
  add(Item): void;
  removeAt(number): void
}

export default class FuzzySearcher {
  fuse: FuseInterface;

  constructor(collection?: Collection, options?: Options) {
    this.fuse = new Fuse(collection, options);
  }

  setCollection = (collection: Collection) => {
    this.fuse.setCollection(collection);
  };

  search = (query: string): Collection => {
    const fuseResultsList = this.fuse.search(query);

    return fuseResultsList.reduce((accumulator, current) => {
      accumulator.push(current.item);

      return accumulator;
    }, []);
  };
}
