// @flow

import React, { Component } from 'react';
import FormControlFeedback from 'react-bootstrap/lib/FormControlFeedback';
import { FormFieldWrapper, FormField } from '@setapp/ui-kit';

import './search-form.scss';
import { defineMessages, injectIntl, type IntlShape } from 'react-intl';

type Props = {
  intl: IntlShape,
  onSubmit: (string) => void,
};

const messages = defineMessages({
  searchFieldPlaceholder: {
    id: 'searchField.placeholder',
    defaultMessage: 'What app is on your mind?',
  },
});

class SearchForm extends Component <Props> {
  input: HTMLInputElement;

  render() {
    const { intl } = this.props;

    return (
      <div onSubmit={this.handleSearchFormSubmit} className="search-form mt-5">
        <form noValidate>
          <FormFieldWrapper
            label={intl.formatMessage(messages.searchFieldPlaceholder)}
          >
            <FormField
              placeholder={intl.formatMessage(messages.searchFieldPlaceholder)}
              onChange={this.handleSearchQueryInput}
              /* We have to set ref by function instead of createRef because of react-bootstrap */
              inputRef={(inputRef) => { this.input = inputRef; }}
              autoFocus
              qaLabel="searchField"
            />
            <FormControlFeedback>
              <span className="search-form__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <g fill="none" fillRule="evenodd" stroke="#3A3844" opacity=".504">
                    <circle cx="8.571" cy="8.571" r="7.857" strokeWidth="1.429" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.857" d="M14.631 14.631L20 20" />
                  </g>
                </svg>
              </span>
            </FormControlFeedback>
          </FormFieldWrapper>
        </form>
      </div>
    );
  }

  handleSearchFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { onSubmit } = this.props;
    const { value } = this.input;

    onSubmit(value);
  };

  handleSearchQueryInput = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const { onSubmit } = this.props;

    onSubmit(value);
  };
}

export { SearchForm as PureSearchForm };

export default injectIntl(SearchForm);
