// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import PanelBox from 'components/shared/panel-box/panel-box';

import './add-payment-details.scss';

type Props = {
  onAddClick: () => mixed,
};

const AddPaymentDetails = ({ onAddClick }: Props) => (
  <PanelBox>
    <PanelBox.Content>
      <div className="add-payment-details__title text_lg">
        <FormattedMessage id="addPaymentDetails.title" defaultMessage="Payment details" />
      </div>
      <svg width="231" height="28" viewBox="0 0 231 28">
        <g fill="none" fillRule="evenodd" transform="translate(0 -.662)">
          <path fill="#F1F0F0" d="M36.563.662H2.437C1.097.662 0 1.862 0 3.33v22.667c0 1.466 1.097 2.666 2.438 2.666h34.124c1.341 0 2.438-1.2 2.438-2.666V3.329C39 1.862 37.903.662 36.562.662z" />
          <rect width="40" height="10.035" x="56" y=".662" fill="#262424" opacity=".068" rx="2.5" />
          <rect width="40" height="10.035" x="101" y=".662" fill="#262424" opacity=".068" rx="2.5" />
          <rect width="40" height="10.035" x="146" y=".662" fill="#262424" opacity=".068" rx="2.5" />
          <rect width="40" height="10.035" x="191" y=".662" fill="#262424" opacity=".068" rx="2.5" />
        </g>
      </svg>
      <div className="add-payment-details__note text_sm">
        <FormattedMessage id="addPaymentDetails.note" defaultMessage="Add payment details to use Setapp without limits" />
      </div>

      <Button
        variant="link"
        onClick={onAddClick}
        data-qa="addPaymentMethodButton"
      >
        <FormattedMessage id="addPaymentDetails.addLink" defaultMessage="Add payment details" />
      </Button>
    </PanelBox.Content>
  </PanelBox>
);

export default AddPaymentDetails;
