// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import SuccessfulRegistrationPageTitle from './page-title/page-title';

type Props = {
  isTokenRequestProcessing?: boolean,
  isDesktopOpened?: boolean,
  onOpenSetappClick: () => any,
};

const SuccessfulRegistrationWhenOpenedDesktopApp = (props: Props) => {
  const { isTokenRequestProcessing, onOpenSetappClick, isDesktopOpened } = props;

  return (
    <div>
      <SuccessfulRegistrationPageTitle flow="desktop" />

      <Button
        onClick={onOpenSetappClick}
        disabled={isTokenRequestProcessing}
      >
        {
          isDesktopOpened ? (
            <FormattedMessage
              id="successfulRegistration.fromDesktop.goToAccountBtnTitle"
              defaultMessage="Go to my account"
            />
          ) : (
            <FormattedMessage
              id="successfulRegistration.fromDesktop.goToSetappBtnTitle"
              defaultMessage="Go to Setapp"
            />
          )
        }
      </Button>
    </div>
  );
};

SuccessfulRegistrationWhenOpenedDesktopApp.defaultProps = {
  isTokenRequestProcessing: false,
  isDesktopOpened: false,
};

export default SuccessfulRegistrationWhenOpenedDesktopApp;
