// @flow

import React, { type Node, PureComponent } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { FormattedMessage } from 'react-intl';
import httpStatuses from 'http-status';
import queryString from 'query-string';

import type { Location, RouterHistory } from 'react-router-dom';

import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';

import {
  updateLocale,
  unsetLocaleChangedFlag,
  confirmEmail,
  updateUser,
} from 'state/user/user-actions';
import { getUser } from 'state/root-reducer';
import {
  addSuccessNotification,
  addWarningNotification,
  addDangerNotification,
} from 'state/notifier/notifier-actions';

import { LOCALE_NAMES } from 'config/locales';

import { removeQueryParams, EMAIL_CONFIRMATION_TOKEN } from 'utils/location';

import type { User } from 'state/user/user-initial-state';

import ConnectedUserPassword from './user-password/user-password';
import ConnectedUserInfo from './user-info/user-info';
import UserLocaleForm from './user-locale/user-locale';


import './account-page.scss';

const RESUBSCRIBE_TO_EMAILS_QUERY_PARAM = 'resubscribe_to_emails';

type Props = {
  unsetLocaleChangedFlag: () => void,
  updateLocale: (string) => Promise<void>,
  updateUser: ($Shape<User>) => Promise<void>,
  user: User,
  location: Location,
  history: RouterHistory,
  confirmEmail: (?string) => Promise<void>,
  addSuccessNotification: ({message: Node}) => void,
  addWarningNotification: ({message: Node}) => void,
  addDangerNotification: ({message: Node}) => void,
};

class AccountPage extends PureComponent<Props> {
  componentDidMount() {
    const { user, unsetLocaleChangedFlag } = this.props;

    if (user.isLocaleChanged) {
      this.displayLocaleChangeSuccessNotification();
      unsetLocaleChangedFlag();
    }

    this.processQueryParams();
  }

  render() {
    const { user } = this.props;

    return (
      <div className="account-settings">
        <PageTitle>
          <FormattedMessage id="accountSettings.title" defaultMessage="Settings" />
        </PageTitle>

        <Row>
          <Col md={5} lg={4}>
            <div className="account-settings-section">
              <ConnectedUserInfo />
            </div>
          </Col>

          <Col md={5} lg={4} smOffset={0} mdOffset={1}>
            <div className="account-settings-section">
              <ConnectedUserPassword />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={11} lg={9}>
            <div className="account-settings-delimiter" />
          </Col>
        </Row>

        <Row>
          <Col md={5} lg={4}>
            <div className="account-settings-section">
              <UserLocaleForm
                currentLocale={user.locale}
                supportedLocales={LOCALE_NAMES}
                onSaveLocale={this.onUpdateLocale}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  onUpdateLocale = (locale: string): Promise<void> => {
    const {
      user,
      updateLocale,
      addDangerNotification,
    } = this.props;

    if (locale === user.locale) {
      this.displayLocaleChangeSuccessNotification();

      return Promise.resolve();
    }

    return updateLocale(locale)
      .catch((error) => {
        addDangerNotification({
          message: error.message,
        });
      });
  };

  displayLocaleChangeSuccessNotification() {
    const { addSuccessNotification } = this.props;

    addSuccessNotification({
      message: (
        <FormattedMessage
          id="accountSettings.userLocales.successNotification"
          defaultMessage="Account language successfully updated."
        />
      ),
    });
  }

  processQueryParams() {
    const { location, history } = this.props;
    const query = queryString.parse(location.search);

    const emailConfirmationToken = query[EMAIL_CONFIRMATION_TOKEN];
    const reSubscribeToEmails = query[RESUBSCRIBE_TO_EMAILS_QUERY_PARAM];

    if (emailConfirmationToken && reSubscribeToEmails) {
      this.confirmEmailAndReSubscribeToEmails();
    } else if (emailConfirmationToken) {
      this.confirmEmail();
    } else if (reSubscribeToEmails) {
      this.reSubscribeToEmails();
    }

    if (EMAIL_CONFIRMATION_TOKEN in query || RESUBSCRIBE_TO_EMAILS_QUERY_PARAM in query) {
      removeQueryParams(history, EMAIL_CONFIRMATION_TOKEN, RESUBSCRIBE_TO_EMAILS_QUERY_PARAM);
    }
  }

  confirmEmail(): Promise<void> {
    const { location, confirmEmail, addSuccessNotification } = this.props;
    const query = queryString.parse(location.search);

    return confirmEmail(query[EMAIL_CONFIRMATION_TOKEN])
      .then(() => {
        addSuccessNotification({
          message: (
            <FormattedMessage
              id="permanentNotifications.emailConfirmation.success"
              defaultMessage="Email address successfully verified."
            />
          ),
        });
      })
      .catch((error) => this.onEmailConfirmationError(error));
  }

  onEmailConfirmationError(error: Object) {
    const { addWarningNotification, addDangerNotification } = this.props;
    const isTokenInvalid = error.status === httpStatuses.BAD_REQUEST && error.getFieldError('emailConfirmationToken');

    if (isTokenInvalid) {
      addWarningNotification({ message: error.getFieldError('emailConfirmationToken').toString() });
    } else {
      addDangerNotification({
        message: <DefaultError />,
      });
    }
  }

  reSubscribeToEmails(): Promise<void> {
    const {
      updateUser,
      addDangerNotification,
      addSuccessNotification,
    } = this.props;

    return updateUser({ marketingSubscribed: true })
      .then(() => {
        addSuccessNotification({
          message: (
            <FormattedMessage
              id="accountSettings.reSubscriptionToEmailsSuccess"
              defaultMessage="Email confirmed. Thanks for staying in touch!"
            />
          ),
        });
      })
      .catch(() => {
        addDangerNotification({
          message: <DefaultError />,
        });
      });
  }

  confirmEmailAndReSubscribeToEmails(): Promise<void> {
    const { confirmEmail, location } = this.props;
    const query = queryString.parse(location.search);

    return confirmEmail(query[EMAIL_CONFIRMATION_TOKEN])
      .catch(() => Promise.resolve()) // ignore email confirmation error and proceed to re-subscribing
      .then(() => this.reSubscribeToEmails());
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapActionsToProps = {
  updateLocale,
  unsetLocaleChangedFlag,
  confirmEmail,
  updateUser,
  addSuccessNotification,
  addWarningNotification,
  addDangerNotification,
};

export { AccountPage as PureAccountPage };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AccountPage);
