// @flow

import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import InContentNotification from 'components/shared/in-content-notification/in-content-notification';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import SubscriptionPrice from '../../subscription-info/price-row/subscription-price/subscription-price';


type Props = {|
  nextPricePlan: PricePlan,
  nextPaymentDate: number,
  nextPriceSum: number,
  tax: number,
  pricePerMemberPerMonth: {
    price: number,
    currency: string,
  },
|};

const PlanChangedNotification = ({
  nextPricePlan,
  nextPaymentDate,
  nextPriceSum,
  pricePerMemberPerMonth,
  tax,
}: Props) => (
  <InContentNotification type="success">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.planChanged"
      defaultMessage="You’re switching to {period} plan on {nextPaymentDate}. Your total will be {priceTotal} ({pricePerMember} per member/month)"
      values={{
        period: <PricePlanTitle priceKey={nextPricePlan.priceKey} />,
        nextPaymentDate: (
          <FormattedDate
            value={nextPaymentDate * 1000}
            year="numeric"
            month="short"
            day="numeric"
          />
        ),
        priceTotal: (
          <SubscriptionPrice
            price={nextPriceSum}
            currency={nextPricePlan.currency}
            paidMonth={nextPricePlan.paidMonth}
            tax={tax}
          />
        ),
        pricePerMember: (
          <FormattedPrice
            price={pricePerMemberPerMonth.price}
            currency={pricePerMemberPerMonth.currency}
          />
        ),
      }}
    />
  </InContentNotification>
);

export default PlanChangedNotification;
