import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

import classnames from 'classnames';
import { FormField, Button } from '@setapp/ui-kit';

import PasswordRequirementsWrapper from 'components/shared/password-requirements-wrapper/password-requirements-wrapper';
import { validators } from 'utils/auth-validation';
import externalUrls from 'config/external-urls';

import './signup-form.scss';

const messages = defineMessages({
  companyNameLabel: {
    id: 'outer.signup.companyNameLabel',
    defaultMessage: 'Company name',
  },
  companyNamePlaceholder: {
    id: 'outer.signup.companyNamePlaceholder',
    defaultMessage: 'Company name',
  },
  emailPlaceholder: {
    id: 'outer.signup.emailPlaceholder',
    defaultMessage: 'Business email',
  },
  passwordPlaceholder: {
    id: 'outer.signup.passwordPlaceholder',
    defaultMessage: 'Password',
  },
});

const TRIAL_LENGTH = 14;

class SignUpForm extends PureComponent {
  state = {
    isPasswordHintTooltipShown: false,
  }

  render() {
    const {
      onFieldChange,
      onFormSubmit,
      companyName,
      password,
      email,
      companyNameError,
      emailError,
      passwordError,
      termsAcceptedError,
      genericError,
      isRequestProcessing,
      isPasswordInvalid,
      intl,
      captcha,
    } = this.props;

    const {
      isPasswordHintTooltipShown,
    } = this.state;

    const errorClasses = classnames('form-error text-danger', {
      hidden: !genericError,
    });
    const btnClasses = classnames({
      processing: isRequestProcessing,
    });

    return (
      <form onSubmit={onFormSubmit} noValidate data-qa="signUpForm">
        <FormField
          id="company-name"
          type="text"
          name="companyName"
          value={companyName}
          autoComplete="company name"
          label={intl.formatMessage(messages.companyNameLabel)}
          placeholder={intl.formatMessage(messages.companyNamePlaceholder)}
          helpText={companyNameError}
          invalid={Boolean(companyNameError)}
          onChange={onFieldChange}
          autoFocus
          required
          qaLabel="companyFormGroup"
        />
        <FormField
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          label={<FormattedMessage id="outer.signup.emailLabel" defaultMessage="Email" />}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          helpText={emailError}
          invalid={Boolean(emailError)}
          onChange={onFieldChange}
          value={email}
          qaLabel="emailFormGroup"
        />
        <PasswordRequirementsWrapper
          isShown={isPasswordHintTooltipShown || isPasswordInvalid}
          isErrorsHighlighted={isPasswordInvalid}
          isMinimumLengthUsed={validators.passwordMatchMinLength(password)}
          isLowercaseUsed={validators.passwordContainsLower(password)}
          isUppercaseUsed={validators.passwordContainsUpper(password)}
        >
          <FormField
            id="password"
            name="password"
            type="password"
            label={<FormattedMessage id="outer.signup.passwordLabel" defaultMessage="Password" />}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            helpText={passwordError}
            invalid={Boolean(passwordError)}
            value={password}
            email={email}
            onChange={onFieldChange}
            qaLabel="passwordFormGroup"
            onFocus={this.showPasswordHintPopup}
            onBlur={this.hidePasswordHintPopup}
          />
        </PasswordRequirementsWrapper>
        {captcha}
        <FormGroup>
          <Checkbox name="marketingSubscribed" onChange={onFieldChange}>
            <span className="signup-form__note text_sm">
              <FormattedMessage
                id="outer.signup.subscribeCaption"
                defaultMessage="I’d like to get pro tips for Mac and iOS apps and news."
              />
            </span>
          </Checkbox>
        </FormGroup>
        <FormGroup validationState={termsAcceptedError ? 'error' : 'success'}>
          <Checkbox name="termsAccepted" onChange={onFieldChange}>
            <span className="signup-form__note text_sm">
              <FormattedMessage
                id="outer.signup.acceptPolicyCaption"
                defaultMessage="Yes, I accept the {termsOfService}, {privacyPolicy}, and {cookiePolicy}."
                values={{
                  termsOfService: (
                    <a href={externalUrls.termsOfUse} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage
                        id="outer.signup.termsOfUseLinkLabel"
                        defaultMessage="Terms&nbsp;of&nbsp;Service"
                      />
                    </a>
                  ),
                  privacyPolicy: (
                    <a href={externalUrls.privacy} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage
                        id="outer.signup.privacyPolicyLinkLabel"
                        defaultMessage="Privacy&nbsp;Policy"
                      />
                    </a>
                  ),
                  cookiePolicy: (
                    <a href={externalUrls.cookiePolicy} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage
                        id="outer.signup.cookiePolicyLinkLabel"
                        defaultMessage="Cookie&nbsp;Policy"
                      />
                    </a>
                  ),
                }}
              />
            </span>
          </Checkbox>
          {termsAcceptedError && <HelpBlock>{termsAcceptedError}</HelpBlock>}
        </FormGroup>
        <p className={errorClasses}>{genericError}</p>
        <Button
          className={btnClasses}
          type="submit"
          disabled={isRequestProcessing}
          block
        >
          <FormattedMessage
            id="outer.signup.submitBtnCaption"
            defaultMessage="Try Free for {trialPeriodLength, plural, one {{trialPeriodLength} day} other {{trialPeriodLength} days}}"
            values={{
              trialPeriodLength: TRIAL_LENGTH,
            }}
          />
        </Button>
      </form>
    );
  }

  // TODO[TK]: create a HOC for password validation UX
  showPasswordHintPopup = () => {
    this.setState({ isPasswordHintTooltipShown: true });
  }

  hidePasswordHintPopup = () => {
    this.setState({ isPasswordHintTooltipShown: false });
  }
}

SignUpForm.propTypes = {
  isRequestProcessing: PropTypes.bool,
  companyName: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  companyNameError: PropTypes.node,
  emailError: PropTypes.node,
  passwordError: PropTypes.node,
  termsAcceptedError: PropTypes.node,
  genericError: PropTypes.string,
  intl: intlShape.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

SignUpForm.defaultProps = {
  isRequestProcessing: false,
  companyName: '',
  email: '',
  password: '',
  companyNameError: null,
  emailError: null,
  passwordError: null,
  termsAcceptedError: null,
  genericError: '',
};

export { SignUpForm as PureSignUpForm };

export default injectIntl(SignUpForm);
