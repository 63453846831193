// @flow

import React from 'react';
import type { Node } from 'react';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { Tooltip } from '@setapp/ui-kit';
import globalMessages from '../../../global-messages';

import './info-button-with-tooltip.scss';

type Props = {
  children: Node,
  intl: IntlShape,
  trigger?: string,
  position?: string,
}

const InfoButtonWithTooltip = ({
  children,
  trigger,
  position,
  intl,
}: Props) => (
  <Tooltip
    trigger={trigger}
    position={position}
    content={children}
    distance="sm"
    useContext
  >
    <button
      type="button"
      className="btn info-button"
      aria-label={intl.formatMessage(globalMessages.btnTooltipAccessibilityLabel)}
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="m14 7c0-3.86599-3.134-7-7-7-3.86599 0-7 3.13401-7 7 0 3.866 3.13401 7 7 7 3.866 0 7-3.134 7-7zm-8.25-3.25c0-.69036.55964-1.25 1.25-1.25s1.25.55964 1.25 1.25-.55964 1.25-1.25 1.25-1.25-.55964-1.25-1.25zm1.25 2.75c-.55228 0-1 .44772-1 1v3c0 .5523.44772 1 1 1s1-.4477 1-1v-3c0-.55228-.44772-1-1-1z"
          fill="#e5e5e5"
          fillRule="evenodd"
        />
      </svg>
    </button>
  </Tooltip>
);

InfoButtonWithTooltip.defaultProps = {
  trigger: 'click',
  position: 'bottom',
};

export { InfoButtonWithTooltip as PureInfoButtonWithTooltip };

export default injectIntl(InfoButtonWithTooltip);
