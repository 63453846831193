import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import externalUrls from 'config/external-urls';

class ImmediatelyDownloadLink extends PureComponent {
  render() {
    const {
      customUrl,
      onDirectDownloadClick,
    } = this.props;

    return (
      <SetappDownloadLinkWrapper>
        <a
          className="link-outer"
          href={customUrl || externalUrls.downloadDesktopClient}
          title="Download Setapp client"
          onClick={onDirectDownloadClick}
        >
          <FormattedMessage
            id="successfulRegistration.downloadDesktopText"
            defaultMessage="here"
          />
        </a>
      </SetappDownloadLinkWrapper>
    );
  }
}

ImmediatelyDownloadLink.propTypes = {
  customUrl: PropTypes.string,
  onDirectDownloadClick: PropTypes.func,
};

ImmediatelyDownloadLink.defaultProps = {
  onDirectDownloadClick: () => {},
  customUrl: null,
};

export default ImmediatelyDownloadLink;
