import React, { PureComponent, ReactElement, MouseEvent } from 'react';
import Cookies from 'js-cookie';
import DesktopAppInstallerUrl from '@setapp/desktop-app-installer-url';
import { connect } from 'react-redux';

import { getUserCampaign } from 'state/root-reducer';

import Gtm from 'utils/analytics/gtm';

const SETAPP_TEST_USER_COOKIE_NAME = '_satu';

type Props = {
  children: ReactElement;
  onClick: (e: MouseEvent<HTMLLinkElement>) => void;
  campaign?: string;
};

class SetappDownloadLinkWrapper extends PureComponent<Props> {
  render() {
    const { children } = this.props;

    return React.cloneElement(React.Children.only(children), {
      onClick: this.onDownloadClick,
    });
  }

  onDownloadClick = (e: MouseEvent<HTMLLinkElement>) => {
    // this event will bubble from child element
    e.preventDefault();

    const { href } = e.currentTarget;
    const { children, onClick, campaign } = this.props;

    if (children.props.onClick) {
      children.props.onClick(e);
    }

    if (onClick) {
      onClick(e);
    }

    const downloadUrl = new DesktopAppInstallerUrl(href, {
      trackingCid: Gtm.getCID(),
      satuId: Cookies.get(SETAPP_TEST_USER_COOKIE_NAME),
      ...(campaign && { campaign }),
    }).toString();

    window.location.assign(downloadUrl);
  }
}

export { SetappDownloadLinkWrapper as PureSetappDownloadLinkWrapper };

const mapStateToProps = (state) => ({
  campaign: getUserCampaign(state),
});

export default connect(mapStateToProps)(SetappDownloadLinkWrapper);
