// @flow

import { type Invoice } from './invoices-initial-state';

export const REQUEST = 'INVOICES_REQUEST';
export const REQUEST_SUCCESS = 'INVOICES_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'INVOICES_REQUEST_ERROR';

type InvoicesRequestAction = {|
  type: 'INVOICES_REQUEST',
|};

type InvoicesRequestSuccessAction = {|
  type: 'INVOICES_REQUEST_SUCCESS',
  payload: Array<Invoice>,
|};

type InvoicesRequestErrorAction = {|
  type: 'INVOICES_REQUEST_ERROR',
  payload: mixed,
  error: true,
|};

export type InvoicesAction = InvoicesRequestAction | InvoicesRequestSuccessAction | InvoicesRequestErrorAction;
