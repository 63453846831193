// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import StepCounter from './step-counter/step-counter';


type Props = {|
  children: Node,
  withLogo?: boolean,
  withStepCounter?: boolean,
  currentStepNumber?: number,
  totalStepsNumber?: number,
|};

const FullscreenLayoutPrimaryContent = (props: Props) => {
  const {
    children,
    withLogo,
    withStepCounter,
    currentStepNumber,
    totalStepsNumber,
  } = props;
  const containerClasses = classNames('fullscreen-layout__primary-container', {
    'fullscreen-layout__primary-container_with-logo': withLogo,
    'fullscreen-layout__primary-container_with-step-counter': withStepCounter,
  });

  return (
    <div className="fullscreen-layout__primary-content">
      <div className={containerClasses} data-qa="fullscreenLayoutContainer">
        {
          withLogo && (
            <div className="fullscreen-layout__setapp-logo">
              <AnimatedLogo size="sm" hasCaption />
            </div>
          )
        }
        {
          withStepCounter && (
            <StepCounter current={currentStepNumber} total={totalStepsNumber} />
          )
        }
        {children}
      </div>
    </div>
  );
};

FullscreenLayoutPrimaryContent.defaultProps = {
  withLogo: false,
  withStepCounter: false,
  currentStepNumber: 0,
  totalStepsNumber: 0,
};

export default FullscreenLayoutPrimaryContent;
