import React from 'react';
import { FormattedMessage } from 'react-intl';
import macImage from './images/mac.png';
import macImage2x from './images/mac@2x.png';
import macImage3x from './images/mac@3x.png';

import './login-marketing-block.scss';

const LoginMarketingBlock = () => (
  <div className="login-marketing-block">
    <div className="login-marketing-block__content">
      <img className="login-marketing-block__mac-image" src={macImage} srcSet={`${macImage2x} 2x, ${macImage3x} 3x`} alt="" />
      <div>
        <FormattedMessage
          id="outer.login.marketingBlock.line1"
          defaultMessage="An app for every task at hand."
        />
      </div>
      <div>
        <FormattedMessage
          id="outer.login.marketingBlock.line2"
          defaultMessage="Search, install, enjoy."
        />
      </div>
    </div>
  </div>
);

export default LoginMarketingBlock;
