// @flow

import React from 'react';

import './sidebar-nav-header.scss';

type Props = {
  onCloseButtonClick: () => void,
  companyName: string,
};

const SidebarNavHeader = ({ onCloseButtonClick, companyName }: Props) => (
  <div className="sidebar-nav-header">
    <p className="sidebar-nav-header__company-name h6">
      {companyName}
    </p>
    <button
      type="button"
      className="sidebar-nav-header__close-navigation-button"
      onClick={onCloseButtonClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g fill="#26262b">
          <path
            d="m1.17157288 8h17.65685422c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2h-17.65685422c-1.1045695 0-2.00000001-.8954305-2.00000001-2s.89543051-2 2.00000001-2z"
            transform="matrix(.70710678 .70710678 -.70710678 .70710678 10 -4.142136)"
          />
          <path
            d="m1.17157288 8h17.65685422c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2h-17.65685422c-1.1045695 0-2.00000001-.8954305-2.00000001-2s.89543051-2 2.00000001-2z"
            transform="matrix(.70710678 -.70710678 .70710678 .70710678 -4.142136 10)"
          />
        </g>
      </svg>
    </button>
  </div>
);

export default SidebarNavHeader;
