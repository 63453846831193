// @flow

import React from 'react';
import { defineMessages, injectIntl, type IntlShape } from 'react-intl';

import welcomeImage from './images/welcome.svg';

type Props = {|
  intl: IntlShape,
|};

const messages = defineMessages({
  welcomeImageAlt: {
    id: 'activatePage.success.welcomeImageAlt',
    defaultMessage: 'A young lady friendly waving hello',
  },
});

const YoungFriendlyLady = (props: Props) => {
  const { intl } = props;

  return (
    <img src={welcomeImage} className="img-responsive" alt={intl.formatMessage(messages.welcomeImageAlt)} />
  );
};

export { YoungFriendlyLady as PureYoungFriendlyLady };

export default injectIntl(YoungFriendlyLady);
