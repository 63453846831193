// @flow
import React from 'react';

import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import type { PaymentDetails } from 'state/payment-method/payment-method-initial-state';
import * as paymentProviders from 'state/payment-method/payment-providers-types';

import CreditCardInfo from './card-info/card-info';
import PayPalInfo from './paypal-info/paypal-info';
import PaddlePaymentDetails from './paddle-info/paddle-info';


type Props = {|
  paymentMethod: PaymentDetails,
|};

const PaymentMethodDetails = ({ paymentMethod }: Props) => {
  if (paymentMethod.provider === paymentProviders.PADDLE) {
    return <PaddlePaymentDetails />;
  }

  if (paymentMethod.type === paymentMethodTypes.PAYPAL) {
    return <PayPalInfo {...paymentMethod.details} />;
  }

  if (paymentMethod.type === paymentMethodTypes.CREDIT_CARD) {
    return <CreditCardInfo {...paymentMethod.details} />;
  }

  return null;
};

export default PaymentMethodDetails;
