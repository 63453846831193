// @flow

// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { SatuCookieHelper } from '@setapp/abn-tests-client';

import { COOKIES_ROOT_DOMAIN } from 'config/app';
import auth from './utils/auth';
import AppRouter from './router';
import configureStore from './state/configure-store';
import type { Store } from './state/state-types';

SatuCookieHelper.init({ domain: COOKIES_ROOT_DOMAIN });

class App extends Component<{}> {
  store: Store;

  constructor(props) {
    super(props);

    this.store = configureStore();
    // for ability to dispatch LOGOUT on access token refreshing error
    auth.setStore(this.store);
  }

  render() {
    return (
      <Provider store={this.store}>
        <AppRouter />
      </Provider>
    );
  }
}

export default hot(App);
