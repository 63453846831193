// @flow

import React, { PureComponent, type ElementRef, type ElementConfig } from 'react';
import { Button } from '@setapp/ui-kit';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import InputGroupButton from 'react-bootstrap/lib/InputGroupButton';

import MinusIcon from './images/minus.svg';
import PlusIcon from './images/plus.svg';

import './number-field.scss';

type Props = {
  onValueChange: (number) => any,
  ...ElementConfig<typeof FormControl>,
};

class NumberField extends PureComponent <Props> {
  input: ElementRef<FormControl>;

  render() {
    // eslint-disable-next-line no-unused-vars
    const { onValueChange, ...inputProps } = this.props;

    return (
      <InputGroup>
        <InputGroupButton>
          <Button
            variant="secondary-outline"
            className="number-field__button"
            onClick={this.onMinusClick}
          >
            <img src={MinusIcon} alt="" width={20} height={20} />
          </Button>
        </InputGroupButton>
        <FormControl
          type="number"
          className="number-field__input"
          inputRef={this.setInputRef}
          onChange={this.onChange}
          {...inputProps}
        />
        <InputGroupButton>
          <Button
            variant="secondary-outline"
            className="number-field__button"
            onClick={this.onPlusClick}
          >
            <img src={PlusIcon} alt="" width={20} height={20} />
          </Button>
        </InputGroupButton>
      </InputGroup>
    );
  }

  setInputRef = (ref: ElementRef<FormControl>) => {
    this.input = ref;
  };

  onMinusClick = () => {
    this.input.stepDown();
    this.onChange();
  };

  onPlusClick = () => {
    this.input.stepUp();
    this.onChange();
  };

  onChange = () => {
    const { onValueChange } = this.props;

    onValueChange(this.input.value);
  };
}

export default NumberField;
