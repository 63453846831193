// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import external from 'config/external-urls';

import PaymentMethodPresentation from '../payment-method-presentation/payment-method-presentation';

import './paddle-info.scss';

const icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 23 30">
    <path fill="#E6A873" fillRule="evenodd" d="M2.483 11.669V9.264C2.483 4.148 6.5 0 11.455 0c4.954 0 8.97 4.148 8.97 9.264v2.405h.57c1.056 0 1.914.857 1.914 1.914v14.383c0 .508-.201.995-.56 1.354-.36.36-.847.561-1.355.561H1.914C.858 29.881 0 29.024 0 27.967V13.584c0-1.058.857-1.915 1.915-1.915h.568zM16.81 9.264c0-3.053-2.398-5.529-5.354-5.529C8.498 3.735 6.1 6.21 6.1 9.264v2.474h10.707V9.264z" />
  </svg>
);

const PaddlePaymentDetails = () => (
  <>
    <PaymentMethodPresentation
      details="**** **** **** ****"
      logo={icon}
    />
    <div className="paddle-info__verified-container">
      <span className="paddle-info__verified text_xs">
        <FormattedMessage
          id="paddleInfo.paddleVerified"
          defaultMessage="Encrypted and secured by {paddleLink}"
          values={{
            paddleLink: (
              <a
                href={external.paddle}
                target="_blank"
                rel="noopener noreferrer"
              >
                Paddle
              </a>
            ),
          }}
        />
      </span>
    </div>
  </>
);

export default PaddlePaymentDetails;
