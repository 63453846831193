import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getSupportUrl } from 'utils/support';

const SupportLink = ({ url, text, ...linkProps }) => (
  <a {...linkProps} href={url} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

SupportLink.propTypes = {
  url: PropTypes.string,
  text: PropTypes.node,
};

SupportLink.defaultProps = {
  url: getSupportUrl(),
  text: <FormattedMessage id="subscriptionInfo.helpLink" defaultMessage="Need help?" />,
};

export default SupportLink;
