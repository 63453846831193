// @flow

import React from 'react';

import type { CellProps, HeaderProps } from 'components/shared/table/table';
import Table from 'components/shared/table/table';

import type { MemberRole } from 'state/team-plan/team-member-roles';
import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

import MemberStatusTitle from './member-status-title/member-status-title';
import MemberRoleTitle from './member-role-title/member-role-title';
import MemberQuickAction from './member-quick-action/member-quick-action';
import ManageMemberDropdown from './manage-member-dropdown/manage-member-dropdown';
import AdditionalDevicesColumnHeader from './additional-devices-column/additional-devices-column';
import AdditionalSeatCount from './additional-seat-count/additional-seat-count';

type Props = {|
  members: Array<TeamMember>,
  showRemoveMemberModal: (TeamMember) => void,
  resendInvite: (TeamMember) => Promise<void>,
  revokeInvite: (TeamMember) => Promise<void>,
  addMemberRole: (TeamMember, MemberRole) => Promise<void>,
  removeMemberRole: (TeamMember) => Promise<void>,
  onAddSeatToMemberAction: (TeamMember) => any,
  onRemoveSeatFromMemberAction: (TeamMember) => any,
  isProcessing: boolean,
|};

const MembersList = ({
  members,
  resendInvite,
  revokeInvite,
  addMemberRole,
  removeMemberRole,
  showRemoveMemberModal,
  isProcessing,
  onAddSeatToMemberAction,
  onRemoveSeatFromMemberAction,
}: Props) => (
  <Table
    columns={[
      {
        Header: ({ data: members }: HeaderProps) => `Email (${members.length})`,
        accessor: 'email',
      },
      {
        Header: 'Member status',
        Cell: ({ row: { original: member } }: CellProps) => (
          <MemberStatusTitle
            status={member.status}
            invitationDate={member.invitationDate * 1000}
          />
        ),
      },
      {
        Header: () => <AdditionalDevicesColumnHeader members={members} />,
        Cell: ({ row: { original: member } }: CellProps) => (
          <AdditionalSeatCount additionalSeats={member.additionalSeats} />
        ),
        accessor: 'additionalDevicesCount',
      },
      {
        Header: 'Role',
        Cell: ({ row: { original: member } }: CellProps) => (
          <MemberRoleTitle role={member.role} />
        ),
      },
      {
        id: 'quickAction',
        Cell: ({ row: { original: member } }: CellProps) => (
          <MemberQuickAction
            isProcessing={isProcessing}
            member={member}
            onResendInviteClick={() => resendInvite(member)}
          />
        ),
      },
      {
        id: 'manageMember',
        Cell: ({ row: { original: member } }: CellProps) => (
          <ManageMemberDropdown
            member={member}
            onAddSeatToMemberAction={onAddSeatToMemberAction}
            showRemoveMemberModal={showRemoveMemberModal}
            onRemoveSeatFromMemberAction={onRemoveSeatFromMemberAction}
            revokeInvite={revokeInvite}
            addMemberRole={addMemberRole}
            removeMemberRole={removeMemberRole}
          />
        ),
      },
    ]}
    data={members}
    isSortable
  />
);

export default MembersList;
