// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: '/',

  // Unauthorized pages
  login: '/login',
  signup: '/signup',
  signupMember: '/signup/member',
  recoverPassword: '/recover-password',
  newPassword: '/new-password',

  // Authorized pages
  dashboard: '/dashboard',
  account: '/account',
  devices: '/devices',
  subscription: '/subscription',
  paymentDetails: '/payment-details',
  paymentHistory: '/payment-history',
  team: '/team',
  jamf: '/jamf',
  activateSubscription: '/activate',
  appsManagement: '/apps',

  successfulRegistration: '/successful-registration',
  // TODO: check if we still need it
  addPaymentMethod: '/add-payment-method',
};
