// @flow

import { useEffect, useState, type Node } from 'react';
import { withRouter, type RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { loginWithOneTimeToken, logout } from 'state/user/user-actions';
import { getUser } from 'state/root-reducer';
import { removeQueryParams } from 'utils/location';
import urls from 'config/urls';

type Props = {
  children: Node,
  isUserAuthenticated: boolean,
  loginWithOneTimeToken: (string) => Promise<mixed>,
  logout: () => Promise<mixed>,
  history: RouterHistory,
  // other route props: match and location
  ...
};

const CrossAuthGuard = (props: Props) => {
  const {
    children,
    history,
    isUserAuthenticated,
    loginWithOneTimeToken,
    logout,
  } = props;
  const { location, replace } = history;
  const query = queryString.parse(location.search);
  const { access_token: accessToken, ...nextLocationQuery } = query;

  const [isAuthenticating, setAuthenticating] = useState(Boolean(accessToken));

  useEffect(() => {
    if (accessToken) {
      removeQueryParams(history, 'access_token');

      loginWithOneTimeToken(accessToken)
        .then(() => {
          setAuthenticating(false);
        })
        .catch(() => {
          if (isUserAuthenticated) {
            logout();
          }
          setAuthenticating(false);

          const { email } = query;
          // if the original URL contained email in query params, preserve it to the login page to fill 'email' field
          const loginQueryParams = email ? { email } : {};

          replace({
            pathname: urls.login,
            query: loginQueryParams,
            state: {
              nextLocation: {
                ...location,
                query: nextLocationQuery,
              },
            },
          });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location, replace, isUserAuthenticated, logout, loginWithOneTimeToken, query]);

  return !isAuthenticating && children;
};

const mapStateToProps = (state) => ({
  isUserAuthenticated: getUser(state).isAuthenticated,
});

const mapActionsToProps = {
  loginWithOneTimeToken,
  logout,
};

export { CrossAuthGuard as PureCrossAuthGuard };

export default connect(mapStateToProps, mapActionsToProps)(withRouter(CrossAuthGuard));
