/* eslint-disable no-underscore-dangle */
import cookies from 'js-cookie';
import { apiURL } from 'config/api';
import {
  getAuthenticator,
  getMemberAuthenticator,
  getTokenManager,
  getSignupMetadata,
} from 'utils/service-locators';
import { LOGOUT } from 'state/user/user-actions-types';

import Gtm from './analytics/gtm';
import { detectLocale, browserToServerLocale } from './intl';
import $ from './request';
import signupCookies from './signup-cookies';

const tokenManager = getTokenManager();
const signUpMetaData = getSignupMetadata();

function saveAuthTokens(response, remember = true) {
  tokenManager.saveAccessToken(response.token);
  tokenManager.saveRefreshToken(response.refreshToken, remember);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  _store: null,

  setStore(store) {
    this._store = store;
  },

  isLoggedIn() {
    return !!tokenManager.getRefreshToken();
  },

  loginWithOneTimeToken(accessToken) {
    return $.post(apiURL.loginCrossAuthToken, {
      withoutTokenAuth: true,
      body: this._addParamsFromCookies({ accessToken }),
    })
      .then((resp) => {
        saveAuthTokens(resp);

        return resp;
      });
  },

  login({
    email, password, remember = true, captcha,
  }) {
    const requestOptions = {
      withoutTokenAuth: true,
      body: this._addParamsFromCookies({
        email,
        password,
        remember: Boolean(remember),
        captcha,
      }),
    };

    return $.post(apiURL.auth, requestOptions)
      .then((resp) => {
        saveAuthTokens(resp, remember);

        return resp;
      });
  },

  logout() {
    return $.post(apiURL.logout)
      .then(() => tokenManager.removeTokens());
  },

  _getTestSegments() {
    return [];
  },

  signup(data) {
    const signUpData = {
      ...data,
      testSegments: this._getTestSegments(),
    };

    // TODO: don't use Authenticator
    return getAuthenticator()
      .signUp(signUpData, {
        language: browserToServerLocale(detectLocale()),
      });
  },

  signupMember(data) {
    const signUpData = {
      ...data,
      testSegments: this._getTestSegments(),
    };

    // TODO: don't use Authenticator
    return getMemberAuthenticator()
      .signUp(signUpData, {
        language: browserToServerLocale(detectLocale()),
      });
  },

  requestPasswordReset(payload) {
    const { email, captcha } = payload;

    return $.post(apiURL.requestPasswordReset, { body: { email, captcha } });
  },

  resetPassword(password, token) {
    return $.patch(`${apiURL.restorePass}/${token}`, { body: { password } })
      .then((response) => {
        saveAuthTokens(response);

        return response;
      });
  },

  requestNewAccessToken() {
    const refreshToken = tokenManager.getRefreshToken(),
      requestOptions = {
        withoutTokenAuth: true,
        body: { refreshToken },
      };

    if (!refreshToken) {
      if (this._store) {
        this._store.dispatch({ type: LOGOUT });
      }

      return Promise.reject();
    }

    return $.post(apiURL.token, requestOptions)
      .then(
        (response) => tokenManager.saveAccessToken(response.token),
        (error) => {
          tokenManager.removeTokens();

          if (this._store) {
            this._store.dispatch({ type: LOGOUT });
          }

          return Promise.reject(error);
        },
      );
  },

  requestCrossAuthToken() {
    return $.post(apiURL.crossAuthToken);
  },

  validateInvitationToken(token = '') {
    return $.get(`${apiURL.invitation}/${token}`);
  },

  validateRestorePassToken(token = '') {
    return $.get(`${apiURL.restorePass}/${token}`);
  },

  /**
   * @param {object} response
   * @returns {object}
   * @private
   */
  _onSignUpSuccess(response) {
    const { registrationFlow } = signUpMetaData.getAll();
    const authData = { ...response, registrationFlow };

    saveAuthTokens(response, true);
    signUpMetaData.cleanAll();
    signupCookies.cleanAll();

    return authData;
  },

  _addParamsFromCookies(body) {
    const params = {};
    const satuParam = cookies.get('_satu'); // todo: create package to work with satu
    const gaParam = cookies.get(Gtm.GA_COOKIE_NAME);

    if (satuParam) {
      params.satu = satuParam;
    }

    if (gaParam) {
      params.ga = gaParam;
    }

    return {
      ...body,
      ...params,
    };
  },
};
