import { ReactNode } from 'react';

import isEmail from 'validator/lib/isEmail';
import isAlpha from 'validator/lib/isAlpha';

const required = (value: string): boolean => !!value;

const passwordMatchMinLength = (value:string): boolean => value.length >= 8;

const emailFormat = (value:string): boolean => isEmail(`${value}`) || !value;

const isPasswordsMatch = ([oldPass, newPass]: Array<string>): boolean => oldPass === newPass;

const passwordContainsUpper = (value: string): boolean => value.split('').some((char) => isAlpha(char) && char === char.toUpperCase());

const passwordContainsLower = (value: string): boolean => value.split('').some((char) => isAlpha(char) && char === char.toLowerCase());

const passwordContainsBothLowerAndUpper = (value: string): boolean => (
  passwordContainsUpper(value) && passwordContainsLower(value)
);

const passwordFormat = (value: string): boolean => (
  (passwordMatchMinLength(value) && passwordContainsBothLowerAndUpper(value)) || !value
);

export const validators = {
  required,
  passwordMatchMinLength,
  passwordFormat,
  emailFormat,
  isPasswordsMatch,
  passwordContainsUpper,
  passwordContainsLower,
  passwordContainsBothLowerAndUpper,
};

type Rules = {
  required?: ReactNode;
  passwordMatchMinLength?: ReactNode;
  passwordFormat?: ReactNode;
  emailFormat?: ReactNode;
  isPasswordsMatch?: ReactNode;
  passwordContainsUpper?: ReactNode;
  passwordContainsLower?: ReactNode;
  passwordContainsBothLowerAndUpper?: ReactNode;
};

export default function validate<Fields extends Record<string, unknown>>(
  fields: Fields,
  rules: { [Property in keyof Fields]: Rules },
): { [Property in keyof Fields]?: string } {
  return Object.keys(fields)
    .reduce((errors, fieldName) => ({
      ...errors,
      [fieldName]: Object.keys(rules[fieldName] || [])
        .reduce((error, rule) => {
          const validatorFunc = validators[rule];
          const fieldValue = rules[fieldName]?.[rule].fields
            ? rules[fieldName]?.[rule].fields.map((field) => fields[field])
            : fields[fieldName];
          const errorMessage = rules[fieldName]?.[rule].message || rules[fieldName]?.[rule];

          return validatorFunc(fieldValue) ? error : errorMessage;
        }, ''),
    }), {});
}
