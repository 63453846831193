// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceIcon } from '@setapp/ui-kit';

import PoolInfo from '../pool-info';

type Props = {
  count: number,
  onAddButtonClick?: ?() => mixed,
};

const DevicesPoolInfo = ({ count, onAddButtonClick }: Props) => (
  <PoolInfo
    tooltip={(
      <FormattedMessage
        id="devicesPoolInfo.tooltip"
        defaultMessage="An unused device is an extra device that you haven't assigned to anyone in a team."
      />
    )}
    title={(
      <FormattedMessage
        id="devicesPoolInfo.title"
        defaultMessage="Unused devices: {devicesInPool}"
        values={{
          devicesInPool: <strong>{count}</strong>,
        }}
      />
    )}
    icon={<DeviceIcon device="macbook" size={24} />}
    qaLabel="devicePool"
    onAddButtonClick={onAddButtonClick}
  />
);

DevicesPoolInfo.defaultProps = {
  onAddButtonClick: null,
};

export default DevicesPoolInfo;
