// @flow

import { combineReducers } from 'redux';

import { ADDITIONAL_SEAT_MONTHLY_TEAM } from 'state/price-plans/price-plans-keys';
import { ADD_SUBSCRIPTION } from 'state/subscription/subscription-action-types';
import type { TeamState, TeamMember, TeamInfoState } from 'state/team-plan/team-plan-initial-state';

import { type Action } from '../state-types';

import * as actionTypes from './team-plan-action-types';
import initialState from './team-plan-initial-state';
import membersReducer from './members/team-members-reducer';
import membersLoadingReducer from './members-loading/members-loading-reducer';
import * as MemberStatuses from './team-member-statuses';

const team = (state: TeamInfoState = initialState, action: Action): TeamInfoState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      // eslint-disable-next-line no-unused-vars
      const { members, ...teamInfo } = action.payload;

      return { ...teamInfo, isLoading: false };
    }

    case actionTypes.ADD_MEMBER: {
      return {
        ...state,
        seatsPoolCount: Math.max(state.seatsPoolCount - 1, 0),
      };
    }

    case actionTypes.INVITE_MEMBERS: {
      return {
        ...state,
        isAnyInvitationPending: true,
      };
    }

    case actionTypes.PURCHASE_INVITATIONS: {
      return {
        ...state,
        isAnyInvitationPurchasePending: true,
      };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }

    case actionTypes.ADD_DEVICE_TO_MEMBER: {
      return {
        ...state,
        additionalDevicesPoolCount: Math.max(state.additionalDevicesPoolCount - 1, 0),
      };
    }

    case ADD_SUBSCRIPTION: {
      if (action.payload.pricePlan.priceKey !== ADDITIONAL_SEAT_MONTHLY_TEAM) {
        return state;
      }

      // Sync additionalDevicesPoolCount after ordering additional seat subscription
      return {
        ...state,
        additionalDevicesPoolCount: state.additionalDevicesPoolCount + action.payload.pricePlan.seatsCount,
      };
    }

    case actionTypes.REMOVE_DEVICE_FROM_MEMBER: {
      return {
        ...state,
        additionalDevicesPoolCount: state.additionalDevicesPoolCount + 1,
      };
    }

    default: {
      return state;
    }
  }
};

const teamPlanReducers = {
  info: team,
  members: membersReducer,
  membersLoading: membersLoadingReducer,
};

type TeamPlanReducers = typeof teamPlanReducers;

export default combineReducers<TeamPlanReducers, Action>(teamPlanReducers);

export const getTeamInfo = (state: TeamState): TeamInfoState => state.info;

export const isFetched = (state: TeamInfoState): boolean => state.id != null;

export const getMembers = (state: TeamState): Array<TeamMember> => state.members;

export const getPlainMembers = (state: TeamState): Array<TeamMember> => state.members
  .filter((member) => member.role === null && member.status === MemberStatuses.ACTIVE);

export const getActiveMembers = (state: TeamState): Array<TeamMember> => getMembers(state)
  .filter(({ status }) => (
    status === MemberStatuses.ACTIVE
    || status === MemberStatuses.PENDING
    || status === MemberStatuses.PAYMENT_PENDING));

export const isMemberLoading = (state: TeamState, memberId: number): boolean => state.membersLoading.includes(memberId);

export const isAnyInvitationPending = (state: TeamInfoState): boolean => state.isAnyInvitationPending;
export const isAnyInvitationPurchasePending = (state: TeamInfoState): boolean => state.isAnyInvitationPurchasePending;
