// @flow

export type ApplicationExclusivity = 'team';

export type Application = {
  +id: number,
  +name: string,
  +shortDescription: string,
  +icon: string,
  +isRestricted: boolean,
  +exclusive: ?ApplicationExclusivity,
};

export type ApplicationsState = {
  +list: Array<Application>,
  +isLoading: boolean,
}

const initialState: ApplicationsState = {
  list: [],
  isLoading: false,
};

export default initialState;
