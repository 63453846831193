// @flow

import React, { PureComponent, type Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { revokePersonalKey } from 'state/jamf/jamf-actions';
import { addDangerNotification, addSuccessNotification } from 'state/notifier/notifier-actions';
import { getJamf } from 'state/root-reducer';

import DefaultError from 'components/shared/default-error/default-error';
import Modal from 'components/shared/modal/modal';

import './revoke-jamf-key.scss';

type Props = {
  show: boolean,
  onHide: () => any,
  onExited: () => any,
  revokePersonalKey: () => Promise<any>,
  addSuccessNotification: ({message: Node}) => mixed,
  addDangerNotification: ({message: Node}) => mixed,
  isJamfProcessing: boolean,
};

class RevokeJamfKey extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      onExited,
      isJamfProcessing,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        withCancelButton
        title={<FormattedMessage id="revokeJamfKeyModal.title" defaultMessage="Are you sure you want to reissue key?" />}
      >
        <p className="revoke-jamf-key__description">
          <FormattedMessage
            id="revokeJamfKeyModal.description"
            defaultMessage="New members will not be able to sign up with the previous key they received."
          />
        </p>
        <Button
          variant="danger-outline"
          block
          disabled={isJamfProcessing}
          onClick={this.onRevokeClick}
        >
          <FormattedMessage
            id="revokeJamfKeyModal.confirmButtonTitle"
            defaultMessage="Reissue key"
          />
        </Button>
      </Modal>
    );
  }

  onRevokeClick = async () => {
    const {
      revokePersonalKey,
      onHide,
      addSuccessNotification,
      addDangerNotification,
    } = this.props;

    try {
      await revokePersonalKey();

      addSuccessNotification({
        message: (
          <FormattedMessage
            id="revokeJamfKeyModal.successNotification"
            defaultMessage="Key successfully reissued"
          />
        ),
      });
    } catch (error) {
      addDangerNotification({
        message: <DefaultError />,
      });
    }

    onHide();
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isJamfProcessing: getJamf(state).isLoading,
});

const mapActionsToProps = {
  revokePersonalKey,
  addSuccessNotification,
  addDangerNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(RevokeJamfKey);

export { RevokeJamfKey as PureRevokeJamfKey };
