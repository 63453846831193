// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeviceIcon } from '@setapp/ui-kit';

import { FormattedPrice, FormattedPeriod } from 'components/shared/formatter/formatter';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import './add-seat-with-purchase.scss';

type Props = {
  memberEmail: string,
  onConfirm: () => any,
  isSubmitDisabled: boolean,
  seatPricePlan: PricePlan,
}

const AddSeatWithPurchase = (props: Props) => {
  const {
    memberEmail,
    onConfirm,
    isSubmitDisabled,
    seatPricePlan,
  } = props;

  return (
    <>
      <div className="add-seat-from-pool">
        <div className="add-seat-from-pool__icon">
          <DeviceIcon device="macbook" size={80} />
        </div>
        <div className="add-seat-from-pool__content">
          <p>
            <FormattedMessage
              id="addSeatModal.withPurchase.seatInfo"
              defaultMessage="You are about to add another device for {email} at {pricePerPeriod}."
              values={{
                email: <strong>{memberEmail}</strong>,
                pricePerPeriod: (
                  <strong>
                    <FormattedMessage
                      id="addSeatModal.withPurchase.seatPricePerPeriod"
                      defaultMessage="{price}/{period}"
                      values={{
                        price: (
                          <FormattedPrice
                            price={seatPricePlan.price}
                            currency={seatPricePlan.currency}
                          />
                        ),
                        period: <FormattedPeriod paidMonth={seatPricePlan.paidMonth} />,
                      }}
                    />
                  </strong>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="addSeatModal.withPurchase.billingCycleNote"
              defaultMessage="Right now, you’ll only be charged a partial fee for the remaining part of this month."
            />
          </p>
        </div>
      </div>
      <hr />
      <div className="add-seat-from-pool__vat-note">
        <FormattedMessage
          id="addSeatModal.withPurchase.taxNote"
          defaultMessage="Sales taxes may apply, depending on your country of residence."
        />
      </div>
      <Button
        block
        onClick={onConfirm}
        disabled={isSubmitDisabled}
        className="add-seat-from-pool__submit"
      >
        <FormattedMessage
          id="addSeatModal.withPurchase.confirmButtonTitle"
          defaultMessage="Add device & purchase"
        />
      </Button>
    </>
  );
};

export default AddSeatWithPurchase;
