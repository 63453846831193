// @flow

import React, { PureComponent } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Button } from '@setapp/ui-kit';

import type { Node } from 'react';
import type { IntlShape } from 'react-intl';

import TagsInput from 'components/shared/tags-input/tags-input';

const messages = defineMessages({
  emailPlaceholder: {
    id: 'inviteMembersForm.email.placeholder',
    defaultMessage: 'Enter email addresses of members you’re inviting',
  },
  emailLabel: {
    id: 'inviteMembersForm.email.label',
    defaultMessage: 'Email addresses',
  },
});

type Props = {
  onCustomFieldChange: (name: string, value: mixed) => mixed,
  onSubmit: (SyntheticEvent<HTMLFormElement>) => any,
  emails: string[],
  emailError: Node,
  formError: Node,
  disabled: boolean,
  description: Node,
  invalidEmails: string[],
  submitTitle: Node,
  intl: IntlShape,
};

class InviteMembersForm extends PureComponent<Props> {
  emailSeparators: string[] = [',', ';', ' ', '\n', '\t'];

  render() {
    const {
      onCustomFieldChange,
      onSubmit,
      emails,
      emailError,
      formError,
      disabled,
      description,
      submitTitle,
      invalidEmails,
    } = this.props;

    const { intl } = this.props;

    const errorClasses = classnames('form-error text-danger', {
      hidden: !formError,
    });

    return (
      <form
        onSubmit={onSubmit}
        noValidate
      >
        <div className="invite-member-modal__description">
          {description}
        </div>
        <TagsInput
          value={emails}
          invalid={Boolean(emailError)}
          helpText={emailError}
          tokenSeparators={this.emailSeparators}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          label={intl.formatMessage(messages.emailLabel)}
          onChange={(value) => onCustomFieldChange('emails', value)}
          invalidValues={invalidEmails}
          showLabel
          qaLabel="emailsInput"
        />
        <p className={errorClasses}>{formError}</p>
        <Button
          disabled={disabled}
          type="submit"
          block
        >
          {submitTitle}
        </Button>
      </form>
    );
  }
}

export { InviteMembersForm as PureInviteMembersForm };

export default injectIntl(InviteMembersForm);
