// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import externalUrls from 'config/external-urls';

import PageTitle from 'components/shared/page-title/page-title';
import PageDescription from 'components/shared/page-description/page-description';

import './jamf-header.scss';

import jamfIcon from './images/jamf.svg';

const JamfHeader = () => (
  <>
    <div className="jamf-header__title">
      <PageTitle>
        <FormattedMessage id="jamfHeader.title" defaultMessage="Jamf distribution" />
      </PageTitle>
      <a
        className="jamf-header__icon"
        href={externalUrls.jamf}
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Link to Jamf home page"
      >
        <img src={jamfIcon} alt="" height="38" width="38" />
      </a>
    </div>
    <PageDescription>
      <FormattedMessage
        id="jamfHeader.subtitle"
        defaultMessage="Jamf is an industry-standard solution for Apple device management. Use it to distribute Setapp among your team members."
      />
    </PageDescription>
  </>
);

export default JamfHeader;
