// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceIconUnused } from '@setapp/ui-kit';

const EmptySeatItem = () => (
  <div className="devices-list-item-row">
    <div className="devices-list-item-col devices-list-item-col-description">
      <strong className="text_lg" data-qa="emptySlot">
        <FormattedMessage id="deviceInfo.emptySlotTitle" defaultMessage="New device" />
      </strong>
      <p className="devices-list-item-note text_md">
        <FormattedMessage
          id="deviceInfo.emptySlotDescription"
          defaultMessage="Open Setapp desktop app on a new device and sign into your account."
        />
      </p>
    </div>
    <div className="devices-list-item-col devices-list-item-col-icon">
      <DeviceIconUnused />
    </div>
  </div>
);

export default EmptySeatItem;
