// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { TeamMember } from 'state/team-plan/team-plan-initial-state';

type HeaderProps = {
  members: Array<TeamMember>,
};

const AdditionalDevicesColumnHeader = ({ members }: HeaderProps) => {
  const numberOfUsedExtraDevices = members.reduce((acc, member) => acc + (member.additionalDevicesCount || 0), 0);

  return (
    <FormattedMessage
      id="teamManagement.devicesColumn.header"
      defaultMessage="Extra devices ({numberOfUsedExtraDevices})"
      values={{ numberOfUsedExtraDevices }}
    />
  );
};

export default AdditionalDevicesColumnHeader;
