// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Location, RouterHistory, Match } from 'react-router-dom';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

import FormContainer from 'components/shared/form/form-container/form-container';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import OuterPageMessage from 'components/shared/outer-page-message/outer-page-message';

import { resetPassword } from 'state/user/user-actions';

import urls from 'config/urls';

import auth from 'utils/auth';
import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';
import { EMAIL_CONFIRMATION_TOKEN } from 'utils/location';

import NewPassForm from './new-password-form/new-password-form';


type Props = {
  resetPassword: (string, string) => Promise<void>,
  match: Match,
  location: Location,
  history: RouterHistory,
};

type State = {
  isRestoreTokenValid: boolean,
  isRestoreCheckingProcessing: boolean,
};

class NewPassFormContainer extends Component<Props, State> {
  state = {
    isRestoreTokenValid: true,
    isRestoreCheckingProcessing: true,
  };

  componentDidMount() {
    this.validateRestorePassToken();
  }

  render() {
    const { isRestoreCheckingProcessing, isRestoreTokenValid } = this.state;

    if (isRestoreCheckingProcessing) {
      return (
        <div style={{ margin: 'auto' }}>
          <AnimatedLogo animate />
        </div>
      );
    }

    if (isRestoreTokenValid) {
      return (
        <FormContainer initialValues={{ password: '' }} validate={this.validateForm} onSubmit={this.onFormSubmit}>
          {
            ({
              fields,
              fieldsErrors,
              formError,
              isProcessing,
              onFieldChange,
              onSubmit,
            }) => (
              <NewPassForm
                fields={fields}
                fieldsErrors={fieldsErrors}
                formError={formError}
                isProcessing={isProcessing}
                onFieldChange={onFieldChange}
                onSubmit={onSubmit}
              />
            )
          }
        </FormContainer>
      );
    }

    return (
      <OuterPageMessage title={(
        <FormattedMessage
          id="outer.newPassword.invalidInvitationToken"
          defaultMessage="Invalid reset password token"
        />)}
      />
    );
  }

  validateRestorePassToken() {
    this.setState({ isRestoreCheckingProcessing: true });

    return auth.validateRestorePassToken(this.getToken())
      .then(() => this.setState({ isRestoreTokenValid: true, isRestoreCheckingProcessing: false }))
      .catch(() => this.setState({ isRestoreTokenValid: false, isRestoreCheckingProcessing: false }));
  }

  onFormSubmit = (fields: {password: string}) => {
    const { password } = fields;

    return this.resetPassword(password);
  };

  resetPassword(password: string) {
    const {
      resetPassword,
      history,
      location,
    } = this.props;
    const token = this.getToken();
    const query = queryString.parse(location.search);
    const emailConfirmationToken = query?.[EMAIL_CONFIRMATION_TOKEN];

    if (!token) {
      throw new Error('Cannot reset password: token cannot be empty');
    }

    return resetPassword(password, token)
      .then(() => {
        analytics.trackEvent(events.NEW_PASSWORD_SET);

        const searchParams = emailConfirmationToken && queryString.stringify(
          { [EMAIL_CONFIRMATION_TOKEN]: emailConfirmationToken }
        );

        history.push({
          pathname: urls.dashboard,
          search: searchParams,
        });
      });
  }

  getToken() {
    const { match, location } = this.props;
    const query = queryString.parse(location.search);

    return query.token || match.params.token;
  }

  validateForm(fields: {password: string}) {
    return validate(fields, {
      password: {
        passwordFormat: (
          <FormattedMessage
            id="outer.newPassword.validation.invalidPassword"
            defaultMessage="At least 1 uppercase and lowercase letter. 8 characters minimum."
          />
        ),
        required: (
          <FormattedMessage
            id="outer.newPassword.validation.emptyPassword"
            defaultMessage="A new password is required"
          />
        ),
      },
    });
  }
}

export { NewPassFormContainer };

const mapActionsToProps = { resetPassword };

export default connect(null, mapActionsToProps)(NewPassFormContainer);
