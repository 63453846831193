// @flow

import React, { type Node } from 'react';

import classNames from 'classnames';

import PanelBoxContent from './panel-box-content/panel-box-content';

import './panel-box.scss';

type Props = {
  children: Node,
  fullHeight?: boolean,
  className?: string,
  dark?: boolean,
};

const PanelBox = ({
  children,
  fullHeight,
  dark,
  className,
}: Props) => {
  const boxClasses = classNames('panel-box', className, {
    'panel-box_full-height': fullHeight,
    'panel-box_dark': dark,
  });

  return (
    <div className={boxClasses}>
      {children}
    </div>
  );
};

PanelBox.defaultProps = {
  fullHeight: false,
  dark: false,
  className: undefined,
};

PanelBox.Content = PanelBoxContent;

export default PanelBox;
